import React from 'react';
import notfound from '../assets/notfound.png';

const NotFound = () => {
	return (
		<div id='notfound'>
			<div className='img-notfound'>
				<img src={notfound} alt='not_found' />
				<h1>Page non trouvée</h1>
			</div>
		</div>
	);
};

export default NotFound;
