import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { icons } from '../../../assets/icons';
import './index.scss';
import useOnclickOutside from 'react-cool-onclickoutside';
import AddAdmin from './addAdmin';
import UpdateAdmin from './UpdateAdmin';
import { adminBlocked, usersList } from '../../../api/redux/slices/SuperAdmin';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { LoaderComponent } from '../../../helpers/components/loader';
import Pagination from '../../../helpers/components/Pagination';
import { showToast } from '../../../components/modal';

const AdminList = () => {
	const { list, loading, total } = useSelector((state) => state.superAdmin);
	const dispatch = useDispatch();
	const params = useParams();

	const [modalOpen, setModalOpen] = useState(false);
	const [updateModal, setUpdateModal] = useState(false);
	const [openDropdown, setOpenDropdown] = useState(false);
	const [indice, setIndice] = useState(null);
	const [page, setPage] = useState(Number(params.page));
	const [adminName, setAdminName] = useState('');
	const [typing, setTyping] = useState(false);

	const ref = useOnclickOutside(() => {
		setOpenDropdown(false);
	});
	const token = localStorage.getItem('accesstoken');

	const stableDispatch = useCallback(() => {
		dispatch(usersList({ token: token, page: adminName ? undefined : page, search: adminName }));
	}, [dispatch, page, token, adminName]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [modalOpen, stableDispatch, adminName, token]);

	const handleBlock = (e, id) => {
		e.preventDefault();
		const data = {
			is_active: false,
		};
		dispatch(adminBlocked({ id: id, token: token, data: data }))
			.unwrap()
			.then(() => {
				setOpenDropdown(false);
				showToast('Utilsateur bloqué', 'success', 'bottom-right');
				dispatch(usersList({ page: page, token: token }));
			})
			.catch((err) => {
				showToast("Une erreur s'est produite", 'error', 'bottom-right');
			});
	};
	const handleUnblock = (e, id) => {
		e.preventDefault();
		const data = {
			is_active: true,
		};
		dispatch(adminBlocked({ token: token, id: id, data: data }))
			.unwrap()
			.then(() => {
				setOpenDropdown(false);
				showToast('Utilsateur debloqué', 'success', 'bottom-right');
				dispatch(usersList({ page: page, token: token }));
			})
			.catch((err) => showToast("Une erreur s'est produite", 'error', 'bottom-right'));
	};

	return (
		<div id='admin-list'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='text'
							placeholder='Rechercher par nom...'
							value={adminName}
							onChange={(e) => {
								setAdminName(e.target.value);
								setTyping(true);
							}}
						/>
						<icons.FiSearch className='searchicon' />
						{typing && (
							<icons.GrFormClose
								className='searchicon'
								onClick={(e) => {
									setAdminName('');
									setTyping(false);
								}}
							/>
						)}
					</div>
				</div>
				<div className='right'>
					<div className='right-items'>
						<button className='button-primary' onClick={() => setModalOpen(!modalOpen)}>
							<icons.SiAddthis />
							<span>Administrateur</span>
						</button>
					</div>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Liste administrateurs</h1>
				</div>
			</div>
			<Fragment>
				<AddAdmin modalOpen={modalOpen} setModalOpen={setModalOpen} page={page} />
			</Fragment>
			<Fragment>
				{updateModal && (
					<UpdateAdmin
						updateModal={updateModal}
						setUpdateModal={setUpdateModal}
						indice={indice}
						page={page}
						stableDispatch={stableDispatch}
					/>
				)}
			</Fragment>
			<div className='mainContent'>
				<table className='content'>
					<thead>
						<tr className='titres'>
							<td>ADMINISTRATEUR</td>
							<td>EMAIL</td>
							<td>N° DE TÉLÉPHONE</td>
							<td>STATUT</td>
							<td>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{loading ? (
							<tr>
								<td colSpan={7}>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							list.map((element) => (
								<tr className='element' key={element._id}>
									<td style={{ textTransform: 'capitalize' }}>
										{element.first_name} {element.last_name}
									</td>
									<td>{element.email}</td>
									<td>{element.phone}</td>
									<td
										className={element.is_active === true ? 'status' : 'status activé'}
										style={{ textTransform: 'capitalize' }}
									>
										{element.is_active === true ? 'Activé' : 'Bloqué'}
									</td>
									<td className='last-element'>
										<div className='contain'>
											<icons.BsThreeDotsVertical
												onClick={() => {
													setOpenDropdown(!openDropdown);
													setIndice(element._id);
												}}
												className='option'
											/>
											{openDropdown && indice === element._id && (
												<div className='modalSelectOpt' ref={ref}>
													<ul>
														{element.is_active ? (
															<li onClick={(e) => handleBlock(e, element._id)}>
																Bloqué administrateur
															</li>
														) : (
															<li onClick={(e) => handleUnblock(e, element._id)}>
																Debloqué administrateur
															</li>
														)}

														<li onClick={() => setUpdateModal(!updateModal)}>
															Modifier administrateur
														</li>
													</ul>
												</div>
											)}
										</div>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink={`/superadmin/users/`} total={total} />
		</div>
	);
};

export default AdminList;
