import React, { useEffect, useState } from 'react';
import Container from '../container';
const ResetPassword = () => {
	const [validPassword, setValidPassword] = useState(false);
	const [newPassword, setNewPassword] = useState({
		password: '',
		c_password: '',
	});
	useEffect(() => {
		if (
			newPassword?.password &&
			newPassword?.c_password &&
			newPassword?.password === newPassword?.c_password &&
			newPassword?.password.length > 6
		) {
			setValidPassword(true);
		} else {
			setValidPassword(false);
		}
	}, [newPassword]);
	const ConfirmRest = () => {
		if (validPassword) {
		}
	};
	return (
		<Container>
			<label htmlFor=''>Nouveau mode de passe</label>
			<input
				type='password'
				value={newPassword?.password}
				onChange={(value) => {
					setNewPassword((prev) => ({ ...prev, password: value.target.value }));
				}}
				placeholder='Mot de passe'
				className='authInput'
			/>
			<label htmlFor=''>Répété le nouveau mot de passe</label>
			<input
				type='password'
				value={newPassword?.c_password}
				onChange={(value) => {
					setNewPassword((prev) => ({ ...prev, c_password: value.target.value }));
				}}
				placeholder='Confirmer mot de passe'
				className='authInput'
			/>
			<input type='button' value='Confirmer' onClick={ConfirmRest} className='btn' />
		</Container>
	);
};

export default ResetPassword;
