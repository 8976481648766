import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getProductDetails } from '../../../api/redux/slices/Admin';
import { icons } from '../../../assets/icons';
import { LoaderComponent } from '../../../helpers/components/loader';
import './index.scss';

const ProductDetails = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const token = localStorage.getItem('accesstoken');
	const [detailProduct, setDetailProduct] = useState({});
	const [loading, setLoading] = useState(false);

	// -------------------------- get products list and filtering ----------------------------//
	const stableDispatch = useCallback(() => {
		setLoading(true);
		dispatch(
			getProductDetails({
				token: token,
				id: params.id,
			}),
		)
			.unwrap()
			.then((res) => setDetailProduct(res.data))
			.finally(() => setLoading(false));
	}, [dispatch, token, params]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [stableDispatch, token]);

	if (loading) {
		return <LoaderComponent />;
	}
	return (
		<div id='product-details'>
			<div className='details-product-header'>
				<Link to={`/admin/products/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Produits</h1>
				</Link>
				<Link to={`/admin/products/product_update/${params.page}/${params.id}`}>
					<button className='button-primary'>Modifier produit</button>
				</Link>
			</div>
			<Fragment>
				<div className='container'>
					<div className='pr-admin'>
						<div className='field-image-uploaded'>
							<div className='imgBlock'>
								<div id='imgUpload'>
									<div className='fileUp'>
										<img
											src={detailProduct?.image?.secure_url}
											id='imgPreview'
											alt='image_uploaded'
										/>
									</div>
								</div>
							</div>
						</div>
						<div id='info-prod-right'>
							<h2>{detailProduct?.designation}</h2>
							<p>{detailProduct?.description}</p>
						</div>
					</div>
					<div className='pr-admin'>
						<div className='table'>
							<table>
								<tbody>
									<tr>
										<td className='indication'>Reférence</td>
										<td>{detailProduct?.reference}</td>
									</tr>
									<tr>
										<td className='indication'>Code à barre</td>
										<td>{detailProduct?.barCode}</td>
									</tr>
									<tr>
										<td className='indication'>Catégorie</td>
										<td>{detailProduct?.category?.designation}</td>
									</tr>
									<tr>
										<td className='indication'>Conténance</td>
										<td>{detailProduct?.capacity}</td>
									</tr>
									<tr>
										<td className='indication'>Nb-pièce-carton</td>
										<td>{detailProduct?.nb_cardboard_piece}</td>
									</tr>
									<tr>
										<td className='indication'>Couleur</td>
										<td>{detailProduct?.color}</td>
									</tr>
									<tr>
										<td className='indication'>Matière</td>
										<td>{detailProduct?.material}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className='table'>
							<table>
								<tbody>
									<tr>
										<td className='indication'>Prix HT</td>
										<td>{detailProduct?.prise_HT} DT</td>
									</tr>
									<tr>
										<td className='indication'>TVA</td>
										<td>{detailProduct?.Tva} %</td>
									</tr>
									<tr>
										<td className='indication'>Prix détaillant</td>
										<td>{detailProduct?.Retail_Price} DT</td>
									</tr>
									<tr>
										<td className='indication'>Prix Grossiste</td>
										<td>{detailProduct?.Wholesale_Price} DT</td>
									</tr>
									<tr>
										<td className='indication'>Prix de Grande Surface</td>
										<td>{detailProduct?.Monoprix_Price} DT</td>
									</tr>
									{/* <tr>
										<td className='indication'>Prix d'UMD</td>
										<td>{detailProduct?.Price_of_UMD} DT</td>
									</tr>
									<tr>
										<td className='indication'>Prix de Black</td>
										<td>{detailProduct?.Price_of_Black} DT</td>
									</tr> */}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</Fragment>
		</div>
	);
};

export default ProductDetails;
