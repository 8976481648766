import axios from '../http-common';

const getListRegions = (token) => {
	return axios.get('/public/region/', {
		headers: { Authorization: `Bearer ${token}` },
	});
};

const uploadImg = (token, formDta) => {
	return axios.post('public/uploadImage', formDta, {
		headers: {
			'content-type': 'multipart/form-data',
			Authorization: `Bearer ${token}`,
		},
	});
};

const eraseImg = (token, formDta) => {
	return axios.post('public/eraseImage', formDta, {
		headers: {
			'content-type': 'multipart/form-data',
			Authorization: `Bearer ${token}`,
		},
	});
};

export const PublicServices = {
	getListRegions,
	uploadImg,
	eraseImg,
};
export default PublicServices;
