import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useDispatch } from 'react-redux';
import ImprimerLogo from '../../../assets/icons/svg/document-text.svg';
import PrintToPdf from './PrintToPdf';
import { useReactToPrint } from 'react-to-print';
import { LoaderComponent } from '../../../helpers/components/loader';
import { ClientInfo, ShopInfo, SuperAdminInfo } from '../../../components/userInfo';
import { convertDate } from '../../../helpers/modules';
import { getReturnByIdCommercial } from '../../../api/redux/slices/Commercial';

const ReturnDetails = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const [returnDetails, setReturnDetails] = useState({});
	const [loading, setLoading] = useState(false);
	const componentRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	useEffect(() => {
		setLoading(true);
		dispatch(
			getReturnByIdCommercial({
				token: localStorage.getItem('accesstoken'),
				id: params.id,
			}),
		)
			.unwrap()
			.then((res) => {
				setReturnDetails(res?.data);
			})
			.finally(() => setLoading(false));
	}, [dispatch, params.id]);

	if (loading) {
		return <LoaderComponent />;
	}
	return (
		<div id='returnDetails'>
			<div className='details-return-header'>
				<Link to={`/commercial/returns/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Bons de retour</h1>
				</Link>
				<button
					style={{ display: 'flex', gap: '10px' }}
					className='button-primary'
					onClick={handlePrint}
				>
					<img src={ImprimerLogo} alt='imprimer logo' />
					Imprimer
				</button>
			</div>
			<div id='title-return'>
				<h1>S.O.M.E.C Société Méditeranéenne d'Equipement et de Commerce</h1>
			</div>
			<div className='container'>
				<div className='credentials'>
					<div className='left'>
						<div className='left-el'>
							<label>Numéro</label>
							<span>{returnDetails?.info?.reference || '-'}</span>
						</div>
						<div className='left-el'>
							<label>Date</label>
							<span>
								{returnDetails?.info?.createdAt
									? convertDate(returnDetails.createdAt, 'DD-MM-YYYY')
									: '-'}
							</span>
						</div>
						<div className='left-el'>
							<label>Référence code client</label>
							<span>
								{returnDetails?.info?.delivery?.order_id?.clientInfo?.customs_code || '-'}
							</span>
						</div>
					</div>
					<div className='left'>
						<div className='left-el'>
							<ClientInfo clientInfo={returnDetails?.info?.delivery?.order_id?.clientInfo} />
							<ShopInfo shop={returnDetails?.info?.delivery?.order_id?.shopInfo} />
						</div>
					</div>
				</div>
				<div className='devis'>
					<div className='devis-head'>
						<h1>BON DE RETOUR</h1>
						<span />
					</div>
					<div className='devis-table'>
						<table className='offres'>
							<thead>
								<tr id='titres'>
									<td>RÉFÉRENCE</td>
									<td>CODE E.A.N</td>
									<td>DÉSIGNATION</td>
									<td>QTÉ</td>
									<td>QTÉ Retournée</td>
								</tr>
							</thead>
							<tbody>
								{loading ? (
									<tr>
										<td colSpan='7'>
											<LoaderComponent margin='10%' />
										</td>
									</tr>
								) : (
									returnDetails?.list?.map((el, index) => (
										<tr className='elements' key={index}>
											<td>{el?.orderItem?.reference}</td>
											<td>{el?.orderItem?.refranceCTL}</td>
											<td>{el?.orderItem?.designation}</td>
											<td>{el?.orderItem?.quantity}</td>
											<td>{el?.quantity}</td>
										</tr>
									))
								)}
							</tbody>
						</table>
					</div>
				</div>
				<div className='livreur'>
					<div className='left'>
						<h4>NOM &amp; PRENOM DU LIVREUR</h4>
						<span>{returnDetails?.info?.delivery?.commercial_name || '------------'}</span>
					</div>
					<div className='left'>
						<h4>MATRICULE VEHICULE</h4>
						<span>{returnDetails?.info?.delivery?.matricule_vehicle || '------------'}</span>
					</div>
				</div>
				<SuperAdminInfo superAdminInfo={returnDetails?.info?.delivery?.order_id?.superAdminInfo} />
			</div>
			<div style={{ display: 'none' }}>
				<PrintToPdf componentRef={componentRef} returnDetails={returnDetails} />
			</div>
		</div>
	);
};

export default ReturnDetails;
