import { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import SeeProduct from './SeeProduct';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	addBasket,
	deleteBasket,
	getBasket,
	getCustomerProductsList,
	getListCategoriesProducts,
	updatedbasketTotalQty,
} from '../../../api/redux/slices/Customer';
import Pagination from '../../../helpers/components/Pagination';
import { ProductCard } from '../../../components/cards/Product';
import { LoaderComponent } from '../../../helpers/components/loader';
import { roundToThreeDecimals, typePrice } from '../../../helpers/modules';
import { showToast } from '../../../components/modal';

const ProductPageCLient = () => {
	const { listProducts, total } = useSelector((state) => state.customer);
	const dispatch = useDispatch();
	const params = useParams();
	const [page, setPage] = useState(Number(params.page));
	const [modalOpen, setModalOpen] = useState(false);
	const [indiceProduit, setIndiceProduit] = useState(null);
	const [listCategories, setListCategories] = useState([]);

	const [searchInput, setSearchInput] = useState('');
	const [categroyValue, setCategoryValue] = useState('');

	const { basketTotalQty } = useSelector((state) => state.customer);
	const { user } = useSelector((state) => state.auth);

	const [isLoading, setIsLoading] = useState(false);
	const accesstoken = localStorage.getItem('accesstoken');

	//----------------------- get products and categories features -------------------------------
	useEffect(() => {
		dispatch(
			getListCategoriesProducts({
				token: accesstoken,
			}),
		)
			.unwrap()
			.then((res) => setListCategories(res.data));
	}, [dispatch, accesstoken]);

	const stableDispatch = useCallback(() => {
		setIsLoading(true);
		dispatch(
			getCustomerProductsList({
				token: accesstoken,
				page: searchInput || categroyValue ? undefined : page,
				search: searchInput,
				categories: categroyValue,
			}),
		).finally(() => setIsLoading(false));
	}, [dispatch, page, categroyValue, searchInput, accesstoken]);

	useEffect(() => {
		dispatch(getBasket({ token: accesstoken, id: user.data._id }))
			.unwrap()
			.then((res) => {
				res.data.forEach((item) => {
					if (!item.product) {
						dispatch(deleteBasket({ token: accesstoken, id: item._id }));
					}
				});
			});
	}, [accesstoken, dispatch, user.data._id]);

	useEffect(() => {
		if (accesstoken) {
			stableDispatch();
		}
	}, [stableDispatch, accesstoken]);

	const handleAddToCart = (product_id, quantity, price) => {
		const total = quantity * price;
		dispatch(addBasket({ token: accesstoken, data: { product_id: product_id, quantity, total } }))
			.unwrap()
			.then(() => {
				dispatch(updatedbasketTotalQty(basketTotalQty + quantity));
				showToast('Produit ajouté au panier !', 'success');
			});

		setModalOpen(false);
	};

	return (
		<div id='pr-page-client'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='search'
							placeholder='Rechercher ...'
							values={searchInput}
							onChange={(e) => setSearchInput(e.target.value)}
						/>
						<icons.FiSearch className='searchicon' />
					</div>
					<div className='filters'>
						<select
							name='catégories'
							value={categroyValue}
							onChange={(e) => setCategoryValue(e.target.value)}
						>
							<option value=''>Catégories</option>
							{listCategories.map((cat) => (
								<Fragment key={cat._id}>
									<option value={cat._id}>{cat.designation}</option>
								</Fragment>
							))}
						</select>
					</div>
				</div>
				<div className='right'>
					<Link to='/client/products/product_cart'>
						<div className='cart'>
							<icons.RiShoppingCartLine className='cart-icon' color='#000' />
							<div className='cart-count'>
								<span>{basketTotalQty}</span>
							</div>
						</div>
						<h3>Panier</h3>
					</Link>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Produits</h1>
				</div>
			</div>
			{isLoading ? (
				<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
					<LoaderComponent margin='10%' />
				</div>
			) : (
				<div id='cartContent'>
					{listProducts?.map((product, index) => (
						<Fragment key={index}>
							<ProductCard
								img_url={product.image?.secure_url || process.env.REACT_APP_IMAGE_PLACEHOLDER_URL}
								onClickShop={() =>
									handleAddToCart(product._id, 1, product[typePrice(user.data.categories)])
								}
								onClickSee={() => {
									setIndiceProduit(product._id);
									setModalOpen(!modalOpen);
								}}
								product_name={product.designation ?? '-'}
								product_price={roundToThreeDecimals(
									product[typePrice(user.data.categories)] * (1 + product.Tva / 100),
								)}
								priceWithVAT={true}
								isDisabled={product.capacity < 1}
							/>
							{indiceProduit === product._id && (
								<SeeProduct
									product={product}
									modalOpen={modalOpen}
									setModalOpen={setModalOpen}
									priceWithVAT={roundToThreeDecimals(
										product[typePrice(user.data.categories)] * (1 + product.Tva / 100),
									)}
								/>
							)}
						</Fragment>
					))}
				</div>
			)}
			<Pagination page={page} setPage={setPage} pageLink='/client/products/' total={total} />
		</div>
	);
};

export default ProductPageCLient;
