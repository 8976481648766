import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getProductsByAdmin, updateProductStockByAdmin } from '../../../api/redux/slices/Admin';
import './index.scss';
import { useParams } from 'react-router-dom';
import { showToast } from '../../../components/modal';
import { ClipLoader } from 'react-spinners';

const UpdateStock = ({ stockModalOpen, setStockModalOpen, dataToUpdate, id }) => {
	const dispatch = useDispatch();
	const params = useParams();
	const token = localStorage.getItem('accesstoken');

	const [value, setValue] = useState(...dataToUpdate.filter((el) => el._id === id));
	const [qté, setQté] = useState(0);
	const [optionStock, setOptionStock] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		setValue(...dataToUpdate.filter((el) => el._id === id));
	}, [dataToUpdate, id, stockModalOpen]);

	const handleUpdateStock = (e) => {
		if (isSubmitting) {
			return;
		}
		setIsSubmitting(true);

		const currentStock = value.capcity;
		e.preventDefault();
		const data = {
			option: optionStock,
			quantity: Number(qté),
		};

		if (data.quantity > value.capacity && !optionStock) {
			showToast('La quantité est plus grande que le stock', 'warn');
			return;
		}
		if (!data.option && data.quantity > currentStock) {
			toast.error("Il n'est pas possible de proposer plus que le stock actuel", {
				position: 'bottom-right',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			return;
		}

		setIsLoading(true);
		dispatch(updateProductStockByAdmin({ token: token, id: id, data: data }))
			.unwrap()
			.then(() => {
				setStockModalOpen(false);
				dispatch(
					getProductsByAdmin({
						token: token,
						name: '',
						category_name: '',
						reference_name: '',
						page: params.page,
					}),
				);
			})
			.catch((err) => {
				toast.error(err.message, {
					position: 'bottom-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.finally(() => setIsLoading(false));
	};
	const handleChange = (e) => {
		const quantity = Number(e.target.value);
		if (quantity < 0 || isNaN(quantity)) {
			setQté(0);
		} else {
			setQté(quantity);
		}
	};
	return (
		<div className={'modalcontainer ' + (stockModalOpen && 'open')}>
			<div className={'modal ' + (stockModalOpen && 'active')}>
				<h1>Broc a eau 1 lt paris 111241-059</h1>
				<form onSubmit={handleUpdateStock}>
					<div className='line-updateStock'>
						<h2>Disponibilité</h2>
						<span style={{ color: value.capacity <= 0 && 'red' }}>
							{value.capacity > 0 ? `En stock (${value.capacity})` : 'Indisponible'}
						</span>
					</div>
					<div className='line-updateStock'>
						<h2>Options</h2>
						<div id='btn-ustock'>
							<button
								id={optionStock ? 'state-on' : 'state-off'}
								onClick={(e) => {
									e.preventDefault();
									setOptionStock(true);
								}}
							>
								En stock
							</button>
							<button
								id={!optionStock ? 'state-on' : 'state-off'}
								onClick={(e) => {
									e.preventDefault();
									setOptionStock(false);
								}}
							>
								Stock manquant
							</button>
						</div>
					</div>
					<div className='line-updateStock'>
						<h2>Quantité</h2>
						<input
							type='number'
							value={parseInt(qté, 10).toString()}
							onChange={handleChange}
							onWheel={(e) => e.currentTarget.blur()}
						/>
					</div>
					<div className='modalbtn'>
						<button
							className='button-nature-inactive'
							onClick={(e) => {
								e.preventDefault();
								setStockModalOpen(false);
							}}
						>
							Annuler
						</button>
						<button
							className='button-primary'
							type='submit'
							disabled={(value.capacity < qté && !optionStock) || isLoading}
						>
							{isLoading ? <ClipLoader color='#FFF' loading={isLoading} size={20} /> : 'Confirmer'}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default UpdateStock;
