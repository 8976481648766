import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { toast } from 'react-toastify';
import { updateCustomerPassword } from '../../../api/redux/slices/Customer';
import { useDispatch } from 'react-redux';
import DeleteConfirmationModal from '../../admin/addUser/ShopDeleteModal';
import { handleLogout } from '../../navigation';
import { ButtonComponent } from '../../../components/buttons';
import { showToast } from '../../../components/modal';
import PasswordInput from '../../../components/passwordInput';

const UpdateClientPassword = () => {
	const dispatch = useDispatch();
	const accesstoken = localStorage.getItem('accesstoken');
	const [isLoading, setIsLoading] = useState(false);

	const [confirmModal, setConfirmModal] = useState({
		errMsg: '',
		isShow: false,
	});

	const [values, setValues] = useState({
		oldPassword: '',
		newPassword: '',
		confirmPassword: '',
	});

	const handleUpdate = (e) => {
		e.preventDefault();
		const data = {
			oldPassword: values.oldPassword,
			newPassword: values.newPassword,
			confirmPassword: values.confirmPassword,
		};

		setIsLoading(false);
		dispatch(updateCustomerPassword({ token: accesstoken, data: data }))
			.unwrap()
			.then((res) => {
				toast.success(res.message, {
					position: 'top-center',
					autoClose: 2000,
				});
				handleLogout(dispatch, accesstoken);
			})
			.catch((err) => {
				toast.warn(err.message, {
					position: 'top-center',
					autoClose: 2000,
				});
			})
			.finally(() => setIsLoading(false));
		setConfirmModal({ ...confirmModal, isShow: false });
	};

	const handleClick = (event) => {
		event.preventDefault();
		if (!values.oldPassword || !values.newPassword || !values.confirmPassword) {
			showToast('Veuillez remplir tous les champs', 'warn');
			return;
		}

		if (
			!values.newPassword ||
			!values.confirmPassword ||
			values.newPassword !== values.confirmPassword
		) {
			showToast('Les mots de passe ne correspondent pas', 'warn');
			return;
		}
		setConfirmModal({ ...confirmModal, isShow: true });
	};
	return (
		<div id='updatePassword'>
			<div className='navbar' style={{ width: 'calc(100vw - 20rem)' }}>
				<div className='left'>
					<h1>Profile</h1>
				</div>
				<div className='submit-btns'>
					<Link to={`/client/profile`}>
						<button className='button-nature' type='button'>
							Annuler
						</button>
					</Link>
					<ButtonComponent
						name='Enregister'
						loading={isLoading}
						type='button'
						className='button-primary'
						onClick={handleClick}
					/>
				</div>
			</div>
			<div id='box'>
				<form>
					<div className='label-input' style={{ position: 'relative' }}>
						<label>Entrer le mot de passe actuel</label>
						<PasswordInput
							id={'oldPassword'}
							placeholder='Entrer le mot de passe actuel'
							value={values.oldPassword}
							onChange={(e) => {
								setValues({ ...values, oldPassword: e.target.value });
							}}
							marginBottom={'22%'}
						/>
					</div>
					<div className='label-input' style={{ position: 'relative' }}>
						<label>Entrer le nouveau mot de passe</label>
						<PasswordInput
							id={'newPassword'}
							placeholder='Entrer le nouveau mot de passe'
							value={values.newPassword}
							onChange={(e) => {
								setValues({ ...values, newPassword: e.target.value });
							}}
							marginBottom={'22%'}
						/>
					</div>
					<div className='label-input' style={{ position: 'relative' }}>
						<label>Confirmer le nouveau mot de passe</label>
						<PasswordInput
							id={'confirmPassword'}
							placeholder='Confirmer le nouveau mot de passe'
							value={values.confirmPassword}
							onChange={(e) => {
								setValues({ ...values, confirmPassword: e.target.value });
							}}
							marginBottom={'22%'}
						/>
					</div>
				</form>
			</div>
			{confirmModal.isShow && (
				<DeleteConfirmationModal
					onDelete={handleUpdate}
					onCancel={() => setConfirmModal({ ...confirmModal, isShow: false })}
					msg='Vous confirmer le changement de passe ?'
				/>
			)}
		</div>
	);
};

export default UpdateClientPassword;
