import useOnclickOutside from 'react-cool-onclickoutside';
import './index.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

export const Modal = ({ errorModal, setModalError }) => {
	const modalContainerClassName = `modalcontainer ${errorModal.isShow ? 'open' : ''}`;
	const modalClassName = `modal ${errorModal.isShow ? 'active' : ''}`;

	const modalStyle = {
		height: '12rem',
		width: '30rem',
		top: '20%',
		flexDirection: 'column',
	};

	const handleModalClose = () => {
		setModalError({ errMsg: '', isShow: false });
	};

	const ref = useOnclickOutside(handleModalClose);

	return (
		<div className={modalContainerClassName}>
			<div className={modalClassName} style={modalStyle} ref={ref}>
				<h4>{errorModal.errMsg}</h4>
				<div>
					<button className='button-danger' onClick={handleModalClose} style={{ margin: '0 auto' }}>
						Close
					</button>
				</div>
			</div>
		</div>
	);
};

export const ModalSuccess = ({ succesModal, setModalSuccess, name, navigate }) => {
	const modalContainerClassName = `modalcontainer ${succesModal.isShow ? 'open' : ''}`;
	const modalClassName = `modal ${succesModal.isShow ? 'active' : ''}`;

	const modalStyle = {
		height: '14rem',
		width: '30rem',
		top: '20%',
		flexDirection: 'column',
	};

	const handleModalClose = () => {
		setModalSuccess({ succMsg: '', isShow: false });
	};

	return (
		<div className={modalContainerClassName}>
			<div className={modalClassName} style={modalStyle}>
				<h4 style={{ textAlign: 'center' }}>{succesModal.succMsg}</h4>
				<div>
					<Link to={navigate}>
						<button
							className='button-primary'
							onClick={handleModalClose}
							style={{ margin: '0 auto' }}
						>
							{name}
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
};
export const DataErrorModal = ({ isOpen, closeModal, errorData, width, height }) => {
	const ref = useOnclickOutside(() => closeModal());

	if (!isOpen) return null;

	const firstKey = Object.keys(errorData[0])[0];

	return (
		<div className='modalcontainer open'>
			<div
				className='modal active'
				style={{
					top: '40%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					overflowY: 'auto',
					width,
					height,
				}}
				ref={ref}
			>
				<table className='error-table' border='1'>
					<thead>
						<tr>
							<th>{firstKey}</th>
							<th style={{ color: 'red' }}>Erreur</th>
						</tr>
					</thead>
					<tbody>
						{errorData.map((error, index) => (
							<tr key={index} className='error-item'>
								<td style={{ textAlign: 'center' }}>{error[firstKey]}</td>
								<td style={{ textAlign: 'center' }}>{error.error}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export const showToast = (
	message,
	type = 'success',
	position = 'top-center',
	autoClose = 2000,
	options = {},
) => {
	toast[type](message, {
		position,
		autoClose: autoClose,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		...options,
	});
};
