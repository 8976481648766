import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PublicServices from '../../services/PublicServices';

const initialState = {
	regions: [],
	listRegions: [],
	isFetching: false,
	imageData: [],
};

export const regionsList = createAsyncThunk('/regions', async ({ token }, thunkApi) => {
	try {
		const res = await PublicServices.getListRegions(token);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const imageApi = createAsyncThunk('/uploadImage', async ({ token, formDta }, thunkApi) => {
	try {
		const res = await PublicServices.uploadImg(token, formDta);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

const publicSlice = createSlice({
	name: 'public',
	initialState,
	extraReducers: {
		[regionsList.pending]: (state) => {
			state.isFetching = true;
		},
		[regionsList.fulfilled]: (state, action) => {
			state.regions = action.payload;
			state.listRegions = action.payload.data;
			state.isFetching = false;
		},
		[imageApi.pending]: (state) => {
			state.isFetching = true;
		},
		[imageApi.fulfilled]: (state, action) => {
			state.imageData = action.payload;
			state.isFetching = false;
		},
	},
});

export default publicSlice.reducer;
