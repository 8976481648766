import React, { useCallback, useState } from 'react';
import './index.scss';
import { icons } from '../../../assets/icons';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getClaimsByAdmin } from '../../../api/redux/slices/Admin';
import { useEffect } from 'react';
import { LoaderComponent } from '../../../helpers/components/loader';
import { convertDate } from '../../../helpers/modules';
import Pagination from '../../../helpers/components/Pagination';

const ComplaintsPage = () => {
	const { claims, total } = useSelector((state) => state.admin);
	const dispatch = useDispatch();
	const params = useParams();

	const [page, setPage] = useState(Number(params.page));

	const [values, setValues] = useState({
		search: '',
		status: '',
	});
	const [loading, setLoading] = useState(false);
	const token = localStorage.getItem('accesstoken');
	//----------------------- GET ALL CLAIMS ---------------------------//
	const stableDispatch = useCallback(() => {
		setLoading(true);
		dispatch(
			getClaimsByAdmin({
				token: token,
				status: values.status,
				search: values.search,
				page: values.search || values.status ? undefined : page,
			}),
		)
			.unwrap()
			.finally(() => setLoading(false))
			.catch((err) => {});
	}, [dispatch, page, values, token]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [stableDispatch, page, values, token]);

	return (
		<div id='complaintsPage'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='search'
							placeholder='Rechercher ...'
							value={values.search}
							onChange={(e) => setValues({ ...values, search: e.target.value })}
						/>
						<icons.FiSearch className='searchicon' />
					</div>
					<div className='filters'>
						<select
							name='catégories'
							id=''
							value={values?.status}
							onChange={(e) => setValues({ ...values, status: e.target.value })}
						>
							<option value=''>Tout</option>
							<option value={true}>Traité</option>
							<option value={false}>En cours</option>
						</select>
					</div>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Liste des réclamations</h1>
				</div>
			</div>
			<div id='complaintsContent'>
				<table id='complaints'>
					<thead>
						<tr id='titres'>
							<td></td>
							<td>CLIENT</td>
							<td>SOCIÉTÉ</td>
							<td className='center'>DATE D'AJOUT</td>
							<td className='center'>OBJECTIF</td>
							<td className='center'>STATUT</td>
							<td className='center'>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{loading ? (
							<tr>
								<td colSpan='7'>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							claims?.map((element, index) => (
								<tr className='complain' key={index}>
									<td></td>
									<td>
										{element?.created_by?.first_name} {element?.created_by?.last_name}
									</td>
									<td>{element?.created_by?.company_name}</td>
									<td className='center'>{convertDate(element?.createdAt, 'DD-MM-YYYY')}</td>
									<td className='center' id='object'>
										{element?.object?.name ?? '-'}
									</td>
									<td className={`center ${element?.status ? ' status traité' : 'status'}`}>
										{element?.status ? 'traité' : 'en cours'}
									</td>
									<td className='last-complain-element center'>
										<Link
											to={`/admin/complaints/complain_details/${page}/${element?._id}`}
											className='link-complain'
										>
											<icons.BsEye className='see-complain' />
										</Link>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink={`/admin/complaints/`} total={total} />
		</div>
	);
};

export default ComplaintsPage;
