import React from 'react';
import './deleteConfirmationModal.scss';
import { ButtonComponent } from '../../../components/buttons';

const DeleteConfirmationModal = ({
	onDelete,
	onCancel,
	msg = 'Voulez-vous supprimer cet élément ?',
	isLoading = false,
}) => {
	return (
		<div className='modal-delete'>
			<div className='delete-confirmation-modal'>
				<h1>Confirmation</h1>
				<p>{msg}</p>
				<div className='modal-btns'>
					<button className='button-nature-inactive' onClick={onCancel}>
						Annuler
					</button>
					<ButtonComponent
						name={'Enregistrer'}
						loading={isLoading}
						type={'button'}
						className={'button-primary'}
						onClick={onDelete}
					/>
				</div>
			</div>
		</div>
	);
};

export default DeleteConfirmationModal;
