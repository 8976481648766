import React, { useState } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

const PasswordInput = ({ id, placeholder, value, onChange, marginBottom, ...props }) => {
	const [passwordVisible, setPasswordVisible] = useState(false);

	const togglePassword = () => {
		var x = document.getElementById(id);
		if (x.type === 'password') {
			x.type = 'text';
			setPasswordVisible(true);
		} else {
			x.type = 'password';
			setPasswordVisible(false);
		}
	};
	const StyleSeePassword = {
		position: 'absolute',
		right: '1rem',
		bottom: marginBottom ? marginBottom : '16%',
		fontSize: '19px',
		color: '#475569',
	};
	return (
		<>
			<input
				id={id}
				type='password'
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				{...props}
			/>
			{passwordVisible ? (
				<BsEyeSlash onClick={togglePassword} style={StyleSeePassword} />
			) : (
				<BsEye onClick={togglePassword} style={StyleSeePassword} />
			)}
		</>
	);
};

export default PasswordInput;
