import React, { useState } from 'react';
import './index.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../../api/redux/slices/Auth';
import { Modal, showToast } from '../../../components/modal/index';
import { ButtonComponent } from '../../../components/buttons';

const UpdateProfile = () => {
	const { user } = useSelector((state) => state.auth);
	const [infos] = useState(user.data);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const token = localStorage.getItem('accesstoken');
	const [isLoading, setIsLoading] = useState(false);
	const [errorModal, setErrorModal] = useState({ errMsg: '', isShow: false });

	const [values, setValues] = useState({
		name: infos.name,
		address: infos.address,
		tva_code: infos.tva_code,
		r_c: infos.r_c,
		customs_code: infos.customs_code,
		phone: infos.phone,
		fax: infos.fax,
		email: infos.email,
	});

	const handleUpdate = (e) => {
		e.preventDefault();

		const data = {
			name: values.name.trim(),
			address: values.address.trim(),
			tva_code: values.tva_code.trim(),
			r_c: values.r_c.trim(),
			customs_code: values.customs_code.trim(),
			phone: values.phone.trim(),
			fax: values.fax.trim(),
		};

		if (
			!data.name ||
			!data.address ||
			!data.tva_code ||
			!data.r_c ||
			!data.customs_code ||
			!data.phone ||
			!data.fax
		) {
			showToast('Veuillez renseigner tous les champs', 'warn');
			return;
		}
		setIsLoading(true);
		dispatch(updateProfile({ token: token, data: data }))
			.unwrap()
			.then(() => {
				showToast('Profile mis a jour avec succes', 'success');
				navigate('/superadmin/profile');
			})
			.catch((err) => {
				setErrorModal({ errMsg: err, isShow: true });
			})
			.finally(() => setIsLoading(false));
	};

	return (
		<div id='updateProfile'>
			{errorModal.isShow && <Modal errorModal={errorModal} setModalError={setErrorModal} />}
			<form onSubmit={handleUpdate}>
				<div className='navbar' style={{ width: 'calc(100vw - 20rem)' }}>
					<Link to='/superadmin/profile'>
						<h1>Profile</h1>
					</Link>
					<div className='add-btns'>
						<Link to='/superadmin/profile'>
							<button className='button-nature'>Annuler</button>
						</Link>
						<ButtonComponent
							name='Enregistrer'
							loading={isLoading}
							type='submit'
							className='button-primary'
						/>
					</div>
				</div>
				<div className='label-input'>
					<label>Nom</label>
					<input
						type='text'
						value={values.name}
						onChange={(e) => setValues({ ...values, name: e.target.value })}
					/>
				</div>
				<div className='label-input'>
					<label>Adresse</label>
					<input
						type='text'
						value={values.address}
						onChange={(e) => setValues({ ...values, address: e.target.value })}
					/>
				</div>
				<div className='label-input'>
					<label>Code TVA</label>
					<input
						type='text'
						value={values.tva_code}
						onChange={(e) => setValues({ ...values, tva_code: e.target.value })}
					/>
				</div>
				<div className='label-input'>
					<label>R.C</label>
					<input
						type='text'
						value={values.r_c}
						onChange={(e) => setValues({ ...values, r_c: e.target.value })}
					/>
				</div>
				<div className='label-input'>
					<label>Code en douane</label>
					<input
						type='text'
						value={values.customs_code}
						onChange={(e) => setValues({ ...values, customs_code: e.target.value })}
					/>
				</div>
				<div className='label-input'>
					<label>téléphone</label>
					<input
						type='text'
						value={values.phone}
						onChange={(e) => {
							const onlyNumbers = e.target.value.replace(/[^0-9+\s]/g, '');
							setValues({ ...values, phone: onlyNumbers });
						}}
					/>
				</div>
				<div className='label-input'>
					<label>Fax</label>
					<input
						type='text'
						value={values.fax}
						onChange={(e) => setValues({ ...values, fax: e.target.value })}
					/>
				</div>
				<div className='label-input'>
					<label>Email</label>
					<input
						type='email'
						value={values.email}
						disabled
						onChange={(e) => setValues({ ...values, fax: e.target.value })}
					/>
				</div>
			</form>
		</div>
	);
};

export default UpdateProfile;
