import axios from '../http-common';

const createAxiosRequest = (token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return {
		get: (url, params = {}) => axios.get(url, { ...config, ...params }),
		post: (url, data) => axios.post(url, data, config),
		put: (url, data) => axios.put(url, data, config),
		delete: (url) => axios.delete(url, config),
	};
};

const axiosWithToken = createAxiosRequest;

export const AdminServices = {
	getListForAdmin: (token, name, categories, regulation, page) =>
		axiosWithToken(token).get(`/admin/list-users/`, {
			params: { search: name, categories, regulation, page },
		}),
	getUserDetails: (token, id) => axiosWithToken(token).get(`/admin/list-users/${id}`),
	create_user_admin: (token, data) => axiosWithToken(token).post('/admin/list-users/add', data),
	updateUserByAdmin: (token, id, data) =>
		axiosWithToken(token).put(`/admin/list-users/${id}`, data),
	updateProfile: (token, data) => axiosWithToken(token).put(`/admin/profile/`, data),
	getCategoriesForAdmin: (token, name, page) =>
		axiosWithToken(token).get(`/admin/categories/`, {
			params: { search: name, page },
		}),
	getCategoriesForAdminProducts: (token) => axiosWithToken(token).get(`/admin/categories/products`),
	deleteCategoriesForAdmin: (token, id) => axiosWithToken(token).delete(`/admin/categories/${id}`),
	addCategoryForAdmin: (token, data) => axiosWithToken(token).post(`/admin/categories`, data),
	updateCategoryForAdmin: (token, id, data) =>
		axiosWithToken(token).put(`/admin/categories/${id}`, data),
	blockUserForAdmin: (token, id, data) =>
		axiosWithToken(token).post(`/admin/list-users/block/${id}`, data),
	getListProducts: (token, name, category_name, page) =>
		axiosWithToken(token).get(`/admin/products/`, {
			params: { search: name, categories: category_name, page },
		}),
	getProductDetails: (token, id) => axiosWithToken(token).get(`/admin/products/${id}`),
	getOrdersProducts: (token, search, category_name, page) =>
		axiosWithToken(token).get(`/admin/orders/products/`, {
			params: { search, categories: category_name, page },
		}),
	productActivation: (token, id, data) =>
		axiosWithToken(token).put(`/admin/products/block/${id}`, data),
	addProductAdmin: (token, data) => axiosWithToken(token).post(`/admin/products`, data),
	updateProductAdmin: (token, id, data) => axiosWithToken(token).put(`/admin/products/${id}`, data),
	deleteProductAdmin: (token, id) => axiosWithToken(token).delete(`/admin/products/${id}`),
	updateStockAdmin: (token, id, data) =>
		axiosWithToken(token).put(`/admin/products/stock/${id}`, data),
	getListOrdersAdmin: (token, page, search, status) =>
		axiosWithToken(token).get(`/admin/orders/`, {
			params: { page, search, status },
		}),
	deleteOrderAdmin: (token, id) => axiosWithToken(token).delete(`/admin/orders/${id}`),
	//get order by id
	getOrderByIdAdmin: (token, id) => axiosWithToken(token).get(`/admin/orders/${id}`),
	//update order by id
	updateOrderByIdAdmin: (token, id, data) => axiosWithToken(token).put(`/admin/orders/${id}`, data),
	//get clients order
	clientsOrderListAdmin: (token, value) =>
		axiosWithToken(token).get(`/admin/orders/customer/${value}`),
	//add order
	addOrderAdmin: (token, data) => axiosWithToken(token).post(`/admin/orders`, data),
	getClaims: (token, status, search, page) =>
		axiosWithToken(token).get(`/admin/claim`, {
			params: { search, status, page },
		}),
	getClaimById: (token, id) => axiosWithToken(token).get(`/admin/claim/${id}`),
	updateClaimStatus: (token, id, status) =>
		axiosWithToken(token).put(`/admin/claim/${id}`, { status }),
	getReturns: (token, search, page) =>
		axiosWithToken(token).get(`/admin/return`, {
			params: { search, page },
		}),
	getBills: (token, search, page) =>
		axiosWithToken(token).get(`/admin/bills`, {
			params: { search, page },
		}),
	addDelivery: (token, data) => axiosWithToken(token).post(`/admin/delivery`, data),
	getDelivery: (token, page, search, status, statusCommerial) =>
		axiosWithToken(token).get(
			`/admin/delivery?page=${page}&search=${search}&status=${status}&commercial_status=${statusCommerial}`,
		),
	getDeliveryById: (token, id) => axiosWithToken(token).get(`/admin/delivery/details/${id}`),
	updateDelivery: (token, id, data) =>
		axiosWithToken(token).put(`/admin/delivery/update/${id}`, data),
	checkStock: (token, list) =>
		axiosWithToken(token).post(`/admin/orders/products/stock`, { list: list }),
	getCommercial: (token) => axiosWithToken(token).get(`/admin/delivery/commercial`),
	addVehicle: (token, data) => axiosWithToken(token).post(`/admin/vehicle/`, data),
	deleteVehicle: (token, id) => axiosWithToken(token).delete(`/admin/vehicle/${id}`),
	getPayementDetails: (token, id) => axiosWithToken(token).get(`/admin/delivery/payement/${id}`),
	confirmPayement: (token, id, data) =>
		axiosWithToken(token).put(`/admin/delivery/payement/status/${id}`, data),
	refusePayement: (token, id, data) =>
		axiosWithToken(token).put(`/admin/delivery/payement/status/${id}`, data),
	getReturnById: (token, id) => axiosWithToken(token).get(`/admin/return/${id}`),
	getBillById: (token, id) => axiosWithToken(token).get(`/admin/bills/${id}`),
};

export default AdminServices;
