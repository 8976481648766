import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { checkStock, clientsOrderListByAdmin } from '../../../api/redux/slices/Admin';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { generateReferenceNumber, roundToThreeDecimals, typePrice } from '../../../helpers/modules';
import { ButtonComponent } from '../../../components/buttons';
import DeleteConfirmationModal from '../addUser/ShopDeleteModal';
import { DataErrorModal, showToast } from '../../../components/modal';

const OrderCart = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [indexDeleteCart, setIndexDeleteCart] = useState(null);
	const [totalQuantity, setTotalQuantity] = useState(0);

	const [cartList, setCartList] = useState([]);
	const [shopId, setShopId] = useState('');

	const [errorData, setErrorData] = useState([]);
	const [isOpen, setIsOpen] = useState(false);

	const token = localStorage.getItem('accesstoken');
	const [isLoading, setIsLoading] = useState(false);

	let resultat = 0;
	//---------------------getting the shop list through the company------------------------//

	const [shops, setShops] = useState([]);
	const [clientType, setClientType] = useState(null);

	useEffect(() => {
		let company = localStorage.getItem('company');
		company = JSON.parse(company);

		setClientType(company?.categories);
		setShops(company?.shop ?? []);
	}, []);

	const stableDispatchCompanies = useCallback(() => {
		dispatch(
			clientsOrderListByAdmin({
				token: token,
				value: params.companyOrdering,
			}),
		);
	}, [dispatch, token, params]);

	useEffect(() => {
		if (token && params.companyOrdering !== '') {
			stableDispatchCompanies();
		}
	}, [stableDispatchCompanies, token, params]);

	//--------------------------- CART LIST ------------------------------------//
	useEffect(() => {
		let cart = localStorage.getItem('cart');
		if (!cart) {
			setCartList([]);
		} else {
			cart = JSON.parse(cart);
			setCartList(cart);
			const totalQuantity = cart
				.map((item) => item.quantity)
				.reduce((total, qty) => parseInt(total) + parseInt(qty), 0);
			setTotalQuantity(totalQuantity);
		}
	}, []);

	//------------------------------- SUPER TOTAL --------------------------//
	cartList.forEach((el) => {
		const res = el.quantity * el.product_id?.[typePrice(clientType)];
		resultat = res + resultat;
	});

	//---------------------- HANDLE DELETE CART ITEM --------------------------//
	const handleDeleteCartItem = (id) => {
		let cart = JSON.parse(localStorage.getItem('cart')) || [];

		const existingProductIndex = cart.findIndex((item) => item.product_id._id === id);

		if (existingProductIndex !== -1) {
			const existingProduct = cart[existingProductIndex];
			const newCart = [
				...cart.slice(0, existingProductIndex),
				...cart.slice(existingProductIndex + 1),
			];
			setTotalQuantity(totalQuantity - existingProduct.quantity);
			setCartList(newCart);
			localStorage.setItem('cart', JSON.stringify(newCart));
		}
	};

	//---------------------- HANLDE TOTAL QUANTITY --------------------------//
	function TotalPrice(price, qty) {
		return roundToThreeDecimals(price * qty);
	}

	//-------------------- NAVIGATE TO CART PAGE ------------------//
	const navigateToCart = () => {
		navigate(`/admin/orders/order_cart/${params.page}/${params.companyId}`);
	};
	//------------------------------ ADD ORDER --------------------------//
	const handleOrder = () => {
		if (!shopId) {
			showToast('Veuillez selectionner un magasin', 'warn');
			return;
		}
		if (cartList.some((item) => item.quantity === 0)) {
			showToast('La quantité minimale doit être de 1', 'warn');
			return;
		}
		let canOrder = true;
		const cartProducts = cartList.map((el) => {
			const data = {
				product_id: el.product_id._id,
				quantity: el.quantity,
			};
			if (data.quantity <= 0 || isNaN(data.quantity)) {
				showToast('La quantité doit être supérieure à 0.', 'warn', 'top-center', 1000);
				canOrder = false;
			}
			return data;
		});

		if (!canOrder) return;
		setIsLoading(true);
		dispatch(checkStock({ token, list: cartProducts }))
			.unwrap()
			.then((res) => {
				if (res.data.length > 0) {
					setIsOpen(true);
					const errData = res.data.map((item) => ({
						Designation: item.designation,
						error:
							item.stock > 0
								? `Stock insuffisant pour ce produit maximum disponible : ${item.stock}`
								: 'Stock insuffisant pour ce produit',
					}));
					setErrorData(errData);
				} else {
					const orderProducts = cartList.map((el) => ({
						product_id: {
							_id: el.product_id._id,
							prise_HT: el.product_id.prise_HT,
							Price_of_Black: el.product_id.Price_of_Black,
							Price_of_UMD: el.product_id.Price_of_UMD,
							Monoprix_Price: el.product_id.Monoprix_Price,
							Wholesale_Price: el.product_id.Wholesale_Price,
							Retail_Price: el.product_id.Retail_Price,
							reference: el.product_id.reference,
							designation: el.product_id.designation,
							Tva: el?.product_id?.Tva,
						},
						quantity: el.quantity,
						remise: 0,
					}));
					const date = new Date();
					const data = {
						list: orderProducts,
						user_id: params.companyId,
						date: date.toISOString(),
						shop_id: shopId,
						total_HT: resultat,
						tva: 19,
						timbre: 1,
						total: resultat + resultat * 0.19,
						number: generateReferenceNumber(),
					};
					if (data.shop_id) {
						localStorage.setItem('order', JSON.stringify({ orderDetails: data }));
						navigate(`/admin/orders/order_add/${params.page}/${params.companyId}`);
					} else {
						toast.error('Select a shop', {
							position: 'top-center',
							autoClose: 2000,
						});
					}
				}
			})
			.catch((error) => {
				showToast(error.data, 'error');
			})
			.finally(() => setIsLoading(false));
	};

	const handleQty = (id, newQuantity) => {
		newQuantity = parseInt(newQuantity, 10);
		if (isNaN(newQuantity)) {
			newQuantity = 0;
		}

		if (newQuantity < 0) {
			return;
		}

		let cart = localStorage.getItem('cart');
		if (cart) {
			cart = JSON.parse(cart);
			const existingProduct = cart.find((item) => item.product_id._id === id);
			if (existingProduct) {
				setTotalQuantity(
					parseInt(totalQuantity) + parseInt(newQuantity) - parseInt(existingProduct.quantity),
				);

				existingProduct.quantity = parseInt(newQuantity);
				setCartList(cart);
				localStorage.setItem('cart', JSON.stringify(cart));
			}
		}
	};

	return (
		<div id='orderCart'>
			{isOpen && (
				<DataErrorModal isOpen={isOpen} closeModal={() => setIsOpen(false)} errorData={errorData} />
			)}
			<div className='navbar'>
				<div className='left'>
					<div className='link-return' style={{ width: 'max-content' }}>
						<Link to={`/admin/orders/order_market/${params.page}/${params.companyId}`}>
							<icons.IoIosArrowBack className='arrow' />
							<h4>Continuez vers mes achats</h4>
						</Link>
					</div>
				</div>
				<div className='right' style={{ cursor: 'pointer' }} onClick={navigateToCart}>
					<div className='cart'>
						<icons.RiShoppingCartLine className='cart-icon' />
						<div className='cart-count'>
							<span>{totalQuantity}</span>
						</div>
					</div>
					<h3>Panier</h3>
				</div>
			</div>
			<div className='content'>
				<h1>Panier</h1>
				<div className='cart-section'>
					<div className='purchases'>
						{cartList.length === 0 ? (
							<h2>Votre panier est vide</h2>
						) : (
							cartList.map((cartItem, key) => (
								<Fragment key={key}>
									<div className='purchase'>
										<div className='left'>
											<div className='element'>
												<div className='element-img'>
													<img
														src={
															cartItem.product_id.image.secure_url ||
															process.env.REACT_APP_IMAGE_PLACEHOLDER_URL
														}
														alt='produit_image'
													/>
												</div>
												<div className='element-desc'>
													<h4>{cartItem.product_id.designation}</h4>
													<span className='price'>
														{cartItem.product_id?.[typePrice(clientType)]} TND
													</span>
												</div>
											</div>
										</div>
										<div className='right'>
											<div className='qty'>
												<input
													type='number'
													value={parseInt(cartItem.quantity, 10).toString()}
													onChange={(e) => {
														handleQty(cartItem.product_id._id, e.target.value);
													}}
													onWheel={(e) => e.target.blur()}
												/>
											</div>
											<div id='qty-price'>
												<span>
													{TotalPrice(
														cartItem.product_id?.[typePrice(clientType)],
														cartItem.quantity,
													)}{' '}
													TND
												</span>
											</div>
											<div className='del'>
												<icons.FaTrashAlt
													className='trash'
													onClick={() => {
														setOpenDeleteModal(true);
														setIndexDeleteCart(cartItem.product_id._id);
													}}
												/>
											</div>
										</div>
									</div>
									{openDeleteModal && (
										<DeleteConfirmationModal
											onDelete={() => {
												handleDeleteCartItem(indexDeleteCart);
												setOpenDeleteModal(false);
											}}
											onCancel={() => setOpenDeleteModal(false)}
										/>
									)}
								</Fragment>
							))
						)}
					</div>
					<div className='count'>
						<div className='count-header'>
							<label>Choisissez une boutique</label>
							<div id='shop-select'>
								<select value={shopId} onChange={(e) => setShopId(e.target.value)}>
									<option value=''>Choisir une boutique</option>
									{shops?.map((boutique, index) => (
										<Fragment key={index}>
											<option value={boutique._id}>
												{boutique.name} - {boutique.ville ? `${boutique.ville}` : ''}
											</option>
										</Fragment>
									))}
								</select>
							</div>
						</div>
						<div className='top'>
							<ul>
								<li id='number'>
									<span>{totalQuantity}</span>
									{totalQuantity === 1 ? 'ARTICLE' : 'ARTICLES'}
								</li>
								<li>
									<span>{roundToThreeDecimals(resultat)}&nbsp;TND</span>
								</li>
							</ul>
						</div>
						<div className='bottom' onClick={handleOrder}>
							<ButtonComponent
								name={'Commander'}
								className={'button-primary'}
								type={'submit'}
								loading={isLoading}
								disabled={cartList?.length === 0}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderCart;
