import React, { Fragment, useState } from 'react';
import { BsPlusSquareDotted, BsTrash } from 'react-icons/bs';
import DeleteConfirmationModal from './ShopDeleteModal';

const ListVehicles = ({ listVehicles, setListVehicles }) => {
	const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
	const [deleteIndex, setDeleteIndex] = useState(null);

	const handleClickEnter = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
		}
	};

	const handleAddVehicle = () => {
		const newVehicle = { vehicle_number: '' };
		setListVehicles((prevVehicles) => [...prevVehicles, newVehicle]);
	};

	const handleDeleteVehicle = (index) => {
		setDeleteIndex(index);
		setDeleteModalVisible(true);
	};

	const handleConfirmDelete = () => {
		setListVehicles((prevList) => prevList.filter((_, i) => i !== deleteIndex));
		setDeleteModalVisible(false);
		setDeleteIndex(null);
	};

	const handleCancelDelete = () => {
		setDeleteModalVisible(false);
		setDeleteIndex(null);
	};

	const handleInputChange = (index, e) => {
		const { value } = e.target;
		setListVehicles((prevList) =>
			prevList.map((item, i) => (i === index ? { ...item, vehicle_number: value } : item)),
		);
	};

	return (
		<Fragment>
			<div className='add-shop-title'>
				<h2>Liste des véhicules</h2>
				<BsPlusSquareDotted
					style={{ fontSize: '1.3rem', cursor: 'pointer' }}
					title='ajouter une nouvelle boutique'
					onClick={handleAddVehicle}
				/>
			</div>
			{listVehicles.map((inputValue, index) => (
				<div className='shop' key={index}>
					<div className='del-shop'>
						<div className='box-bordered' title='supprimer cette boutique'>
							<BsTrash className='del-shop-icon' onClick={() => handleDeleteVehicle(index)} />
						</div>
					</div>
					<label>Matricule du vehicle</label>
					<input
						style={{ width: '100%' }}
						type='text'
						name='vehicle_number'
						value={inputValue.vehicle_number}
						onChange={(e) => handleInputChange(index, e)}
						onKeyDown={handleClickEnter}
					/>
				</div>
			))}
			{isDeleteModalVisible && (
				<DeleteConfirmationModal onDelete={handleConfirmDelete} onCancel={handleCancelDelete} />
			)}
		</Fragment>
	);
};

export default ListVehicles;
