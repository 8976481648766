import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'; // Add this import
import { icons } from '../../../assets/icons';
import './index.scss';
import { showToast } from '../../../components/modal';

import { ButtonComponent } from '../../../components/buttons';
import { LoaderComponent } from '../../../helpers/components/loader';
import { confirmPayement, getPayementDetails } from '../../../api/redux/slices/Admin';
import { convertDate, roundToThreeDecimals } from '../../../helpers/modules';
import ModalMessage from './ModalMessage';

const PaymentDetails = () => {
	const params = useParams();
	const queryParams = new URLSearchParams(window.location.search);
	const isShow = queryParams.get('isShow');

	const dispatch = useDispatch();

	const token = localStorage.getItem('accesstoken');
	const [loading, setLoading] = useState(false);
	const [isLoading, setIsloading] = useState(false);
	const navigate = useNavigate();

	const [messageModal, setMessageModal] = useState({
		isShow: false,
		message: '',
	});

	const [payementDetails, setPayementDetails] = useState({});

	useEffect(() => {
		setLoading(true);
		dispatch(getPayementDetails({ token, id: params.id }))
			.unwrap()
			.then((res) => setPayementDetails(res.data))
			.finally(() => setLoading(false));
	}, [dispatch, token, params.id]);

	const handleConfirm = () => {
		setIsloading(true);
		dispatch(confirmPayement({ token, id: params.id, data: { status: 'confirm' } }))
			.unwrap()
			.then(() => {
				showToast('Bon de livraison confirme', 'success');
				navigate(`/admin/deliveries/${params.id}`);
			})
			.finally(() => setIsloading(false));
	};

	if (loading) {
		return <LoaderComponent />;
	}
	return (
		<div id='complainAdminDetails'>
			{messageModal.isShow && (
				<ModalMessage
					messageModal={messageModal}
					setMessageModal={setMessageModal}
					params={params}
				/>
			)}
			<div id='details-complain-header'>
				<Link to={`/admin/deliveries/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Bons de livraison</h1>
				</Link>
				{isShow === 'true' ? (
					<div className='buttons'>
						<button
							onClick={() => setMessageModal({ ...messageModal, isShow: true })}
							className='button-refuser'
						>
							Refuser paiement
						</button>
						<ButtonComponent
							name={'Confirmer paiement'}
							loading={isLoading}
							type={'button'}
							className='button-primary'
							onClick={handleConfirm}
						/>
					</div>
				) : null}
			</div>
			<div id='headComplain'>
				<div className='infos'>
					<div>
						<h2>Reference: </h2>
						<span>{payementDetails?.delivery?.reference}</span>
					</div>
					<div>
						<h2>Date: </h2>
						<span>{convertDate(payementDetails?.delivery?.date, 'DD-MM-YYYY')}</span>
					</div>
					<div>
						<h2>Client: </h2>
						<span>{payementDetails?.delivery?.client}</span>
					</div>
					<div>
						<h2>Societe: </h2>
						<span>{payementDetails?.delivery?.company_name}</span>
					</div>
					<div>
						<h2>Total: </h2>
						<span>{payementDetails?.delivery?.total}</span>
					</div>
				</div>
			</div>
			<div className='content'>
				{payementDetails?.payement?.message && (
					<div id='montant'>
						<h3 style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
							Raison du refus <span className='refus'>!</span>
						</h3>
						<p style={{ color: '#454545', fontSize: '1rem' }}>{payementDetails.payement.message}</p>
					</div>
				)}
				<div>
					<h3>Type de paiement</h3>
					<p>
						{payementDetails?.payement?.type === 'cash'
							? 'Espèces'
							: payementDetails?.payement?.type === 'cheque'
							  ? "Chèque d'argent"
							  : 'Virement bancaire'}
					</p>
				</div>
				<div>
					<h3>Montant payé</h3>
					<p>{payementDetails?.payement?.price} dt</p>
				</div>
				<div>
					<h3>Reste de paiement</h3>
					<p>
						{payementDetails?.payement?.price > payementDetails?.delivery?.total ? (
							<>
								Montant supplémentaire
								<span style={{ color: 'green' }}>
									{roundToThreeDecimals(
										payementDetails?.payement?.price - payementDetails?.delivery?.total,
									)}
								</span>
							</>
						) : (
							<span>
								{roundToThreeDecimals(
									payementDetails?.delivery?.total - payementDetails?.payement?.price,
								)}
							</span>
						)}{' '}
						dt
					</p>
				</div>
			</div>
			{payementDetails?.payement?.image && (
				<div id='img'>
					<h3>Preuve d'image</h3>

					<div className='image'>
						<div className='imgBlock'>
							<div id='imgUpload'>
								<div className='fileUp'>
									<img
										src={payementDetails?.payement?.image?.secure_url}
										id='imgPreview'
										value=''
										alt='image_uploaded'
										width={200}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PaymentDetails;
