import { useParams } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';
import { icons } from '../../../assets/icons';
import SeeProduct from './SeeProduct';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCategoriesForProducts,
	getCommercialProductsList,
} from '../../../api/redux/slices/Commercial';
import Pagination from '../../../helpers/components/Pagination';
import { LoaderComponent } from '../../../helpers/components/loader';

const ProductPageCom = () => {
	const [modalOpen, setModalOpen] = useState(false);
	const [indiceProduit, setIndiceProduit] = useState(null);
	const params = useParams();
	const { listProducts, total } = useSelector((state) => state.commercial);
	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState(Number(params.page));
	const [search, setSearch] = useState('');
	const [categoryValue, setCategoryValue] = useState('');

	const [categoriesList, setCategorisList] = useState([]);

	const dispatch = useDispatch();

	const token = localStorage.getItem('accesstoken');

	//----------------------- get products and categories features -------------------------------//
	useEffect(() => {
		dispatch(getCategoriesForProducts({ token: token }))
			.unwrap()
			.then((res) => setCategorisList(res.data));
	}, [dispatch, token]);

	useEffect(() => {
		setIsLoading(true);
		dispatch(
			getCommercialProductsList({
				token: token,
				page: search || categoryValue ? undefined : page,
				search,
				category_name: categoryValue,
			}),
		)
			.unwrap()
			.finally(() => setIsLoading(false));
	}, [dispatch, token, categoryValue, search, page]);

	//------------------------ main component ----------------------//
	return (
		<div id='pr-page-com'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='search'
							placeholder='Rechercher ...'
							onChange={(e) => setSearch(e.target.value)}
						/>
						<icons.FiSearch className='searchicon' />
					</div>
					<div className='filters'>
						<select
							name='catégories'
							value={categoryValue}
							onChange={(e) => setCategoryValue(e.target.value)}
						>
							<option value=''>Catégories</option>
							{categoriesList.map((cat) => (
								<Fragment key={cat._id}>
									<option value={cat._id}>{cat.designation}</option>
								</Fragment>
							))}
						</select>
					</div>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Produits</h1>
				</div>
			</div>
			{isLoading ? (
				<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
					<LoaderComponent margin='10%' />
				</div>
			) : (
				<div id='cartContent'>
					{listProducts.map((element, index) => (
						<div key={index}>
							<div className='card'>
								<div className='top'>
									<img
										src={element.image.secure_url || process.env.REACT_APP_IMAGE_PLACEHOLDER_URL}
										alt='product_image'
									/>
									<div className='cart-actions'>
										<div
											className='eye'
											onClick={() => {
												setIndiceProduit(element._id);
												setModalOpen(!modalOpen);
											}}
										>
											<icons.BsEye />
										</div>
									</div>
								</div>
								<div className='bottom'>
									<h3>{element.designation}</h3>
									{element.capacity < 1 ? (
										<p style={{ color: '#EF4444', fontSize: '16px', fontWeight: 500 }}>
											Rupture de stock
										</p>
									) : (
										<>
											<span>{element.prise_HT} HT</span>
										</>
									)}
								</div>
							</div>
							{indiceProduit === element._id && (
								<SeeProduct
									product_id={element._id}
									modalOpen={modalOpen}
									setModalOpen={setModalOpen}
								/>
							)}
						</div>
					))}
				</div>
			)}
			<Pagination page={page} setPage={setPage} pageLink='/commercial/products/' total={total} />
		</div>
	);
};

export default ProductPageCom;
