import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useDispatch } from 'react-redux';
import { addOrderByAdmin } from '../../../api/redux/slices/Admin';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { roundToThreeDecimals, totalHT, typePrice } from '../../../helpers/modules';
import { ButtonComponent } from '../../../components/buttons';
import ShopInfo from './ShopInfo';

const AddOrder = () => {
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const token = localStorage.getItem('accesstoken');

	const [detailOrder, setDetailOrder] = useState({});
	const [remiseTotal, setRemiseTotal] = useState(0);
	const [updatedList, setUpdatedList] = useState(detailOrder?.list);
	const [loaderAction, setLoaderAction] = useState(false);
	const [newDate, setNewDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

	useEffect(() => {
		setRemiseTotal(Math.max(remiseTotal, 0));
	}, [remiseTotal]);

	//------------------------------ GET ORDER------------------------//
	useEffect(() => {
		let order = localStorage.getItem('order');
		order = JSON.parse(order);
		let company = localStorage.getItem('company');
		company = JSON.parse(company);
		setDetailOrder({ ...order, client: company });
		setUpdatedList(order?.orderDetails?.list ?? []);
	}, [token, params]);

	const [clientType, setClientType] = useState('');

	useEffect(() => {
		setClientType(detailOrder?.client?.categories);
	}, [detailOrder?.client?.categories]);

	const handleAddOrder = (e) => {
		e.preventDefault();
		setLoaderAction(true);

		const productList = updatedList.map((item) => {
			const prise_product_HT = item?.product_id?.[typePrice(clientType)] * (1 - item.remise / 100);
			const Amount_excluding_tax = prise_product_HT * (1 + (item?.product_id?.Tva || 0) / 100);

			return {
				product_id: item.product_id._id,
				quantity: item.quantity,
				remise: item.remise ?? 0,
				prise_product_HT: roundToThreeDecimals(prise_product_HT),
				Amount_excluding_tax: roundToThreeDecimals(Amount_excluding_tax),
			};
		});

		const { date, ...orderDetailsWithoutDate } = detailOrder.orderDetails;

		let data = {
			...orderDetailsWithoutDate,
			list: productList,
			date: newDate,
			user_id: detailOrder.orderDetails?.user_id,
			total: TotalTCC(),
			total_HT: totalHT(updatedList, clientType),
		};
		delete data.client;

		dispatch(addOrderByAdmin({ token: token, data: data }))
			.unwrap()
			.then((res) => {
				toast.info('commande ajouté', {
					position: 'top-center',
					autoClose: 1000,
				});
				navigate(`/admin/orders/${params.page}`);
			})
			.catch((err) => {
				toast.error(err?.data?.message || err, {
					position: 'top-center',
					autoClose: 2000,
				});
			})
			.finally(() => {
				setLoaderAction(false);
			});
	};

	const handleChangeProductRemise = (index, e) => {
		setUpdatedList((prevList) => {
			const updatedListCopy = [...prevList];
			updatedListCopy[index] = {
				...updatedListCopy[index],
				remise: Number(e.target.value),
			};
			return updatedListCopy;
		});
	};

	const handleDispatchRemiseTotal = (e) => {
		const value = Number(e.target.value);
		if (value < 0) {
			return;
		}
		setRemiseTotal(value);
		setUpdatedList((prevList) => {
			const updatedListCopy = [...prevList];
			updatedListCopy.map((el) => {
				return {
					...el,
					remise: value,
				};
			});
			const tmp = updatedListCopy.map((el) => {
				return {
					...el,
					remise: value,
				};
			});
			return tmp;
		});
	};

	const calculateTotalTva = () => {
		const tvaMap = new Map();

		updatedList?.forEach((el) => {
			const tvaValue = el?.product_id?.Tva ?? 0;
			const totalAmount =
				el?.product_id?.[typePrice(clientType)] *
					el?.quantity *
					(1 - el.remise / 100) *
					(tvaValue / 100) ?? 0;

			if (tvaMap.has(tvaValue)) {
				tvaMap.set(tvaValue, tvaMap.get(tvaValue) + totalAmount);
			} else {
				tvaMap.set(tvaValue, totalAmount);
			}
		});

		return Array.from(tvaMap.entries()).map(([tvaValue, totalAmount]) => ({
			tvaValue,
			totalAmount: roundToThreeDecimals(totalAmount),
		}));
	};
	const TotalTCC = () => {
		const totalTvaSum = calculateTotalTva().reduce((acc, { totalAmount }) => acc + totalAmount, 0);
		const totalHTAmount = totalHT(updatedList, clientType) ?? 0;
		const timbreAmount = detailOrder?.orderDetails?.timbre ?? 0;

		const totalTCC = roundToThreeDecimals(totalTvaSum) + totalHTAmount + timbreAmount;

		return roundToThreeDecimals(totalTCC);
	};

	const renderTvaValues = () => {
		const tvaList = calculateTotalTva();

		return tvaList?.map(({ tvaValue, totalAmount }, index) => (
			<div key={index}>
				<h1>{`TVA (${tvaValue}%)`}</h1> <span id='somme-totale'>{totalAmount}</span>
			</div>
		));
	};

	return (
		<div id='updateOrder'>
			<div className='update-order-header'>
				<Link to={`/admin/orders/order_cart/${params.page}/${params.companyId}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Panier</h1>
				</Link>
				<div style={{ display: 'flex', gap: '1rem' }}>
					<Link to={`/admin/orders/${params.page}`}>
						<button className='button-nature'>Annuler</button>
					</Link>
					<ButtonComponent
						name={'Enregistrer'}
						className={'button-primary'}
						loading={loaderAction}
						type={'button'}
						onClick={handleAddOrder}
					/>
				</div>
			</div>

			<div className='container'>
				<form>
					<div className='credentials'>
						<div className='infos-societe left' style={{ border: 'none' }}>
							<label>SOCIETE MED D'EQUIPT ET DE COMMERCE</label>
							<label>VTE EN GROS ART DE MENAGE 7 RUE EL KENDI KHZAMA OUEST</label>
							<label>SOUSSE</label>
						</div>
						<div className='left'>
							<div className='total'>
								<label>Montant Reçu</label>
								<span>{roundToThreeDecimals(detailOrder?.client?.received_amount) || '0'} DT</span>
							</div>
							<div className='total'>
								<label>Montant Dépensé</label>
								<span>{roundToThreeDecimals(detailOrder?.client?.spent_amount) || '0'} DT</span>
							</div>
						</div>
					</div>
					<div className='credentials'>
						<div className='left'>
							<div className='left-el'>
								<label>Numéro</label>
								<span>-</span>
							</div>
							<div className='left-el'>
								<label>Date</label>
								<input
									type='date'
									value={moment(newDate).isValid() ? moment(newDate).format('YYYY-MM-DD') : ''}
									onChange={(e) => setNewDate(e.target.value)}
								/>
							</div>
							<div className='left-el'>
								<label>Référence code client</label>
								<span>{detailOrder?.client?.customs_code || '-'}</span>
							</div>
						</div>
						<div className='right'>
							<label style={{ textTransform: 'capitalize' }}>
								{detailOrder?.client?.company_name || '-'}
							</label>
							<span>
								{detailOrder?.client?.first_name} {detailOrder?.client?.last_name}
							</span>
							<span>Matricule Fiscale: {detailOrder?.client?.tax_registration_number}</span>
							<span>N° téléphone: {detailOrder?.client?.phone ?? ''}</span>
							<span>
								<ShopInfo
									shop_id={detailOrder?.orderDetails?.shop_id}
									shop={detailOrder?.client?.shop}
								/>
							</span>
						</div>
					</div>
					<div className='devis'>
						<div className='devis-head'>
							<h1 id='devis-name-order-admin'>Devis</h1>
							<div className='total-devis'>
								<div>
									<div className='total-devis-line'>
										<h1>TYPE DE CLIENT</h1>
										<div className='info-dropdown'>
											<select value={clientType} onChange={(e) => setClientType(e.target.value)}>
												<option value='grossiste'>Grossiste</option>
												<option value='detaillant'>Detaillant</option>
												{/* <option value='umd'>UMD</option>
												<option value='black'>Black</option> */}
												<option value='monoprix'>Grande surface</option>
											</select>
										</div>
									</div>
									<div className='total-devis-line'>
										<h1>APPLIQUÉ LA REMISE À TOUS (%)</h1>
										<input
											type='number'
											value={parseInt(remiseTotal, 10).toString()}
											onChange={handleDispatchRemiseTotal}
										/>
									</div>
								</div>
							</div>
							<p>
								Cher Client,
								<br />
								Nous avons bien reçu votre demande de devis et nous vous en remercions.
								<br />
								Nous vous prions de trouver ci-dessous nos conditions les meilleures. Cher Client,{' '}
								<br />
							</p>
						</div>
						<div className='devis-table'>
							<table className='offres'>
								<thead>
									<tr id='titres'>
										<td>RÉFÉRENCE</td>
										<td>RÉFÉRENCE CLT</td>
										<td>DÉSIGNATION</td>
										<td>QTÉ</td>
										<td>PU HT</td>
										<td>REMISE (%)</td>
										<td>TVA (%)</td>
										<td>MONTANT HT</td>
									</tr>
								</thead>
								<tbody>
									{updatedList?.map((el, index) => (
										<tr className='elements' key={index}>
											<td>{el?.product_id?.reference ?? '-'}</td>
											<td>{el?.product_id?.referenceCLT ?? '-'}</td>
											<td>{el?.product_id?.designation ?? '-'}</td>
											<td>
												<span>{el?.quantity}</span>
											</td>
											<td>
												{roundToThreeDecimals(
													el?.product_id?.[typePrice(clientType)] * el?.quantity,
												) ?? '-'}
											</td>
											<td>
												<input
													type='number'
													name='remise'
													min={0}
													value={el.remise}
													onWheel={(e) => e.currentTarget.blur()}
													onChange={(e) => handleChangeProductRemise(index, e)}
												/>
											</td>
											<td>{el?.product_id?.Tva ?? '-'}</td>
											<td>
												{roundToThreeDecimals(
													el?.product_id?.[typePrice(clientType)] *
														el?.quantity *
														(1 - el?.remise / 100),
												) ?? '-'}
											</td>
										</tr>
									))}
								</tbody>
							</table>
							<div className='total-devis-table'>
								<div id='box'>
									<div>
										<h1>TOTAL HT</h1>
										<span id='somme-totale'>{totalHT(updatedList, clientType) ?? '-'}</span>
									</div>
									{renderTvaValues()}
									<div>
										<h1>TIMBRE</h1>
										<span id='somme-totale'>{detailOrder?.orderDetails?.timbre ?? 0}</span>
									</div>
									<div>
										<h1>TOTAL TTC</h1>
										<span id='somme-totale'>{TotalTCC()}</span>
									</div>
								</div>
							</div>
						</div>
						<div className='message'>
							<p>
								Nous sommes à votre disposition pour tout complément d'informations. <br />
								Nous vous prions d'agréer, Cher Client, nos sincères salutaations. <br />
								Le service commercial.
							</p>
						</div>
						<div className='footer'>
							<div className='left'>
								<ul>
									<li>Rue Mahmoud El Karoui SAHLOUL, SOUSSE 4051</li>
									<li>
										CODE TVA <span>11021539B/A/M 0000</span>
									</li>
									<li>
										RC: <span>B9141302009</span>
									</li>
									<li>
										Code en Douane <span>842782Z</span>
									</li>
								</ul>
							</div>
							<div className='left'>
								<ul>
									<li>
										Tél <span>31 405 595</span>
									</li>
									<li>
										Fax <span>32 405 595</span>
									</li>
									<li>
										Email: <span>somec.sousse@gmail.com</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AddOrder;
