import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useDispatch } from 'react-redux';
import { getCliamById } from '../../../api/redux/slices/Customer';
import { LoaderComponent } from '../../../helpers/components/loader';
import { convertDate } from '../../../helpers/modules';

const ClientComplainDetails = () => {
	const params = useParams();
	const [claimDetails, setClaimDetails] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		setIsLoading(true);
		dispatch(
			getCliamById({
				token: localStorage.getItem('accesstoken'),
				id: params.id,
			}),
		)
			.unwrap()
			.then((res) => setClaimDetails(res.data))
			.finally(() => setIsLoading(false));
	}, [dispatch, params.id]);

	if (isLoading) {
		return <LoaderComponent />;
	}
	return (
		<div id='complainClientDetails'>
			<div id='details-complain-header'>
				<Link to={`/client/complaints/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Mes reclamations</h1>
				</Link>
			</div>
			<div id='headComplain'>
				<div className='c-line'>
					<h2>Date: </h2>
					<span>{convertDate(claimDetails?.date, 'DD-MM-YYYY')}</span>
				</div>
			</div>
			<div id='object'>
				<h2>Sujet de la réclamation</h2>
				<span>{claimDetails?.type?.name}</span>
			</div>
			<div id='message-complain'>
				<h2>Message</h2>
				<p>{claimDetails?.message}</p>
			</div>
			<div id='message-complain'>
				<h2>Statut</h2>
				<p>{claimDetails?.status ? 'Traité' : 'En cours'}</p>
			</div>
		</div>
	);
};

export default ClientComplainDetails;
