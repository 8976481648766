import React, { Fragment } from 'react';

const StatusChangeComponent = ({
	statusValue,
	setStatusValue,
	listStatusOptions,
	name = 'Select Status',
}) => {
	return (
		<div className='filters'>
			<select name='status' value={statusValue} onChange={(e) => setStatusValue(e.target.value)}>
				<option value=''>{name}</option>
				{listStatusOptions.map((status) => (
					<Fragment key={status.id}>
						<option value={status.label}>{status.value}</option>
					</Fragment>
				))}
			</select>
		</div>
	);
};

export default StatusChangeComponent;
