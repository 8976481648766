import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getReturnsByAdmin } from '../../../api/redux/slices/Admin';
import { LoaderComponent } from '../../../helpers/components/loader';
import { convertDate } from '../../../helpers/modules';
import Pagination from '../../../helpers/components/Pagination';
import useStableArr from '../../../utils/useStableArr';
import { CSVLink } from 'react-csv';
import { handleCheck, handleCheckAll } from '../../../utils/checkUtilsAdmin';

const ReturnsPage = () => {
	const { returns, checkedList, total } = useSelector((state) => state.admin);
	const dispatch = useDispatch();
	const params = useParams();
	const [page, setPage] = useState(Number(params.page));

	const [searchInput, setSearchInput] = useState('');
	const [loading, setLoading] = useState(false);
	const [isCheck, setIsCheck] = useState(false);
	const [newArr, setNewArr] = useState(returns?.map((el) => ({ ...el, checked: false })));
	const token = localStorage.getItem('accesstoken');
	//----------------------- GET ALL CLAIMS ---------------------------//
	const stableDispatch = useCallback(() => {
		setLoading(true);
		dispatch(
			getReturnsByAdmin({
				token: token,
				search: searchInput,
				page: searchInput ? undefined : page,
			}),
		)
			.unwrap()
			.finally(() => setLoading(false));
	}, [dispatch, page, searchInput, token]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [stableDispatch, page, searchInput, token]);

	// --------------- RETRIVE LATEST DATA ---------------//
	useStableArr(returns, checkedList, setNewArr, setIsCheck);

	//-------------- HANDLE Export ----------------//
	const headers = [
		{ label: 'NUMÉRO', key: 'reference' },
		{ label: 'DATE', key: 'createdAt' },
		{ label: 'CLIENT', key: 'client' },
		{ label: 'SOCIÉTÉ', key: 'company_name' },
		{ label: 'TOTAL', key: 'totalQuantity' },
	];

	const csvDeliveryReport = {
		filename: 'Returns.csv',
		data: checkedList,
		headers: headers,
	};

	if (loading && returns?.length === 0) {
		<LoaderComponent />;
	}
	return (
		<div id='returnPage'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='search'
							placeholder='Rechercher ...'
							value={searchInput}
							onChange={(e) => setSearchInput(e.target.value)}
						/>
						<icons.FiSearch className='searchicon' />
					</div>
				</div>
				<div className='right'>
					<div className='right-items'>
						<CSVLink {...csvDeliveryReport} target='blank'>
							<button className='button-nature' title='exporter livraison(s)'>
								<icons.BiExport />
							</button>
						</CSVLink>
						{/* <button className='button-nature' title='importer retour(s)'>
							<icons.BiImport />
						</button> */}
					</div>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Bons de retour</h1>
				</div>
			</div>
			<div id='returnsContent'>
				<table id='returns'>
					<thead>
						<tr id='titres'>
							<td className='input-check'>
								<input
									type='checkbox'
									className='checkbox'
									checked={isCheck}
									onChange={() => handleCheckAll(dispatch, newArr, setNewArr, isCheck, setIsCheck)}
								/>
							</td>
							<td>NUMÉRO</td>
							<td>DATE</td>
							<td>CLIENT</td>
							<td>SOCIÉTÉ</td>
							<td className='center'>ARTICLES RETOURNÉS</td>
							<td className='center'>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{loading ? (
							<tr>
								<td colSpan='7'>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							newArr.map((el, index) => (
								<tr className='return' key={index}>
									<td className='input-check'>
										<input
											type='checkbox'
											className='checkbox'
											checked={el?.checked ?? false}
											onChange={() => handleCheck(dispatch, newArr, setNewArr, el?._id)}
										/>
									</td>
									<td>{el?.reference ?? '-'}</td>
									<td>{convertDate(el?.createdAt, 'DD-MM-YYYY')}</td>
									<td>{el?.client ?? '-'}</td>
									<td>{el?.company_name ?? '-'}</td>
									<td className='center'>{el?.totalQuantity ?? '-'} </td>
									<td className='last-return-element center'>
										<Link
											to={`/admin/returns/return_details/${params.page}/${el._id}`}
											className='link-return'
										>
											<icons.BsEye className='see-return' />
										</Link>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink={`/admin/returns/`} total={total} />
		</div>
	);
};

export default ReturnsPage;
