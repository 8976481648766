import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useDispatch } from 'react-redux';
import { getClaimByIdAdmin, updateClaimStatus } from '../../../api/redux/slices/Admin';
import { LoaderComponent } from '../../../helpers/components/loader';
import { convertDate } from '../../../helpers/modules';
import { ButtonComponent } from '../../../components/buttons';
import { showToast } from '../../../components/modal/index';

const ComplainDetails = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const [detailComplain, setDetailComplain] = useState();
	const [loading, setLoading] = useState(false);
	const [isloading, setIsLoading] = useState(false);

	const token = localStorage.getItem('accesstoken');
	const [selectedStatus, setSelectedStatus] = useState(null);
	const navigate = useNavigate();

	//----------------------- GET ALL CLAIMS ---------------------------//
	useEffect(() => {
		setLoading(true);
		dispatch(
			getClaimByIdAdmin({
				token: token,
				id: params.id,
			}),
		)
			.unwrap()
			.then((res) => {
				setDetailComplain(res.data);
			})
			.finally(() => setLoading(false));
	}, [dispatch, params, token]);

	useEffect(() => {
		setSelectedStatus(detailComplain?.status);
	}, [detailComplain?.status]);

	const updateClaim = () => {
		setIsLoading(true);
		dispatch(
			updateClaimStatus({
				token: token,
				id: params.id,
				status: selectedStatus,
			}),
		)
			.unwrap()
			.then(() => {
				showToast('Statut mis à jour', 'success');
				setTimeout(() => {
					navigate(`/admin/complaints/${params.page}`);
				}, []);
			})
			.finally(() => setIsLoading(false));
	};

	if (loading) return <LoaderComponent />;
	return (
		<div id='complainDetails'>
			<div id='details-complain-header'>
				<Link to={`/admin/complaints/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Listes des reclamations</h1>
				</Link>
			</div>
			<div id='headComplain'>
				<div className='c-line'>
					<h2>Date: </h2>
					<span>{convertDate(detailComplain?.date, 'DD-MM-YYYY')}</span>
				</div>
				<div className='c-line'>
					<h2>Client: </h2>
					<span>{detailComplain?.created_by}</span>
				</div>
				<div className='c-line'>
					<h2>Société: </h2>
					<span>{detailComplain?.company}</span>
				</div>
				<div className='c-line'>
					<h2>Email:</h2>
					<span>{detailComplain?.email}</span>
				</div>
				<div className='c-line'>
					<h2>Téléphone:</h2>
					<span>{detailComplain?.phone}</span>
				</div>
			</div>
			{detailComplain?.type && (
				<div id='object'>
					<h2>Objet de la réclamation</h2>
					<span>{detailComplain?.type.name}</span>
				</div>
			)}
			<div id='message-complain'>
				<h2>Message</h2>
				<p>{detailComplain?.message}</p>
			</div>
			<div id='statut-complain'>
				<h2>Statut de la réclamation</h2>
				<div id='radio-status'>
					<label>
						<input
							type='radio'
							checked={!selectedStatus}
							onChange={() => setSelectedStatus((prev) => !prev)}
						/>
						En cours
					</label>
					<label>
						<input
							type='radio'
							checked={selectedStatus}
							onChange={() => setSelectedStatus((prev) => !prev)}
						/>
						Traité
					</label>
				</div>
			</div>
			<ButtonComponent
				name={'Enregistrer'}
				loading={isloading}
				type={'button'}
				className={'button-primary'}
				onClick={updateClaim}
			/>
		</div>
	);
};

export default ComplainDetails;
