import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { newAdmin } from '../../../api/redux/slices/SuperAdmin';
import { icons } from '../../../assets/icons';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '../../../components/modal';

const AddAdmin = ({ modalOpen, setModalOpen }) => {
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [confirm_PasswordVisible, setConfirm_PasswordVisible] = useState(false);
	const [isFocus, setIsFocus] = useState(false);

	const dispatch = useDispatch();
	const token = localStorage.getItem('accesstoken');

	const [values, setValues] = useState({
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
		password: '',
		confirm_password: '',
	});

	const handleAdd = (e) => {
		e.preventDefault();
		const data = {
			first_name: values.first_name.trim(),
			last_name: values.last_name.trim(),
			email: values.email.trim(),
			phone: values.phone.trim(),
			password: values.password,
			confirm_password: values.confirm_password,
		};

		if (!data.first_name || !data.last_name || !data.email || !data.phone) {
			showToast('Veuillez renseigner tous les champs', 'warn');
			return;
		}
		dispatch(newAdmin({ token: token, data: data }))
			.unwrap()
			.then(() => {
				showToast('Utilisateur ajouté', 'success');
				setModalOpen(false);
			})
			.catch((err) => showToast("Une erreur s'est produite", 'error'));
	};

	const togglePassword = () => {
		var x = document.getElementById('passwordInput');
		if (x.type === 'password') {
			x.type = 'text';
			setPasswordVisible(true);
		} else {
			x.type = 'password';
			setPasswordVisible(false);
		}
	};
	const toggleConfirm_Password = () => {
		var x = document.getElementById('confirm_passwordInput');
		if (x.type === 'password') {
			x.type = 'text';
			setConfirm_PasswordVisible(true);
		} else {
			x.type = 'password';
			setConfirm_PasswordVisible(false);
		}
	};

	return (
		<div className={'modalcontainer ' + (modalOpen && 'open')}>
			<div className={'modal ' + (modalOpen && 'active')}>
				<h1>Ajouter un nouveau administrateur</h1>
				<form onSubmit={handleAdd}>
					<div className='line-admin'>
						<div className='label-input'>
							<label>Nom</label>
							<input
								type='text'
								placeholder='Nom'
								value={values.first_name}
								onChange={(e) => {
									setValues({ ...values, first_name: e.target.value });
									setIsFocus(true);
								}}
								required
							/>
						</div>
						<div className='label-input'>
							<label>Prénom</label>
							<input
								type='text'
								placeholder='Prénom'
								value={values.last_name}
								onChange={(e) => setValues({ ...values, last_name: e.target.value })}
								required
							/>
						</div>
					</div>
					<div className='label-input'>
						<label>N° de téléphone</label>
						<input
							type='text'
							placeholder='ex: 21 212 212'
							value={values.phone}
							onChange={(e) => {
								const onlyNumbers = e.target.value.replace(/[^0-9+\s]/g, '');
								setValues({ ...values, phone: onlyNumbers });
							}}
							required
						/>
					</div>
					<div className='label-input'>
						<label>Email</label>
						<input
							type='email'
							placeholder='nom@email.com'
							value={values.email}
							onChange={(e) => setValues({ ...values, email: e.target.value })}
							required
						/>
					</div>
					<div className='label-input'>
						<label>Mot de passe </label>
						<input
							type='password'
							placeholder='Entrer le mot de passe'
							id='passwordInput'
							value={values.password}
							onChange={(e) => setValues({ ...values, password: e.target.value })}
							required
						/>
						{passwordVisible ? (
							<icons.BsEyeSlash onClick={togglePassword} className='see-password' />
						) : (
							<icons.BsEye onClick={togglePassword} className='see-password' />
						)}
					</div>
					<div className='label-input'>
						<label>Répéter le mot de passe</label>
						<input
							type='password'
							id='confirm_passwordInput'
							placeholder='confirmer le mot de passe'
							value={values.confirm_password}
							onChange={(e) =>
								setValues({ ...values, confirm_password: e.target.value.replace(/\s/g, '') })
							}
							required
						/>
						{confirm_PasswordVisible ? (
							<icons.BsEyeSlash onClick={toggleConfirm_Password} className='see-password' />
						) : (
							<icons.BsEye onClick={toggleConfirm_Password} className='see-password' />
						)}
					</div>

					<div className='modalbtn'>
						<button
							className={isFocus ? 'button-nature' : 'button-nature-inactive'}
							onClick={(e) => {
								e.preventDefault();
								setValues({
									email: '',
									password: '',
									confirm_password: '',
									phone: '',
									first_name: '',
									last_name: '',
								});
								setModalOpen(!modalOpen);
							}}
						>
							Annuler
						</button>
						<button
							type='submit'
							className={isFocus ? 'button-primary' : 'button-primary-inactive'}
						>
							Confirmer
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AddAdmin;
