import { Link, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { icons } from './../../assets/icons/index';

const Pagination = ({ page, setPage, pageLink, total }) => {
	const totalPages = Math.ceil(total / 10);
	page = parseInt(page);
	const params = useParams();

	useEffect(() => {
		if (Number(params.page) !== page) {
			if (!params.page) {
				setPage(1);
			} else {
				setPage(Number(params.page));
			}
		}
	}, [page, params.page, setPage]);

	return (
		<div className='pagination'>
			{totalPages > 1 && (
				<div className='count'>
					<span>{page > 0 && page}</span> - <span>10</span> sur <span>{total}</span>
				</div>
			)}

			<div className='arrows'>
				{page > 1 && (
					<Link to={page === 1 ? `${pageLink}${page}` : `${pageLink}${page - 1}`}>
						<icons.IoIosArrowBack
							className='arrow'
							onClick={() => {
								page === 1 ? setPage(1) : setPage(page - 1);
							}}
						/>
					</Link>
				)}

				{page < totalPages && (
					<Link to={`${pageLink}${parseInt(page) + 1}`}>
						<icons.IoIosArrowForward
							className='arrow'
							onClick={() => {
								page === 0 ? setPage(1) : setPage(page + 1);
							}}
						/>
					</Link>
				)}
			</div>
		</div>
	);
};
export default Pagination;
