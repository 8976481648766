import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './index.scss';
import { icons } from '../../../assets/icons';
import { useDispatch } from 'react-redux';
import { LoaderComponent } from '../../../helpers/components/loader';
import { getUserDetails } from '../../../api/redux/slices/Commercial';

const ClientDetails = () => {
	const params = useParams();

	const [infos, setInfos] = useState({});
	const token = localStorage.getItem('accesstoken');
	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch();
	const stableDispatch = useCallback(() => {
		setIsLoading(true);
		dispatch(getUserDetails({ token: token, id: params.id }))
			.unwrap()
			.then((res) => {
				setInfos(res.data);
			})
			.finally(() => setIsLoading(false));
	}, [dispatch, params, token]);

	useEffect(() => {
		stableDispatch();
	}, [stableDispatch, params.id]);

	// ------------------------- Loader ---------------------------------//
	if (isLoading) {
		return <LoaderComponent />;
	}
	return (
		<div id='clientProfile-cp'>
			<div id='add-header'>
				<Link to={`/commercial/users/${params.page}`}>
					<icons.BsArrowLeft style={{ marginRight: '10px' }} />
					<h1>Liste des clients</h1>
				</Link>
			</div>
			<div className='cp-box'>
				<h2>Type de catégorie</h2>
				<div className='line' style={{ marginBottom: '1rem' }}>
					<h3>Catégorie</h3>
					<span>{infos.categories}</span>
				</div>
				<h2>Type de réglément</h2>
				<div className='line' style={{ marginBottom: '1rem' }}>
					<h3>Réglément</h3>
					<span>{infos.regulation}</span>
				</div>
				<h2>Information générale</h2>
				<div className='line'>
					<h3>nom</h3>
					<span>{infos.first_name}</span>
				</div>
				<div className='line'>
					<h3>Prénom</h3>
					<span>{infos.last_name}</span>
				</div>
				<div className='line'>
					<h3>Email</h3>
					<span>{infos.email}</span>
				</div>
				<div className='line'>
					<h3>Référence code client</h3>
					<span>{infos.customs_code}</span>
				</div>
				<div className='line'>
					<h3>N° De Téléphone</h3>
					<span>{infos.phone}</span>
				</div>
				<div className='line'>
					<h3>Nom de société</h3>
					<span>{infos.company_name ? infos.company_name : '-'}</span>
				</div>
				<div className='line'>
					<h3>Matricule fiscal</h3>
					<span>{infos.tax_registration_number}</span>
				</div>
				{infos.role === 'customer' ? <h2>Liste des boutiques</h2> : null}
				{infos.role === 'customer' &&
					infos.shop.map((boutique) => (
						<Fragment key={boutique._id}>
							<div className='cp-box'>
								<div className='line'>
									<h3> {boutique.name}</h3>
									<span>
										{boutique.address_number} &nbsp; {boutique.street} &nbsp; {boutique.postal_code}{' '}
										&nbsp; {boutique.ville} &nbsp; {boutique.region}
									</span>
								</div>
							</div>
						</Fragment>
					))}
			</div>
		</div>
	);
};

export default ClientDetails;
