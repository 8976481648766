import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import useStableArr from '../../../utils/useStableArr';
import { LoaderComponent } from '../../../helpers/components/loader';
import Pagination from '../../../helpers/components/Pagination';
import { CSVLink } from 'react-csv';
import { convertDate } from '../../../helpers/modules';
import { handleCheck, handleCheckAll } from '../../../utils/checkUtilsCustomer';
import { getBillsByCustomer } from '../../../api/redux/slices/Customer';

const BillsClient = () => {
	const { bills, checkedList, total } = useSelector((state) => state.customer);
	const dispatch = useDispatch();
	const params = useParams();

	const [page, setPage] = useState(Number(params.page));
	const [newArr, setNewArr] = useState([]);
	const [isCheck, setIsCheck] = useState(false);
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(false);
	const token = localStorage.getItem('accesstoken');

	//----------------------- GET ALL Bills---------------------------//
	const dispatchRequest = useCallback(
		(search) => {
			setLoading(true);
			dispatch(
				getBillsByCustomer({
					token: token,
					page: search ? undefined : page,
					search: search.toUpperCase(),
				}),
			)
				.unwrap()
				.finally(() => setLoading(false));
		},
		[dispatch, page, token],
	);

	useEffect(() => {
		if (!search) {
			dispatchRequest(search);
		} else if (search.length > 2) {
			dispatchRequest(search);
		}
	}, [dispatchRequest, search, token]);

	// --------------- RETRIVE LATEST Bills DATA ---------------//
	useStableArr(bills, checkedList, setNewArr, setIsCheck);

	const headers = [
		{ label: 'NUMÉRO', key: 'reference' },
		{ label: 'DATE', key: 'createdAt' },
		{ label: 'CLIENT', key: 'delivery.order_id.clientInfo.client' },
		{ label: 'SOCIÉTÉ', key: 'delivery.order_id.clientInfo.company_name' },
		{ label: 'TOTAL', key: 'delivery.order_id.total' },
	];

	const csvDeliveryReport = {
		filename: 'Bills.csv',
		data: checkedList,
		headers: headers,
	};
	return (
		<div id='billPage'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							onChange={(e) => setSearch(e.target.value)}
							value={search}
							type='search'
							placeholder='Rechercher ...'
						/>
						<icons.FiSearch className='searchicon' />
					</div>
				</div>
				<div className='right'>
					<div className='right-items'>
						<CSVLink {...csvDeliveryReport} target='blank'>
							<button className='button-nature' title='exporter livraison(s)'>
								<icons.BiExport />
							</button>
						</CSVLink>
					</div>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Bons de factures</h1>
				</div>
			</div>
			<div id='billsContent'>
				<table id='bills'>
					<thead>
						<tr id='titres'>
							<td className='input-check'>
								<input
									onChange={() => handleCheckAll(dispatch, newArr, setNewArr, isCheck, setIsCheck)}
									checked={isCheck}
									type='checkbox'
									className='checkbox'
								/>
							</td>
							<td>NUMÉRO</td>
							<td>DATE</td>
							<td>TOTAL</td>
							<td style={{ margin: 'auto' }}>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{loading ? (
							<tr>
								<td colSpan={5}>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							newArr?.map((bill, index) => (
								<tr className='delivery' key={index}>
									<td className='input-check'>
										<input
											onChange={() => handleCheck(dispatch, newArr, setNewArr, bill?._id)}
											checked={bill?.checked ?? false}
											type='checkbox'
											className='checkbox'
										/>
									</td>
									<td>{bill.reference}</td>
									<td>{convertDate(bill?.createdAt, 'DD-MM-YYYY')}</td>
									<td>{bill?.delivery?.order_id?.total} DT</td>
									<td style={{ margin: 'auto' }} className='last-delivery-element'>
										<Link
											to={`/client/bills/bill_details/${params.page}/${bill._id}`}
											className='link-delivery'
										>
											<icons.BsEye className='see-delivery' />
										</Link>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink={`/client/bills/`} total={total} />
		</div>
	);
};

export default BillsClient;
