import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthServices from '../../services/AuthServices';

const initialState = {
	loading: false,
	user: [],
	error: null,
	errorMessage: null,
	authenticated: false,
	role: '',
	actived: '',
};

export const login = createAsyncThunk('/login', async ({ email, password }, thunkApi) => {
	try {
		const res = await AuthServices.connexion({ email, password });
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const sendEmail = createAsyncThunk('/sendEmail', async ({ email }, thunkApi) => {
	try {
		const res = await AuthServices.sendEmail(email);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const getUser = createAsyncThunk('/me', async ({ token }, thunkApi) => {
	try {
		const res = await AuthServices.getUserByToken(token);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const updateProfile = createAsyncThunk('/profile', async ({ token, data }, thunkApi) => {
	try {
		const res = await AuthServices.updateProfile(token, data);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const logout = createAsyncThunk('/logout', async ({ token }, thunkApi) => {
	try {
		const res = await AuthServices.deconnexion(token);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const clearState = () => {
	localStorage.removeItem('accesstoken');
};

export const adminProfileUpdate = createAsyncThunk(
	'/updateadmin',
	async ({ token, data }, thunkApi) => {
		try {
			const res = await AuthServices.updateAdminProfile(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const adminPasswordUpdate = createAsyncThunk(
	'/updateadmin',
	async ({ token, data }, thunkApi) => {
		try {
			const res = await AuthServices.updateAdminPassword(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		[clearState.fulfilled]: (state) => {
			state.loading = false;
			state.user = [];
			state.role = '';
			state.error = null;
			state.errorMessage = null;
			state.authenticated = false;
		},
	},
	extraReducers: {
		[logout.pending]: (state) => {
			state.loading = true;
		},
		[logout.fulfilled]: (state, action) => {
			state.user = [];
			state.role = '';
			state.loading = false;
			state.authenticated = false;
		},
		[logout.rejected]: (state, action) => {
			state.error = true;
		},
		[login.pending]: (state) => {
			state.loading = true;
		},
		[login.fulfilled]: (state, action) => {
			state.user = action.payload;
			state.loading = false;
		},
		[login.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload.message;
			state.authenticated = false;
			state.loading = false;
		},
		[getUser.pending]: (state) => {
			state.loading = true;
		},
		[getUser.fulfilled]: (state, action) => {
			state.user = action.payload;
			state.authenticated = true;
			state.role = action.payload.data.role;
			state.actived = action.payload.data.is_active;
			state.loading = false;
		},
		[getUser.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload;
			state.loading = false;
		},
		[updateProfile.pending]: (state) => {
			state.loading = true;
		},
		[updateProfile.fulfilled]: (state, action) => {
			state.user = { ...state.user, ...action.payload };
			state.loading = false;
		},
		[updateProfile.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload;
			state.loading = false;
		},
		[adminProfileUpdate.pending]: (state) => {
			state.loading = true;
		},
		[adminProfileUpdate.fulfilled]: (state, action) => {
			state.user = action.payload;
			state.error = false;
			state.isSuccess = action.payload.success;
			state.loading = false;
		},
		[adminProfileUpdate.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload;
			state.loading = false;
			state.isSuccess = false;
		},
		[adminPasswordUpdate.pending]: (state) => {
			state.loading = true;
		},
		[adminPasswordUpdate.fulfilled]: (state, action) => {
			state.user = action.payload;
			state.error = false;
			state.isSuccess = action.payload.success;
			state.loading = false;
		},
		[adminPasswordUpdate.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload;
			state.loading = false;
			state.isSuccess = false;
		},
	},
});

export default authSlice.reducer;
