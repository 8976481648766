import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './index.scss';

const ClientProfile = () => {
	const { user } = useSelector((state) => state.auth);
	const [infos, setInfos] = useState(user.data);

	useEffect(() => {
		setInfos(user.data);
	}, [user.data]);

	return (
		<div id='clientProfile-cp'>
			<div className='navbar'>
				<div className='left'>
					<h1>Profile</h1>
				</div>
				<div className='right'>
					<div className='right-items'>
						<Link to={`/client/profile/update_password`}>
							<button className='button-nature'>Changer le mot de passe</button>
						</Link>
						<Link to={`/client/profile/update`}>
							<button className='button-primary'>Modifier le profile</button>
						</Link>
					</div>
				</div>
			</div>
			<div className='cp-box'>
				<h2>Information générale</h2>
				<div className='line'>
					<h3>nom</h3>
					<span>{infos.first_name}</span>
				</div>
				<div className='line'>
					<h3>prénom</h3>
					<span>{infos.last_name}</span>
				</div>
				<div className='line'>
					<h3>email</h3>
					<span>{infos.email}</span>
				</div>
				<div className='line'>
					<h3>Référence code client</h3>
					<span>{infos.customs_code}</span>
				</div>
				<div className='line'>
					<h3>N° De Téléphone</h3>
					<span>{infos.phone}</span>
				</div>
				<div className='line'>
					<h3>nom de société</h3>
					<span>{infos.company_name ? infos.company_name : '-'}</span>
				</div>
				<div className='line'>
					<h3>Matricule fiscale</h3>
					<span>{infos.tax_registration_number ? infos.tax_registration_number : '-'}</span>
				</div>
			</div>
			<div className='cp-box'>
				<h2>Liste des boutiques</h2>
				{infos?.shop?.map((boutique) => (
					<div className='line' key={boutique._id}>
						<h3>{boutique.name}</h3>
						<span>
							{boutique.address_number}, {boutique.street}, {boutique.postal_code}, {boutique.ville}
							, {boutique.region}
						</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default ClientProfile;
