import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getOrderByIdAdmin, updateOrderByIdAdmin } from '../../../api/redux/slices/Admin';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoaderComponent } from '../../../helpers/components/loader';
import moment from 'moment';
import { roundToThreeDecimals, totalHT, typePrice } from '../../../helpers/modules';
import { ButtonComponent } from '../../../components/buttons';
import { DataErrorModal, showToast } from '../../../components/modal/index';
import { ShopInfo, ClientInfo, SuperAdminInfo } from '../../../components/userInfo/index';

const UpdateOrder = () => {
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const token = localStorage.getItem('accesstoken');

	const { orderDetails } = useSelector((state) => state.admin);

	const [detailOrder, setDetailOrder] = useState(orderDetails ?? {});
	const [remiseTotal, setRemiseTotal] = useState(0);
	const [updatedList, setUpdatedList] = useState(detailOrder?.list);
	const [loadingData, setLoadingData] = useState(false);
	const [loaderAction, setLoaderAction] = useState(false);
	const [updatedQuantities, setUpdatedQuantities] = useState(updatedList?.map((el) => el.quantity));

	const [errorData, setErrorData] = useState([]);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		setRemiseTotal(Math.max(remiseTotal, 0));
	}, [remiseTotal]);

	//------------------------------ GET ORDER------------------------//
	const stableDispatchOrderById = useCallback(() => {
		setLoadingData(true);
		dispatch(
			getOrderByIdAdmin({
				token: token,
				id: params.id,
			}),
		)
			.unwrap()
			.then((res) => {
				const updatedQuantities = [];
				let holdRemise = res.data.list[0]?.remise;

				res.data.list.forEach((el) => {
					updatedQuantities.push(el.quantity);
					if (holdRemise !== el.remise) {
						holdRemise = el.remise;
					}
				});

				setDetailOrder(res.data);
				setUpdatedList(res.data.list);
				setUpdatedQuantities(updatedQuantities);
				setRemiseTotal(res.data.list[0]?.remise !== holdRemise ? 0 : holdRemise);
			})
			.catch((err) => {
				toast.error(err?.error?.message || 'can not fetch this order', {
					position: 'top-center',
					autoClose: 2000,
				});
				setTimeout(() => {
					navigate(`/admin/order/${params.page}`);
				}, 1000);
			})
			.finally(() => setLoadingData(false));
	}, [dispatch, params, token, navigate]);

	useEffect(() => {
		stableDispatchOrderById();
	}, [stableDispatchOrderById, token, params]);

	const [clientType, setClientType] = useState(detailOrder?.clientInfo?.categories);
	const [newDate, setNewDate] = useState();

	useEffect(() => {
		setClientType(detailOrder?.order?.clientInfo?.categories);
		setNewDate(moment(detailOrder?.order?.date).format('YYYY-MM-DD'));
	}, [detailOrder]);

	const handleUpdateOrder = (e) => {
		e.preventDefault();

		if (updatedList.some((item) => item.quantity === 0)) {
			showToast('La quantité minimale doit être de 1', 'warn');
			return;
		}
		setLoaderAction(true);
		const productList = updatedList.map((item) => {
			const prise_product_HT = item?.product_id?.[typePrice(clientType)] * (1 - item?.remise / 100);
			const Amount_excluding_tax = prise_product_HT * (1 + (item?.product_id?.Tva || 0) / 100);
			return {
				order_item_id: item?._id,
				quantity: item?.quantity,
				remise: item?.remise,
				prise_product_HT: roundToThreeDecimals(prise_product_HT),
				Amount_excluding_tax: roundToThreeDecimals(Amount_excluding_tax),
			};
		});

		const {
			date,
			clientInfo,
			shopInfo,
			superAdminInfo,
			shop_id,
			user_id,
			created_By,
			role_created_By,
			...orderDetailsWithoutDate
		} = detailOrder.order;
		const data = {
			...orderDetailsWithoutDate,
			date: newDate,
			list: productList,
			total: TotalTCC(),
			total_HT: totalHT(updatedList, clientType),
		};
		const { _id, createdAt, updatedAt, __v, created_id, reference, status, number, ...cleanData } =
			data;

		dispatch(
			updateOrderByIdAdmin({
				token: token,
				id: params.id,
				data: cleanData,
			}),
		)
			.unwrap()
			.then((res) => {
				toast.info(res.message, {
					position: 'top-center',
					autoClose: 1000,
				});
				setTimeout(() => {
					navigate(`/admin/orders/${params.page}`);
				}, 1000);
			})
			.catch((err) => {
				if (err?.data) {
					setIsOpen(true);
					const errData = err.data.map((item) => ({
						Designation: item.designation,
						error: `Stock insuffisant pour ce produit maximum disponible : ${item.stock}`,
					}));
					setErrorData(errData);
				} else {
					toast.error(err || err.response.message, {
						position: 'top-center',
						autoClose: 1000,
					});
				}
			})
			.finally(() => setLoaderAction(false));
	};

	const handleChangeProductRemise = (index, e) => {
		setUpdatedList((prevList) => {
			const updatedListCopy = [...prevList];
			updatedListCopy[index] = {
				...updatedListCopy[index],
				remise: Number(e.target.value),
			};
			return updatedListCopy;
		});
	};

	const handleChangeProductQuantity = (index, e) => {
		const newQuantity = Number(e.target.value);
		setUpdatedQuantities((prevQuantities) => {
			const updatedQuantitiesCopy = [...prevQuantities];
			updatedQuantitiesCopy[index] = newQuantity;
			return updatedQuantitiesCopy;
		});

		setUpdatedList((prevList) => {
			const updatedListCopy = [...prevList];
			updatedListCopy[index] = {
				...updatedListCopy[index],
				quantity: newQuantity,
			};
			return updatedListCopy;
		});
	};

	const handleDispatchRemiseTotal = (e) => {
		const value = Number(e.target.value);
		if (value < 0) {
			return;
		}
		setRemiseTotal(value);
		setUpdatedList((prevList) => {
			const updatedListCopy = [...prevList];
			updatedListCopy.map((el) => {
				return {
					...el,
					remise: value,
				};
			});
			const tmp = updatedListCopy.map((el) => {
				return {
					...el,
					remise: value,
				};
			});
			return tmp;
		});
	};

	const calculateTotalTva = () => {
		const tvaMap = new Map();

		updatedList?.forEach((el) => {
			const tvaValue = el?.product_id?.Tva ?? '-';
			const totalAmount =
				el?.product_id?.[typePrice(clientType)] *
					el?.quantity *
					(1 - el.remise / 100) *
					(tvaValue / 100) ?? 0;

			if (tvaMap.has(tvaValue)) {
				tvaMap.set(tvaValue, tvaMap.get(tvaValue) + totalAmount);
			} else {
				tvaMap.set(tvaValue, totalAmount);
			}
		});

		return Array.from(tvaMap.entries()).map(([tvaValue, totalAmount]) => ({
			tvaValue,
			totalAmount: roundToThreeDecimals(totalAmount),
		}));
	};

	const TotalTCC = () => {
		const totalTvaSum = calculateTotalTva().reduce((acc, { totalAmount }) => acc + totalAmount, 0);

		const totalHTAmount = totalHT(updatedList, clientType) ?? 0;
		const timbreAmount = detailOrder?.orderDetails?.timbre ?? 1;

		const totalTCC = roundToThreeDecimals(totalTvaSum) + totalHTAmount + timbreAmount;

		return roundToThreeDecimals(totalTCC);
	};

	const renderTvaValues = () => {
		const tvaList = calculateTotalTva();

		return tvaList?.map(({ tvaValue, totalAmount }, index) => (
			<div key={index}>
				<h1>{`TVA (${tvaValue}%)`}</h1> <span id='somme-totale'>{totalAmount}</span>
			</div>
		));
	};

	return (
		<div id='updateOrder'>
			{isOpen && (
				<DataErrorModal isOpen={isOpen} closeModal={() => setIsOpen(false)} errorData={errorData} />
			)}
			<div className='update-order-header'>
				<Link to={`/admin/orders/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Bons de commandes</h1>
				</Link>
				<div style={{ display: 'flex', gap: '1rem' }}>
					<Link to={`/admin/orders/${params.page}`}>
						<button className='button-nature'>Annuler</button>
					</Link>
					<ButtonComponent
						name={'Enregistrer'}
						className={'button-primary'}
						loading={loaderAction}
						type={'button'}
						onClick={handleUpdateOrder}
					/>
				</div>
			</div>
			{loadingData ? (
				<LoaderComponent />
			) : (
				<div className='container'>
					<form>
						<div className='credentials'>
							<div className='infos-societe left' style={{ border: 'none' }}>
								<label style={{ textTransform: 'uppercase' }}>
									{detailOrder?.order?.superAdminInfo?.name}
								</label>
							</div>
							<div className='left'>
								<div className='total'>
									<label>Montant Reçu</label>
									<span>
										{roundToThreeDecimals(detailOrder?.order?.clientInfo?.received_amount) || '0'}{' '}
										DT
									</span>
								</div>
								<div className='total'>
									<label>Montant Dépensé</label>
									<span>
										{roundToThreeDecimals(detailOrder?.order?.clientInfo?.spent_amount) || '0'} DT
									</span>
								</div>
							</div>
						</div>
						<div className='credentials'>
							<div className='left'>
								<div className='left-el'>
									<label>Numéro</label>
									<span>{detailOrder?.order?.reference ?? '-'}</span>
								</div>
								<div className='left-el'>
									<label>Date</label>
									<input type='date' value={newDate} onChange={(e) => setNewDate(e.target.value)} />
								</div>
								<div className='left-el'>
									<label>Référence code client</label>
									<span>{detailOrder?.order?.clientInfo?.customs_code || '-'}</span>
								</div>
							</div>
							<div className='right'>
								<ClientInfo clientInfo={detailOrder?.order?.clientInfo} />
								<span>
									<ShopInfo shop={detailOrder?.order?.shopInfo} />
								</span>
							</div>
						</div>
						<div className='devis'>
							<div className='devis-head'>
								<h1 id='devis-name-order-admin'>Devis</h1>
								<div className='total-devis'>
									<div>
										<div className='total-devis-line'>
											<h1>TYPE DE CLIENT</h1>
											<div className='info-dropdown'>
												<select value={clientType} onChange={(e) => setClientType(e.target.value)}>
													<option value='grossiste'>Grossiste</option>
													<option value='detaillant'>Detaillant</option>
													{/* <option value='umd'>UMD</option>
													<option value='black'>Black</option> */}
													<option value='monoprix'>Grande surface</option>
												</select>
											</div>
										</div>
										<div className='total-devis-line'>
											<h1>APPLIQUÉ LA REMISE À TOUS (%)</h1>
											<input
												type='number'
												value={remiseTotal}
												onChange={handleDispatchRemiseTotal}
											/>
										</div>
									</div>
								</div>
								<p>
									Cher Client,
									<br />
									Nous avons bien reçu votre demande de devis et nous vous en remercions.
									<br />
									Nous vous prions de trouver ci-dessous nos conditions les meilleures. Cher Client,{' '}
									<br />
								</p>
							</div>
							<div className='devis-table'>
								<table className='offres'>
									<thead>
										<tr id='titres'>
											<td>RÉFÉRENCE</td>
											<td>RÉFÉRENCE CLT</td>
											<td>DÉSIGNATION</td>
											<td>QTÉ</td>
											<td>PU HT</td>
											<td>REMISE (%)</td>
											<td>TVA (%)</td>
											<td>MONTANT HT</td>
										</tr>
									</thead>
									<tbody>
										{updatedList?.map((el, index) => (
											<tr className='elements' key={index}>
												<td>{el?.reference ?? '-'}</td>
												<td>{el?.refranceCTL ?? '-'}</td>
												<td>{el?.designation ?? '-'}</td>
												<td>
													<input
														type='number'
														name='quantity'
														min={0}
														value={updatedQuantities?.[index]}
														onWheel={(e) => e.currentTarget.blur()}
														onChange={(e) => handleChangeProductQuantity(index, e)}
													/>
												</td>
												<td>{el?.product_id?.[typePrice(clientType)] ?? '-'}</td>
												<td>
													<input
														type='number'
														name='remise'
														min={0}
														value={el.remise}
														onWheel={(e) => e.currentTarget.blur()}
														onChange={(e) => handleChangeProductRemise(index, e)}
													/>
												</td>
												<td>{el?.product_id?.Tva ?? '-'}</td>
												<td>
													{roundToThreeDecimals(
														el?.product_id?.[typePrice(clientType)] *
															el?.quantity *
															(1 - el?.remise / 100),
													) ?? '-'}
												</td>
											</tr>
										))}
									</tbody>
								</table>
								<div className='total-devis-table'>
									<div id='box'>
										<div>
											<h1>TOTAL HT</h1>
											<span id='somme-totale'>
												{roundToThreeDecimals(totalHT(updatedList, clientType)) ?? '-'}
											</span>
										</div>
										{renderTvaValues()}
										<div>
											<h1>TIMBRE</h1>
											<span id='somme-totale'>{detailOrder?.order?.timbre ?? 0}</span>
										</div>
										<div>
											<h1>TOTAL TTC</h1>
											<span id='somme-totale'>{TotalTCC()}</span>
										</div>
									</div>
								</div>
							</div>
							<div className='message'>
								<p>
									Nous sommes à votre disposition pour tout complément d'informations. <br />
									Nous vous prions d'agréer, Cher Client, nos sincères salutaations. <br />
									Le service commercial.
								</p>
							</div>
							<SuperAdminInfo superAdminInfo={detailOrder?.order?.superAdminInfo} />
						</div>
					</form>
				</div>
			)}
		</div>
	);
};

export default UpdateOrder;
