import { convertDate } from '../../../helpers/modules';

const PrintToPdf = ({ componentRef, deliveryDetails }) => {
	if (deliveryDetails.length === 0) {
		return;
	}
	const {
		delivery: { order_id, ...details },
		...list
	} = deliveryDetails;
	let itemsPerPage = [8, 15];

	const renderTableRows = (start, end) => {
		return list?.list.slice(start, end).map((el, index) => (
			<tr className='elements' key={index}>
				<td>{el?.reference ?? '-'}</td>
				<td>{el?.refranceCTL ?? '-'}</td>
				<td>{el?.designation ?? '-'}</td>
				<td>{el?.quantity ?? '-'}</td>
			</tr>
		));
	};
	const renderTable = (start, end) => {
		return (
			<div key={start} className='page-content'>
				<div className='devis-table'>
					<table className='offres'>
						<thead>
							<tr id='titres'>
								<td>RÉFÉRENCE</td>
								<td>CODE E.A.N</td>
								<td>DÉSIGNATION</td>
								<td>QTÉ</td>
							</tr>
						</thead>
						<tbody>{renderTableRows(start, end)}</tbody>
					</table>
				</div>
			</div>
		);
	};
	return (
		<div className='delivery-pdf' ref={componentRef}>
			<div className='devis'>
				<h4>BON DE LIVRASION</h4>
				<div className='info'>
					<div>
						<label>Numéro:</label>
						<label>Date:</label>
						<label>Référence code client:</label>
					</div>
					<div>
						<span>{details?.reference ?? '-'}</span>
						<span>{convertDate(details?.date, 'DD-MM-YYYY') ?? ''}</span>
						<span>{order_id?.clientInfo?.customs_code ?? '-'}</span>
					</div>
				</div>
			</div>
			<div className='general-info'>
				<div className='left'>
					<h2 className='infos-societe'>{order_id.superAdminInfo?.name}</h2>
					<div className='address'>
						<span>VTE EN GROS ART DE MENAGE</span>
						<span>{order_id.superAdminInfo?.address}</span>
					</div>
				</div>
				<div className='right'>
					<h3>{order_id.clientInfo?.company_name ?? ''}</h3>

					<p>{order_id.clientInfo?.client ?? ''}</p>
					<p>Matricule Fiscale: {order_id.clientInfo?.tax_registration_number ?? ''}</p>
					<p>N° téléphone: {order_id?.clientInfo?.phone ?? ''}</p>
					<span>{order_id?.shopInfo.address_number} </span>
					<span>{order_id?.shopInfo.street} </span>
					<span>{order_id?.shopInfo.ville} </span>
					<span>{order_id?.shopInfo.postal_code} </span>
					<span>{order_id?.shopInfo.region} </span>
				</div>
			</div>
			<div className='cher-client'>
				<p>
					Cher Client,
					<br />
					Nous avons bien reçu votre demande de devis et nous vous en remercions.
					<br />
					Nous vous prions de trouver ci-dessous nos conditions les meilleures
				</p>
			</div>
			{renderTable(0, itemsPerPage[0])}
			{Array.from(
				{
					length: Math.ceil((list?.list?.length - itemsPerPage[0]) / itemsPerPage[1]),
				},
				(_, index) =>
					renderTable(
						itemsPerPage[0] + index * itemsPerPage[1],
						itemsPerPage[0] + (index + 1) * itemsPerPage[1],
					),
			)}
			<div className='livreur'>
				<div className='left'>
					<h4>NOM &amp; PRENOM DU LIVREUR</h4>
					<span>{deliveryDetails?.delivery?.commercial_name || '------------'}</span>
				</div>
				<div className='left'>
					<h4>MATRICULE VEHICULE</h4>
					<span>{deliveryDetails?.delivery?.matricule_vehicle || '------------'}</span>
				</div>
			</div>
			<div className='footer'>
				<div className='left'>
					<ul>
						<li>{order_id?.superAdminInfo?.address}</li>
						<li>
							CODE TVA <span>{order_id?.superAdminInfo?.tva_code}</span>
						</li>
						<li>
							RC: <span>{order_id?.superAdminInfo?.r_c}</span>
						</li>
						<li>
							Code en Douane <span>{order_id?.superAdminInfo?.customs_code || '-'}</span>
						</li>
					</ul>
				</div>
				<div className='left'>
					<ul>
						<li>
							Tél <span>{order_id?.superAdminInfo?.phone}</span>
						</li>
						<li>
							Fax <span>{order_id?.superAdminInfo?.fax}</span>
						</li>
						<li>
							Email: <span>{order_id?.superAdminInfo?.email}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default PrintToPdf;
