import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { icons } from '../../../assets/icons';
import { Link, useParams } from 'react-router-dom';
import './index.scss';
import { useDispatch } from 'react-redux';
import { getUserDetails } from '../../../api/redux/slices/Admin';
import { LoaderComponent } from '../../../helpers/components/loader';
import { convertCategory, convertRegulation } from '../../../helpers/modules';

const UserDetails = () => {
	const [infos, setInfos] = useState({});
	const params = useParams();
	const token = localStorage.getItem('accesstoken');
	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch();

	const stableDispatch = useCallback(() => {
		setIsLoading(true);
		dispatch(getUserDetails({ token: token, id: params.id }))
			.unwrap()
			.then((res) => {
				setInfos(res.data);
			})
			.finally(() => setIsLoading(false));
	}, [dispatch, params.id, token]);

	useEffect(() => {
		stableDispatch();
	}, [stableDispatch]);

	if (isLoading) {
		return <LoaderComponent />;
	}

	return (
		<div id='user-details'>
			<div className='details-user-header'>
				<Link to={`/admin/users/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Liste d'utilisateurs</h1>
				</Link>
				<Link to={`/admin/users/update_user/${params.page}/${params.id}`}>
					<button className='button-primary'>Modifier</button>
				</Link>
			</div>
			<div className='container'>
				<div className='user-type'>
					<h3>Type d'utilisateur</h3>
					<div className='line'>
						<label>Utilisateur</label>
						<span>{infos.role === 'customer' ? 'Client' : infos.role}</span>
					</div>
				</div>
				{infos.role === 'customer' && (
					<>
						<div className='user-type'>
							<h3>Type de catégorie</h3>
							<div className='line'>
								<label>Catégorie</label>
								<span>
									{infos.categories && infos.role === 'customer'
										? convertCategory(infos.categories)
										: '-'}
								</span>
							</div>
						</div>
						<div className='user-type'>
							<h3>Type de réglément</h3>
							<div className='line'>
								<label>Réglément</label>
								<span>
									{infos.regulation && infos.role === 'customer'
										? convertRegulation(infos.regulation)
										: '-'}
								</span>
							</div>
						</div>
					</>
				)}
				<div className='user-type'>
					<h3>Information générale</h3>
					<div className='line'>
						<label>Nom</label>
						<span>{infos.first_name}</span>
					</div>
					<div className='line'>
						<label>Prénom</label>
						<span>{infos.last_name}</span>
					</div>
					<div className='line'>
						<label>Email</label>
						<span>{infos.email}</span>
					</div>
					{infos.role === 'customer' && (
						<div className='line'>
							<label>Référence code client</label>
							<span>{infos.customs_code}</span>
						</div>
					)}
					<div className='line'>
						<label>N° de téléphone</label>
						<span>{infos.phone}</span>
					</div>
					<div className='line'>
						<label>Nom de société</label>
						<span>{infos.company_name ? infos.company_name : '-'}</span>
					</div>
					<div className='line'>
						<label>Matricule fiscal</label>
						<span>{infos.tax_registration_number}</span>
					</div>
					<div className='line'>
						<label>Chiffre d'affaire</label>
						<span>{infos.received_amount ? Number(infos.received_amount).toFixed(3) : '-'}</span>
					</div>
					<div className='line'>
						<label>Accompte</label>
						<span>
							{infos.received_amount && infos.spent_amount
								? (Number(infos.received_amount) - Number(infos.spent_amount)).toFixed(3)
								: '-'}
						</span>
					</div>
					{infos?.vehicles?.map((vehicle, index) => (
						<div className='line' key={index}>
							<label>Matricule vehicule {index + 1}</label>
							<span key={vehicle._id}>{vehicle.vehicle_number}</span>
						</div>
					))}
					{infos?.created_By?.first_name && (
						<div className='line'>
							<label>Créé par</label>
							<span>
								{infos?.created_By?.first_name} {infos?.created_By?.last_name}
							</span>
						</div>
					)}
				</div>
				{infos.role === 'customer' ? <h3>Liste des boutiques</h3> : null}
				{infos.role === 'customer' &&
					infos.shop.map((boutique) => (
						<Fragment key={boutique._id}>
							<div className='user-type'>
								<div className='line'>
									<label> {boutique.name}</label>
									<span>
										{boutique.region} &nbsp;
										{boutique.address_number} &nbsp; {boutique.street} &nbsp; {boutique.postal_code}{' '}
										&nbsp; {boutique.ville}
									</span>
								</div>
							</div>
						</Fragment>
					))}
			</div>
		</div>
	);
};

export default UserDetails;
