import React, { Fragment } from 'react';
import { icons } from '../../../assets/icons';
import { TunisiaCities } from '../../../data';
import DeleteConfirmationModal from './ShopDeleteModal';

const ShopFields = ({
	shopFields,
	handleChangeShopForm,
	handleDeleteAddedShop,
	showDeleteModal,
	setShowDeleteModal,
	shopToDeleteIndex,
	setShopToDeleteIndex,
	addField,
}) => {
	const ensureNonNegativeNumber = (e) => {
		const inputValue = parseInt(e.target.value, 10);
		if (inputValue < 0) {
			const newEvent = { ...e, target: { ...e.target, value: '0' } };
			return newEvent;
		}
		return e;
	};

	return (
		<Fragment>
			<div className='add-shop-title'>
				<h2>Liste des boutiques</h2>
				<icons.BsPlusSquareDotted
					style={{ fontSize: '1.3rem', cursor: 'pointer' }}
					title='ajouter une nouvelle boutique'
					onClick={addField}
				/>
			</div>
			{shopFields.map((inputValue, index) => (
				<div className='shop' key={index}>
					<div className='del-shop'>
						<div className='box-bordered' title='supprimer cette boutique'>
							<icons.BsTrash
								className='del-shop-icon'
								onClick={() => {
									setShowDeleteModal(true);
									setShopToDeleteIndex(inputValue.id);
								}}
							/>
						</div>
					</div>
					<label>Nom de boutique</label>
					<input
						type='text'
						placeholder='Nom de boutique'
						name='name'
						value={inputValue.name}
						onChange={(e) => handleChangeShopForm(inputValue.id, e)}
						required
					/>
					<div className='delivery-details'>
						<label>Adresse</label>
						<div className='info-1'>
							<input
								type='number'
								placeholder='Numéro'
								name='address_number'
								value={parseInt(inputValue.address_number, 10).toString()}
								onChange={(e) => handleChangeShopForm(inputValue.id, ensureNonNegativeNumber(e))}
								onWheel={(e) => e.currentTarget.blur()}
								required
							/>
							<input
								type='text'
								placeholder='Rue'
								name='street'
								value={inputValue.street}
								onChange={(e) => handleChangeShopForm(inputValue.id, e)}
								required
							/>
						</div>
						<div className='info-1'>
							<input
								type='text'
								placeholder='Ville'
								name='ville'
								value={inputValue.ville}
								onChange={(e) => handleChangeShopForm(inputValue.id, e)}
								required
							/>
							<input
								type='text'
								placeholder='Code postal'
								name='postal_code'
								value={inputValue.postal_code}
								onChange={(e) => handleChangeShopForm(inputValue.id, e)}
								required
							/>
						</div>
						<div className='info-dropdown'>
							<select
								required
								name='region'
								value={inputValue.region}
								onChange={(e) => handleChangeShopForm(inputValue.id, e)}
							>
								<option>Choisir un gouvernorat</option>
								{TunisiaCities.map((city, index) => (
									<Fragment key={index}>
										<option key={index} value={city.name}>
											{city.name}
										</option>
									</Fragment>
								))}
							</select>
						</div>
					</div>
					{showDeleteModal && (
						<DeleteConfirmationModal
							onDelete={() => {
								handleDeleteAddedShop(shopToDeleteIndex);
								setShowDeleteModal(false);
							}}
							onCancel={() => setShowDeleteModal(false)}
						/>
					)}
				</div>
			))}
		</Fragment>
	);
};

export default ShopFields;
