import React, { Fragment, useCallback, useEffect, useState } from 'react';
import './index.scss';
import { icons } from '../../../assets/icons';
import ComplainModal from './ComplainModal';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoaderComponent } from '../../../helpers/components/loader';
import Pagination from '../../../helpers/components/Pagination';
import { ClaimsClientsData } from '../../../api/redux/slices/Customer';
import { convertDate } from '../../../helpers/modules';

const ComplaintsClient = () => {
	const { claimsArr, total } = useSelector((state) => state.customer);
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [page, setPage] = useState(1);

	//------------------------ Fetch claims -----------------//
	const stableDispatch = useCallback(() => {
		setIsLoading(true);
		dispatch(
			ClaimsClientsData({
				token: localStorage.getItem('accesstoken'),
				search: undefined,
				page: page,
			}),
		)
			.unwrap()
			.finally(() => setIsLoading(false));
	}, [dispatch, page]);

	useEffect(() => {
		stableDispatch();
	}, [stableDispatch, modalOpen]);

	return (
		<div id='complaintsCl'>
			<div className='navbar'>
				<div className='left'></div>
				<div className='right'>
					<button className='button-primary' onClick={() => setModalOpen(!modalOpen)}>
						Nouvelle réclamation
					</button>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Mes réclamations</h1>
				</div>
			</div>
			<Fragment>
				<ComplainModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
			</Fragment>
			<div id='complaintsContent'>
				<table id='complaints'>
					<thead>
						<tr id='titres'>
							<td>DATE D'AJOUT</td>
							<td>OBJECTIF</td>
							<td>MESSAGE</td>
							<td style={{ margin: 'auto' }}>STATUT</td>
							<td style={{ margin: 'auto' }}>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{isLoading ? (
							<tr>
								<td colSpan={5}>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							claimsArr?.map((element, index) => (
								<tr className='complain' key={index}>
									<td>{convertDate(element.createdAt, 'DD-MM-YYYY')}</td>
									<td className='object'>{element.object.name}</td>
									<td id='object'>{element.message}</td>
									<td
										style={{ margin: 'auto' }}
										className={`status ${element.status ? 'traité' : ''}`}
									>
										{element.status ? 'Traité' : 'En cours'}
									</td>
									<td style={{ margin: 'auto' }} className='last-complain-element'>
										<Link to={`/client/complaints/complain_details/${page}/${element._id}`}>
											<icons.BsEye className='see-complain' />
										</Link>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink={`/client/complaints/`} total={total} />
		</div>
	);
};

export default ComplaintsClient;
