import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { claimList } from '../../../api/redux/slices/SuperAdmin';
import { LoaderComponent } from '../../../helpers/components/loader';

const DetailsComplainAdmin = () => {
	const { claimsList, loading } = useSelector((state) => state.superAdmin);
	const dispatch = useDispatch();
	const token = localStorage.getItem('accesstoken');

	const [infos, setInfos] = useState(claimsList);
	const params = useParams();

	const stableDispatch = useCallback(() => {
		dispatch(claimList({ token: token, page: params.page }));
	}, [dispatch, params.page, token]);
	useEffect(() => {
		stableDispatch();
	}, [stableDispatch]);

	useEffect(() => {
		if (params.id) {
			setInfos(claimsList.filter((x) => x._id === params.id));
		}
	}, [params.id, claimsList]);

	const handleConvert = (date) => {
		const newDate = new Date(date);
		return newDate.toLocaleString();
	};

	if (loading) {
		return <LoaderComponent />;
	}
	return (
		<div id='dc-superadmin'>
			<div id='dc-admin-header'>
				<Link to={`/superadmin/complaints/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '&#0F172A', marginRight: '10px' }} />
					<h1>Liste des réclamations</h1>
				</Link>
			</div>
			{infos?.map((claim) => (
				<Fragment key={claim._id}>
					<div id='headComplain'>
						<div className='c-line'>
							<h2>Date: </h2>
							<span>{handleConvert(claim.date_start)}</span>
						</div>
						<div className='c-line'>
							<h2>Client: </h2>
							<span>{claim.created_by?.first_name}</span>
						</div>
						<div className='c-line'>
							<h2>Société: </h2>
							<span>{claim.created_by?.company_name}</span>
						</div>
						<div className='c-line'>
							<h2>Email:</h2>
							<span>{claim.created_by?.email}</span>
						</div>
						<div className='c-line'>
							<h2>Téléphone:</h2>
							<span>{claim.created_by?.phone}</span>
						</div>
					</div>
					<div id='object'>
						<h2>Objet de la réclamation</h2>
						<span>{claim.object?.name || '-'}</span>
					</div>
					<div id='message-complain'>
						<h2>Message</h2>
						<p>{claim.message}</p>
					</div>
					<div id='message-complain'>
						<h2>Statut de la réclamation</h2>
						<p>{claim.status ? 'Traité' : 'En cours'}</p>
					</div>
				</Fragment>
			))}
		</div>
	);
};

export default DetailsComplainAdmin;
