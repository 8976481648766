import React, { useEffect, useState } from 'react';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToBasket, updateBasketTotalQty } from '../../../api/redux/slices/Commercial';
import { showToast } from '../../../components/modal';
import { ClipLoader } from 'react-spinners';
import { typePrice } from '../../../helpers/modules';

const SeeProduct = ({ product_id, modalOpen, setModalOpen }) => {
	const { listProducts, basketTotalQty } = useSelector((state) => state.commercial);
	const dispatch = useDispatch();
	const [infos, setInfos] = useState(...listProducts.filter((el) => el._id === product_id));
	const [quantity, setQuantity] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	const token = localStorage.getItem('accesstoken');
	const company = JSON.parse(localStorage.getItem('company'));
	const clientType = company?.categories;

	useEffect(() => {
		setInfos(...listProducts.filter((el) => el._id === product_id));
	}, [product_id, listProducts]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (quantity < 1 || isNaN(quantity)) {
			showToast('La quantité doit être supérieure à 0.', 'warn', 'top-center', 1000);
			return;
		}
		const data = {
			product_id: product_id,
			quantity: quantity,
			total: quantity * infos?.[typePrice(clientType)],
			user_id: company._id,
		};
		dispatch(updateBasketTotalQty(basketTotalQty + parseInt(quantity)));
		setIsLoading(true);
		dispatch(addProductToBasket({ token, data: data }))
			.unwrap()
			.then(() => {
				showToast('Produit ajouté au panier', 'success');
			})
			.catch((err) => {
				showToast(err, 'error');
			})
			.finally(() => setIsLoading(false));
		setModalOpen(false);
	};

	return (
		<div className={'modalcontainer ' + (modalOpen && 'open')}>
			<div className={'modal ' + (modalOpen && 'active')} style={{ top: '40%', left: '50%' }}>
				<div className='left-modal'>
					<img
						src={infos.image.secure_url || process.env.REACT_APP_IMAGE_PLACEHOLDER_URL}
						alt='product_image'
					/>
				</div>

				<div className='right-modal'>
					<h3>{infos.designation}</h3>
					<span id='price'>{infos?.[typePrice(clientType)]} TND</span>
					<p className='desc'>{infos.description}</p>
					<div className='line-desc'>
						<h4>Contenance</h4>
						<span>{infos.capacity}</span>
					</div>
					<div className='line-desc'>
						<h4>Nombre de pièce -carton</h4>
						<span>{infos.nb_cardboard_piece}</span>
					</div>
					<div className='line-desc'>
						<h4>Couleur</h4>
						<span>{infos.color}</span>
					</div>
					<div className='line-desc'>
						<h4>Matière</h4>
						<span>{infos.material}</span>
					</div>
					<div className='line-desc'>
						<h4>Quantité</h4>
						<input
							type='number'
							value={quantity}
							onChange={(e) => {
								const inputNumber = parseInt(e.target.value, 10);
								if (inputNumber < 0) {
									setQuantity(0);
									return;
								}
								setQuantity(inputNumber);
							}}
						/>
					</div>
					<div className='btn'>
						<button
							disabled={infos.capacity < quantity}
							onClick={handleSubmit}
							className='button-primary'
						>
							{isLoading ? (
								<ClipLoader color='#FFF' loading={isLoading} size={20} />
							) : (
								<>
									<icons.RiShoppingCartLine />
									<span>Ajouter au panier</span>
								</>
							)}
						</button>
					</div>
				</div>
				<div className='close-modal' onClick={() => setModalOpen(false)}>
					<icons.MdClose />
				</div>
			</div>
		</div>
	);
};

export default SeeProduct;
