import React from 'react';
import { icons } from '../../../assets/icons';
import './index.scss';
import { showToast } from '../../../components/modal';
import useOnclickOutside from 'react-cool-onclickoutside';
import { typePrice } from '../../../helpers/modules';

const SeeProduct = ({
	modalOpen,
	setModalOpen,
	product_image,
	product,
	clientType,
	onClickShop,
}) => {
	const [quantity, setQuantity] = React.useState(1);

	const shop = () => {
		if (quantity < 1 || isNaN(quantity)) {
			showToast('La quantité doit être supérieure à 0.', 'warn', 'top-center', 1000);
			return;
		}
		onClickShop(product, Math.max(quantity, 1));
		setModalOpen(false);
		showToast('Produit ajouté au panier.', 'success', 'top-center', 1000);
	};

	const ref = useOnclickOutside(() => {
		setModalOpen(false);
	});

	return (
		<div className={'modalcontainer ' + (modalOpen && 'open')}>
			<div className={'modal ' + (modalOpen && 'active')} style={{ top: '40%' }} ref={ref}>
				<div className='left-modal'>
					<img src={product_image} alt='image_produit' />
				</div>

				<div className='right-modal'>
					<h3>{product.designation ?? '-'}</h3>
					<span id='price'>{product?.[typePrice(clientType)]} TND</span>
					<p className='desc'>{product.description}</p>
					<div className='line-desc'>
						<h4>Contenance</h4>
						<span>{product.capacity ?? '-'}</span>
					</div>
					<div className='line-desc'>
						<h4>Nombre de pièce -carton</h4>
						<span>{product.nb_cardboard_piece ?? '-'}</span>
					</div>
					<div className='line-desc'>
						<h4>Couleur</h4>
						<span>{product.color ?? '-'}</span>
					</div>
					<div className='line-desc'>
						<h4>Matière</h4>
						<span>{product.material}</span>
					</div>
					<div className='line-desc'>
						<h4>Quantité</h4>
						<input
							type='number'
							value={quantity}
							onChange={(e) => {
								const inputNumber = parseInt(e.target.value, 10);
								if (inputNumber < 0) {
									setQuantity(0);
									return;
								}
								setQuantity(inputNumber);
							}}
						/>
					</div>
					<div className='btn' onClick={shop} style={{ width: 'max-content' }}>
						<button disabled={product.capacity < quantity} className='button-primary'>
							<icons.RiShoppingCartLine />
							<span>Ajouter au panier</span>
						</button>
					</div>
				</div>
				<div className='close-modal' onClick={() => setModalOpen(false)}>
					<icons.MdClose />
				</div>
			</div>
		</div>
	);
};

export default SeeProduct;
