import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomerServices from '../../services/CustomerServices';

const initialState = {
	claims: [],
	claimsArr: [],
	loading: false,
	error: null,
	errorMessage: null,
	isSuccess: false,
	listCategories: [],
	listProducts: [],
	listOrders: [],
	bills: [],
	orderDetails: {},
	basket: [],
	checkedList: [],
	totalQty: 0,
	basketTotalQty: 0,
	total: 0,
};

export const updateCustomerProfile = createAsyncThunk(
	'/update_profile_customer',
	async ({ token, data }, thunkApi) => {
		try {
			const res = await CustomerServices.updateCustomerProfile(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const updateCustomerPassword = createAsyncThunk(
	'/update_profile_password_customer',
	async ({ token, data }, thunkApi) => {
		try {
			const res = await CustomerServices.updateCustomerPassword(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getCustomerCategories = createAsyncThunk(
	'/get_categories_customer',
	async ({ token, page, search }, thunkApi) => {
		try {
			const res = await CustomerServices.getCustomerCategories(token, page, search);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getListCategoriesProducts = createAsyncThunk(
	'/getListCategoriesProducts',
	async ({ token }, thunkApi) => {
		try {
			const res = await CustomerServices.getListCategoriesProducts(token);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getCustomerProductsList = createAsyncThunk(
	'/get_products_customer',
	async ({ token, page, search, categories }, thunkApi) => {
		try {
			const res = await CustomerServices.getCustomerProductsList(token, page, search, categories);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const addBasket = createAsyncThunk('/add_basket', async ({ token, data }, thunkApi) => {
	try {
		const res = await CustomerServices.addBasket(token, data);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const getBasket = createAsyncThunk('/get_basket', async ({ token, id }, thunkApi) => {
	try {
		const res = await CustomerServices.getBasket(token, id);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const deleteBasket = createAsyncThunk('/delete_basket', async ({ token, id }, thunkApi) => {
	try {
		const res = await CustomerServices.deleteBasket(token, id);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
export const udpateStockProduitBasket = createAsyncThunk(
	'/update_stock_basket',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await CustomerServices.udpateStockProduitBasket(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const clearBasket = createAsyncThunk('/clear_basket', async ({ token }, thunkApi) => {
	try {
		const res = await CustomerServices.clearBasket(token);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const addOrderByCustomer = createAsyncThunk(
	'/add_order',
	async ({ token, data }, thunkApi) => {
		try {
			const res = await CustomerServices.addOrderByCustomer(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getOrdersByCustomer = createAsyncThunk(
	`/getOrdersByCustomer`,
	async ({ token, page, search, status }, thunkApi) => {
		try {
			const res = await CustomerServices.getOrdersByCustomer(token, page, search, status);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getOrderByIdCustomer = createAsyncThunk(
	`/getOrderByIdCustomer`,
	async ({ token, id }, thunkApi) => {
		try {
			const res = await CustomerServices.getOrderByIdCustomer(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getBillsByCustomer = createAsyncThunk(
	`/getBillsByCustomer`,
	async ({ token, page, search }, thunkApi) => {
		try {
			const res = await CustomerServices.getBillsByCustomer(token, page, search);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getBillByIdCustomer = createAsyncThunk(
	`/getBillByIdCustomer`,
	async ({ token, id }, thunkApi) => {
		try {
			const res = await CustomerServices.getBillByIdCustomer(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const ClaimsClientsData = createAsyncThunk(
	`/ClaimsClientsData`,
	async ({ token, page, search }, thunkApi) => {
		try {
			const res = await CustomerServices.ClaimsClientsData(token, page, search);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const CustomerClaimsTypes = createAsyncThunk(
	`/CustomerClaimsTypes`,
	async ({ token }, thunkApi) => {
		try {
			const res = await CustomerServices.CustomerClaimsTypes(token);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const addCliamsByCustomer = createAsyncThunk(
	`/addCliamsByCustomer`,
	async ({ token, data }, thunkApi) => {
		try {
			const res = await CustomerServices.addCliamsByCustomer(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getCliamById = createAsyncThunk(`/getCliamById`, async ({ token, id }, thunkApi) => {
	try {
		const res = await CustomerServices.getCliamById(token, id);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
export const checkStock = createAsyncThunk(`/checkStock`, async ({ token, list }, thunkApi) => {
	try {
		const res = await CustomerServices.checkStock(token, list);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
export const clearBasketCustomer = createAsyncThunk(
	`/clearBasketCustomer`,
	async ({ token, id }, thunkApi) => {
		try {
			const res = await CustomerServices.clearBasketCustomer(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
const customerSlice = createSlice({
	name: 'customer',
	initialState,
	reducers: {
		handleChangeQuantity: (state, action) => {
			const { productId, newQuantity } = action.payload;
			const itemToUpdate = state.basket.find((item) => item.product._id === productId);

			if (itemToUpdate) {
				state.totalQty += newQuantity - itemToUpdate.quantity;
				itemToUpdate.quantity = newQuantity;
			}
		},
		clearCheckList: (state) => {
			state.checkedList = [];
		},
		setCheckedList: (state, action) => {
			const itemId = action.payload._id;
			if (!state.checkedList.some((item) => item._id === itemId)) {
				return {
					...state,
					checkedList: [...state.checkedList, action.payload],
				};
			}
			return state;
		},
		setUnCheckedList: (state, action, updated) => {
			state.checkedList = state.checkedList.filter((el) => el._id !== action.payload._id);
		},
		updatedbasketTotalQty: (state, action) => {
			state.basketTotalQty = action.payload;
		},
	},
	extraReducers: {
		[updateCustomerProfile.fulfilled]: (state, action) => {
			state.isSuccess = true;
			state.loading = false;
		},
		[updateCustomerPassword.fulfilled]: (state, action) => {
			state.isSuccess = true;
			state.loading = false;
		},
		[getCustomerCategories.fulfilled]: (state, action) => {
			state.isSuccess = true;
			state.loading = false;
			state.listCategories = action.payload.data;
			state.total = action.payload.total;
		},
		[getCustomerProductsList.fulfilled]: (state, action) => {
			state.isSuccess = true;
			state.loading = false;
			state.listProducts = action.payload.data;
			state.total = action.payload.total;
		},
		[getBasket.pending]: (state) => {
			state.loading = true;
		},
		[getBasket.fulfilled]: (state, action) => {
			state.basket = action.payload.data;
			const totaklQty = action.payload.data.reduce((acc, item) => acc + item.quantity, 0);
			state.basketTotalQty = isNaN(totaklQty) ? 0 : totaklQty;
			state.loading = false;
		},
		[getBasket.rejected]: (state, action) => {
			state.error = true;
			state.errorMessage = action.payload.message;
			state.loading = false;
		},
		[addBasket.pending]: (state) => {
			state.loading = true;
		},
		[addBasket.fulfilled]: (state) => {
			state.loading = false;
		},
		[udpateStockProduitBasket.pending]: (state) => {
			state.loading = true;
		},
		[udpateStockProduitBasket.fulfilled]: (state) => {
			state.loading = false;
		},
		[deleteBasket.pending]: (state) => {
			state.loading = true;
		},
		[deleteBasket.fulfilled]: (state) => {
			state.loading = false;
		},
		[addOrderByCustomer.fulfilled]: (state) => {
			state.basket = [];
		},
		[getOrdersByCustomer.fulfilled]: (state, action) => {
			state.listOrders = action.payload.data;
			state.total = action.payload.total;
		},
		[getOrderByIdCustomer.fulfilled]: (state, action) => {
			state.orderDetails = action.payload.data;
		},
		[ClaimsClientsData.fulfilled]: (state, action) => {
			state.claimsArr = action.payload.data;
			state.total = action.payload.total;
		},
		[getBillsByCustomer.fulfilled]: (state, action) => {
			state.bills = action.payload.data;
			state.total = action.payload.total;
		},
	},
});

export default customerSlice.reducer;
export const {
	handleChangeQuantity,
	setCheckedList,
	setUnCheckedList,
	clearCheckList,
	updatedbasketTotalQty,
} = customerSlice.actions;
