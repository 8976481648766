import React from 'react';
import { ButtonComponent } from '../../../components/buttons';

const OrderConfirm = ({ setConfirmOrderModal, confirmOrderModal, handleAddOrder, loading }) => {
	return (
		<div className={'modalcontainer ' + (confirmOrderModal && 'open')}>
			<div
				className={'modal ' + (confirmOrderModal && 'active')}
				style={{ top: '20%', left: '50%' }}
			>
				<div className='client-modal-order'>
					<h1>Etes-vous sur de vouloir continuer cette commande ?</h1>
				</div>
				<div className='modalbtn'>
					<button className='button-nature' onClick={() => setConfirmOrderModal(false)}>
						Annuler
					</button>
					<ButtonComponent
						className='button-primary'
						onClick={() => {
							handleAddOrder();
						}}
						loading={loading}
						name={'Enregistrer'}
					/>
				</div>
			</div>
		</div>
	);
};

export default OrderConfirm;
