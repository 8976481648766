import React, { useState, useEffect } from 'react';
import { addDelivery, getCommercial } from '../../../api/redux/slices/Admin';
import { useDispatch } from 'react-redux';
import { showToast } from '../../../components/modal';
import { ButtonComponent } from '../../../components/buttons';
import { LoaderComponent } from '../../../helpers/components/loader';

const ModalConfirmDelivery = ({ isOpen, onClose, idxConfirmDelviry, createdId }) => {
	const [selectedCommercialId, setSelectedCommercialId] = useState('');
	const [selectedVehicle, setSelectedVehicle] = useState('');
	const [commercial, setCommercial] = useState([]);
	const token = localStorage.getItem('accesstoken');
	const [isloading, setIsloading] = useState(false);
	const [commericalLoading, setCommericalLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		setCommericalLoading(true);
		dispatch(getCommercial({ token: token }))
			.unwrap()
			.then((res) => {
				const sortedUsers = res.users.sort((a, b) => a.first_name.localeCompare(b.first_name));
				createdId &&
					res.users.forEach((item) => {
						if (item._id === createdId) {
							setSelectedCommercialId(item._id);
							return item;
						}
					});
				setCommercial(sortedUsers);
			})
			.finally(() => setCommericalLoading(false));
	}, [dispatch, token, createdId]);

	const handleCommercialChange = (e) => {
		setSelectedCommercialId(e.target.value);
	};

	const handleVehicleChange = (e) => {
		setSelectedVehicle(e.target.value);
	};

	const handleConfirm = () => {
		if (!selectedCommercialId || !selectedVehicle) {
			showToast('Veuillez remplir tous les champs', 'error');
			return;
		}
		setIsloading(true);
		dispatch(
			addDelivery({
				token: token,
				data: {
					order_id: idxConfirmDelviry,
					commercial_id: selectedCommercialId,
					matricule_vehicle: selectedVehicle,
				},
			}),
		)
			.unwrap()
			.then(() => showToast('Bon de livraison ajouté', 'success'))
			.catch((err) => showToast(err?.message || err, 'error'))
			.finally(() => {
				setIsloading(false);
				onClose();
			});
	};

	return (
		<div className={'modalcontainer ' + (isOpen && 'open')}>
			<div
				className={'modal ' + (isOpen && 'active')}
				style={{ height: '21rem', width: '30rem', top: '28%' }}
			>
				<h1 className='confirm'>Confirmer la livraison</h1>
				{commericalLoading ? (
					<div>
						<LoaderComponent margin='15%' />
					</div>
				) : (
					<>
						<div className='select'>
							<label>Sélectionner le commercial :</label>
							<select value={selectedCommercialId} onChange={handleCommercialChange}>
								<option value=''>Ajouter un livreur</option>
								{commercial.map((item) => (
									<option key={item._id} value={item._id}>
										{item.first_name} {item.last_name}
									</option>
								))}
							</select>
						</div>
						<div className='select'>
							<label>Sélectionner le véhicule :</label>
							<select
								value={selectedVehicle}
								onChange={handleVehicleChange}
								disabled={!selectedCommercialId}
							>
								<option value=''>Sélectionner le véhicule</option>
								{selectedCommercialId &&
									commercial
										.find((item) => item._id === selectedCommercialId)
										?.vehicles.map((vehicle) => (
											<option key={vehicle._id} value={vehicle.vehicle_number}>
												{vehicle.vehicle_number}
											</option>
										))}
							</select>
						</div>
						<div className='btn'>
							<button className='button-nature' onClick={onClose}>
								Annuler
							</button>
							<ButtonComponent
								name={'Enregistrer'}
								loading={isloading}
								type={'button'}
								className={'button-primary'}
								onClick={handleConfirm}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default ModalConfirmDelivery;
