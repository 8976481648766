import React, { useEffect, useState } from 'react';
import './index.scss';
import Container from '../container';
const CodeConfirm = () => {
	const [pinState, setPinState] = useState('');
	const [disabledButton, setDisabledButton] = useState(true);
	const focusInputs = (id, value) => {
		if (value && value.length > 0) {
			document.getElementById(id).focus();
		}
	};
	const keyUpPin = (name, event) => {
		if (event.code === 'Backspace') {
			focusInputs(name, '1');
		}
	};
	useEffect(() => {
		if (pinState.one && pinState.two && pinState.three && pinState.four) {
			setDisabledButton(false);
		} else {
			setDisabledButton(true);
		}
	}, [pinState]);
	const confirmCode = () => {};
	return (
		<Container>
			<form className='form'>
				<label htmlFor=''>Code de confirmation</label>
				<div className='pinCodeCard'>
					<input
						type='text'
						id='one'
						className='pin'
						onChange={(value) => {
							setPinState((prevState) => ({ ...prevState, one: value.target.value }));
							focusInputs('two', value.target.value);
						}}
						maxLength={1}
					/>
					<input
						type='text'
						id='two'
						className='pin'
						onKeyUp={(event) => keyUpPin('one', event)}
						onChange={(value) => {
							setPinState((prevState) => ({ ...prevState, two: value.target.value }));
							focusInputs('three', value.target.value);
						}}
						maxLength={1}
					/>
					<input
						type='text'
						id='three'
						className='pin'
						onKeyUp={(event) => keyUpPin('two', event)}
						onChange={(value) => {
							setPinState((prevState) => ({ ...prevState, three: value.target.value }));
							focusInputs('four', value.target.value);
						}}
						maxLength={1}
					/>
					<input
						type='text'
						id='four'
						className='pin'
						onKeyUp={(event) => keyUpPin('three', event)}
						onChange={(value) => {
							setPinState((prevState) => ({ ...prevState, four: value.target.value }));
							focusInputs('bth', value.target.value);
						}}
						maxLength={1}
					/>
				</div>
				<input
					type='button'
					value='Confirmer'
					id='bth'
					onKeyUp={(event) => keyUpPin('four', event)}
					onClick={!disabledButton ? confirmCode : null}
					className='btn'
				/>
			</form>
		</Container>
	);
};

export default CodeConfirm;
