import React, { useEffect, useState } from 'react';

const Avatar = ({ fileUploaded, setFileUploaded, width, height = '15rem' }) => {
	const [imageUp, setImageUp] = useState(false);

	useEffect(() => {
		if (fileUploaded?.url) setImageUp(true);
	}, [fileUploaded]);

	const handleUpload = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFileUploaded({ file, url: URL.createObjectURL(file) });
			setImageUp(true);
		}
	};
	const prepareUpload = () => {
		const inputFile = document.getElementById('imgUploading');
		inputFile.click();
	};
	const triggerImgUpload = () => {
		prepareUpload();
	};

	const removeImg = () => {
		setFileUploaded({ file: '', url: '' });
		setImageUp(false);
		const inputFile = document.getElementById('imgUploading');
		inputFile.value = null;
	};

	const modalStyle = {
		position: 'absolute',
		top: '0px',
		right: '0px',
		background: 'red',
		color: 'white',
		fontSize: '15px',
		width: '25px',
		height: '25px',
		display: 'grid',
		placeItems: 'center',
		borderRadius: '50%',
		cursor: 'pointer',
		margin: '6px',
	};

	return (
		<div className='imgBlock' style={{ width: width }}>
			<div id='imgUpload' style={{ height: height }}>
				<input
					type='file'
					id='imgUploading'
					onChange={handleUpload}
					accept='image/*'
					title='clickez pour ajouter une image'
				/>
				{imageUp && (
					<div className='fileUp'>
						<div onClick={removeImg} style={modalStyle}>
							X
						</div>
						<img src={fileUploaded?.url} alt='image_uploaded' onClick={triggerImgUpload} />
					</div>
				)}
			</div>
		</div>
	);
};

export default Avatar;
