import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import ImprimerLogo from '../../../assets/icons/svg/document-text.svg';
import './index.scss';
import { LoaderComponent } from '../../../helpers/components/loader';
import { ShopInfo, SuperAdminInfo } from '../../../components/userInfo';
import { convertDate, roundToThreeDecimals } from '../../../helpers/modules';
import { renderTvaValues } from '../../../utils/groupTvaValues';
import PrintToPdf from '../../admin/orderDetails/PrintToPdf';
import { getOrderByIdCustomer } from '../../../api/redux/slices/Customer';

const DetailsOrderClient = () => {
	const { orderDetails } = useSelector((state) => state.customer);
	const dispatch = useDispatch();
	const params = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const token = localStorage.getItem('accesstoken');

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	useEffect(() => {
		setIsLoading(true);
		dispatch(getOrderByIdCustomer({ token, id: params.id }))
			.unwrap()
			.finally(() => setIsLoading(false));
	}, [dispatch, params, token]);

	return (
		<div id='orderDetails'>
			<div className='details-order-header'>
				<Link to={`/client/orders/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Bons de commandes</h1>
				</Link>
				<div style={{ display: 'flex', gap: '10px' }}>
					<button
						style={{ display: 'flex', gap: '10px' }}
						onClick={handlePrint}
						className='button-primary'
					>
						<img src={ImprimerLogo} alt='imprimer logo' />
						Imprimer
					</button>
				</div>
			</div>
			{isLoading ? (
				<LoaderComponent />
			) : (
				<>
					<div className='container'>
						<Fragment>
							<div className='credentials'>
								<div className='infos-societe left' style={{ border: 'none' }}>
									<label style={{ textTransform: 'uppercase' }}>
										{orderDetails?.order?.superAdminInfo?.name}
									</label>
								</div>
								<div className='left'>
									<div className='total'>
										<label>Montant Reçu</label>
										<span>
											{roundToThreeDecimals(orderDetails?.order?.clientInfo.received_amount) || 0}{' '}
											DT
										</span>
									</div>
									<div className='total'>
										<label>Montant Dépensé</label>
										<span>
											{roundToThreeDecimals(orderDetails?.order?.clientInfo.spent_amount) || 0} DT
										</span>
									</div>
								</div>
							</div>
							<div className='credentials'>
								<div className='left'>
									<div className='left-el'>
										<label>Numéro</label>
										<span>{orderDetails?.order?.reference ?? '-'}</span>
									</div>
									<div className='left-el'>
										<label>Date</label>
										<span>{convertDate(orderDetails?.order?.date, 'DD-MM-YYYY') ?? ''}</span>
									</div>
									<div className='left-el'>
										<label>Référence code client</label>
										<span>{orderDetails?.order?.clientInfo?.customs_code ?? '-'}</span>
									</div>
								</div>
								<div className='left'>
									<div className='left-e'>
										<label style={{ textTransform: 'capitalize' }}>
											{orderDetails?.order?.clientInfo?.company_name ?? ''}
										</label>
									</div>
									<div className='left-e'>
										<span>{orderDetails?.order?.clientInfo?.client ?? ''}</span>
									</div>
									<div className='left-e'>
										<span>
											Matricule Fiscale:{' '}
											{orderDetails?.order?.clientInfo?.tax_registration_number ?? ''}
										</span>
									</div>
									<div className='left-e'>
										<span>Telephone: {orderDetails?.order?.clientInfo?.phone ?? ''}</span>
									</div>
									<div className='left-e'>
										<ShopInfo shop={orderDetails?.order?.shopInfo} />
									</div>
								</div>
							</div>
							<div className='devis'>
								<div className='devis-head'>
									<h1>Devis</h1>
									<p>
										Cher Client,
										<br />
										Nous avons bien reçu votre demande de devis et nous vous en remercions.
										<br />
										Nous vous prions de trouver ci-dessous nos conditions les meilleures
									</p>
								</div>
								<div className='devis-table'>
									<table className='offres'>
										<thead>
											<tr id='titres'>
												<td>RÉFÉRENCE</td>
												<td>RÉFÉRENCE CLT</td>
												<td>DÉSIGNATION</td>
												<td>QTÉ</td>
												<td>PU HT</td>
												<td>TVA (%)</td>
												<td>MONTANT HT</td>
											</tr>
										</thead>
										<tbody>
											{orderDetails?.list?.map((el, index) => (
												<tr className='elements' key={index}>
													<td>{el?.reference ?? '-'}</td>
													<td>{el?.refranceCTL ?? '-'}</td>
													<td>{el?.designation ?? '-'}</td>
													<td>{el?.quantity ?? '-'}</td>
													<td>{el?.prise_product_HT ?? '-'}</td>
													<td>{el?.tva ?? '-'}</td>
													<td>{el?.prise_product_HT * el?.quantity || '-'}</td>
												</tr>
											))}
										</tbody>
									</table>
									<div className='total-devis'>
										<div id='box'>
											<div>
												<h1>TOTAL HT</h1>
												<span id='somme-totale'>{orderDetails?.order?.total_HT ?? 0}</span>
											</div>
											{renderTvaValues(orderDetails)}
											<div>
												<h1>TIMBRE</h1>
												<span id='somme-totale'>{orderDetails?.order?.timbre ?? 0}</span>
											</div>
											<div>
												<h1>TOTAL TTC</h1>
												<span id='somme-totale'>{orderDetails?.order?.total ?? 0}</span>
											</div>
										</div>
									</div>
								</div>
								<div className='message'>
									<p>
										Nous sommes à votre disposition pour tout complément d'informations. <br />
										Nous vous prions d'agréer, Cher Client, nos sincères salutations. <br />
										Le service commercial.
									</p>
								</div>
								<SuperAdminInfo superAdminInfo={orderDetails?.order?.superAdminInfo} />
							</div>
						</Fragment>
					</div>
					<div style={{ display: 'none' }}>
						<PrintToPdf componentRef={componentRef} orderDetails={orderDetails} />
					</div>
				</>
			)}
		</div>
	);
};

export default DetailsOrderClient;
