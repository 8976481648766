import React, { Fragment, useEffect, useState } from 'react';
import './index.scss';
import { useDispatch } from 'react-redux';
import { CustomerClaimsTypes, addCliamsByCustomer } from '../../../api/redux/slices/Customer';
import { ButtonComponent } from '../../../components/buttons';
import { showToast } from '../../../components/modal';

const ComplainModal = ({ modalOpen, setModalOpen }) => {
	const [selectSubject, setSelectSubject] = useState('');
	const [listClaims, setListClaims] = useState([]);
	const [message, setMessage] = useState('');
	const [isLoading, setIsloading] = useState(false);

	const token = localStorage.getItem('accesstoken');
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			CustomerClaimsTypes({
				token,
			}),
		)
			.unwrap()
			.then((res) => setListClaims(res.data))
			.catch((err) => {
				return;
			});
	}, [dispatch, token]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!selectSubject || !message.trim()) {
			showToast('Veuillez remplir tous les champs', 'warn');
			return;
		}

		setIsloading(true);
		dispatch(
			addCliamsByCustomer({
				token,
				data: {
					object: selectSubject,
					message: message.trim(),
				},
			}),
		)
			.unwrap()
			.finally(() => {
				setModalOpen(false);
				setIsloading(false);
			});
	};

	return (
		<div className={'modalcontainer ' + (modalOpen && 'open')}>
			<div className={'modal ' + (modalOpen && 'active')}>
				<h1>Envoyez une réclamation</h1>
				<form onSubmit={handleSubmit}>
					<div className='box'>
						<label>Sujet</label>
						<div className='infodropdown'>
							<select value={selectSubject} onChange={(e) => setSelectSubject(e.target.value)}>
								<option value=''>Liste des sujets</option>
								{listClaims?.map((item) => (
									<Fragment key={item._id}>
										<option value={item._id}>{item.name}</option>
									</Fragment>
								))}
							</select>
						</div>
					</div>
					<div className='desc-modal'>
						<label>Message</label>
						<textarea
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							cols='3'
							rows='3'
						/>
					</div>
					<div className='modalbtn'>
						<button
							className='button-nature-inactive'
							onClick={(e) => {
								e.preventDefault();
								setModalOpen(false);
							}}
						>
							Annuler
						</button>
						<ButtonComponent
							loading={isLoading}
							className={'button-primary'}
							name={'Envoyer'}
							type={'submit'}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ComplainModal;
