import { useMemo } from 'react';

const getShopDetails = (item) => {
	return item?.shop.map((shop, index) => ({
		[`boutique_${index + 1}`]: shop.name || '-',
		[`numero_rue_boutique_${index + 1}`]: shop.address_number || '-',
		[`rue_boutique_${index + 1}`]: shop.street || '-',
		[`code_postal_boutique_${index + 1}`]: shop.postal_code || '-',
		[`ville_boutique_${index + 1}`]: shop.ville || '-',
	}));
};

const useCSVExport = (checkedList) => {
	const dataCsv = useMemo(() => {
		if (!checkedList) return [];

		return checkedList?.map((item) => ({
			checked: item.checked,
			first_name: item.first_name,
			last_name: item.last_name,
			email: item.email,
			phone: item.phone,
			role: item.role,
			société: item.company_name ? item.company_name : '-',
			tax_registration_number: item.tax_registration_number,
			vehicle_number: item.vehicle_number ? item.vehicle_number : '-',
			bloqué: item.is_active ? 'Non' : 'Oui',
			...Object.assign({}, ...getShopDetails(item)),
		}));
	}, [checkedList]);

	let dynamicHeaders = [];

	const maxBoutiques = Math.max(...checkedList.map((item) => item.shop.length), 0);

	for (let i = 1; i <= maxBoutiques; i++) {
		dynamicHeaders.push(
			{ label: `NOM BOUTIQUE ${i}`, key: `boutique_${i}` },
			{ label: `NUMERO RUE ${i}`, key: `numero_rue_boutique_${i}` },
			{ label: `CODE POSTAL ${i}`, key: `code_postal_boutique_${i}` },
			{ label: `VILLE ${i}`, key: `ville_boutique_${i}` },
		);
	}

	const headers = [
		{ label: 'NOM', key: 'first_name' },
		{ label: 'PRENOM', key: 'last_name' },
		{ label: 'EMAIL', key: 'email' },
		{ label: 'FONCTION', key: 'role' },
		{ label: 'BLOQUE', key: 'bloqué' },
		{ label: 'TELEPHONE 1', key: 'phone' },
		{ label: 'TVA', key: 'tax_registration_number' },
		{ label: 'IMMATRICULATION VEHICULE', key: 'vehicle_number' },
		{ label: 'NOM DE SOCIETE', key: 'company_name' },
		{ label: 'CATEGORIE', key: 'categories' },
		{ label: 'REGLEMENT', key: 'regulation' },
		{ label: 'FONCTION', key: 'role' },
		...dynamicHeaders,
	];

	const csvUsersReport = {
		filename: 'Utilisateurs.csv',
		data: dataCsv,
		headers: headers,
	};

	return csvUsersReport;
};

export default useCSVExport;
