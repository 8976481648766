import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import ConfirmOrderModal from './ConfirmOrderModal';
import DeleteConfirmationModal from '../../admin/addUser/ShopDeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import {
	addOrderByCustomer,
	checkStock,
	clearBasketCustomer,
	deleteBasket,
	getBasket,
	handleChangeQuantity,
	updatedbasketTotalQty,
} from '../../../api/redux/slices/Customer';
import { DataErrorModal, showToast } from '../../../components/modal';
import { roundToThreeDecimals, typePrice } from '../../../helpers/modules';
import { calculateTotalTva } from '../../../utils/groupTvaValues';
import { ButtonComponent } from '../../../components/buttons';
import { udpateStockProduitBasket } from '../../../api/redux/slices/Customer';

const ProductCartClient = () => {
	const [confirmModal, setConfirmModal] = useState(false);

	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [indexDeleteCart, setIndexDeleteCart] = useState(null);

	const token = localStorage.getItem('accesstoken');
	const { basket, basketTotalQty } = useSelector((state) => state.customer);
	const [oldBaksetTotalQty, setOldBasketTotalQty] = useState(basketTotalQty);
	const { user } = useSelector((state) => state.auth);
	const [selectedBoutiqueId, setSelectedBoutiqueId] = useState('');
	const [outOfStock, setOutOfStock] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [errorData, setErrorData] = useState([]);
	const [isOpen, setIsOpen] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const BoutiquesData = useMemo(
		() =>
			user.data.shop.map((el) => ({
				name: el.name,
				ville: el.ville,
				id: el._id,
			})),
		[user.data.shop],
	);

	useEffect(() => {
		dispatch(getBasket({ token, id: user.data._id }));
	}, [token, dispatch, user.data._id]);
	const handleOrder = () => {
		let canOrder = true;
		const list = basket.map((el) => {
			if (el.quantity <= 0 || isNaN(el.quantity)) {
				showToast('La quantité doit être supérieure à 0.', 'warn', 'top-center', 1000);
				canOrder = false;
			}
			return {
				product_id: el.product._id,
				quantity: el.quantity,
			};
		});

		if (!canOrder) return;
		setIsLoading(true);
		dispatch(checkStock({ token, list }))
			.unwrap()
			.then((res) => {
				if (res.data.length > 0) {
					setIsOpen(true);
					const errData = res.data.map((item) => ({
						Designation: item.designation,
						error:
							item.stock > 0
								? `Stock insuffisant pour ce produit maximum disponible : ${item.stock}`
								: 'Stock insuffisant pour ce produit',
					}));
					setErrorData(errData);
				} else {
					setConfirmModal(true);
				}
			})
			.catch((error) => {
				showToast(error.data, 'error');
			})
			.finally(() => setIsLoading(false));
	};
	const totalHT = () => {
		let total = 0;
		basket?.forEach((el) => {
			let tmp = total + el.quantity * el?.product?.[typePrice(user.data.categories)];
			total = tmp * (1 - (el.remise ?? 0) * 0.01);
		});
		return roundToThreeDecimals(total);
	};
	const totalTCC = () => {
		const list = basket.map((el) => {
			return {
				tva: el?.product?.Tva,
				prise_product_HT: el?.product?.[typePrice(user.data.categories)],
				quantity: el?.quantity,
			};
		});

		const totalTvaSum = calculateTotalTva({ list }).reduce(
			(acc, { totalAmount }) => acc + totalAmount,
			0,
		);

		const totalHTAmount = totalHT();
		const timbre = 1;

		const totalTCC = Number(totalTvaSum) + Number(totalHTAmount) + parseInt(timbre);

		return roundToThreeDecimals(Number(totalTCC));
	};
	const handleAddOrder = () => {
		const list = basket.map((item) => {
			const prise_product_HT = item?.product?.[typePrice(user.data.categories)];
			const Amount_excluding_tax = prise_product_HT * (1 + (item?.product?.Tva || 0) / 100);

			return {
				product_id: item.product._id,
				quantity: item.quantity,
				remise: item.remise ?? 0,
				prise_product_HT: prise_product_HT,
				Amount_excluding_tax: Amount_excluding_tax,
			};
		});
		const data = {
			list: [...list],
			user_id: user.data._id,
			shop_id: selectedBoutiqueId,
			number: user.data.phone,
			date: new Date().toISOString(),
			total: totalTCC(),
			total_HT: totalHT(),
			tva: 19,
			timbre: 1,
		};

		setIsLoading(true);
		dispatch(addOrderByCustomer({ token, data }))
			.unwrap()
			.then(() => {
				dispatch(clearBasketCustomer({ token, id: user.data._id }));
				navigate('/client/orders/1');
			})
			.catch((err) => {
				if (err?.message === 'Ces produits ne sont pas disponibles.')
					showToast('Il y a un produit qui est désactivé', 'error');
				else showToast(err?.message || err, 'error');
			})
			.finally(() => {
				setIsLoading(false);
				setConfirmModal(false);
			});
	};
	const handleChangeQty = (e, item) => {
		const newQuantity = Number(e.target.value);
		if (newQuantity < 0) return;
		dispatch(updatedbasketTotalQty(basketTotalQty - item.quantity + newQuantity));
		newQuantity > item.product.capcity ? setOutOfStock(true) : setOutOfStock(false);
		dispatch(handleChangeQuantity({ productId: item.product._id, newQuantity }));
	};
	const handleDeleteCart = () => {
		dispatch(deleteBasket({ token, id: indexDeleteCart }))
			.unwrap()
			.then(() => {
				showToast('Produit supprimé du panier', 'success');
				dispatch(getBasket({ token, id: user.data._id }));
			});
		setOpenDeleteModal(false);
	};
	const totalPriceTCC = basket.reduce(
		(acc, el) =>
			acc +
			el.product?.[typePrice(user.data.categories)] * (1 + el.product?.Tva / 100) * el.quantity,
		0,
	);

	const handleCalculatePrice = (item) =>
		roundToThreeDecimals(
			item.product?.[typePrice(user.data.categories)] *
				(1 + item.product?.Tva / 100) *
				item.quantity,
		);

	const handleChangebasket = async () => {
		const data = basket.map((product) => {
			const data = {
				quantity: Number(product.quantity),
				total: roundToThreeDecimals(
					product.quantity * product.product?.[typePrice(user.data.categories)],
				),
			};
			return dispatch(udpateStockProduitBasket({ token, id: product._id, data }));
		});

		await Promise.all(data).then(() => {
			showToast('Produits mis à jour avec succès', 'success', 'top-center', 1000);
			setOldBasketTotalQty(basketTotalQty);
		});
	};

	return (
		<div id='pr-cart-cl'>
			{isOpen && (
				<DataErrorModal
					isOpen={isOpen}
					closeModal={() => setIsOpen(false)}
					errorData={errorData}
					width='35rem'
					height='auto'
				/>
			)}
			<div className='navbar'>
				<div className='left'>
					<div className='return-link'>
						<Link to='/client/products/1'>
							<icons.IoIosArrowBack className='arrow' />
							<h4>Continuer mes achats</h4>
						</Link>
					</div>
				</div>
				<div className='right'>
					<div className='cart'>
						<icons.RiShoppingCartLine className='cart-icon' />
						<div className='cart-count'>
							<span>{basketTotalQty}</span>
						</div>
					</div>
					<Link to='/client/products/product_cart'>
						<h3>Panier</h3>
					</Link>
				</div>
			</div>
			<Fragment>
				<ConfirmOrderModal
					setConfirmModal={setConfirmModal}
					confirmModal={confirmModal}
					handleAddOrder={handleAddOrder}
					loading={isLoading}
				/>
			</Fragment>
			<div className='content'>
				<div className='box'>
					<h1>Panier</h1>
					<button
						disabled={basketTotalQty === oldBaksetTotalQty}
						className={basketTotalQty !== oldBaksetTotalQty ? 'active' : ''}
						onClick={handleChangebasket}
					>
						Actualiser mon panier
					</button>
				</div>
				<div className='cart-section'>
					<div className='purchases'>
						{basket?.map((item) => (
							<div className='purchase' key={item.product?._id}>
								<div className='left'>
									<div className='element'>
										<div className='element-img'>
											<img src={item.product?.image?.secure_url} alt='produit_image' />
										</div>
										<div className='element-desc'>
											<h4>{item.product?.designation}</h4>
											<span className='price'>
												{roundToThreeDecimals(
													item.product?.[typePrice(user.data.categories)] *
														(1 + item.product?.Tva / 100),
												)}{' '}
												TTC
											</span>
										</div>
									</div>
								</div>
								<div className='right'>
									<div className='confirmQTY'>
										<div className='qty'>
											<input
												type='number'
												value={parseInt(item.quantity, 10).toString()}
												onChange={(e) => handleChangeQty(e, item)}
											/>
										</div>
									</div>
									<div id='qty-price'>
										<span>{handleCalculatePrice(item)} TTC</span>
									</div>
									<div
										className='del'
										onClick={() => {
											setOpenDeleteModal(true);
											setIndexDeleteCart(item._id);
										}}
									>
										<icons.FaTrashAlt className='trash' />
									</div>
								</div>
							</div>
						))}
					</div>
					<div className='count'>
						<div className='count-header'>
							<label>Choisissez une boutique</label>
							<div id='shop-select'>
								<select
									onChange={(e) => setSelectedBoutiqueId(e.target.value)}
									value={selectedBoutiqueId}
								>
									<Fragment>
										<option value=''>Choisir une boutique</option>
										{BoutiquesData.map((boutique, index) => (
											<option key={index} value={boutique.id}>
												{boutique.name} - {boutique.ville}
											</option>
										))}
									</Fragment>
								</select>
							</div>
						</div>
						<div className='top'>
							<ul>
								<li id='number'>
									<span>{basketTotalQty}</span>
									{basketTotalQty === 1 ? 'ARTICLE' : 'ARTICLES'}
								</li>
								<li>
									<span>{roundToThreeDecimals(totalPriceTCC)} TTC</span>
								</li>
							</ul>
						</div>
						<div className='bottom'>
							<ButtonComponent
								disabled={!basket.length || outOfStock}
								loading={isLoading}
								className='button-primary'
								onClick={(e) => {
									e.preventDefault();
									if (!selectedBoutiqueId) {
										showToast('Veuillez choisir une boutique', 'warn');
										return;
									}
									handleOrder();
								}}
								name='Commander'
							/>
						</div>
					</div>
				</div>
			</div>

			{openDeleteModal && (
				<DeleteConfirmationModal
					onDelete={handleDeleteCart}
					onCancel={() => setOpenDeleteModal(false)}
				/>
			)}
		</div>
	);
};

export default ProductCartClient;
