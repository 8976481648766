import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateCommercialProfile } from '../../../api/redux/slices/Commercial';
import { icons } from '../../../assets/icons';
import { ButtonComponent } from '../../../components/buttons';
import { showToast } from '../../../components/modal';
import ListVehicles from './ListVehicles';

const UpdateComProfile = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const token = localStorage.getItem('accesstoken');
	const { infos: userInfos } = useSelector((state) => state.commercial);
	const [infos, setInfos] = useState(userInfos);
	const [isLoading, setIsloading] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = {
			last_name: infos.last_name.trim(),
			first_name: infos.first_name.trim(),
			email: infos.email.trim(),
			phone: infos.phone.trim(),
			company_name: infos.company_name.trim(),
			tax_registration_number: infos.tax_registration_number.trim(),
			is_client: false,
		};

		if (
			!data.last_name ||
			!data.first_name ||
			!data.email ||
			!data.phone ||
			!data.company_name ||
			!data.tax_registration_number
		) {
			showToast('Veuillez remplir tous les champs', 'warn');
			return;
		}
		setIsloading(true);
		dispatch(updateCommercialProfile({ token, data: data }))
			.unwrap()
			.then((res) => {
				showToast('Mis à jour', 'success');
				navigate('/commercial/profile');
			})
			.catch((err) => {
				showToast(err?.message || err, 'error');
			})
			.finally(() => setIsloading(false));
	};

	return (
		<div id='update-cp'>
			<form onSubmit={handleSubmit}>
				<div className='navbar' style={{ width: 'calc(100vw - 20rem)', marginBottom: '2rem' }}>
					<Link
						to='/commercial/profile'
						style={{ width: '500px', display: 'flex', alignItems: 'center' }}
					>
						<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
						<h1>Profile</h1>
					</Link>
					<div className='submit-btns'>
						<div className='add-btns'>
							<Link to='/commercial/profile'>
								<button className='button-nature'>Annuler</button>
							</Link>
							<ButtonComponent
								name='Enregistrer'
								loading={isLoading}
								type='submit'
								className='button-primary'
							/>
						</div>
					</div>
				</div>
				<div className='user-infos'>
					<h2>Information genérale</h2>
					<div className='form-line'>
						<div className='frag'>
							<label>Nom</label>
							<input
								type='text'
								placeholder='nom'
								value={infos.last_name}
								onChange={(e) => setInfos({ ...infos, last_name: e.target.value })}
							/>
						</div>
						<div className='frag'>
							<label htmlFor='prenom'>Prénom</label>
							<input
								type='text'
								placeholder='prenom'
								value={infos.first_name}
								onChange={(e) => setInfos({ ...infos, first_name: e.target.value })}
							/>
						</div>
					</div>
					<div className='field'>
						<label htmlFor='email'>Email</label>
						<input
							type='email'
							placeholder='nom@email.com'
							value={infos.email}
							onChange={(e) => setInfos({ ...infos, email: e.target.value })}
						/>
					</div>
					<div className='field'>
						<label htmlFor='number1'>Numéro de téléphone</label>
						<input
							type='text'
							value={infos.phone}
							onChange={(e) => {
								const onlyNumbers = e.target.value.replace(/[^0-9+\s]/g, '');
								setInfos({ ...infos, phone: onlyNumbers });
							}}
							placeholder='ex: 21 212 212'
						/>
					</div>
					<div className='field'>
						<label htmlFor='namesociete'>Nom de société</label>
						<input
							type='text'
							placeholder='nom de société'
							value={infos.company_name}
							onChange={(e) => setInfos({ ...infos, company_name: e.target.value })}
						/>
					</div>
					<div className='field' style={{ marginBottom: '1rem' }}>
						<label htmlFor='mfiscale'>Matricule fiscale</label>
						<input
							type='text'
							placeholder='ex: 0001985G A M 000'
							value={infos.tax_registration_number}
							onChange={(e) =>
								setInfos({
									...infos,
									tax_registration_number: e.target.value,
								})
							}
						/>
					</div>
				</div>
			</form>
			<ListVehicles id={infos._id} listVehicles={infos?.vehicles} />
		</div>
	);
};

export default UpdateComProfile;
