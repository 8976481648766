import { roundToThreeDecimals } from '../../../helpers/modules';

export const billCalculation = (detailBill) => {
	const tvaMap = [];

	detailBill?.list?.forEach((el) => {
		const tvaRate = el?.tva ?? '-';
		const existingIndex = tvaMap.findIndex((item) => item.tvaRate === tvaRate);

		const quantity = el?.quantity;
		const priseProductHT = el?.prise_product_HT;
		const remise = el?.remise;
		const tva = el?.tva;
		const amountExcludingTax = el?.Amount_excluding_tax;

		const discountBase = roundToThreeDecimals((priseProductHT * quantity) / (1 - remise / 100));
		const discountCalculation = discountBase - priseProductHT * quantity;
		const tvaCalculation = priseProductHT * quantity * (tva / 100);
		const totalTCC = amountExcludingTax * quantity;

		if (existingIndex !== -1) {
			tvaMap[existingIndex].TOTAL_TAXE += tvaCalculation;
			tvaMap[existingIndex].BASE += discountBase;
			tvaMap[existingIndex].REMISE += discountCalculation;
			tvaMap[existingIndex].TOTAL_TCC += totalTCC;
		} else {
			tvaMap.push({
				CODE: 'C' + tva,
				TAUX: tva,
				tvaRate,
				BASE: discountBase,
				TOTAL_TAXE: tvaCalculation,
				REMISE: discountCalculation,
				TOTAL_TCC: totalTCC,
			});
		}
	});

	return tvaMap;
};
export const finalTotal = (tvaMap) => {
	let totalHT = 0;
	let totalTCC = 0;
	let totalRemise = 0;

	for (const item of tvaMap) {
		totalHT += item.BASE;
		totalRemise += item.REMISE;
		totalTCC += item.TOTAL_TCC;
	}
	return {
		TOTAL_HT: roundToThreeDecimals(totalHT),
		TOTAL_REMISE: roundToThreeDecimals(totalRemise),
		TOTAL_TCC: roundToThreeDecimals(totalTCC),
	};
};
export const total = (detailBill) => {
	let totalBase = 0;
	let totalTVA = 0;
	const tvaMap = billCalculation(detailBill);
	tvaMap.forEach((el) => {
		totalBase += el.BASE;
		totalTVA += el.TOTAL_TAXE;
	});
	return (
		<tr className='bt-head-4'>
			<td>Total</td>
			<td>{roundToThreeDecimals(totalBase)}</td>
			<td></td>
			<td>{roundToThreeDecimals(totalTVA + 1)}</td>
		</tr>
	);
};
export const totalNet = (detailBill) => {
	const totalNet = billCalculation(detailBill).reduce((a, b) => a + b.TOTAL_TCC, 0);
	return roundToThreeDecimals(totalNet + 1);
};
export const convertNumberToWords = (n) => {
	if (n < 0) return false;

	const units = ['', 'Un', 'Deux', 'Trois', 'Quatre', 'Cinq', 'Six', 'Sept', 'Huit', 'Neuf'];
	const teens = [
		'Dix',
		'Onze',
		'Douze',
		'Treize',
		'Quatorze',
		'Quinze',
		'Seize',
		'Dix-sept',
		'Dix-huit',
		'Dix-neuf',
	];
	const tens = [
		'',
		'',
		'Vingt',
		'Trente',
		'Quarante',
		'Cinquante',
		'Soixante',
		'Soixante-dix',
		'Quatre-vingts',
		'Quatre-vingt-dix',
	];

	if (n === 0) return 'Zéro';

	const translate = (num) => {
		let word = '';

		if (num < 10) {
			word = units[num] + ' ';
		} else if (num < 20) {
			word = teens[num - 10] + ' ';
		} else if (num < 100) {
			const remainder = translate(num % 10);
			if (num >= 70 && num < 80) {
				word = 'Soixante ' + remainder;
			} else if (num >= 90) {
				word = 'Quatre-vingt-' + remainder;
			} else {
				word = tens[Math.floor(num / 10)] + ' ' + remainder;
			}
		} else if (num < 1000) {
			word =
				units[Math.floor(num / 100)] + ' Cent ' + (num % 100 === 0 ? '' : translate(num % 100));
		} else if (num < 1000000) {
			word =
				translate(Math.floor(num / 1000)) +
				' Dinar ' +
				(num % 1000 === 0 ? '' : translate(num % 1000));
		} else if (num < 1000000000) {
			word =
				translate(Math.floor(num / 1000000)) +
				' Million ' +
				(num % 1000000 === 0 ? '' : translate(num % 1000000));
		} else {
			word =
				translate(Math.floor(num / 1000000000)) +
				' Milliard ' +
				(num % 1000000000 === 0 ? '' : translate(num % 1000000000));
		}

		return word;
	};

	const result = translate(n);
	return result.trim() + '.';
};
