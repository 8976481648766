import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDetails } from '../../../api/redux/slices/Commercial';
import { LoaderComponent } from '../../../helpers/components/loader';

const ComProfile = () => {
	const dispatch = useDispatch();
	const token = localStorage.getItem('accesstoken');
	const { infos } = useSelector((state) => state.commercial);

	const [isLoading, setIsloading] = useState(false);

	useEffect(() => {
		setIsloading(true);
		dispatch(getProfileDetails({ token }))
			.unwrap()
			.finally(() => setIsloading(false));
	}, [dispatch, token]);

	if (isLoading) {
		return <LoaderComponent />;
	}
	return (
		<div id='comProfile-cp'>
			<div className='navbar'>
				<div className='left'>
					<h1>Profile</h1>
				</div>
				<div className='right'>
					<div className='right-items'>
						<Link to={`/commercial/profile/update_password`}>
							<button className='button-nature'>Changer le mot de passe</button>
						</Link>
						<Link to={`/commercial/profile/update`}>
							<button className='button-primary'>Modifier le profile</button>
						</Link>
					</div>
				</div>
			</div>
			<div className='cp-box'>
				<h2>Information générale</h2>
				<div className='line'>
					<h3>nom</h3>
					<span>{infos.last_name}</span>
				</div>
				<div className='line'>
					<h3>prénom</h3>
					<span>{infos.first_name}</span>
				</div>
				<div className='line'>
					<h3>email</h3>
					<span>{infos.email}</span>
				</div>
				<div className='line'>
					<h3>N° De Téléphone</h3>
					<span>{infos.phone}</span>
				</div>
				<div className='line'>
					<h3>nom de société</h3>
					<span>{infos.company_name ? infos.company_name : '-'}</span>
				</div>
				<div className='line'>
					<h3>Matricule fiscale</h3>
					<span>{infos.tax_registration_number ? infos.tax_registration_number : '-'}</span>
				</div>
				{infos.vehicles?.map((vehicele, index) => (
					<div className='line' key={index}>
						<h3>Matricule véhicule {index + 1}</h3>
						<span>{vehicele?.vehicle_number || '-'}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default ComProfile;
