import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import useStableArr from '../../../utils/useStableArr';
import { TopBar } from '../../../components/navbar';
import { convertDate } from '../../../helpers/modules';
import { LoaderComponent } from '../../../helpers/components/loader';
import { icons } from '../../../assets/icons';
import Pagination from '../../../helpers/components/Pagination';
import { generateId } from '../../../helpers/modules';

import './index.scss';
import { getOrdersByCustomer } from '../../../api/redux/slices/Customer';
import { handleCheck, handleCheckAll } from '../../../utils/checkUtilsCustomer';

const OrdersPage = () => {
	const { listOrders, checkedList, total } = useSelector((state) => state.customer);
	const dispatch = useDispatch();
	const params = useParams();
	const token = localStorage.getItem('accesstoken');

	const [modalCsvexport, setModalCsvexport] = useState(false);
	const [page, setPage] = useState(Number(params.page));
	const [searchValue, setSearchValue] = useState('');
	const [statusValue, setStatusValue] = useState('');
	const [isCheck, setIsCheck] = useState(false);
	const [newArr, setNewArr] = useState(
		listOrders.map((el) => ({ ...el, checked: false, custom_id: generateId() })),
	);

	//------------------------------ get list orders ------------------------//
	const [isloading, setIsLoading] = useState(false);

	const stableDispatch = useCallback(async () => {
		setIsLoading(true);
		dispatch(
			getOrdersByCustomer({
				token: token,
				page: searchValue || statusValue ? undefined : page,
				search: searchValue,
				status: statusValue,
			}),
		)
			.unwrap()
			.finally(() => setIsLoading(false));
	}, [dispatch, page, token, searchValue, statusValue]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [stableDispatch, token]);

	// --------------- clone the list of orders into the new array ---------------//
	useStableArr(listOrders, checkedList, setNewArr, setIsCheck);

	//-------------------------------- CSV export ---------------------------//
	const headers = [
		{ label: 'NUMERO', key: 'number' },
		{ label: 'REFERENCE', key: 'reference' },
		{ label: 'DATE', key: 'date' },
		{ label: 'TIMBRE', key: 'timbre' },
		{ label: 'TOTAL', key: 'total' },
		{ label: 'TOTAL HT', key: 'total_HT' },
		{ label: 'TVA', key: 'tva' },
		{ label: 'CATEGORIE', key: 'clientInfo.categories' },
		{ label: 'NUMERO DE TEL', key: 'clientInfo.phone' },
		{ label: 'UTILISATEUR', key: 'clientInfo.client' },
		{ label: 'SOCIETE', key: 'clientInfo.company_name' },
	];
	const csvOrdersReport = {
		filename: 'Commandes.csv',
		data: checkedList,
		headers: headers,
	};

	return (
		<div id='orderPageCustomer'>
			<TopBar
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				modalCsvexport={modalCsvexport}
				setModalCsvexport={setModalCsvexport}
				statusValue={statusValue}
				setStatusValue={setStatusValue}
				csvOrdersReport={csvOrdersReport}
				isImport={false}
			/>
			<div id='head'>
				<div>
					<h1>Bons de commandes</h1>
				</div>
			</div>
			<div id='ordersContentAdmin'>
				<table id='orders'>
					<thead>
						<tr id='titres'>
							<td className='input-check'>
								<input
									type='checkbox'
									className='checkbox'
									checked={isCheck}
									onChange={() => handleCheckAll(dispatch, newArr, setNewArr, isCheck, setIsCheck)}
								/>
							</td>
							<td>NUMÉRO</td>
							<td>DATE</td>
							<td>CRÉÉR PAR</td>
							<td>TOTAL</td>
							<td className='center'>STATUT</td>
							<td className='center'>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{isloading ? (
							<tr>
								<td colSpan='7'>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							newArr?.map((order, index) => (
								<tr className='order' key={index}>
									<td className='input-check'>
										<input
											type='checkbox'
											className='checkbox'
											checked={order.checked}
											onChange={() => handleCheck(dispatch, newArr, setNewArr, order._id)}
										/>
									</td>
									<td style={{ textTransform: 'uppercase' }}>{order?.reference || '-'}</td>
									<td>{order?.date ? convertDate(order?.date, 'DD-MM-YYYY') : '-'}</td>
									<td>{order?.created_By || '-'}</td>
									<td>{order?.total || '-'} DT</td>
									<td
										className={`center status ${
											order?.status === 'ORDER' ? 'en-cours' : 'confirmer'
										}`}
									>
										{order?.status === 'ORDER' ? 'en cours' : 'confirmé'}
									</td>
									<td className='last-order-element'>
										<Link
											to={`/client/orders/order_details/${page}/${order._id}`}
											className='link-order'
										>
											<icons.BsEye className='see-order' />
										</Link>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink={'/client/orders/'} total={total} />
		</div>
	);
};

export default OrdersPage;
