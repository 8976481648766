// useStableArr.js
import { useEffect } from 'react';

const useStableArr = (arr, checkedList, setNewArr, setIsCheck) => {
	useEffect(() => {
		const stableArr = () => {
			const checkedIds = checkedList.map((item) => item._id);
			let count = 0;
			const updatedArr = arr.map((item) => {
				const checked = checkedIds.includes(item._id);
				if (checked) {
					count += 1;
				}
				return { ...item, checked };
			});
			setNewArr(updatedArr);
			count === arr.length && arr.length > 0 ? setIsCheck(true) : setIsCheck(false);
		};

		stableArr();
	}, [arr, checkedList, setNewArr, setIsCheck]);
};

export default useStableArr;
