import React, { useEffect, useState } from 'react';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useSelector } from 'react-redux';

const SeeProduct = ({ product_id, modalOpen, setModalOpen }) => {
	const { listProducts } = useSelector((state) => state.commercial);
	const [infos, setInfos] = useState(...listProducts.filter((el) => el._id === product_id));

	useEffect(() => {
		setInfos(...listProducts.filter((el) => el._id === product_id));
	}, [product_id, listProducts]);

	return (
		<div className={'modalcontainer ' + (modalOpen && 'open')}>
			<div className={'modal ' + (modalOpen && 'active')} style={{ top: '40%' }}>
				<div className='left-modal'>
					<img
						src={infos.image.secure_url || process.env.REACT_APP_IMAGE_PLACEHOLDER_URL}
						alt='product_image'
					/>
				</div>

				<div className='right-modal'>
					<h3>{infos.designation}</h3>
					<span id='price'>{infos.prise_HT} TND</span>
					<p className='desc'>{infos.description}</p>
					<div className='line-desc'>
						<h4>Contenance</h4>
						<span>{infos.capacity}</span>
					</div>
					<div className='line-desc'>
						<h4>Nombre de pièce -carton</h4>
						<span>{infos.nb_cardboard_piece}</span>
					</div>
					<div className='line-desc'>
						<h4>Couleur</h4>
						<span>{infos.color}</span>
					</div>
					<div className='line-desc'>
						<h4>Matière</h4>
						<span>{infos.material}</span>
					</div>
				</div>
				<div className='close-modal' onClick={() => setModalOpen(false)}>
					<icons.MdClose />
				</div>
			</div>
		</div>
	);
};

export default SeeProduct;
