import React from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { accepteDelivery, refuseDelivery } from '../../../api/redux/slices/Commercial';
import { showToast } from '../../../components/modal/index';

const DeliveryOptions = ({ delivery, page, setDeliveryOpenDropdown, setDeliveryChangeStatus }) => {
	const dispatch = useDispatch();
	const ref = useOnclickOutside(() => {
		setDeliveryOpenDropdown(false);
	});

	const handleAccepteDelivery = () => {
		dispatch(
			accepteDelivery({
				token: localStorage.getItem('accesstoken'),
				id: delivery._id,
			}),
		)
			.unwrap()
			.then(() => {
				showToast('Livraison acceptée avec succes', 'success');
				setDeliveryChangeStatus(true);
			})
			.finally(() => {
				setDeliveryOpenDropdown(false);
			});
	};
	const handleRefuseDelivery = () => {
		dispatch(
			refuseDelivery({
				token: localStorage.getItem('accesstoken'),
				id: delivery._id,
			}),
		)
			.unwrap()
			.then(() => {
				showToast('Livraison refusee avec succes', 'info');
				setDeliveryChangeStatus(true);
			})
			.finally(() => {
				setDeliveryOpenDropdown(false);
			});
	};

	return (
		<div className='modalSelectOpt' ref={ref}>
			{delivery.status_commercial === 'IN_PROGRESS' && (
				<ul>
					<li onClick={handleAccepteDelivery}>Accepter livraison</li>
					<li onClick={handleRefuseDelivery} style={{ color: '#EF4444' }}>
						Refuser livraison
					</li>
				</ul>
			)}
			{delivery.status === 'IN_PROGRESS' && delivery.status_commercial !== 'IN_PROGRESS' && (
				<ul>
					<Link to={`/commercial/deliveries/confirm_delivery/${page}/${delivery._id}`}>
						<li>Confirmer livraison</li>
					</Link>
				</ul>
			)}
			{(delivery.status === 'CONFIRM') & !delivery?.return_id ? (
				<ul>
					<Link to={`/commercial/deliveries/add_return/${page}/${delivery._id}`}>
						<li>Faire bon de retour</li>
					</Link>
				</ul>
			) : null}
			{delivery.status === 'REFUSE' && (
				<ul>
					<Link to={`/commercial/deliveries/confirm_delivery/${page}/${delivery._id}`}>
						<li>Modifier paiement</li>
					</Link>
				</ul>
			)}
		</div>
	);
};

export default DeliveryOptions;
