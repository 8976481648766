import React from 'react';
import './index.scss';
import { icons } from './../../assets/icons/index';

export const ProductCard = ({
	img_url,
	onClickShop,
	onClickSee,
	product_name,
	product_price,
	priceWithVAT = false,
	isDisabled = false,
}) => {
	return (
		<div className='card'>
			<div className='top'>
				<img src={img_url} alt='product_image' />
				<div className='cart-actions'>
					<button
						disabled={isDisabled}
						className={`shop ${isDisabled && 'disabled'}`}
						onClick={onClickShop}
					>
						<icons.RiShoppingCartLine className='cart-cart-icon' />
					</button>
					<div className={`eye`} onClick={onClickSee}>
						<icons.BsEye />
					</div>
				</div>
			</div>
			<div className='bottom'>
				<h3>{product_name}</h3>
				{isDisabled ? (
					<p style={{ color: '#EF4444', fontSize: '16px', fontWeight: 500 }}>Rupture de stock</p>
				) : (
					<span>
						{product_price} {priceWithVAT ? 'TTC' : 'TND'}
					</span>
				)}
			</div>
		</div>
	);
};
