import { Fragment, useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useStableArr from '../../../utils/useStableArr';
import { TopBar } from '../../../components/navbar';
import { convertDate } from '../../../helpers/modules';
import { handleCheck, handleCheckAll } from '../../../utils/checkUtilsCommercial';
import { LoaderComponent } from '../../../helpers/components/loader';
import { ButtonComponent } from '../../../components/buttons';
import { icons } from '../../../assets/icons';
import Pagination from '../../../helpers/components/Pagination';
import { generateId } from '../../../helpers/modules';
import { showToast } from '../../../components/modal';
import {
	clientsOrderListByCommercial,
	getOrdersByCommercial,
} from '../../../api/redux/slices/Commercial';
import './index.scss';

const OrdersPage = () => {
	const { listOrders, checkedList, total } = useSelector((state) => state.commercial);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const token = localStorage.getItem('accesstoken');

	const [modalOpen, setModalOpen] = useState(false);
	const [modalCsvexport, setModalCsvexport] = useState(false);
	const [page, setPage] = useState(Number(params.page));
	const [searchValue, setSearchValue] = useState('');
	const [statusValue, setStatusValue] = useState('');
	const [searchValueCompanies, setSearchValueCompanies] = useState('');
	const [isCheck, setIsCheck] = useState(false);
	const [newArr, setNewArr] = useState(
		listOrders.map((el) => ({ ...el, checked: false, custom_id: generateId() })),
	);

	const [filteredData, setFilteredData] = useState([]);
	const [choosed, setChoosed] = useState(false);
	const [user, setUser] = useState(null);
	const [loadingCompanies, setLoadingCompanies] = useState(false);
	const ref = useOnclickOutside(() => {
		setModalOpen(false);
		setSearchValueCompanies('');
	});

	//------------------------------ get list orders ------------------------//
	const [isloading, setIsLoading] = useState(false);

	const stableDispatch = useCallback(async () => {
		setIsLoading(true);
		dispatch(
			getOrdersByCommercial({
				token: token,
				page: searchValue || statusValue ? undefined : page,
				search: searchValue,
				status: statusValue,
			}),
		)
			.unwrap()
			.finally(() => setIsLoading(false));
	}, [dispatch, page, token, searchValue, statusValue]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [stableDispatch, token]);

	//------------------------------ get list companies ------------------------//
	const stableDispatchCompanies = useCallback(() => {
		if (token && searchValueCompanies !== '') {
			localStorage.removeItem('cart');
			localStorage.removeItem('order');
			localStorage.removeItem('company');
			setLoadingCompanies(true);
			dispatch(
				clientsOrderListByCommercial({
					token: token,
					value: searchValueCompanies,
				}),
			)
				.unwrap()
				.then((res) =>
					setFilteredData(
						res.data.list.filter((value) => {
							return value.company_name.toLowerCase().match(searchValueCompanies.toLowerCase());
						}),
					),
				)
				.finally(() => setLoadingCompanies(false));
		} else {
			setFilteredData([]);
		}
	}, [dispatch, token, searchValueCompanies]);

	useEffect(() => {
		stableDispatchCompanies();
	}, [stableDispatchCompanies, token, searchValueCompanies]);

	// --------------- clone the list of orders into the new array ---------------//
	useStableArr(listOrders, checkedList, setNewArr, setIsCheck);

	//-------------------------------- CSV export ---------------------------//
	const headers = [
		{ label: 'NUMERO', key: 'number' },
		{ label: 'REFERENCE', key: 'reference' },
		{ label: 'DATE', key: 'date' },
		{ label: 'TIMBRE', key: 'timbre' },
		{ label: 'TOTAL', key: 'total' },
		{ label: 'TOTAL HT', key: 'total_HT' },
		{ label: 'TVA', key: 'tva' },
		{ label: 'CATEGORIE', key: 'clientInfo.categories' },
		{ label: 'NUMERO DE TEL', key: 'clientInfo.phone' },
		{ label: 'UTILISATEUR', key: 'clientInfo.client' },
		{ label: 'SOCIETE', key: 'clientInfo.company_name' },
	];
	const csvOrdersReport = {
		filename: 'Commandes.csv',
		data: checkedList,
		headers: headers,
	};

	//-------------- redirect to the order market -----------------------//
	const redirectMarket = (e) => {
		e.preventDefault();
		if (user?.company_name && choosed) {
			localStorage.setItem('company', JSON.stringify(user));
			navigate(`/commercial/orders/order_market/${page}`);
		} else {
			showToast('Veuillez choisir une société', 'warn');
		}
	};

	return (
		<div id='orderPageCommercial'>
			<TopBar
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				modalCsvexport={modalCsvexport}
				setModalOpen={setModalOpen}
				setModalCsvexport={setModalCsvexport}
				buttonTitle={'Commande'}
				statusValue={statusValue}
				setStatusValue={setStatusValue}
				csvOrdersReport={csvOrdersReport}
				isImport={false}
			/>
			<div id='head'>
				<div>
					<h1>Bons de commandes</h1>
				</div>
			</div>
			<div className={'modalcontainer ' + (modalOpen && 'open')}>
				<div
					className={'modal ' + (modalOpen && 'active')}
					ref={ref}
					style={{ top: '25%', left: '50%' }}
				>
					<form onSubmit={redirectMarket}>
						<div className='field'>
							<label>Pour qui cette commande ?</label>
							<input
								type='text'
								placeholder='Entrer le nom'
								value={searchValueCompanies}
								onChange={(e) => {
									setSearchValueCompanies(e.target.value);
									setChoosed(false);
								}}
							/>
							<div className='dynamicData'>
								{!choosed &&
									filteredData.slice(0, 5).map((val, key) => {
										return (
											<Fragment key={key}>
												<span
													onClick={() => {
														setSearchValueCompanies(val.company_name);
														setChoosed(true);
														setUser(val);
													}}
												>
													{val.company_name}{' '}
													<span style={{ color: 'grey', fontSize: '0.8rem' }}>
														{val.tax_registration_number}
													</span>
												</span>
											</Fragment>
										);
									})}
							</div>
						</div>
						<div className='modalbtn' style={{ gap: '1rem' }}>
							<ButtonComponent
								className='button-nature'
								onClick={(e) => {
									e.preventDefault();
									setModalOpen(!modalOpen);
								}}
								type='button'
								name={'Annuler'}
							/>
							<ButtonComponent
								name={'Continuer'}
								className='button-primary'
								type='submit'
								loading={loadingCompanies}
							/>
						</div>
					</form>
				</div>
			</div>
			<div id='ordersContentAdmin'>
				<table id='orders'>
					<thead>
						<tr id='titres'>
							<td className='input-check'>
								<input
									type='checkbox'
									className='checkbox'
									checked={isCheck}
									onChange={() => handleCheckAll(dispatch, newArr, setNewArr, isCheck, setIsCheck)}
								/>
							</td>
							<td>NUMÉRO</td>
							<td>DATE</td>
							<td>CLIENT</td>
							<td>SOCIÉTÉ</td>
							<td>CRÉÉR PAR</td>
							<td>TOTAL</td>
							<td className='center'>STATUT</td>
							<td className='center'>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{isloading ? (
							<tr>
								<td colSpan='7'>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							newArr?.map((order, index) => (
								<tr className='order' key={index}>
									<td className='input-check'>
										<input
											type='checkbox'
											className='checkbox'
											checked={order.checked}
											onChange={() => handleCheck(dispatch, newArr, setNewArr, order._id)}
										/>
									</td>
									<td style={{ textTransform: 'uppercase' }}>{order?.reference || '-'}</td>
									<td>{order?.date ? convertDate(order?.date, 'DD-MM-YYYY') : '-'}</td>
									<td>{order?.clientInfo?.client || '-'}</td>
									<td>{order?.clientInfo?.company_name || '-'}</td>
									<td>{order?.created_By || '-'}</td>
									<td>{order?.total || '-'} DT</td>
									<td
										className={`center status ${
											order?.status === 'ORDER' ? 'en-cours' : 'confirmer'
										}`}
									>
										{order?.status === 'ORDER' ? 'en cours' : 'confirmer'}
									</td>
									<td className='last-order-element'>
										<Link
											to={`/commercial/orders/order_details/${page}/${order._id}`}
											className='link-order'
										>
											<icons.BsEye className='see-order' />
										</Link>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink={'/commercial/orders/'} total={total} />
		</div>
	);
};

export default OrdersPage;
