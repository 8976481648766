// UserInfoComponents.jsx
import React from 'react';

export const ClientInfo = ({ clientInfo }) => {
	return (
		<>
			<label style={{ textTransform: 'capitalize' }}>{clientInfo?.company_name || '-'}</label>
			<span>{clientInfo?.client}</span>
			<span>Matricule Fiscale: {clientInfo?.tax_registration_number}</span>
			<span>N° téléphone: {clientInfo?.phone ?? ''}</span>
		</>
	);
};

export const ShopInfo = ({ shop }) => {
	if (!shop) {
		return null;
	}

	return (
		<div>
			<span>{shop.address_number} </span>
			<span>{shop.street} </span>
			<span>{shop.ville} </span>
			<span>{shop.postal_code} </span>
			<span>{shop.region} </span>
		</div>
	);
};

export const SuperAdminInfo = ({ superAdminInfo }) => (
	<div className='footer'>
		<div className='left'>
			<ul>
				<li>{superAdminInfo?.address}</li>
				<li>
					CODE TVA <span>{superAdminInfo?.tva_code}</span>
				</li>
				<li>
					RC: <span>{superAdminInfo?.r_c}</span>
				</li>
				<li>
					Code en Douane <span>{superAdminInfo?.customs_code || '-'}</span>
				</li>
			</ul>
		</div>
		<div className='left'>
			<ul>
				<li>
					Tél <span>{superAdminInfo?.phone}</span>
				</li>
				<li>
					Fax <span>{superAdminInfo?.fax}</span>
				</li>
				<li>
					Email: <span>{superAdminInfo?.email}</span>
				</li>
			</ul>
		</div>
	</div>
);
