import { roundToThreeDecimals } from '../../../helpers/modules';
import { convertNumberToWords } from './billFunctions';

const PrintToPdf = ({ componentRef, detailBill, billCalculationArr, totalNetValue, totalArr }) => {
	let itemsPerPage = [9, 15];

	if (Object.keys(detailBill).length === 0) {
		return;
	}
	const renderTableRows = (start, end) => {
		return detailBill?.list?.slice(start, end).map((item, index) => (
			<tr className='elements' key={index}>
				<td>{item?.reference}</td>
				<td>{item?.designation}</td>
				<td>{item?.quantity}</td>
				<td>
					{roundToThreeDecimals(
						(item?.prise_product_HT * item?.quantity) / (1 - item?.remise / 100),
					)}
				</td>
				<td>{item?.remise}%</td>
				<td>{roundToThreeDecimals(item?.prise_product_HT * item?.quantity)}</td>
				<td>{roundToThreeDecimals(item?.Amount_excluding_tax * item?.quantity)}</td>
			</tr>
		));
	};
	const renderTable = (start, end) => {
		return (
			<div key={start} className='page-content'>
				<div className='devis-table'>
					<table className='offres'>
						<thead>
							<tr id='titres'>
								<td>RÉFÉRENCE</td>
								<td>DÉSIGNATION</td>
								<td>QTÉ</td>
								<td>PRIX UNIT H.T</td>
								<td>REMISE</td>
								<td>MONTANT H.T</td>
								<td>TX.TVA</td>
							</tr>
						</thead>
						<tbody>{renderTableRows(start, end)}</tbody>
					</table>
				</div>
			</div>
		);
	};
	return (
		<div className='bill-pdf-client' ref={componentRef}>
			<div className='container'>
				<div className='devis'>
					<h4>BON DE FACTURE</h4>
					<div className='info'>
						<div>
							<label>Numéro:</label>
							<label>Date:</label>
							<label>Référence code client:</label>
						</div>
						<div>
							<span>{detailBill?.bill?.reference}</span>
							<span>12-12-2022</span>
							<span>{detailBill?.bill?.delivery?.order_id?.clientInfo?.customs_code || '-'}</span>
						</div>
					</div>
				</div>
				<div className='general-info'>
					<div className='left'>
						<h2 className='infos-societe'>VTE EN GROS</h2>
						<div className='address'>
							<span>VTE EN GROS ART DE MENAGE</span>
							<span>{detailBill?.bill?.delivery?.order_id?.superAdminInfo?.address}</span>
						</div>
					</div>
					<div className='right'>
						<h3>{detailBill?.bill?.delivery?.order_id?.clientInfo?.company_name ?? ''}</h3>

						<p>{detailBill?.bill?.delivery?.order_id?.clientInfo?.client ?? ''}</p>
						<p>
							Matricule Fiscale:{' '}
							{detailBill?.bill?.delivery?.order_id?.clientInfo?.tax_registration_number ?? ''}
						</p>
						<p>N° téléphone: {detailBill?.bill?.delivery?.order_id?.clientInfo?.phone ?? ''}</p>
						<span>{detailBill?.bill?.delivery?.order_id?.shopInfo.address_number} </span>
						<span>{detailBill?.bill?.delivery?.order_id?.shopInfo.street} </span>
						<span>{detailBill?.bill?.delivery?.order_id?.shopInfo.ville} </span>
						<span>{detailBill?.bill?.delivery?.order_id?.shopInfo.postal_code} </span>
						<span>{detailBill?.bill?.delivery?.order_id?.shopInfo.region} </span>
					</div>
				</div>
				<div className='devis-table'>
					{renderTable(0, itemsPerPage[0])}
					{Array.from(
						{
							length: Math.ceil((detailBill?.list?.length - itemsPerPage[0]) / itemsPerPage[1]),
						},
						(_, index) =>
							renderTable(
								itemsPerPage[0] + index * itemsPerPage[1],
								itemsPerPage[0] + (index + 1) * itemsPerPage[1],
							),
					)}
					{detailBill?.list?.length >= 2 && <div style={{ pageBreakAfter: 'always' }} />}
					<div className='billTable'>
						<table id='bt-principal'>
							<tbody>
								<tr id='firstBigRow'>
									<td>
										<table id='bt-nested-1'>
											<thead>
												<tr id='bt-head-1'>
													<td>CODE</td>
													<td>BASE</td>
													<td>TAUX</td>
													<td>TOTAL TAXE</td>
												</tr>
											</thead>
											<tbody>
												{billCalculationArr?.map((el, index) => (
													<tr className='bt-tl-1' key={index}>
														<td>{el.CODE}</td>
														<td>{roundToThreeDecimals(el.BASE)}</td>
														<td>{el.TAUX}%</td>
														<td>{roundToThreeDecimals(el.TOTAL_TAXE)}</td>
													</tr>
												))}
												<tr className='bt-tl-1'>
													<td>CTI</td>
													<td>0.000</td>
													<td />
													<td>1</td>
												</tr>
											</tbody>
										</table>
									</td>
									<td>
										<table id='bt-nested-2'>
											<thead>
												<tr id='bt-head-2'>
													<td>TOTAL H.T</td>
													<td>REMISE</td>
													<td>ESCOMPTE</td>
													<td>TOTAL TTC</td>
												</tr>
											</thead>
											<tbody>
												{billCalculationArr?.map((el, index) => (
													<tr className='bt-tl-2' id='bt-tl-2' key={index}>
														<td>{roundToThreeDecimals(el.BASE)}</td>
														<td>{roundToThreeDecimals(el.REMISE)}</td>
														<td>0.000</td>
														<td>{roundToThreeDecimals(el.TOTAL_TCC)}</td>
													</tr>
												))}
												<tr className='bt-tl-2'>
													<td />
													<td />
													<td />
													<td />
												</tr>
											</tbody>
										</table>
									</td>
									<td>
										<table id='bt-nested-3'>
											<thead>
												<tr id='bt-head-3'>
													<td>NET A PAYER</td>
												</tr>
											</thead>
											<tbody>
												<tr className='bt-tl-3'>
													<td>{totalNetValue}</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
								<tr id='secondBigRow'>
									<td>
										<table id='bt-nested-4'>
											<tbody>
												<tr className='bt-head-4'>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
												</tr>
												<tr className='bt-head-4'>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
												</tr>
												{totalArr}
											</tbody>
										</table>
									</td>
									<td>
										<table id='bt-nested-5'>
											<thead>
												<tr id='bt-head-5'>
													<td>CONDITIONS DE REGLEMENT:</td>
													<td></td>
												</tr>
											</thead>
											<tbody>
												<tr className='bt-tl-5'>
													<td></td>
													<td></td>
												</tr>
												<tr className='bt-tl-5'>
													<td>90 JOURS</td>
													<td></td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
						<div className='billEnd'>
							<h2>ARRETER LA PRÉSENTE FACTURE À LA SOMME DE : </h2>
							<span>{convertNumberToWords(parseInt(totalNetValue * 1000))}</span>
						</div>
					</div>
				</div>
				<div className='footer'>
					<div className='left'>
						<ul>
							<li>{detailBill?.bill?.delivery?.order_id?.superAdminInfo?.address}</li>
							<li>
								CODE TVA{' '}
								<span>{detailBill?.bill?.delivery?.order_id?.superAdminInfo?.tva_code}</span>
							</li>
							<li>
								RC: <span>{detailBill?.bill?.delivery?.order_id?.superAdminInfo?.r_c}</span>
							</li>
							<li>
								Code en Douane{' '}
								<span>
									{detailBill?.bill?.delivery?.order_id?.superAdminInfo?.customs_code || '-'}
								</span>
							</li>
						</ul>
					</div>
					<div className='left'>
						<ul>
							<li>
								Tél <span>{detailBill?.bill?.delivery?.order_id?.superAdminInfo?.phone}</span>
							</li>
							<li>
								Fax <span>{detailBill?.bill?.delivery?.order_id?.superAdminInfo?.fax}</span>
							</li>
							<li>
								Email: <span>{detailBill?.bill?.delivery?.order_id?.superAdminInfo?.email}</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrintToPdf;
