import React, { useCallback, useEffect, useState } from 'react';
import './index.scss';
import { icons } from '../../../assets/icons';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { claimList, search_admin_claim } from '../../../api/redux/slices/SuperAdmin';
import { LoaderComponent } from '../../../helpers/components/loader';
import Pagination from '../../../helpers/components/Pagination';

const AdminComplaints = () => {
	const { claimsList, loading, total } = useSelector((state) => state.superAdmin);
	const dispatch = useDispatch();
	const params = useParams();
	const token = localStorage.getItem('accesstoken');

	const [searchValue, setSearchValue] = useState('');
	const [searchStatus, setSearchStatus] = useState();
	const [callback, setCallback] = useState(false);
	const [typing, setTyping] = useState(false);
	const [page, setPage] = useState(Number(params.page));

	useEffect(() => {
		searchStatus !== 'Tout'
			? dispatch(
					search_admin_claim({
						token: token,
						value: searchValue,
						page: searchValue || searchStatus ? undefined : page,
						status: searchStatus,
					}),
			  )
			: dispatch(search_admin_claim({ token: token, value: searchValue, page: page }));
	}, [dispatch, searchStatus, searchValue, token, page]);

	const stableDispatch = useCallback(() => {
		dispatch(claimList({ token: token, page: page }));
	}, [dispatch, page, token]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [page, callback, stableDispatch, token]);

	const handleConvert = (date) => {
		const newDate = new Date(date);
		return newDate.toLocaleString();
	};

	return (
		<div id='admin-complaints'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='text'
							placeholder='Rechercher ...'
							value={searchValue}
							onChange={(e) => {
								setSearchValue(e.target.value);
								setTyping(true);
							}}
						/>
						<icons.FiSearch className='searchicon' />
						{typing && (
							<icons.GrFormClose
								className='searchicon'
								onClick={(e) => {
									setSearchValue('');
									setCallback(!callback);
									setTyping(false);
								}}
							/>
						)}
					</div>
					<div className='filters'>
						<select value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
							<option>Tout</option>
							<option value={true}>Traité</option>
							<option value={false}>En cours</option>
						</select>
					</div>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Liste des réclamations</h1>
				</div>
			</div>
			<div className='mainContent'>
				<table className='content'>
					<thead>
						<tr className='titres'>
							<td>CLIENT</td>
							<td>SOCIÉTÉ</td>
							<td>DATE D'AJOUT</td>
							<td>OBJECTIF</td>
							<td style={{ margin: 'auto' }}>STATUT</td>
							<td>TRAITÉE PAR</td>
							<td style={{ margin: 'auto' }}>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{loading ? (
							<tr>
								<td colSpan={7}>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							claimsList?.map((element, index) => (
								<tr className='element' key={index}>
									<td style={{ textTransform: 'capitalize' }}>
										{element.created_by?.first_name || '-'}
									</td>
									<td>{element.created_by?.company_name || '-'}</td>
									<td>{handleConvert(element.date_start)}</td>
									<td className='object'>{element.object?.name}</td>
									<td
										className={element.status === true ? 'status' : 'status activé'}
										style={{ textTransform: 'capitalize', margin: 'auto' }}
									>
										{element.status === true ? 'Traité' : 'En cours'}
									</td>
									<td>
										{element.created_by?.first_name || '-'} {element.created_by?.last_name}
									</td>
									<td className='last-element'>
										<Link
											to={`/superadmin/complaints/complain_details/${params.page}/${element._id}`}
											style={{
												textDecoration: 'none',
												color: 'inherit',
												display: 'inline-flex',
												fontSize: '20px',
											}}
										>
											<icons.BsEye />
										</Link>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				pageLink={`/superadmin/complaints/`}
				total={total}
			/>
		</div>
	);
};

export default AdminComplaints;
