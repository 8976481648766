import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { ReturnsData } from '../../../data';

const UpdateReturn = () => {
	const params = useParams();
	const [detailReturn, setDetailReturn] = useState([]);
	const [returnId, setReturnId] = useState();

	useEffect(() => {
		if (params.id) {
			ReturnsData.forEach((el) => {
				if (el.id === params.id) {
					setDetailReturn([el]);
					setReturnId(params.id);
				}
			});
		}
	}, [params.id]);

	return (
		<div id='updateReturn'>
			<div className='update-return-header'>
				<Link to={`/admin/returns/return_details/${returnId}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>bon de retour</h1>
				</Link>
				<div style={{ display: 'flex', gap: '1rem' }}>
					<Link to={`/admin/returns/return_details/${returnId}`}>
						<button className='button-nature'>Annuler</button>
					</Link>
					<Link to={`/admin/returns/return_details/${returnId}`}>
						<button className='button-primary'>Enregistrer</button>
					</Link>
				</div>
			</div>
			<div className='container'>
				<form>
					<div className='credentials'>
						<div className='left'>
							<div className='left-el'>
								<label>Numéro</label>
								<input type='text' defaultValue={`FC2200410`} />
							</div>
							<div className='left-el'>
								<label>Date</label>
								<input type='date' defaultValue={`2022-05-22`} />
							</div>
							<div className='left-el'>
								<label>Référence</label>
								<input type='text' defaultValue={`-`} />
							</div>
						</div>
						<div className='right'>
							<label>MY HOUSE</label>
							<div className='right-el'>
								<input type='text' defaultValue={`MAHDIA`} />
								<input type='text' defaultValue={`TVA 114795866F/N/C 000`} />
								<input type='text' defaultValue={`5111 MAHDIA`} />
							</div>
						</div>
					</div>
					<div className='devis'>
						<div className='devis-head'>
							<h1>BON DE RETOUR</h1>
							<span />
						</div>
						<div className='devis-table'>
							<table className='offres'>
								<thead>
									<tr id='titres'>
										<td>RÉFÉRENCE</td>
										<td>CODE E.A.N</td>
										<td>DÉSIGNATION</td>
										<td>QTÉ</td>
									</tr>
								</thead>
								<tbody>
									<tr className='elements'>
										<td>HE111241-059</td>
										<td>8966574136842365</td>
										<td>BROC A EAU 1L PARIS 111241-059</td>
										<td>
											<input
												type='number'
												defaultValue={12.0}
												onWheel={(e) => e.currentTarget.blur()}
											/>
										</td>
									</tr>
									<tr className='elements'>
										<td>HE111241-059</td>
										<td>8966574136842365</td>
										<td>BROC A EAU 1L PARIS 111241-059</td>
										<td>
											<input type='number' defaultValue={12.0} />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className='livreur'>
						<div className='left'>
							<h4>NOM &amp; PRENOM DU LIVREUR</h4>
							<input type='text' placeholder='-' />
						</div>
						<div className='left'>
							<h4>MATRICULE VEHICULE</h4>
							<input type='text' placeholder='-' />
						</div>
					</div>
				</form>
				<div className='footer'>
					<div className='left'>
						<ul>
							<li>Rue Mahmoud El Karoui SAHLOUL, SOUSSE 4051</li>
							<li>
								CODE TVA <span>11021539B/A/M 0000</span>
							</li>
							<li>
								RC: <span>B9141302009</span>
							</li>
							<li>
								Code en Douane <span>842782Z</span>
							</li>
						</ul>
					</div>
					<div className='left'>
						<ul>
							<li>
								Tél <span>31 405 595</span>
							</li>
							<li>
								Fax <span>32 405 595</span>
							</li>
							<li>
								Email: <span>somec.sousse@gmail.com</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpdateReturn;
