import axios from '../http-common';

const createAxiosRequest = (token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return {
		get: (url, params = {}) => axios.get(url, { ...config, ...params }),
		post: (url, data) => axios.post(url, data, config),
		put: (url, data) => axios.put(url, data, config),
		delete: (url) => axios.delete(url, config),
	};
};

const axiosWithToken = createAxiosRequest;
//------------Auth----------------//
const connexion = (data) => {
	return axios.post('/auth/login', data);
};

const deconnexion = (token) => {
	return axiosWithToken(token).post('/auth/logout');
};

const getUserByToken = (token) => {
	return axiosWithToken(token).get('/auth/me');
};

// ------------Super-Admin-----------//
const updateProfile = (token, data) => {
	return axiosWithToken(token).put('/super-admin/profile/', data);
};

//---------------Admin------------------//
const updateAdminProfile = (token, data) => {
	return axiosWithToken(token).put(`/admin/profile/`, data);
};

const updateAdminPassword = (token, data) => {
	return axiosWithToken(token).put(`/admin/reset-password/`, data);
};

const AuthServices = {
	connexion,
	getUserByToken,
	updateProfile,
	updateAdminProfile,
	updateAdminPassword,
	deconnexion,
};
export default AuthServices;
