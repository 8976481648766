import './index.scss';
import { icons } from '../../../assets/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import UpdateStock from './UpdateStock';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteProductByAdmin,
	getCategoriesForProducts,
	getProductsByAdmin,
	newProductByAdmin,
	productStatus,
} from '../../../api/redux/slices/Admin';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { LoaderComponent } from '../../../helpers/components/loader';
import Papa from 'papaparse';
import { DataErrorModal, showToast } from '../../../components/modal';
import Pagination from '../../../helpers/components/Pagination';
import DeleteConfirmationModal from '../addUser/ShopDeleteModal';
import useStableArr from '../../../utils/useStableArr';
import { handleCheck, handleCheckAll } from '../../../utils/checkUtilsAdmin';

const ProductPage = () => {
	const { actived } = useSelector((state) => state.auth);
	const { listCategoriesProducts, productsArr, checkedList, total } = useSelector(
		(state) => state.admin,
	);
	const [newArr, setNewArr] = useState(productsArr.map((el) => ({ ...el, checked: false })));

	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();
	const ref = useOnclickOutside(() => {
		setProductOpenDropdown(false);
	});

	const [page, setPage] = useState(Number(params.page));
	const token = localStorage.getItem('accesstoken');

	const [productOpenDropdown, setProductOpenDropdown] = useState(false);
	const [indiceProduit, setIndiceProduit] = useState(null);
	const [stockModalOpen, setStockModalOpen] = useState(false);
	const [categoryValue, setCategoryValue] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [isCheck, setIsCheck] = useState(false);
	const [productId, setProductId] = useState('');

	const [errorData, setErrorData] = useState([]);
	const [errorModalOpen, setErrorModalOpen] = useState(false);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteIndex, setDeleteIndex] = useState(null);
	//----------------------------- retrieve a file to upload ---------------------------//
	const fileInputRef = useRef(null);
	const getfile = () => {
		fileInputRef.current.value = null;
		fileInputRef.current.click();
	};

	//----------------------- get products and categories features -------------------------------//
	const [isloading, setIsLoading] = useState(false);

	const stableDispatch = useCallback(() => {
		setIsLoading(true);
		dispatch(
			getProductsByAdmin({
				token: token,
				name: searchValue,
				category_name: categoryValue,
				page: searchValue || categoryValue ? undefined : page,
			}),
		).finally(() => setIsLoading(false));
	}, [dispatch, page, token, categoryValue, searchValue]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [stableDispatch, token, navigate]);

	const stableDispatchCat = useCallback(() => {
		dispatch(getCategoriesForProducts({ token: token }));
	}, [dispatch, token]);

	useEffect(() => {
		if (token) {
			stableDispatchCat();
		}
	}, [stableDispatchCat, token]);

	// --------------- clone the list of products into the new array ---------------//
	useStableArr(productsArr, checkedList, setNewArr, setIsCheck);

	//----------------------------- Hndle block and unblock customer or commercial -------------------------------------//
	const handleBlock = (e, id) => {
		e.preventDefault();

		const data = {
			status: false,
		};
		dispatch(productStatus({ token: token, id: id, data }))
			.unwrap()
			.then(() => {
				setProductOpenDropdown(false);
				showToast('Produit desactivé', 'info', 'bottom-right');
				dispatch(
					getProductsByAdmin({
						token: token,
						name: '',
						category_name: '',
						reference_name: '',
						page: page,
					}),
				);
			})
			.catch((err) => {
				showToast(err, 'error', 'right-bottom');
			});
	};
	const handleUnblock = (e, id) => {
		e.preventDefault();

		const data = {
			status: true,
		};
		dispatch(productStatus({ token: token, id: id, data }))
			.unwrap()
			.then(() => {
				setProductOpenDropdown(false);
				showToast('Produit reactivé', 'info', 'bottom-right');
				dispatch(
					getProductsByAdmin({
						token: token,
						name: '',
						category_name: '',
						reference_name: '',
						page: page,
					}),
				);
			})
			.catch((err) => {
				showToast(err, 'error', 'right-bottom');
			});
	};
	//--------------------- Loader and user status ------------------------//
	useEffect(() => {
		if (!actived) {
			showToast('Utilsateur bloqué', 'info');
		}
	}, [actived]);

	//------------------------- redirection to update page  ---------------------------//
	const modifyProduct = (id) => {
		navigate(`/admin/products/product_update/${params.page}/${id}`);
	};

	//----------------------- Delete single product -------------------------------------//
	const handleDeleteOneProduct = (id) => {
		dispatch(deleteProductByAdmin({ token: token, id: id }))
			.unwrap()
			.then(() => {
				setProductOpenDropdown(false);
				dispatch(
					getProductsByAdmin({
						token: token,
						name: searchValue,
						category_name: categoryValue,
						page: page,
					}),
				);
				showToast('Produit supprimé', 'info');
			})
			.catch((err) => {
				showToast(err?.message, 'error');
			});
	};

	//-------------------------- Delete checked products ----------------------------//
	const deleteAllChecked = () => {
		checkedList.forEach((product) => {
			dispatch(deleteProductByAdmin({ token: token, id: product._id }))
				.unwrap()
				.then(() => {
					dispatch(
						getProductsByAdmin({
							token: token,
							name: searchValue,
							category_name: categoryValue,
							page: page,
						}),
					);
					dispatch(getCategoriesForProducts({ token: token }));
				})
				.catch((err) => {
					toast.error(err.message, {
						position: 'top-center',
						autoClose: 1000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				});
		});
	};

	//------------ CSV export ----------------//
	const headers = [
		{ label: 'REFERENCE', key: 'reference' },
		{ label: 'CODE BARRE', key: 'barCode' },
		{ label: 'DESIGNATION', key: 'designation' },
		{ label: 'DESCRIPTION', key: 'description' },
		{ label: 'CATEGORIE', key: 'category.designation' },
		{ label: 'MATIERE PRODUIT', key: 'material' },
		{ label: 'NOMBRE PIECE/CARTON', key: 'nb_cardboard_piece' },
		{ label: 'CONTENANCE', key: 'capacity' },
		{ label: 'COLEUR PRODUIT', key: 'color' },
		{ label: 'STATUT', key: 'status' },
		{ label: 'PRIX HT', key: 'prise_HT' },
		{ label: 'PRIX GROS', key: 'Wholesale_Price' },
		{ label: 'PRIX DETAIL', key: 'Retail_Price' },
		// { label: 'PRIX UMD', key: 'Price_of_UMD' },
		// { label: 'PRIX BLACK', key: 'Price_of_Black' },
		{ label: 'PRIX GRANDE SURFACE', key: 'Monoprix_Price' },
		{ label: 'TVA', key: 'Tva' },
		{ label: 'TIMBRE', key: 'stamp' },
	];

	const csvProductsReport = {
		filename: 'Products.csv',
		data: checkedList,
		headers: headers,
	};

	//------------------------ CSV import ----------------------//
	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];

		if (selectedFile) {
			const reader = new FileReader();

			reader.onload = async ({ target }) => {
				const csv = Papa.parse(target.result, {
					header: true,
				});
				const parsedData = csv?.data;
				const errors = [];
				const promises = parsedData.reduce((accumulator, values) => {
					const data = {
						reference: values.REFERENCE,
						barCode: values['CODE BARRE'],
						designation: values.DESIGNATION || '-',
						description: values.DESCRIPTION || '-',
						material: values['MATIERE PRODUIT'] || '-',
						nb_cardboard_piece: values['NOMBRE PIECE/CARTON'] || 1,
						capacity: values.CONTENANCE || 1,
						prise_HT: values['PRIX HT'] || 1,
						Wholesale_Price: values['PRIX GROS'] || 1,
						Retail_Price: values['PRIX DETAIL'] || 1,
						Price_of_UMD: values['PRIX UMD'] || 1,
						Price_of_Black: values['PRIX BLACK'] || 1,
						color: values['COLEUR PRODUIT'] || '-',
						Monoprix_Price: values['PRIX MONOPRIX'] || 1,
						Tva: values.TVA || 0,
						stamp: values.TIMBRE || 1,
					};
					if (data.reference && data.barCode) {
						accumulator.push(
							dispatch(newProductByAdmin({ token: token, data }))
								.unwrap()
								.catch((err) => {
									if (err?.error?.message) {
										showToast(err?.error?.message, 'error');
									} else {
										errors.push({
											Reference: values.REFERENCE,
											error: err?.message || 'Unknown error',
										});
									}
								}),
						);
					}

					return accumulator;
				}, []);

				await Promise.all(promises);

				if (errors.length > 0) {
					setErrorData(errors);
					setErrorModalOpen(true);
				} else {
					showToast('Toutes les produits ont été ajoutées', 'success');
					stableDispatch();
				}
			};

			reader.readAsText(selectedFile);
		}
	};

	//------------------------ main component ----------------------//
	return (
		<div id='productPage'>
			{showDeleteModal && (
				<DeleteConfirmationModal
					onDelete={() => {
						if (deleteIndex !== null) {
							handleDeleteOneProduct(deleteIndex);
						} else {
							deleteAllChecked();
						}
						setShowDeleteModal(false);
					}}
					onCancel={() => setShowDeleteModal(false)}
					msg={
						checkedList.length > 1
							? 'Êtes-vous sûr de vouloir supprimer tous les produits sélectionnés ?'
							: 'Êtes-vous sûr de bien vouloir supprimer ce produit ?'
					}
				/>
			)}

			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='search'
							placeholder='Nom du produit ...'
							value={searchValue}
							onChange={(e) => setSearchValue(e.target.value)}
						/>
						<icons.FiSearch className='searchicon' />
					</div>
					<div className='filters'>
						<select
							name='catégories'
							value={categoryValue}
							onChange={(e) => setCategoryValue(e.target.value)}
						>
							<option value=''>Catégories</option>
							{listCategoriesProducts.map((cat) => (
								<Fragment key={cat._id}>
									<option value={cat._id}>{cat.designation}</option>
								</Fragment>
							))}
						</select>
					</div>
				</div>
				<div className='right'>
					<div className='right-items'>
						<Link to={`/admin/products/add_product/${page}`}>
							<button className='button-primary'>
								<icons.SiAddthis />
								<span>Produit</span>
							</button>
						</Link>
						<CSVLink {...csvProductsReport} target='blank'>
							<button className='button-nature' title='exporter produit(s)'>
								<icons.BiExport />
							</button>
						</CSVLink>
						<button className='button-nature' title='importer category' onClick={getfile}>
							<icons.BiImport />
							<input
								type='file'
								id='fileuploading'
								ref={fileInputRef}
								style={{ display: 'none' }}
								accept='.csv'
								onChange={handleFileChange}
							/>
						</button>
					</div>
				</div>
			</div>
			<div id='head'>
				<div>
					<h1>Produits</h1>
				</div>
				<div
					className='deluser'
					onClick={() => {
						if (checkedList.length > 0) {
							setShowDeleteModal(true);
						} else showToast('Choisissez au moins un produit.', 'warn');
					}}
				>
					<icons.FaTrashAlt className='trash' />
				</div>
			</div>
			{stockModalOpen && (
				<UpdateStock
					dataToUpdate={newArr}
					id={productId}
					stockModalOpen={stockModalOpen}
					setStockModalOpen={setStockModalOpen}
				/>
			)}
			<div id='productsContent'>
				<table id='products'>
					<thead>
						<tr id='titres'>
							<td className='input-check'>
								<input
									type='checkbox'
									className='checkbox'
									checked={isCheck}
									onChange={() => handleCheckAll(dispatch, newArr, setNewArr, isCheck, setIsCheck)}
								/>
							</td>
							<td>RÉFÉRENCE</td>
							<td>PRODUIT</td>
							<td>CATÉGORIE</td>
							<td className='center'>STOCK</td>
							<td className='center'>STATUT</td>
							<td>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{isloading ? (
							<tr>
								<td colSpan='7'>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							newArr?.map((p) => (
								<tr className='product' key={p._id}>
									<td className='input-check'>
										<input
											type='checkbox'
											className='checkbox'
											checked={p.checked}
											onChange={() => handleCheck(dispatch, newArr, setNewArr, p._id)}
										/>
									</td>
									<td>{p.reference || '-'}</td>
									<td>{p.designation || '-'}</td>
									<td>{p.category?.designation || '-'}</td>
									<td className={`center ${p.capacity === 0 ? 'product-stock' : undefined}`}>
										{p.capacity > 0 ? p.capacity : 'Indisponible'}
									</td>
									<td
										className={`center ${
											p.status === false ? 'product-status' : 'product-status activé'
										}`}
									>
										{p.status ? 'Activé' : 'Desactivé'}
									</td>

									<td className='last-user-element'>
										<Link
											to={`/admin/products/product_details/${page}/${p._id}`}
											className='link-user'
										>
											<icons.BsEye className='see-user' />
										</Link>
										<div className='contain'>
											<icons.BsThreeDotsVertical
												onClick={() => {
													setProductOpenDropdown(!productOpenDropdown);
													setIndiceProduit(p._id);
												}}
												className='user-option'
											/>
											{productOpenDropdown && indiceProduit === p._id && (
												<div className='modalSelectOpt' ref={ref}>
													<ul>
														<li onClick={() => modifyProduct(p._id)}>Modifier produit</li>
														<li
															onClick={() => {
																setStockModalOpen(!stockModalOpen);
																setProductId(p._id);
															}}
														>
															Modifier le stock
														</li>
														{p.status ? (
															<li
																onClick={(e) => {
																	handleBlock(e, p._id);
																}}
															>
																Desactivé produit
															</li>
														) : (
															<li
																onClick={(e) => {
																	handleUnblock(e, p._id);
																}}
															>
																Activé produit
															</li>
														)}
														<li
															onClick={() => {
																setShowDeleteModal(true);
																setDeleteIndex(p._id);
															}}
														>
															Supprimer produit
														</li>
													</ul>
												</div>
											)}
										</div>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink={`/admin/products/`} total={total} />
			<DataErrorModal
				isOpen={errorModalOpen}
				closeModal={() => setErrorModalOpen(false)}
				errorData={errorData}
				width='35rem'
				height='auto'
			/>
		</div>
	);
};

export default ProductPage;
