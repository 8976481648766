import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { update_Admin } from '../../../api/redux/slices/SuperAdmin';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '../../../components/modal';
import { ButtonComponent } from '../../../components/buttons';

const UpdateAdmin = ({ updateModal, setUpdateModal, indice, page, stableDispatch }) => {
	const [isFocus, setIsFocus] = useState(false);
	const dispatch = useDispatch();
	const { list } = useSelector((state) => state.superAdmin);
	const [infos, setInfos] = useState(...list.filter((el) => el._id === indice));
	const token = localStorage.getItem('accesstoken');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setInfos(...list.filter((el) => el._id === indice));
	}, [indice, list]);

	const [values, setValues] = useState({
		first_name: infos.first_name,
		last_name: infos.last_name,
		email: infos.email,
		phone: infos.phone,
		password: '',
		confirm_password: '',
	});

	const handleUpdate = (e) => {
		e.preventDefault();
		const data = {
			first_name: values.first_name.trim(),
			last_name: values.last_name.trim(),
			email: values.email.trim(),
			phone: values.phone.trim(),
			password: values.password,
			confirm_password: values.confirm_password,
		};
		const data_without_password = {
			first_name: values.first_name.trim(),
			last_name: values.last_name.trim(),
			email: values.email.trim(),
			phone: values.phone.trim(),
		};
		if (!data.first_name || !data.last_name || !data.email || !data.phone) {
			showToast('Veuillez renseigner tous les champs', 'warn');
			return;
		}
		setIsLoading(true);
		dispatch(
			update_Admin({
				id: indice,
				data: values.password.length !== 0 ? data : data_without_password,
				token: token,
			}),
		)
			.unwrap()
			.then(() => {
				showToast('Mis à jour', 'success');
				setUpdateModal(false);
				stableDispatch();
			})
			.catch((err) => {
				showToast(err, 'warn');
			})
			.finally(() => setIsLoading(false));
	};

	return (
		<div className={'modalcontainer ' + (updateModal && 'open')}>
			<div className={'modal ' + (updateModal && 'active')} id='updateAdmin'>
				<h1>Modifier un administrateur</h1>
				<form onSubmit={handleUpdate}>
					<div className='line-admin'>
						<div className='label-input'>
							<label>Nom</label>
							<input
								type='text'
								placeholder='Nom'
								value={values.first_name}
								onChange={(e) => {
									setValues({ ...values, first_name: e.target.value });
									setIsFocus(true);
								}}
							/>
						</div>
						<div className='label-input'>
							<label>Prénom</label>
							<input
								type='text'
								placeholder='Prénom'
								value={values.last_name}
								onChange={(e) => {
									setValues({ ...values, last_name: e.target.value });
									setIsFocus(true);
								}}
							/>
						</div>
					</div>
					<div className='line-admin'>
						<div className='label-input'>
							<label>Mot de passe</label>
							<input
								type='password'
								value={values.password}
								placeholder='nouveau mot de passe'
								onChange={(e) => {
									setValues({ ...values, password: e.target.value });
									setIsFocus(true);
								}}
							/>
						</div>
						<div className='label-input'>
							<label>Repetez le mot de passe</label>
							<input
								type='password'
								placeholder='repetez mot de passe'
								value={values.confirm_password}
								onChange={(e) => {
									setValues({ ...values, confirm_password: e.target.value });
									setIsFocus(true);
								}}
							/>
						</div>
					</div>
					<div className='label-input'>
						<label>N° de téléphone</label>
						<input
							type='text'
							placeholder='ex: 21 212 212'
							value={values.phone}
							onChange={(e) => {
								const onlyNumbers = e.target.value.replace(/[^0-9+\s]/g, '');
								setValues({ ...values, phone: onlyNumbers });
								setIsFocus(true);
							}}
						/>
					</div>
					<div className='label-input'>
						<label>Email</label>
						<input
							type='email'
							placeholder='nom@email.com'
							value={values.email}
							onChange={(e) => {
								setValues({ ...values, email: e.target.value });
								setIsFocus(true);
							}}
						/>
					</div>
					<div className='modalbtn'>
						<button
							className={isFocus ? 'button-nature' : 'button-nature-inactive'}
							onClick={(e) => {
								e.preventDefault();
								setUpdateModal(!updateModal);
							}}
						>
							Annuler
						</button>
						<ButtonComponent
							name={'Enregistrer'}
							loading={isLoading}
							type={'submit'}
							className={isFocus ? 'button-primary' : 'button-primary-inactive'}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default UpdateAdmin;
