import axios from '../http-common';

const createAxiosRequest = (token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return {
		get: (url, params = {}) => axios.get(url, { ...config, ...params }),
		post: (url, data) => axios.post(url, data, config),
		put: (url, data) => axios.put(url, data, config),
		delete: (url) => axios.delete(url, config),
	};
};

const axiosWithToken = createAxiosRequest;

export const CommercialServices = {
	getProfileDetails: (token) => axiosWithToken(token).get('/commercial/profile/'),
	getListForCommercial: (token, name, categories, regulation, page) =>
		axiosWithToken(token).get(`/commercial/clients/`, {
			params: { search: name, categories, regulation, page },
		}),
	addVehicle: (token, data) => axiosWithToken(token).post(`/commercial/vehicle/`, data),
	deleteVehicle: (token, id) => axiosWithToken(token).delete(`/commercial/vehicle/${id}`),
	create_user_commercial: (token, data) =>
		axiosWithToken(token).post('/commercial/clients/create', data),
	blockUserForCommercial: (token, id, data) =>
		axiosWithToken(token).post(`/commercial/list-users/block/${id}`, data),
	getListClaimsCommercial: (page) => axiosWithToken().get(`/users/claim/type/?page=${page}`),
	getUserDetails: (token, id) => axiosWithToken(token).get(`/commercial/client/${id}`),
	updateCommercialProfile: (token, data) => axiosWithToken(token).put(`/commercial/profile`, data),
	updateCommercialPassword: (token, data) =>
		axiosWithToken(token).put(`/commercial/profile/password`, data),
	getProductsListForCommercial: (token, page, search, category_name) =>
		axiosWithToken(token).get(`/commercial/orders/products`, {
			params: { page, search, categories: category_name },
		}),
	getCategoriesForProducts: (token) => axiosWithToken(token).get(`/commercial/products/categories`),
	create_user_client_by_commercial: (token, data) =>
		axiosWithToken(token).post('/commercial/clients/create', data),
	updateUserByCommercial: (token, id, data) =>
		axiosWithToken(token).put(`/commercial/clients/update/${id}`, data),
	getCategoriesForCommercial: (token, page, search) =>
		axiosWithToken(token).get(`/commercial/categories/`, {
			params: { page, search },
		}),
	getOrdersByCommercial: (token, page, search, status) =>
		axiosWithToken(token).get(`/commercial/orders/`, {
			params: { page, search, status },
		}),
	getOrderByIdCommercial: (token, id) =>
		axiosWithToken(token).get(`/commercial/orders/details/${id}`),
	checkStock: (token, list) =>
		axiosWithToken(token).post(`/commercial/orders/products/stock`, { list: list }),
	clientsOrderListByCommercial: (token, value) =>
		axiosWithToken(token).get(`/commercial/orders/customer/${value}`),
	addProductToBasket: (token, data) => axiosWithToken(token).post(`/commercial/basket`, data),
	udpateStockProduitBasket: (token, id, data) =>
		axiosWithToken(token).put(`/commercial/basket/stock/${id}`, data),
	getProductsBasketByCommercial: (token, id) =>
		axiosWithToken(token).get(`/commercial/basket/${id}`),
	deleteProductBasketByCommercial: (token, id) =>
		axiosWithToken(token).delete(`/commercial/basket/${id}`),
	addOrderByCommercial: (token, data) => axiosWithToken(token).post(`/commercial/orders`, data),
	clearProductBasketByCommercial: (token, id) =>
		axiosWithToken(token).delete(`/commercial/clear-basket/${id}`),
	getDeliveryByCommercial: (token, page, search, status) =>
		axiosWithToken(token).get(
			`/commercial/delivery?page=${page}&search=${search}&status=${status}`,
		),
	addPayment: (token, id, data) =>
		axiosWithToken(token).post(`/commercial/delivery/payement/${id}`, data),
	updatePayment: (token, id, data) =>
		axiosWithToken(token).put(`/commercial/delivery/payement/${id}`, data),
	getDeliveryByIdCommercial: (token, id) =>
		axiosWithToken(token).get(`/commercial/delivery/details/${id}`),
	getPayementDetails: (token, id) =>
		axiosWithToken(token).get(`/commercial/delivery/payement/${id}`),
	accepteDelivery: (token, id) =>
		axiosWithToken(token).put(`/commercial/delivery/commercial/${id}`, {
			status: 'confirm',
		}),
	refuseDelivery: (token, id) =>
		axiosWithToken(token).put(`/commercial/delivery/commercial/${id}`, {
			status: 'refuse',
		}),
	getListProductDelivery: (token, id) =>
		axiosWithToken(token).get(`/commercial/delivery/return/${id}`),
	addReturn: (token, id, data) =>
		axiosWithToken(token).post(`/commercial/delivery/return/${id}`, data),
	getClaimByCommercial: (token) => axiosWithToken(token).get(`/commercial/claim/`),
	getBillsByCommercial: (token, search, page) =>
		axiosWithToken(token).get(`/commercial/bills?page=${page}&search=${search}`),
	getBillById: (token, id) => axiosWithToken(token).get(`/commercial/bills/${id}`),
	getBillByIdCommercial: (token, id) => axiosWithToken(token).get(`/commercial/bills/${id}`),
	getReturnByCommercial: (token, page, search) =>
		axiosWithToken(token).get(`/commercial/return?page=${page}&search=${search}`),
	getReturnByIdCommercial: (token, id) => axiosWithToken(token).get(`/commercial/return/${id}`),
};

export default CommercialServices;
