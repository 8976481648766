import React from 'react';
import { icons } from '../../../assets/icons';
import './index.scss';

const OrderSuccess = ({ setOrderSuccessModal, orderSuccessModal }) => {
	return (
		<div className={'modalcontainer ' + (orderSuccessModal && 'open')}>
			<div className={'modal ' + (orderSuccessModal && 'active')}>
				<div className='close-modal' onClick={() => setOrderSuccessModal(!orderSuccessModal)}>
					<icons.MdClose />
				</div>
				<div className='client-modal-order'>
					<h1>Le devis à été envoyé avec succès !</h1>
				</div>
			</div>
		</div>
	);
};

export default OrderSuccess;
