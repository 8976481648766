import React from 'react';
import { RotatingSquare } from 'react-loader-spinner';

export const LoaderComponent = ({ margin = '20%' }) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				margin,
			}}
		>
			<RotatingSquare
				ariaLabel='rotating-square'
				visible={true}
				color='#316161'
				id='login-spinner'
			/>
		</div>
	);
};
