import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import produit_image from '../../../assets/produit_image.jpg';
import { BoutiquesData } from '../../../data';
import OrderSuccess from './OrderSuccess';
import OrderFail from './OrderFail';

const ProductCartCom = () => {
	const [orderSuccessModal, setOrderSuccessModal] = useState(false);
	const [orderFailModal, setOrderFailModal] = useState(false);

	const handleOrder = () => {
		// setOrderSuccessModal(!orderSuccessModal);
		setOrderFailModal(!orderFailModal);
	};

	return (
		<div id='pr-cart-client'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input type='search' placeholder='Rechercher ...' />
						<icons.FiSearch className='searchicon' />
					</div>
					<div className='filters'>
						<select name='catégories' id=''>
							<option value=''>Catégories</option>
							<option value=''>grossiste</option>
							<option value=''>commercial</option>
						</select>
					</div>
				</div>
				<div className='right'>
					<div className='cart'>
						<icons.RiShoppingCartLine className='cart-icon' />
						<div className='cart-count'>
							<span>2</span>
						</div>
					</div>
					<h3>Panier</h3>
				</div>
			</div>
			<Fragment>
				<OrderSuccess
					setOrderSuccessModal={setOrderSuccessModal}
					orderSuccessModal={orderSuccessModal}
				/>
			</Fragment>
			<Fragment>
				<OrderFail setOrderFailModal={setOrderFailModal} orderFailModal={orderFailModal} />
			</Fragment>
			<div className='content'>
				<h1>Panier</h1>
				<div className='cart-section'>
					<div className='purchases'>
						<div className='purchase'>
							<div className='left'>
								<div className='element'>
									<div className='element-img'>
										<img src={produit_image} alt='produit_image' />
									</div>
									<div className='element-desc'>
										<h4>Potentiomètre de précision 359</h4>
										<span className='price'>398.000 TND</span>
									</div>
								</div>
							</div>
							<div className='right'>
								<div className='qty'>
									<input type='number' defaultValue={2} onWheel={(e) => e.currentTarget.blur()} />
								</div>
								<div id='qty-price'>
									<span>796.000 TND</span>
								</div>
								<div className='del'>
									<icons.FaTrashAlt className='trash' />
								</div>
							</div>
						</div>
						<div className='purchase'>
							<div className='left'>
								<div className='element'>
									<div className='element-img'>
										<img src={produit_image} alt='produit_image' />
									</div>
									<div className='element-desc'>
										<h4>Potentiomètre de précision 359</h4>
										<span className='price'>398.000 TND</span>
									</div>
								</div>
							</div>
							<div className='right'>
								<div className='qty'>
									<input type='number' defaultValue={2} onWheel={(e) => e.currentTarget.blur()} />
								</div>
								<div id='qty-price'>
									<span>796.000 TND</span>
								</div>
								<div className='del'>
									<icons.FaTrashAlt className='trash' />
								</div>
							</div>
						</div>
					</div>
					<div className='count'>
						<div className='count-header'>
							<label>Choisissez une boutique</label>
							<div id='shop-select'>
								<select>
									{BoutiquesData.map((boutique, index) => (
										<Fragment key={index}>
											<option key={index}>{boutique.boutique}</option>
										</Fragment>
									))}
								</select>
							</div>
						</div>
						<div className='top'>
							<ul>
								<li id='number'>
									<span>6</span> ARTICLES
								</li>
								<li>
									<span>896.000TND</span>
								</li>
							</ul>
						</div>
						<div className='bottom'>
							<button className='button-primary' onClick={handleOrder}>
								Commander
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className='return-link'>
				<Link to='/client/products/'>
					<icons.IoIosArrowBack className='arrow' />
					<h4>Continuez vers mes achats</h4>
				</Link>
			</div>
		</div>
	);
};

export default ProductCartCom;
