import React, { useState } from 'react';
import './index.scss';
import Container from '../container';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../api/redux/slices/Auth';
import { RotatingSquare } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { icons } from '../../../assets/icons';
import { showToast } from '../../../components/modal';

const LoginSchema = Yup.object().shape({
	email: Yup.string().required('Email is required').email('Invalid email address'),
	password: Yup.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters'),
});

const Login = () => {
	const [load, setLoad] = useState(false);
	const { error } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [passwordVisible, setPasswordVisible] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm();

	const onSubmit = (data) => {
		LoginSchema.validate(data, { abortEarly: false })
			.then(() => {
				setLoad(true);
				dispatch(login(data))
					.unwrap()
					.then((res) => {
						if (res.data.user.is_active === false) {
							showToast('Utilisateur bloqué', 'info');
						} else {
							localStorage.setItem('accesstoken', res.data.token);
							toast.success('Successfully Authenticated', {
								position: 'top-center',
								autoClose: 2000,
							});
							navigate('/');
						}
					})
					.catch((err) => {
						toast.error(err.message, {
							position: 'top-center',
							autoClose: 2000,
						});
					})
					.finally(() => setLoad(false));
			})
			.catch((validationErrors) => {
				validationErrors.inner.forEach((error) => {
					setError(error.path, { message: error.message });
				});
			});
	};

	const togglePassword = () => {
		var x = document.getElementById('passwordInput');
		if (x.type === 'password') {
			x.type = 'text';
			setPasswordVisible(true);
		} else {
			x.type = 'password';
			setPasswordVisible(false);
		}
	};

	return (
		<Container>
			<form onSubmit={handleSubmit(onSubmit)} className='form'>
				<label>Connexion</label>
				<input type='text' placeholder='Email' {...register('email')} />
				{errors.email && <span className='error'>{errors.email.message}</span>}
				<label>Mot de passe</label>
				<div className='label-input' style={{ marginTop: '0' }}>
					<input
						id='passwordInput'
						type='password'
						placeholder='Password'
						{...register('password')}
						onClick={(e) => e.target.select()}
					/>
					{passwordVisible ? (
						<icons.BsEyeSlash onClick={togglePassword} className='see-password' />
					) : (
						<icons.BsEye onClick={togglePassword} className='see-password' />
					)}
				</div>

				{errors.password && <span className='error'>{errors.password.message}</span>}
				{/* <Link to='/send-email' className='resetPassword'>
					Mot de passe oublié ?
				</Link> */}
				<button type='submit' className='btn'>
					{load && !error ? (
						<RotatingSquare
							ariaLabel='rotating-square'
							visible={true}
							color='white'
							id='login-spinner'
						/>
					) : (
						'Connexion'
					)}
				</button>
			</form>
		</Container>
	);
};

export default Login;
