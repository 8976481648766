import { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import SeeProduct from './SeeProduct';
import { useDispatch } from 'react-redux';
import { getCategoriesForProducts, getOrdersProducts } from '../../../api/redux/slices/Admin';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProductCard } from '../../../components/cards/Product';
import { LoaderComponent } from '../../../helpers/components/loader';
import { typePrice } from '../../../helpers/modules';

const OrderMarket = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const { listCategoriesProducts } = useSelector((state) => state.admin);
	const token = localStorage.getItem('accesstoken');

	const [page, setPage] = useState(Number(params.page));
	const [modalOpen, setModalOpen] = useState(false);
	const [indiceProduit, setIndiceProduit] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	const [categoryValue, setCategoryValue] = useState('');
	const [totalQuantity, setTotalQuantity] = useState(0);
	const [productsArr, setProductsArr] = useState([]);
	const [isloading, setIsloading] = useState(false);

	const [totalPage, setTotalPage] = useState(0);
	const company = JSON.parse(localStorage.getItem('company'));
	const clientType = company?.categories;

	//--------------------------- GET PRODUCTS LIST ------------------------------------//
	const stableDispatchProducts = useCallback(() => {
		dispatch(getCategoriesForProducts({ token: token }));
		setIsloading(true);
		dispatch(
			getOrdersProducts({
				token: token,
				search: searchValue,
				category_name: categoryValue,
				page: searchValue || categoryValue ? undefined : page,
			}),
		)
			.unwrap()
			.then((res) => {
				setProductsArr(res.data);
				setTotalPage(res.total);
			})
			.finally(() => setIsloading(false));
	}, [dispatch, page, token, categoryValue, searchValue]);

	useEffect(() => {
		if (token) {
			stableDispatchProducts();
		}
	}, [stableDispatchProducts, token, navigate]);

	useEffect(() => {
		let cart = localStorage.getItem('cart');
		if (!cart) {
		} else {
			cart = JSON.parse(cart);
			const totalQuantity = cart
				.map((item) => item.quantity)
				.reduce((total, qty) => parseInt(total) + parseInt(qty), 0);
			setTotalQuantity(parseInt(totalQuantity));
		}
	}, []);
	//----------------------------- add to cart --------------------------------//
	const addCart = (product, qty) => {
		const data = {
			product_id: product,
			quantity: qty,
		};

		let cart = localStorage.getItem('cart');
		if (!cart) {
			cart = [];
		} else {
			cart = JSON.parse(cart);
		}
		const existingProduct = cart.find((item) => item.product_id._id === product._id);
		if (existingProduct) {
			existingProduct.quantity += qty;
			setTotalQuantity(parseInt(totalQuantity) + parseInt(qty));
		} else {
			cart.push(data);
			setTotalQuantity(parseInt(totalQuantity) + parseInt(qty));
		}
		localStorage.setItem('cart', JSON.stringify(cart));
	};

	//-------------------- navigate to cart page ------------------//
	const navigateToCart = () => {
		navigate(`/admin/orders/order_cart/${params.page}/${params.companyId}`);
	};

	return (
		<div id='ordermarket'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='search'
							placeholder='Rechercher ...'
							value={searchValue}
							onChange={(e) => setSearchValue(e.target.value)}
						/>
						<icons.FiSearch className='searchicon' />
					</div>
					<div className='filters'>
						<select
							name='catégories'
							id=''
							value={categoryValue}
							onChange={(e) => setCategoryValue(e.target.value)}
						>
							<option value=''>Catégories</option>
							{listCategoriesProducts.map((cat) => (
								<Fragment key={cat._id}>
									<option value={cat._id}>{cat.designation}</option>
								</Fragment>
							))}
						</select>
					</div>
				</div>
				<div className='right' onClick={navigateToCart} style={{ cursor: 'pointer' }}>
					<div className='cart'>
						<icons.RiShoppingCartLine className='cart-icon' />
						<div className='cart-count'>
							<span>{totalQuantity}</span>
						</div>
					</div>
					<h3>Panier</h3>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Produits</h1>
				</div>
			</div>
			{isloading ? (
				<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
					<LoaderComponent margin='10%' />
				</div>
			) : (
				<div id='cartOrderContent'>
					{productsArr?.map((product, index) => (
						<Fragment key={index}>
							<ProductCard
								img_url={product.image.secure_url || process.env.REACT_APP_IMAGE_PLACEHOLDER_URL}
								onClickShop={() => addCart(product, 1)}
								onClickSee={() => {
									setIndiceProduit(product._id);
									setModalOpen(!modalOpen);
								}}
								product_name={product.designation ?? '-'}
								product_price={product?.[typePrice(clientType)] ?? '-'}
								isDisabled={product.capacity < 1}
							/>
							{indiceProduit === product._id && (
								<SeeProduct
									modalOpen={modalOpen}
									setModalOpen={setModalOpen}
									product_image={
										product.image.secure_url || process.env.REACT_APP_IMAGE_PLACEHOLDER_URL
									}
									product={product}
									clientType={clientType}
									onClickShop={addCart}
								/>
							)}
						</Fragment>
					))}
				</div>
			)}
			<div className='pagination'>
				{Math.ceil(totalPage / 10) > 1 && (
					<div className='count'>
						<span>{page > 0 && page}</span> - <span>10</span> sur <span>{totalPage}</span>
					</div>
				)}
				<div className='arrows'>
					{page > 1 && (
						<Link
							to={
								page === 1
									? `/admin/orders/order_market/${page}/${params.companyId}`
									: `/admin/orders/order_market/${page - 1}/${params.companyId}`
							}
						>
							<icons.IoIosArrowBack
								className='arrow'
								onClick={() => {
									page === 1 ? setPage(1) : setPage(page - 1);
								}}
							/>
						</Link>
					)}
					{page < Math.ceil(totalPage / 10) && (
						<Link to={`/admin/orders/order_market/${page + 1}/${params.companyId}`}>
							<icons.IoIosArrowForward
								className='arrow'
								onClick={() => {
									page === 0 ? setPage(1) : setPage(page + 1);
								}}
							/>
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

export default OrderMarket;
