import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'; // Add this import
import { icons } from '../../../assets/icons';
import './index.scss';
import { showToast } from '../../../components/modal';
import { addReturn, getListProductDelivery } from '../../../api/redux/slices/Commercial';
import { ButtonComponent } from '../../../components/buttons';
import { convertDate } from '../../../helpers/modules';
import { LoaderComponent } from '../../../helpers/components/loader';

const AddReturn = () => {
	const params = useParams();
	const dispatch = useDispatch();

	const token = localStorage.getItem('accesstoken');
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(false);

	const [listProductDelviery, setListProductDelivery] = useState([]);
	const [infos, setInfos] = useState({});
	const [isCheck, setIsCheck] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		setLoading(true);
		dispatch(getListProductDelivery({ token, id: params.id }))
			.unwrap()
			.then((res) => {
				setInfos(res.data?.delivery);
				const updateList = res?.data?.list?.map((item) => ({
					...item,
					quantityRetour: 0,
				}));
				setListProductDelivery(updateList);
			})
			.finally(() => setLoading(false));
	}, [dispatch, token, params.id]);

	const handleQuantityChange = (index, value) => {
		const updatedList = [...listProductDelviery];
		updatedList[index].quantityRetour = value;
		setListProductDelivery([...updatedList]);
	};
	const handleConfirm = () => {
		const data = listProductDelviery.reduce((acc, item) => {
			if (item.quantityRetour !== 0 && item.quantityRetour <= item.quantity) {
				acc.push({
					order_item_id: item._id,
					quantity: item.quantityRetour,
				});
			}
			return acc;
		}, []);

		if (data.length === 0) {
			showToast('Quantité de retour invalide', 'warn');
			return;
		}
		setIsLoading(true);
		dispatch(
			addReturn({
				token: token,
				id: params.id,
				data,
			}),
		)
			.unwrap()
			.then(() => {
				showToast('Retour ajoute avec succes', 'success');
				navigate(`/commercial/deliveries/${params.page}`);
			})
			.catch((err) => showToast(err?.message || err, 'error'))
			.finally(() => setIsLoading(false));
	};
	const handleCheckAll = () => {
		setIsCheck(!isCheck);
		if (isCheck) {
			setListProductDelivery((prevList) =>
				prevList.map((item) => ({
					...item,
					checked: false,
				})),
			);
		} else {
			setListProductDelivery((prevList) =>
				prevList.map((item) => ({
					...item,
					checked: true,
				})),
			);
		}
	};

	const handleCheck = (index) => {
		const updatedList = [...listProductDelviery];
		updatedList[index].checked = !updatedList[index].checked;
		setListProductDelivery([...updatedList]);
	};

	if (loading) {
		return <LoaderComponent />;
	}
	return (
		<div id='complainClientDetails'>
			<div id='details-complain-header'>
				<Link to={`/commercial/deliveries/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Bons de livraisons</h1>
				</Link>
				<div className='buttons'>
					<Link to={`/commercial/deliveries/${params.id}`}>
						<button className='button-nature'>Annuler</button>
					</Link>
					<ButtonComponent
						name={'Enregistrer'}
						loading={isLoading}
						type={'button'}
						className='button-primary'
						onClick={handleConfirm}
					/>
				</div>
			</div>
			<div id='headComplain'>
				<div className='infos'>
					<div>
						<h2>Référence: </h2>
						<span>{infos?.reference}</span>
					</div>
					<div>
						<h2>Date: </h2>
						<span>{convertDate(infos?.date, 'DD-MM-YYYY')}</span>
					</div>
					<div>
						<h2>Client: </h2>
						<span>{infos?.client}</span>
					</div>
					<div>
						<h2>Societe: </h2>
						<span>{infos?.company_name}</span>
					</div>
					<div>
						<h2>Total: </h2>
						<span>{infos?.total} DT</span>
					</div>
				</div>
			</div>
			<div className='devis-table'>
				<table className='offres'>
					<thead>
						<tr id='titres'>
							<td className='input-check'>
								<input
									type='checkbox'
									className='checkbox'
									checked={isCheck}
									onChange={handleCheckAll}
								/>
							</td>
							<td>RÉFÉRENCE</td>
							<td>CODE E.A.N</td>
							<td>DÉSIGNATION</td>
							<td>QTÉ</td>
							<td>QTÉ Retournée</td>
						</tr>
					</thead>
					<tbody>
						{listProductDelviery?.map((item, index) => (
							<tr key={index} className={`elements ${!item.checked ? 'active' : ''}`}>
								<td className='input-check'>
									<input
										type='checkbox'
										className='checkbox'
										checked={item.checked}
										onChange={() => handleCheck(index)}
									/>
								</td>
								<td>{item.reference}</td>
								<td>{item.refranceCTL}</td>
								<td>{item.designation}</td>
								<td>{item.quantity}</td>
								<td className='qyt'>
									<input
										type='number'
										value={item.quantityRetour}
										disabled={!item.checked}
										onChange={(e) => handleQuantityChange(index, parseInt(e.target.value, 10))}
										onWheel={(e) => e.target.blur()}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default AddReturn;
