import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCategory } from '../../../api/redux/slices/Admin';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '../../../components/modal';

const UpdateCategory = ({ lineIndice, modalOpen, setModalOpen }) => {
	const { listCategories } = useSelector((state) => state.admin);
	const [infos, setInfos] = useState(...listCategories.filter((el) => el._id === lineIndice));
	const dispatch = useDispatch();
	const [isFocus, setIsFocus] = useState(false);
	const token = localStorage.getItem('accesstoken');

	useEffect(() => {
		setInfos(...listCategories.filter((el) => el._id === lineIndice));
	}, [lineIndice, listCategories, modalOpen]);

	const [values, setValues] = useState({
		description: infos.description,
		designation: infos.designation,
	});

	const handleUpdate = (e) => {
		e.preventDefault();
		const data = {
			designation: values.designation.trim(),
			description: values.description.trim(),
			is_active: true,
		};
		if (!data.description || !data.designation) {
			showToast('Veuillez remplir tous les champs', 'warn');
		}
		dispatch(updateCategory({ token: token, id: lineIndice, data: data }))
			.unwrap()
			.then(() => {
				showToast('Categorie modifié avec succes', 'success');
				setModalOpen(false);
			})
			.catch((err) => showToast(err.message || err.error, 'error'));
	};

	return (
		<div className={'modalcontainer ' + (modalOpen && 'open')}>
			<div className={'modal ' + (modalOpen && 'active')}>
				<h1>Modifier cette catégorie</h1>
				<form onSubmit={handleUpdate}>
					<div className='label-input'>
						<label>Nom de catégorie</label>
						<input
							type='text'
							placeholder='Nom'
							value={values.designation}
							onChange={(e) => {
								setValues({ ...values, designation: e.target.value });
								setIsFocus(true);
							}}
							required
						/>
					</div>
					<div className='desc-modal'>
						<label>Description</label>
						<textarea
							name='description'
							cols='3'
							rows='5'
							placeholder='Description'
							value={values.description}
							onChange={(e) => {
								setValues({ ...values, description: e.target.value });
								setIsFocus(true);
							}}
						/>
					</div>
					<div className='submit-btns'>
						<div className='add-btns'>
							<button
								className={isFocus ? 'button-nature' : 'button-nature-inactive'}
								onClick={(e) => {
									e.preventDefault();
									setModalOpen(false);
								}}
							>
								Annuler
							</button>
							<button
								className={isFocus ? 'button-primary' : 'button-primary-inactive'}
								type='submit'
							>
								Modifier
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default UpdateCategory;
