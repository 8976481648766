import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { LoaderComponent } from '../../../helpers/components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { getBillsByAdmin } from '../../../api/redux/slices/Admin';
import Pagination from '../../../helpers/components/Pagination';
import useStableArr from '../../../utils/useStableArr';
import { handleCheck, handleCheckAll } from '../../../utils/checkUtilsAdmin';
import { convertDate } from '../../../helpers/modules';
import { CSVLink } from 'react-csv';

const BillPage = () => {
	const { bills, checkedList, total } = useSelector((state) => state.admin);
	const dispatch = useDispatch();
	const params = useParams();

	const [page, setPage] = useState(Number(params.page));
	const [newArr, setNewArr] = useState(bills?.map((el) => ({ ...el, checked: false })));
	const [isCheck, setIsCheck] = useState(false);
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(false);
	const token = localStorage.getItem('accesstoken');
	//----------------------- GET ALL CLAIMS ---------------------------//
	const stableDispatch = useCallback(() => {
		setLoading(true);
		dispatch(
			getBillsByAdmin({
				token: token,
				search: search,
				page: search ? undefined : page,
			}),
		)
			.unwrap()
			.finally(() => setLoading(false));
	}, [dispatch, page, search, token]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [stableDispatch, page, search, token]);

	// --------------- RETRIVE LATEST CLAIMS DATA ---------------//
	useStableArr(bills, checkedList, setNewArr, setIsCheck);

	const headers = [
		{ label: 'NUMÉRO', key: 'reference' },
		{ label: 'DATE', key: 'createdAt' },
		{ label: 'CLIENT', key: 'delivery.order_id.clientInfo.client' },
		{ label: 'SOCIÉTÉ', key: 'delivery.order_id.clientInfo.company_name' },
		{ label: 'TOTAL', key: 'delivery.order_id.total' },
	];

	const csvDeliveryReport = {
		filename: 'Bills.csv',
		data: checkedList,
		headers: headers,
	};

	return (
		<div id='billPageAdmin'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							type='search'
							placeholder='Rechercher ...'
						/>
						<icons.FiSearch className='searchicon' />
					</div>
				</div>
				<div className='right'>
					<div className='right-items'>
						<CSVLink {...csvDeliveryReport} target='blank'>
							<button className='button-nature' title='exporter livraison(s)'>
								<icons.BiExport />
							</button>
						</CSVLink>
						{/* <button className='button-nature' title='importer facture(s)'>
							<icons.BiImport />
						</button> */}
					</div>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Bons de factures</h1>
				</div>
			</div>
			<div id='billsContent'>
				<table id='bills'>
					<thead>
						<tr id='titres'>
							<td className='input-check'>
								<input
									onChange={() => handleCheckAll(dispatch, newArr, setNewArr, isCheck, setIsCheck)}
									checked={isCheck}
									type='checkbox'
									className='checkbox'
								/>
							</td>
							<td>NUMÉRO</td>
							<td>DATE</td>
							<td>CLIENT</td>
							<td>SOCIÉTÉ</td>
							<td>TOTAL</td>
							<td>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{loading ? (
							<tr>
								<td colSpan={7}>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							newArr.map((bill, index) => (
								<tr className='delivery' key={index}>
									<td className='input-check'>
										<input
											onChange={() => handleCheck(dispatch, newArr, setNewArr, bill?._id)}
											checked={bill?.checked ?? false}
											type='checkbox'
											className='checkbox'
										/>
									</td>
									<td>{bill.reference}</td>
									<td>{convertDate(bill?.createdAt, 'DD-MM-YYYY')}</td>
									<td>{bill?.delivery?.order_id?.clientInfo?.client}</td>
									<td>{bill?.delivery?.order_id?.clientInfo?.company_name}</td>
									<td>{bill?.delivery?.order_id?.total} DT</td>
									<td className='last-delivery-element'>
										<Link
											to={`/admin/bills/bill_details/${page}/${bill._id}`}
											className='link-delivery'
										>
											<icons.BsEye className='see-delivery' />
										</Link>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink={`/admin/bills/`} total={total} />
		</div>
	);
};

export default BillPage;
