import { convertDate, roundToThreeDecimals } from '../../../helpers/modules';
import { renderTvaValues } from '../../../utils/groupTvaValues';

const PrintToPdf = ({ componentRef, orderDetails }) => {
	const itemsPerPage = [9, 15];

	const renderTableRows = (start, end) => {
		return orderDetails?.list?.slice(start, end).map((el, index) => (
			<tr className='elements' key={index}>
				<td>{el?.designation ?? '-'}</td>
				<td>{el?.quantity ?? '-'}</td>
				<td>{el?.prise_product_HT ?? '-'}</td>
				<td>{el?.tva ?? '-'}</td>
				<td>{roundToThreeDecimals(el?.prise_product_HT * el?.quantity) || '-'}</td>
			</tr>
		));
	};
	const renderTable = (start, end) => {
		return (
			<div key={start} className='page-content'>
				<div className='devis-table'>
					<table className='offres'>
						<thead>
							<tr id='titres'>
								<td>DÉSIGNATION</td>
								<td>QTÉ</td>
								<td>PU HT</td>
								<td>TVA (%)</td>
								<td>MONTANT HT</td>
							</tr>
						</thead>
						<tbody>{renderTableRows(start, end)}</tbody>
					</table>
				</div>
			</div>
		);
	};
	return (
		<div className='order-pdf' ref={componentRef}>
			<div className='devis'>
				<h4>DEVIS</h4>
				<div className='info'>
					<div>
						<label>Numéro:</label>
						<label>Date:</label>
						<label>Référence code client:</label>
					</div>
					<div>
						<span>{orderDetails?.order?.reference ?? '-'}</span>
						<span>{convertDate(orderDetails?.order?.date, 'DD-MM-YYYY') ?? ''}</span>
						<span>{orderDetails?.order?.clientInfo?.customs_code ?? '-'}</span>
					</div>
				</div>
			</div>
			<div className='general-info'>
				<div className='left'>
					<h2 className='infos-societe'>{orderDetails?.order?.superAdminInfo?.name}</h2>
					<div className='address'>
						<span>VTE EN GROS ART DE MENAGE</span>
						<span>{orderDetails?.order?.superAdminInfo?.address}</span>
					</div>
				</div>
				<div className='right'>
					<h3>{orderDetails?.order?.clientInfo?.company_name ?? ''}</h3>

					<p>{orderDetails?.order?.clientInfo?.client ?? ''}</p>
					<p>Matricule Fiscale: {orderDetails?.order?.clientInfo?.tax_registration_number ?? ''}</p>
					<p>Telephone: {orderDetails?.order?.clientInfo?.phone ?? ''}</p>
					<span>{orderDetails?.order?.shopInfo.address_number} </span>
					<span>{orderDetails?.order?.shopInfo.street} </span>
					<span>{orderDetails?.order?.shopInfo.ville} </span>
					<span>{orderDetails?.order?.shopInfo.postal_code} </span>
					<span>{orderDetails?.order?.shopInfo.region} </span>
				</div>
			</div>
			<div className='cher-client'>
				<p>
					Cher Client,
					<br />
					Nous avons bien reçu votre demande de devis et nous vous en remercions.
					<br />
					Nous vous prions de trouver ci-dessous nos conditions les meilleures
				</p>
			</div>
			{renderTable(0, itemsPerPage[0])}
			{Array.from(
				{
					length: Math.ceil((orderDetails?.list?.length - itemsPerPage[0]) / itemsPerPage[1]),
				},
				(_, index) => {
					return renderTable(
						itemsPerPage[0] + index * itemsPerPage[1],
						itemsPerPage[0] + (index + 1) * itemsPerPage[1],
					);
				},
			)}
			<div className='total-devis'>
				<div id='box'>
					<div>
						<h1>TOTAL HT</h1>
						<span id='somme-totale'>{orderDetails?.order?.total_HT ?? 0}</span>
					</div>
					{renderTvaValues(orderDetails)}
					<div>
						<h1>TIMBRE</h1>
						<span id='somme-totale'>{orderDetails?.order?.timbre ?? 0}</span>
					</div>
					<div>
						<h1>TOTAL TTC</h1>
						<span id='somme-totale'>{orderDetails?.order?.total ?? 0}</span>
					</div>
				</div>
			</div>
			<div className='message'>
				<p>
					Nous sommes à votre disposition pour tout complément d'informations. <br />
					Nous vous prions d'agréer, Cher Client, nos sincères salutations. <br />
					Le service commercial.
				</p>
			</div>
			<div className='footer'>
				<div className='left'>
					<ul>
						<li>{orderDetails?.order?.superAdminInfo?.address}</li>
						<li>
							CODE TVA <span>{orderDetails?.order?.superAdminInfo?.tva_code}</span>
						</li>
						<li>
							RC: <span>{orderDetails?.order?.superAdminInfo?.r_c}</span>
						</li>
						<li>
							Code en Douane <span>{orderDetails?.order?.superAdminInfo?.customs_code || '-'}</span>
						</li>
					</ul>
				</div>
				<div className='left'>
					<ul>
						<li>
							Tél <span>{orderDetails?.order?.superAdminInfo?.phone}</span>
						</li>
						<li>
							Fax <span>{orderDetails?.order?.superAdminInfo?.fax}</span>
						</li>
						<li>
							Email: <span>{orderDetails?.order?.superAdminInfo?.email}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default PrintToPdf;
