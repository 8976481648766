import React, { Fragment } from 'react';
import { ClipLoader } from 'react-spinners';

export const ButtonComponent = ({ name, loading, type, className, onClick, disabled }) => {
	return (
		<Fragment>
			<button type={type} className={className} onClick={onClick} disabled={disabled || loading}>
				{loading ? <ClipLoader color='#FFF' loading={loading} size={20} /> : name}
			</button>
		</Fragment>
	);
};
