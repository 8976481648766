import { BiCategory, BiExport, BiMailSend, BiImport } from 'react-icons/bi';
import {
	RiHome5Line,
	RiUserSearchLine,
	RiRepeatLine,
	RiShoppingCartLine,
	RiArrowDropUpLine,
	RiArrowDropDownLine,
} from 'react-icons/ri';
import { CgProfile } from 'react-icons/cg';
import {
	BsListTask,
	BsPlusSquareDotted,
	BsTrash,
	BsShield,
	BsBoxSeam,
	BsEye,
	BsEyeSlash,
	BsCheckCircleFill,
	BsCheckCircle,
	BsThreeDotsVertical,
	BsArrowLeft,
} from 'react-icons/bs';
import { MdMyLocation, MdClose } from 'react-icons/md';
import { VscCalendar } from 'react-icons/vsc';
import { FiSearch } from 'react-icons/fi';
import { SiAddthis } from 'react-icons/si';
import { FaTrash, FaRegEdit, FaToggleOn, FaToggleOff, FaTrashAlt } from 'react-icons/fa';
import { IoIosArrowBack, IoIosArrowForward, IoMdLogOut } from 'react-icons/io';
import { TbClipboardText, TbTruckDelivery } from 'react-icons/tb';
import { VscChecklist } from 'react-icons/vsc';
import { GrFormClose } from 'react-icons/gr';

export const icons = {
	BiCategory,
	BiMailSend,
	BsTrash,
	BsPlusSquareDotted,
	BiExport,
	BiImport,
	RiHome5Line,
	BsArrowLeft,
	RiShoppingCartLine,
	RiArrowDropDownLine,
	RiUserSearchLine,
	TbTruckDelivery,
	RiRepeatLine,
	RiArrowDropUpLine,
	CgProfile,
	TbClipboardText,
	BsBoxSeam,
	VscChecklist,
	BsListTask,
	BsShield,
	MdMyLocation,
	MdClose,
	VscCalendar,
	GrFormClose,
	FiSearch,
	FaTrashAlt,
	BsThreeDotsVertical,
	BsEyeSlash,
	SiAddthis,
	FaTrash,
	FaRegEdit,
	BsEye,
	IoIosArrowBack,
	IoIosArrowForward,
	IoMdLogOut,
	FaToggleOn,
	FaToggleOff,
	BsCheckCircleFill,
	BsCheckCircle,
};
