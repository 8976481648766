import axios from 'axios';
const token = localStorage.getItem('accesstoken');

export default axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${token}`,
	},
});
