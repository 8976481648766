import React, { Fragment, useEffect, useState } from 'react';
import './App.scss';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from './api/redux/slices/Auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NavBar from './pages/navigation/index';
import AdminRoutes from './routes/AdminRoutes';
import SuperAdminRoutes from './routes/SuperAdminRoutes';
import CommercialRoutes from './routes/CommercialRoutes';
import CustomerRoutes from './routes/CustomerRoutes';
import SendEmail from './pages/auth/send-email';
import ResetPassword from './pages/auth/reset-password';
import Login from './pages/auth/login';
import CodeConfirm from './pages/auth/code-confrim';
import { regionsList } from './api/redux/slices/Public';
import { LoaderComponent } from './helpers/components/loader';

function App() {
	const dispatch = useDispatch();
	const { role, actived, authenticated, error } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const token = localStorage.getItem('accesstoken');
	const [userRole, setUserRole] = useState('');

	useEffect(() => {
		if (token) {
			dispatch(regionsList({ token: token }));
			dispatch(getUser({ token: token }))
				.unwrap()
				.then((res) => {
					setUserRole(res.data.role);
				})
				.catch((err) => {
					toast.warn(err, {
						position: 'bottom-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
				});
		} else navigate('/login');
	}, [dispatch, token, navigate, role, actived]);

	if (token && !authenticated && error === null) return <LoaderComponent />;

	//prevent the keyboard enter action
	document.body.addEventListener('keydown', (e) => {
		if (e.key === 'Enter' && e.target.tagName === 'INPUT') {
			e.preventDefault();
		}
	});

	return (
		<div className='App'>
			<div style={{ position: 'absolute' }}>
				<ToastContainer
					position='top-center'
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</div>
			<div id='main'>
				{token && <NavBar />}
				<div className={authenticated ? 'rightPanel' : null}>
					{authenticated && (
						<Fragment>
							{userRole === 'admin' && <AdminRoutes />}
							{userRole === 'super-admin' && <SuperAdminRoutes />}
							{userRole === 'commercial' && <CommercialRoutes />}
							{userRole === 'customer' && <CustomerRoutes />}
						</Fragment>
					)}
					{!authenticated && (
						<Routes>
							<Route path='/' element={<Login />} />
							<Route path='/login' element={<Login />} />
							<Route path='/send-email' element={<SendEmail />} />
							<Route path='/code-confirm' element={<CodeConfirm />} />
							<Route path='/reset-password' element={<ResetPassword />} />
						</Routes>
					)}
				</div>
			</div>
		</div>
	);
}

export default App;
