import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { DeliveriesData } from '../../../data';
const UpdateDelivery = () => {
	const params = useParams();
	const [detailDelivery, setDetailDelivery] = useState([]);
	const [deliveryId, setDeliveryId] = useState();

	useEffect(() => {
		if (params.id) {
			DeliveriesData.forEach((el) => {
				if (el.id === params.id) {
					setDetailDelivery([el]);
					setDeliveryId(params.id);
				}
			});
		}
	}, [params.id]);

	return (
		<div id='updateDelivery'>
			<div className='update-delivery-header'>
				<Link to={`/admin/deliveries/delivery_details/${deliveryId}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Livraison</h1>
				</Link>
				<div style={{ display: 'flex', gap: '1rem' }}>
					<Link to={`/admin/deliveries/delivery_details/${deliveryId}`}>
						<button className='button-nature'>Annuler</button>
					</Link>
					<Link to={`/admin/deliveries/delivery_details/${deliveryId}`}>
						<button className='button-primary'>Enregistrer</button>
					</Link>
				</div>
			</div>
			<div className='container'>
				<form>
					<div className='credentials'>
						<div className='left'>
							<div className='left-el'>
								<label>Numéro</label>
								<input type='text' defaultValue={`FC2200410`} />
							</div>
							<div className='left-el'>
								<label>Date</label>
								<input type='date' defaultValue={`2022-05-22`} />
							</div>
							<div className='left-el'>
								<label>Référence</label>
								<input type='text' defaultValue={`-`} />
							</div>
						</div>
						<div className='right'>
							<div className='right-el'>
								<label>Infos Client</label>
								<span>MAHDIA</span>
								<span>TVA 114795866F/N/C 000</span>
								<span>5111 MAHDIA</span>
							</div>
						</div>
					</div>
					<div className='devis'>
						<div className='devis-head'>
							<h1>BON DE LIVRAISON</h1>
							<span />
						</div>
						<div className='devis-table'>
							<table className='offres'>
								<thead>
									<tr id='titres'>
										<td>RÉFÉRENCE</td>
										<td>CODE E.A.N</td>
										<td>DÉSIGNATION</td>
										<td>QTÉ</td>
									</tr>
								</thead>
								<tbody>
									<tr className='elements'>
										<td>HE111241-059</td>
										<td>8966574136842365</td>
										<td>BROC A EAU 1L PARIS 111241-059</td>
										<td>12</td>
									</tr>
									<tr className='elements'>
										<td>HE111241-059</td>
										<td>8966574136842365</td>
										<td>BROC A EAU 1L PARIS 111241-059</td>
										<td>12</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className='livreur'>
						<div className='left'>
							<h4>NOM &amp; PRENOM DU LIVREUR</h4>
							<input type='text' placeholder='-' />
						</div>
						<div className='left'>
							<h4>MATRICULE VEHICULE</h4>
							<input type='text' placeholder='-' />
						</div>
					</div>
				</form>
				<div className='footer'>
					<div className='left'>
						<ul>
							<li>Rue Mahmoud El Karoui SAHLOUL, SOUSSE 4051</li>
							<li>
								CODE TVA <span>11021539B/A/M 0000</span>
							</li>
							<li>
								RC: <span>B9141302009</span>
							</li>
							<li>
								Code en Douane <span>842782Z</span>
							</li>
						</ul>
					</div>
					<div className='left'>
						<ul>
							<li>
								Tél <span>31 405 595</span>
							</li>
							<li>
								Fax <span>32 405 595</span>
							</li>
							<li>
								Email: <span>somec.sousse@gmail.com</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpdateDelivery;
