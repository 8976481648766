import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { adminPasswordUpdate } from '../../../api/redux/slices/Auth';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ButtonComponent } from '../../../components/buttons/index';
import PasswordInput from '../../../components/passwordInput';
import { showToast } from '../../../components/modal';

const EditPassword = ({ resetPage }) => {
	const dispatch = useDispatch();

	const [isFocus, setIsFocus] = useState(false);
	const [isLoading, setisLoading] = useState(false);
	const [values, setValues] = useState({
		oldPassword: '',
		newPassword: '',
		confirmPassword: '',
	});

	const accesstoken = localStorage.getItem('accesstoken');

	const handleUpdate = (e) => {
		e.preventDefault();
		const data = {
			oldPassword: values.oldPassword,
			newPassword: values.newPassword,
			confirmPassword: values.confirmPassword,
		};
		if (!values.oldPassword || !values.newPassword || !values.confirmPassword) {
			showToast('Veuillez renseigner tous les champs', 'warn');
			return;
		}
		if (values.newPassword !== values.confirmPassword) {
			showToast('Mots de passe non identiques', 'warn');
			return;
		}
		setisLoading(true);
		dispatch(adminPasswordUpdate({ token: accesstoken, data: data }))
			.unwrap()
			.then((res) => {
				showToast(res.message, 'success');
				resetPage();
			})
			.catch((err) => {
				showToast(err.message, 'error');
			})
			.finally(() => setisLoading(false));
	};

	const handleCancel = (e) => {
		e.preventDefault();
		resetPage();
	};
	return (
		<div className='edit-profile'>
			<form onSubmit={handleUpdate}>
				<div className='navbar' style={{ width: 'calc(100vw - 20rem)' }}>
					<div className='left'>
						<h1>Profile</h1>
					</div>
					<div className='edit-profile-button'>
						<button
							className={isFocus ? 'button-nature' : 'button-nature-inactive'}
							onClick={handleCancel}
						>
							Annuler
						</button>
						<ButtonComponent
							className={isFocus ? 'button-primary' : 'button-primary-inactive'}
							loading={isLoading}
							type='submit'
							name={'Enregistrer'}
						/>
					</div>
				</div>
				<div className='label-input' style={{ position: 'relative' }}>
					<label>Entrer le mot de passe actuel </label>
					<PasswordInput
						id={'oldPassword'}
						placeholder={'Entrer le mot de passe actuel'}
						value={values.oldPassword}
						onChange={(e) => {
							setValues({ ...values, oldPassword: e.target.value });
							setIsFocus(true);
						}}
					/>
				</div>
				<div className='label-input' style={{ position: 'relative' }}>
					<label>Entrer le nouveau mot de passe</label>
					<PasswordInput
						id={'newPassword'}
						placeholder={'Entrer le nouveau mot de passe'}
						value={values.newPassword}
						onChange={(e) => {
							setValues({ ...values, newPassword: e.target.value });
							setIsFocus(true);
						}}
					/>
				</div>
				<div className='label-input' style={{ position: 'relative' }}>
					<label>Confirmer le nouveau mot de passe</label>
					<PasswordInput
						id={'confirmPassword'}
						placeholder={'Confirmer le nouveau mot de passe'}
						value={values.confirmPassword}
						onChange={(e) => {
							setValues({ ...values, confirmPassword: e.target.value });
							setIsFocus(true);
						}}
					/>
				</div>
			</form>
		</div>
	);
};

export default EditPassword;
