import React, { useState, useCallback, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { usersList_commercial } from '../../../api/redux/slices/Commercial';
import { CSVLink } from 'react-csv';
import useStableArr from '../../../utils/useStableArr';
import Pagination from '../../../helpers/components/Pagination';
import useCSVExport from '../../admin/clientList/csvExport';
import { LoaderComponent } from '../../../helpers/components/loader';
import { handleCheck, handleCheckAll } from '../../../utils/checkUtilsCommercial';
import { convertCategory, convertRegulation } from '../../../helpers/modules';

const ClientListPageCom = () => {
	const { list, checkedList, total } = useSelector((state) => state.commercial);

	const [userName, setUserName] = useState('');
	const [categoriesName, setCategoriesName] = useState('');
	const [regulationName, setRegulationName] = useState('');
	const [typing, setTyping] = useState(false);
	const [newArr, setNewArr] = useState([]);
	const [isCheck, setIsCheck] = useState(false);
	const csvUsersReport = useCSVExport(checkedList);
	const params = useParams();
	const dispatch = useDispatch();
	const [page, setPage] = useState(Number(params.page));
	const token = localStorage.getItem('accesstoken');
	const [isLoading, setIsLoading] = useState(false);

	//------------------------- get list of all users -------------------------------//
	const stableDispatch = useCallback(() => {
		setIsLoading(true);
		dispatch(
			usersList_commercial({
				token: token,
				name: userName,
				categories: categoriesName,
				regulation: regulationName,
				page: userName || categoriesName || regulationName ? undefined : page,
			}),
		)
			.unwrap()
			.finally(() => setIsLoading(false));
	}, [dispatch, userName, categoriesName, regulationName, page, token]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [stableDispatch, token]);
	// --------------- clone the list of users into the new array ---------------//
	useStableArr(list, checkedList, setNewArr, setIsCheck);

	return (
		<div id='clientListPage'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='text'
							placeholder='Rechercher ...'
							value={userName}
							onChange={(e) => {
								setUserName(e.target.value);
								setTyping(true);
							}}
						/>
						<icons.FiSearch className='searchicon' />
						{typing && (
							<icons.GrFormClose
								className='searchicon'
								onClick={(e) => {
									setUserName('');
									setTyping(false);
								}}
							/>
						)}
					</div>
					<div className='filters'>
						<select value={categoriesName} onChange={(e) => setCategoriesName(e.target.value)}>
							<option value=''>Catégories</option>
							<option value='grossiste'>Grossiste</option>
							<option value='detaillant'>Detaillant</option>
							<option value='monoprix'>Grande surface</option>
							{/* <option value='umd'>UMD</option>
							<option value='black'>Black</option> */}
						</select>
					</div>
					<div className='filters'>
						<select value={regulationName} onChange={(e) => setRegulationName(e.target.value)}>
							<option value=''>Régléments</option>
							<option value='cheque'>Chèque</option>
							<option value='espece'>Espèce</option>
							<option value='traite'>Traité</option>
						</select>
					</div>
				</div>
				<div className='right'>
					<div className='right-items'>
						<Link to={`/commercial/users/add_client/${page}`}>
							<button className='button-primary'>
								<icons.SiAddthis />
								<span>Client</span>
							</button>
						</Link>
						<button className='button-nature' title='exporter utilisateur'>
							<CSVLink {...csvUsersReport} target='blank'>
								<icons.BiExport />
							</CSVLink>
						</button>
					</div>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Liste des clients</h1>
				</div>
			</div>
			<div className='usersContent'>
				<table id='users'>
					<thead>
						<tr id='titres'>
							<td className='input-check'>
								<input
									type='checkbox'
									className='checkbox'
									checked={isCheck}
									onChange={() => handleCheckAll(dispatch, newArr, setNewArr, isCheck, setIsCheck)}
								/>
							</td>
							<td>CLIENT</td>
							<td>SOCIÉTÉ</td>
							<td>MATRICULE FISCALE</td>
							<td>CATÉGORIE</td>
							<td>REGLEMENT</td>
							<td style={{ margin: 'auto' }}> STATUT</td>
							<td style={{ margin: 'auto' }}>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{isLoading ? (
							<tr>
								<td colSpan={7}>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							newArr.map((u, index) => (
								<tr id={`element-${index}`} className='user' key={u._id}>
									<td className='input-check'>
										<input
											type='checkbox'
											className='checkbox'
											checked={u.checked}
											onChange={() => handleCheck(dispatch, newArr, setNewArr, u._id)}
										/>
									</td>
									<td>
										{u.first_name} {u.last_name}
									</td>
									<td style={{ textTransform: 'lowercase' }}>
										{u.company_name ? u.company_name : '-'}
									</td>
									<td style={{ textTransform: 'uppercase' }}>{u.tax_registration_number}</td>
									<td>
										{u.categories && u.role === 'customer' ? convertCategory(u.categories) : '-'}
									</td>
									<td>
										{u.regulation && u.role === 'customer' ? convertRegulation(u.regulation) : '-'}
									</td>
									<td
										style={{ margin: 'auto' }}
										className={u.is_active === true ? 'user-status' : 'user-status activé'}
									>
										{u.is_active === true ? 'Activé' : 'Bloqué'}
									</td>
									<td className='last-user-element' style={{ margin: 'auto' }}>
										<Link
											to={`/commercial/users/client_details/${page}/${u._id}`}
											className='link-user'
										>
											<icons.BsEye className='see-user' />
										</Link>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink={`/commercial/users/`} total={total} />
		</div>
	);
};

export default ClientListPageCom;
