import React from 'react';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useNavigate } from 'react-router-dom';

const OrderSuccessCom = ({ setOrderSuccess, orderSuccess }) => {
	const navigate = useNavigate();
	return (
		<div className={'modalcontainer ' + (orderSuccess && 'open')}>
			<div
				className={'modal ' + (orderSuccess && 'active')}
				style={{
					width: '30rem',
					height: '7rem',
					justifyContent: 'center',
					top: '20%',
					left: '50%',
				}}
			>
				<div
					className='close-modal'
					onClick={() => {
						setOrderSuccess(false);
						navigate(`/commercial/orders/1`);
					}}
					style={{ paddingTop: '1.5rem' }}
				>
					<icons.MdClose />
				</div>
				<div className='client-modal-order'>
					<h1 style={{ color: '#316161' }}>Le devis à été envoyé avec succès !</h1>
				</div>
			</div>
		</div>
	);
};

export default OrderSuccessCom;
