import React from 'react';
import './index.scss';
import store from '../../../assets/backgrounds/store_background.jpg';
import logo from '../../../assets/logos/somec_logo.png';

const Container = ({ children }) => {
	return (
		<div className='container'>
			<div className='box'>
				<div className='left'>
					<div className='header'>
						<img src={logo} alt='somec_logo' className='logo' />
						<span>Bienvenue, veuillez saisir vos coordonnées</span>
					</div>
					{children}
					<div className='footer'>
						<span>&copy;All rights reserved Run-IT 2023</span>
					</div>
				</div>
				<div className='right'>
					<img src={store} alt='store_logo' />
				</div>
			</div>
		</div>
	);
};

export default Container;
