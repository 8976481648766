import React from 'react';
import './index.scss';
import { ButtonComponent } from '../../../components/buttons';

const ConfirmOrderModal = ({ confirmModal, setConfirmModal, handleAddOrder, loading }) => {
	const handleClickAddOrder = (e) => {
		e.preventDefault();
		handleAddOrder();
	};
	return (
		<div className={'modalcontainer ' + (confirmModal && 'open')}>
			<div className={'modal ' + (confirmModal && 'active')} id='confirmorderclient'>
				<form>
					<div className='field'>
						<h1>Êtes-vous sûr de vouloir confirmer cette commande?</h1>
					</div>
					<div className='modalbtn' style={{ gap: '1rem' }}>
						<button
							className='button-nature'
							onClick={(e) => {
								e.preventDefault();
								setConfirmModal(!confirmModal);
							}}
						>
							Annuler
						</button>
						<ButtonComponent
							name={'Enregistrer'}
							loading={loading}
							type={'button'}
							className={'button-primary'}
							onClick={handleClickAddOrder}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ConfirmOrderModal;
