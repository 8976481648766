import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { getOrderByIdAdmin } from '../../../api/redux/slices/Admin';
import { LoaderComponent } from '../../../helpers/components/loader';
import { toast } from 'react-toastify';
import { convertDate, roundToThreeDecimals } from '../../../helpers/modules';
import ImprimerLogo from '../../../assets/icons/svg/document-text.svg';
import { ShopInfo, SuperAdminInfo } from '../../../components/userInfo';
import { useReactToPrint } from 'react-to-print';
import PrintToPdf from './PrintToPdf';
import { renderTvaValues } from '../../../utils/groupTvaValues';

const OrderDetail = () => {
	const { orderDetails } = useSelector((state) => state.admin);
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();
	const token = localStorage.getItem('accesstoken');

	const [detailOrder, setDetailOrder] = useState(orderDetails ?? {});
	const [loadingData, setLoadingData] = useState(false);

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const stableDispatchOrderById = useCallback(() => {
		setLoadingData(true);
		dispatch(
			getOrderByIdAdmin({
				token: token,
				id: params.id,
			}),
		)
			.unwrap()
			.then((res) => {
				setDetailOrder(res.data);
			})
			.catch((err) => {
				toast.error(err?.error?.message || 'can not fetch this order', {
					position: 'top-center',
					autoClose: 2000,
				});
				setTimeout(() => {
					navigate(`/admin/orders/${params.page}`);
				}, 1000);
			})
			.finally(() => setLoadingData(false));
	}, [dispatch, params, token, navigate]);

	useEffect(() => {
		if (token && params.id) {
			stableDispatchOrderById();
		}
	}, [stableDispatchOrderById, token, params]);

	return (
		<div id='orderDetails'>
			<div className='details-order-header'>
				<Link to={`/admin/orders/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Bons de commandes</h1>
				</Link>
				<div style={{ display: 'flex', gap: '10px' }}>
					{orderDetails?.order?.status === 'ORDER' && (
						<Link to={`/admin/orders/order_update/${params.page}/${params.id}`}>
							<button className='button-nature'>Modifier</button>
						</Link>
					)}
					<button
						style={{ display: 'flex', gap: '10px' }}
						onClick={handlePrint}
						className='button-primary'
					>
						<img src={ImprimerLogo} alt='imprimer logo' />
						Imprimer
					</button>
				</div>
			</div>
			{loadingData ? (
				<LoaderComponent />
			) : (
				<>
					<div className='container'>
						<Fragment>
							<div className='credentials'>
								<div className='infos-societe left' style={{ border: 'none' }}>
									<label style={{ textTransform: 'uppercase' }}>
										{detailOrder?.order?.superAdminInfo?.name}
									</label>
								</div>
								<div className='left'>
									<div className='total'>
										<label>Montant Reçu</label>
										<span>
											{roundToThreeDecimals(detailOrder?.order?.clientInfo.received_amount) || 0} DT
										</span>
									</div>
									<div className='total'>
										<label>Montant Dépensé</label>
										<span>
											{roundToThreeDecimals(detailOrder?.order?.clientInfo.spent_amount) || 0} DT
										</span>
									</div>
								</div>
							</div>
							<div className='credentials'>
								<div className='left'>
									<div className='left-el'>
										<label>Numéro</label>
										<span>{detailOrder?.order?.reference ?? '-'}</span>
									</div>
									<div className='left-el'>
										<label>Date</label>
										<span>{convertDate(detailOrder?.order?.date, 'DD-MM-YYYY') ?? ''}</span>
									</div>
									<div className='left-el'>
										<label>Référence code client</label>
										<span>{detailOrder?.order?.clientInfo?.customs_code ?? '-'}</span>
									</div>
								</div>
								<div className='left'>
									<div className='left-e'>
										<label style={{ textTransform: 'capitalize' }}>
											{detailOrder?.order?.clientInfo?.company_name ?? ''}
										</label>
									</div>
									<div className='left-e'>
										<span>{detailOrder?.order?.clientInfo?.client ?? ''}</span>
									</div>
									<div className='left-e'>
										<span>
											Matricule Fiscale:{' '}
											{detailOrder?.order?.clientInfo?.tax_registration_number ?? ''}
										</span>
									</div>
									<div className='left-e'>
										<span>N° téléphone: {detailOrder?.order?.clientInfo?.phone ?? ''}</span>
									</div>
									<div className='left-e'>
										<ShopInfo shop={orderDetails?.order?.shopInfo} />
									</div>
								</div>
							</div>
							<div className='devis'>
								<div className='devis-head'>
									<h1>Devis</h1>
									<p>
										Cher Client,
										<br />
										Nous avons bien reçu votre demande de devis et nous vous en remercions.
										<br />
										Nous vous prions de trouver ci-dessous nos conditions les meilleures
									</p>
								</div>
								<div className='devis-table'>
									<table className='offres'>
										<thead>
											<tr id='titres'>
												<td>RÉFÉRENCE</td>
												<td>RÉFÉRENCE CLT</td>
												<td>DÉSIGNATION</td>
												<td>QTÉ</td>
												<td>PU HT</td>
												<td>TVA (%)</td>
												<td>MONTANT HT</td>
											</tr>
										</thead>
										<tbody>
											{detailOrder?.list?.map((el, index) => (
												<tr className='elements' key={index}>
													<td>{el?.reference ?? '-'}</td>
													<td>{el?.refranceCTL ?? '-'}</td>
													<td>{el?.designation ?? '-'}</td>
													<td>{el?.quantity ?? '-'}</td>
													<td>
														{roundToThreeDecimals(
															(el?.prise_product_HT * el?.quantity) / (1 - el?.remise / 100),
														) ?? '-'}
													</td>
													<td>{el?.tva ?? '-'}</td>
													<td>
														{roundToThreeDecimals(el?.prise_product_HT * el?.quantity) || '-'}
													</td>
												</tr>
											))}
										</tbody>
									</table>
									<div className='total-devis'>
										<div id='box'>
											<div>
												<h1>TOTAL HT</h1>
												<span id='somme-totale'>{detailOrder?.order?.total_HT ?? 0}</span>
											</div>
											{renderTvaValues(orderDetails)}
											<div>
												<h1>TIMBRE</h1>
												<span id='somme-totale'>{detailOrder?.order?.timbre ?? 0}</span>
											</div>
											<div>
												<h1>TOTAL TTC</h1>
												<span id='somme-totale'>{detailOrder?.order?.total ?? 0}</span>
											</div>
										</div>
									</div>
								</div>
								<div className='message'>
									<p>
										Nous sommes à votre disposition pour tout complément d'informations. <br />
										Nous vous prions d'agréer, Cher Client, nos sincères salutations. <br />
										Le service commercial.
									</p>
								</div>
								<SuperAdminInfo superAdminInfo={detailOrder?.order?.superAdminInfo} />
							</div>
						</Fragment>
					</div>
					<div style={{ display: 'none' }}>
						<PrintToPdf componentRef={componentRef} orderDetails={orderDetails} />
					</div>
				</>
			)}
		</div>
	);
};

export default OrderDetail;
