import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { generateId } from '../../../helpers/modules';
import { toast } from 'react-toastify';
import { updateCustomerProfile } from '../../../api/redux/slices/Customer';
import ShopFields from '../../admin/addUser/ShopFields';
import { ButtonComponent } from '../../../components/buttons';
import { showToast } from '../../../components/modal';

const UpdateClientProfile = () => {
	const { user } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [infos] = useState(user.data);
	const accessToken = localStorage.getItem('accesstoken');
	const [isLoading, setIsLoading] = useState(false);
	const [shopToDeleteIndex, setShopToDeleteIndex] = useState(null);
	const [values, setValues] = useState({
		last_name: infos.last_name,
		first_name: infos.first_name,
		email: infos.email,
		phone: infos.phone,
		company_name: infos.company_name,
		tax_registration_number: infos.tax_registration_number,
		categories: infos.categories,
		regulation: infos.regulation,
		customs_code: infos.customs_code,
	});

	const addField = () => {
		const newField = {
			id: uuidv4(),
			name: '',
			address_number: '',
			street: '',
			ville: '',
			postal_code: '',
			region: '',
		};

		setShopFields([...shopFields, newField]);
	};

	const [shopFields, setShopFields] = useState(
		infos?.shop?.length === 0
			? [
					{
						id: uuidv4(),
						name: '',
						address_number: '',
						street: '',
						ville: '',
						postal_code: '',
						region: '',
						fakeId: generateId(),
					},
			  ]
			: infos.shop.map((el) => ({ ...el, id: uuidv4() })),
	);

	const handleSubmit = (e) => {
		e.preventDefault();
		let canAddUser = true;
		const trimmedShopFields = shopFields.map((item) => {
			const data = {
				name: item.name.trim(),
				address_number: item.address_number,
				street: item.street.trim(),
				ville: item.ville.trim(),
				postal_code: item.postal_code,
				region: item.region,
			};

			if (!data.name || !data.street || !data.ville || !data.postal_code || !data.region) {
				showToast('Veuillez remplir tous les champs', 'warn');
				canAddUser = false;
			}
			return data;
		});

		if (!canAddUser) return;

		const data = {
			last_name: values.last_name.trim(),
			first_name: values.first_name.trim(),
			email: values.email.trim(),
			phone: values.phone.trim(),
			company_name: values.company_name.trim(),
			tax_registration_number: values.tax_registration_number.trim(),
			customs_code: values.customs_code.trim(),
			categories: values.categories,
			regulation: values.regulation,
			shop: trimmedShopFields,
		};
		if (
			!data.last_name ||
			!data.first_name ||
			!data.email ||
			!data.phone ||
			!data.company_name ||
			!data.tax_registration_number ||
			!data.customs_code
		) {
			showToast('Veuillez remplir tous les champs', 'warn');
			return;
		}
		setIsLoading(true);
		dispatch(updateCustomerProfile({ token: accessToken, data }))
			.unwrap()
			.then((res) => {
				toast.success('Mis à jour', {
					position: 'top-center',
					autoClose: 2000,
				});
				navigate('/client/profile');
			})
			.catch((err) => {
				toast.warn(err, {
					position: 'top-center',
					autoClose: 2000,
				});
			})
			.finally(() => setIsLoading(false));
	};

	const handleChangeShopForm = (id, e) => {
		let data = [...shopFields];
		const index = data.findIndex((item) => item.id === id);

		if (index !== -1) {
			e.target.name === 'postal_code'
				? (data[index][e.target.name] = e.target.value.replace(/\D/g, ''))
				: (data[index][e.target.name] = e.target.value);
			setShopFields(data);
		}
	};
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const handleDeleteAddedShop = (id) => {
		if (shopFields.length > 1) {
			const updatedShopFields = shopFields.filter((field) => field.id !== id);
			setShopFields(updatedShopFields);
		} else showToast('Au moins une boutique est requise', 'warn');
	};

	return (
		<div id='update-cp'>
			<form onSubmit={handleSubmit}>
				<div className='navbar'>
					<div className='left'>
						<Link to='/client/profile'>
							<h1>Profile</h1>
						</Link>
					</div>
					<div className='submit-btns'>
						<Link to={'/client/profile'}>
							<button className='button-nature'>Annuler</button>
						</Link>
						<ButtonComponent
							type='submit'
							name='Enregistrer'
							loading={isLoading}
							className='button-primary'
						/>
					</div>
				</div>
				<div className='user-infos-client'>
					<h2>Information genérale</h2>
					<div className='form-line'>
						<div className='frag'>
							<label>Nom</label>
							<input
								type='text'
								required
								placeholder='nom'
								value={values.last_name}
								onChange={(e) => setValues({ ...values, last_name: e.target.value })}
							/>
						</div>
						<div className='frag'>
							<label htmlFor='prenom'>Prénom</label>
							<input
								type='text'
								required
								placeholder='prenom'
								value={values.first_name}
								onChange={(e) => setValues({ ...values, first_name: e.target.value })}
							/>
						</div>
					</div>
					<div className='field'>
						<label htmlFor='email'>Email</label>
						<input
							type='email'
							required
							placeholder='nom@email.com'
							value={values.email}
							onChange={(e) => setValues({ ...values, email: e.target.value })}
						/>
					</div>
					<div className='field'>
						<label htmlFor='number1'>Numéro de téléphone</label>
						<input
							type='text'
							required
							value={values.phone}
							onChange={(e) => setValues({ ...values, phone: e.target.value })}
							placeholder='ex: 21 212 212'
						/>
					</div>
					<div className='field'>
						<label htmlFor='namesociete'>Nom de société</label>
						<input
							type='text'
							required
							placeholder='nom de société'
							value={values.company_name}
							onChange={(e) => setValues({ ...values, company_name: e.target.value })}
						/>
					</div>
					<div className='field'>
						<label htmlFor='mfiscale'>Matricule fiscale</label>
						<input
							type='text'
							required
							placeholder='ex: 0001985G A M 000'
							value={values.tax_registration_number}
							onChange={(e) =>
								setValues({
									...values,
									tax_registration_number: e.target.value,
								})
							}
						/>
					</div>
					<div className='field'>
						<label>Référence code client</label>
						<input
							type='text'
							placeholder='ex: 1245557'
							value={values.customs_code}
							disabled
							style={{ borderColor: '#ccc', color: 'rgb(123 120 120)' }}
						/>
					</div>
				</div>
				<ShopFields
					shopFields={shopFields}
					handleChangeShopForm={handleChangeShopForm}
					handleDeleteAddedShop={handleDeleteAddedShop}
					showDeleteModal={showDeleteModal}
					setShowDeleteModal={setShowDeleteModal}
					shopToDeleteIndex={shopToDeleteIndex}
					setShopToDeleteIndex={setShopToDeleteIndex}
					addField={addField}
				/>
			</form>
		</div>
	);
};

export default UpdateClientProfile;
