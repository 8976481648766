import React, { useCallback, useEffect, useState } from 'react';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerCategories } from '../../../api/redux/slices/Customer';
import Pagination from '../../../helpers/components/Pagination';
import { useParams } from 'react-router-dom';
import { LoaderComponent } from '../../..//helpers/components/loader';

const CategoriesPage = () => {
	const { listCategories, total } = useSelector((state) => state.customer);
	const dispatch = useDispatch();
	const params = useParams();
	const accesstoken = localStorage.getItem('accesstoken');
	const [isLoading, setIsLoading] = useState(false);

	const [page, setPage] = useState(Number(params.page));
	const [searchInput, setSearchInput] = useState('');

	//----------------------- get all categories ---------------------------//
	const stableDispatch = useCallback(() => {
		setIsLoading(true);
		dispatch(
			getCustomerCategories({
				token: accesstoken,
				page: searchInput ? undefined : page,
				search: searchInput,
			}),
		)
			.unwrap()
			.finally(() => setIsLoading(false));
	}, [dispatch, page, searchInput, accesstoken]);

	useEffect(() => {
		if (accesstoken) {
			stableDispatch();
		}
	}, [stableDispatch, accesstoken]);

	return (
		<div id='catagory-cp'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='search'
							placeholder='Rechercher ...'
							values={searchInput}
							onChange={(e) => setSearchInput(e.target.value)}
						/>
						<icons.FiSearch className='searchicon' />
					</div>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Catégories</h1>
				</div>
			</div>
			<div className='mainContent'>
				<table className='content'>
					<thead>
						<tr className='titres'>
							<td>CATÉGORIE</td>
							<td>DESCRIPTION</td>
						</tr>
					</thead>
					<tbody>
						{isLoading ? (
							<tr>
								<td colSpan={2}>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							listCategories?.map((element) => (
								<tr className='element' key={element._id}>
									<td style={{ textTransform: 'capitalize' }}>{element.designation}</td>
									<td id='last'>{element.description}</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink={'/client/categories/'} total={total} />
		</div>
	);
};

export default CategoriesPage;
