import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { new_user_client_commercial } from '../../../api/redux/slices/Commercial';
import { useDispatch } from 'react-redux';
import { Modal, showToast } from '../../../components/modal';
import { v4 as uuidv4 } from 'uuid';
import { ButtonComponent } from '../../../components/buttons';
import ShopFields from '../../admin/addUser/ShopFields';
import PasswordInput from '../../../components/passwordInput';

const AddClient = () => {
	const [isFocus, setIsFocus] = useState(false);
	const [errorModal, setErrorModal] = useState({ errMsg: '', isShow: false });
	const { page } = useParams();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const token = localStorage.getItem('accesstoken');
	const [isLoading, setIsLoading] = useState(false);
	const [shopToDeleteIndex, setShopToDeleteIndex] = useState(null);

	const [values, setValues] = useState({
		first_name: '',
		last_name: '',
		is_client: true,
		email: '',
		phone: '',
		company_name: '',
		password: '',
		confirmPassword: '',
		tax_registration_number: '',
		customs_code: '',
		regulation: 'espece',
		categories: 'detaillant',
	});

	const [shopFields, setShopFields] = useState([
		{
			id: uuidv4(),
			name: '',
			address_number: '',
			street: '',
			ville: '',
			postal_code: '',
			region: '',
		},
	]);

	const addField = () => {
		let newfield = {
			id: uuidv4(),
			name: '',
			address_number: '',
			street: '',
			ville: '',
			postal_code: '',
			region: '',
		};

		setShopFields([...shopFields, newfield]);
	};

	const handleAddClient = (e) => {
		e.preventDefault();
		delete values.vehicle_number;

		let canAddUser = true;

		const trimmedShopFields = shopFields.map((item) => {
			const data = {
				name: item.name.trim(),
				address_number: item.address_number,
				street: item.street.trim(),
				ville: item.ville.trim(),
				postal_code: item.postal_code,
				region: item.region,
			};

			if (!data.name || !data.street || !data.ville || !data.region) {
				showToast('Veuillez remplir tous les champs', 'warn');
				canAddUser = false;
			}
			return data;
		});

		if (canAddUser) {
			const ClientData = {
				tax_registration_number: values.tax_registration_number.trim(),
				customs_code: values.customs_code.trim(),
				first_name: values.first_name.trim(),
				last_name: values.last_name.trim(),
				email: values.email.trim(),
				phone: values.phone.trim(),
				company_name: values.company_name.trim(),
				is_client: true,
				password: values.password,
				confirmPassword: values.confirmPassword,
				regulation: values.regulation,
				categories: values.categories,
				shop: trimmedShopFields,
			};
			if (
				!ClientData.tax_registration_number ||
				!ClientData.customs_code ||
				!ClientData.first_name ||
				!ClientData.last_name ||
				!ClientData.email ||
				!ClientData.phone ||
				!ClientData.company_name
			) {
				showToast('Veuillez remplir tous les champs', 'warn');
				return;
			}
			if (ClientData.password !== ClientData.confirmPassword) {
				showToast('Mots de passe non identiques', 'warn');
				return;
			}
			setIsLoading(true);
			dispatch(new_user_client_commercial({ token: token, data: ClientData }))
				.unwrap()
				.then(() => {
					navigate(`/commercial/users/${page}`);
				})
				.catch((err) => {
					setErrorModal({
						errMsg: err.response?.data?.message || err?.message || err || 'An error occurred',
						isShow: true,
					});
				})
				.finally(() => setIsLoading(false));
		}
	};
	const handleChangeShopForm = (id, e) => {
		let data = [...shopFields];
		const index = data.findIndex((item) => item.id === id);

		if (index !== -1) {
			e.target.name === 'postal_code'
				? (data[index][e.target.name] = e.target.value.replace(/\D/g, ''))
				: (data[index][e.target.name] = e.target.value);
			setShopFields(data);
		}
	};
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const handleDeleteAddedShop = (id) => {
		if (shopFields.length > 1) {
			const updatedShopFields = shopFields.filter((field) => field.id !== id);
			setShopFields(updatedShopFields);
		} else showToast('Au moins une boutique est requise', 'warn');
	};

	return (
		<div id='addClient'>
			{errorModal.isShow && <Modal errorModal={errorModal} setModalError={setErrorModal} />}
			<form onSubmit={handleAddClient}>
				<div className='navbar' style={{ width: 'calc(100vw - 20rem)', marginBottom: '2rem' }}>
					<Link
						to={`/commercial/users/${page}`}
						style={{ width: '500px', display: 'flex', alignItems: 'center' }}
					>
						<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
						<h1>Liste d'utilisateurs</h1>
					</Link>
					<div className='submit-btns'>
						<div className='add-btns'>
							<Link to={`/commercial/users/${page}`}>
								<button className={isFocus ? 'button-nature' : 'button-nature-inactive'}>
									Annuler
								</button>
							</Link>
							<ButtonComponent
								name='Enregistrer'
								loading={isLoading}
								type='submit'
								className={isFocus ? 'button-primary' : 'button-primary-inactive'}
							/>
						</div>
					</div>
				</div>
				<div className='user-infos'>
					<h2 style={{ marginBottom: '.5rem' }}>Type catégorie</h2>
					<div className='line'>
						<input
							type='radio'
							name='usercategory'
							defaultChecked
							value={values.categories}
							onChange={() => setValues({ ...values, categories: 'detaillant' })}
						/>
						<label>Détaillant</label>
					</div>
					<div className='line'>
						<input
							type='radio'
							name='usercategory'
							value={values.categories}
							onChange={() => setValues({ ...values, categories: 'grossiste' })}
						/>
						<label htmlFor='grossiste'>Grossiste</label>
					</div>
					<div className='line'>
						<input
							type='radio'
							name='usercategory'
							value={values.categories}
							onChange={() => setValues({ ...values, categories: 'monoprix' })}
						/>
						<label htmlFor='categoryclient'>Grande surface</label>
					</div>
					{/* <div className='line'>
						<input
							type='radio'
							name='usercategory'
							value={values.categories}
							onChange={() => setValues({ ...values, categories: 'umd' })}
						/>
						<label htmlFor='categoryclient'>UMD</label>
					</div>
					<div className='line'>
						<input
							type='radio'
							name='usercategory'
							value={values.categories}
							onChange={() => setValues({ ...values, categories: 'black' })}
						/>
						<label htmlFor='categoryclient'>Black</label>
					</div> */}
					<h2 style={{ marginTop: '1rem' }}>Information genérale</h2>
					<div className='form-line'>
						<div className='frag'>
							<label>Nom</label>
							<input
								type='text'
								placeholder='nom'
								value={values.first_name}
								onChange={(e) => {
									setValues({ ...values, first_name: e.target.value });
									setIsFocus(true);
								}}
								required
							/>
						</div>
						<div className='frag'>
							<label htmlFor='prenom'>Prénom</label>
							<input
								type='text'
								placeholder='prenom'
								value={values.last_name}
								onChange={(e) => setValues({ ...values, last_name: e.target.value })}
								required
							/>
						</div>
					</div>
					<div className='field'>
						<label htmlFor='email'>Email</label>
						<input
							type='email'
							placeholder='nom@email.com'
							value={values.email}
							onChange={(e) => setValues({ ...values, email: e.target.value })}
							required
						/>
					</div>
					<div className='field'>
						<label htmlFor='number1'>Numéro de téléphone</label>
						<input
							type='text'
							placeholder='ex: 21 212 212'
							value={values.phone}
							onChange={(e) => {
								const onlyNumbers = e.target.value.replace(/[^0-9+\s]/g, '');
								setValues({ ...values, phone: onlyNumbers });
							}}
							required
						/>
					</div>
					<div className='field'>
						<label htmlFor='namesociete'>Nom de société</label>
						<input
							type='text'
							placeholder='nom de société'
							value={values.company_name}
							onChange={(e) => setValues({ ...values, company_name: e.target.value })}
							required
						/>
					</div>
					<div className='field'>
						<label>Matricule fiscale</label>
						<input
							type='text'
							placeholder='ex: 0001985G A M 000'
							value={values.tax_registration_number}
							onChange={(e) =>
								setValues({
									...values,
									tax_registration_number: e.target.value,
								})
							}
							required
						/>
					</div>
					<div className='field'>
						<label>Référence code client</label>
						<input
							type='text'
							placeholder='ex: 1245557'
							value={values.customs_code}
							onChange={(e) =>
								setValues({
									...values,
									customs_code: e.target.value,
								})
							}
							required
						/>
					</div>
					<div className='field' style={{ position: 'relative' }}>
						<label>Ajouter le mot de passe</label>
						<PasswordInput
							id={'password'}
							placeholder='mot de passe'
							value={values.password}
							onChange={(e) => setValues({ ...values, password: e.target.value })}
							required
						/>
					</div>
					<div className='field' style={{ position: 'relative' }}>
						<label>Confirmer le mot de passe</label>
						<PasswordInput
							id={'confirmpassword'}
							placeholder='confirmer le mot de passe'
							value={values.confirmPassword}
							onChange={(e) => setValues({ ...values, confirmPassword: e.target.value })}
							required
						/>
					</div>
				</div>
				<ShopFields
					shopFields={shopFields}
					handleChangeShopForm={handleChangeShopForm}
					handleDeleteAddedShop={handleDeleteAddedShop}
					showDeleteModal={showDeleteModal}
					setShowDeleteModal={setShowDeleteModal}
					shopToDeleteIndex={shopToDeleteIndex}
					setShopToDeleteIndex={setShopToDeleteIndex}
					addField={addField}
				/>
			</form>
		</div>
	);
};

export default AddClient;
