import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { adminProfileUpdate } from '../../../api/redux/slices/Auth';
import './index.scss';
import { toast } from 'react-toastify';
import { ButtonComponent } from '../../../components/buttons';

const EditProfile = ({ infos, resetPage }) => {
	const dispatch = useDispatch();

	const [isFocus, setIsFocus] = useState(false);
	const [isLoading, setisLoading] = useState(false);

	const [values, setValues] = useState({
		first_name: infos.first_name,
		last_name: infos.last_name,
		phone: infos.phone,
		email: infos.email,
	});

	const accesstoken = localStorage.getItem('accesstoken');

	const handleUpdate = (e) => {
		e.preventDefault();
		const data = {
			first_name: values.first_name,
			last_name: values.last_name,
			phone: values.phone,
			email: values.email,
		};

		setisLoading(true);
		dispatch(adminProfileUpdate({ token: accesstoken, data: data }))
			.unwrap()
			.then(() => {
				toast.success('Mis à jour', {});
				resetPage();
			})
			.catch((err) => {
				toast.warn(err, {
					position: 'top-center',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.finally(() => setisLoading(false));
	};
	const handleCancel = (e) => {
		e.preventDefault();
		resetPage();
	};

	return (
		<div className='edit-profile'>
			<form onSubmit={handleUpdate}>
				<div className='navbar' style={{ width: 'calc(100vw - 20rem)' }}>
					<div className='left'>
						<h1>Profile</h1>
					</div>
					<div className='edit-profile-button'>
						<button
							className={isFocus ? 'button-nature' : 'button-nature-inactive'}
							onClick={handleCancel}
						>
							Annuler
						</button>
						<ButtonComponent
							className={isFocus ? 'button-primary' : 'button-primary-inactive'}
							loading={isLoading}
							type='submit'
							name={'Enregistrer'}
						/>
					</div>
				</div>
				<div className='label-input'>
					<label>Nom</label>
					<input
						type='text'
						placeholder='Foulen'
						value={values.first_name}
						onChange={(e) => {
							setValues({ ...values, first_name: e.target.value });
							setIsFocus(true);
						}}
					/>
				</div>
				<div className='label-input'>
					<label>Prénom</label>
					<input
						type='text'
						placeholder='Ben foulen'
						value={values.last_name}
						onChange={(e) => {
							setValues({ ...values, last_name: e.target.value });
							setIsFocus(true);
						}}
					/>
				</div>
				<div className='label-input'>
					<label>Adresse e-mail</label>
					<input
						type='email'
						placeholder='foulen.benfoulen@email.com'
						value={values.email}
						onChange={(e) => {
							setValues({ ...values, email: e.target.value });
							setIsFocus(true);
						}}
					/>
				</div>
				<div className='label-input'>
					<label>Numéro de téléphone</label>
					<input
						type='text'
						placeholder='21 212 212'
						value={values.phone}
						onChange={(e) => {
							setValues({ ...values, phone: e.target.value });
							setIsFocus(true);
						}}
					/>
				</div>
			</form>
		</div>
	);
};

export default EditProfile;
