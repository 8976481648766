import React, { useState } from 'react';
import { ButtonComponent } from '../../../components/buttons';
import { showToast } from '../../../components/modal';
import { refusePayement } from '../../../api/redux/slices/Admin';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ModalMessage = ({ messageModal, setMessageModal, params }) => {
	const [refusalReason, setRefusalReason] = useState('');
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const modalContainerClassName = `modalcontainer ${messageModal.isShow ? 'open' : ''}`;
	const modalClassName = `modal ${messageModal.isShow ? 'active' : ''}`;
	const token = localStorage.getItem('accesstoken');
	const navigate = useNavigate();

	const modalStyle = {
		height: '22rem',
		width: '35rem',
		top: '40%',
		flexDirection: 'column',
		gap: '12px',
	};

	const handleModalClose = () => {
		setMessageModal({ succMsg: '', isShow: false });
	};

	const handleRefuser = (e) => {
		e.preventDefault();
		if (refusalReason.trim() === '') {
			showToast('Veuillez renseigner la raison du refus', 'error');
			return;
		}
		setLoading(true);
		dispatch(
			refusePayement({
				token,
				id: params.id,
				data: { status: 'refuse', message: refusalReason.trim() },
			}),
		)
			.unwrap()
			.then(() => {
				showToast('Message de refus envoyé', 'info');
				navigate('/admin/deliveries/1');
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className={modalContainerClassName}>
			<div className={modalClassName} style={modalStyle}>
				<h2 style={{ color: '#0F172A' }}>Raison du refus</h2>
				<textarea
					value={refusalReason}
					onChange={(e) => setRefusalReason(e.target.value)}
					style={{
						width: '30rem',
						height: '9rem',
						border: '1px solid #CBD5E1',
						padding: '10px',
						resize: 'none',
					}}
				/>
				<div style={{}}>
					<div
						style={{
							width: '50%',
							display: 'flex',
							gap: '10px',
							margin: 'auto 0 0 auto',
						}}
					>
						<button
							className='button-nature-inactive'
							onClick={handleModalClose}
							style={{ margin: '0 auto' }}
						>
							Annuler
						</button>
						<ButtonComponent
							name={'Enregistrer'}
							loading={loading}
							type={'button'}
							className={'button-primary'}
							onClick={handleRefuser}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalMessage;
