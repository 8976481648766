import {
	ClientListPage,
	ProfilePage,
	AddUser,
	UpdateUser,
	UserDetails,
	CategoryPage,
	ProductPage,
	AddProduct,
	ProductDetails,
	DeliveryPage,
	DeliveryDetail,
	UpdateDelivery,
	OrdersPage,
	OrderDetail,
	UpdateOrder,
	OrderCart,
	OrderMarket,
	BillPage,
	BillDetails,
	UpdateBill,
	ReturnsPage,
	ReturnDetails,
	UpdateReturn,
	ComplaintsPage,
	ComplainDetails,
	UpdateProduct,
	DeliveryConfirm,
	PaymentDetails,
	AddOrder,
} from '../pages/admin';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from './../pages/NotFound';

const AdminRoutes = () => {
	return (
		<Routes>
			<Route exact path='/' element={<ProfilePage />} />
			<Route exact path='/admin/profile' element={<ProfilePage />} />
			<Route exact path='/admin/categories/:page' element={<CategoryPage />} />
			<Route exact path='/admin/products/:page' element={<ProductPage />} />
			<Route exact path='/admin/products/add_product/:page' element={<AddProduct />} />
			<Route exact path='/admin/products/product_update/:page/:id' element={<UpdateProduct />} />
			<Route exact path='/admin/products/product_details/:page/:id' element={<ProductDetails />} />
			<Route exact path='/admin/users/:page' element={<ClientListPage />} />
			<Route exact path='/admin/users/add_user/:page' element={<AddUser />} />
			<Route exact path='/admin/users/update_user/:page/:id' element={<UpdateUser />} />
			<Route exact path='/admin/users/user_details/:page/:id' element={<UserDetails />} />
			<Route exact path='/admin/orders/:page' element={<OrdersPage />} />
			<Route exact path='/admin/orders/order_details/:page/:id' element={<OrderDetail />} />
			<Route exact path='/admin/orders/order_add/:page/:companyId' element={<AddOrder />} />
			<Route exact path='/admin/orders/order_update/:page/:id' element={<UpdateOrder />} />
			<Route exact path='/admin/orders/order_market/:page/:companyId' element={<OrderMarket />} />
			<Route exact path='/admin/orders/order_cart/:page/:companyId' element={<OrderCart />} />
			<Route exact path='/admin/deliveries/:page' element={<DeliveryPage />} />
			<Route
				exact
				path='/admin/deliveries/delivery_details/:page/:id'
				element={<DeliveryDetail />}
			/>
			<Route
				exact
				path='/admin/deliveries/delivery_confirm/:page/:id'
				element={<DeliveryConfirm />}
			/>
			<Route
				exact
				path='/admin/deliveries/delivery_update/:page/:id'
				element={<UpdateDelivery />}
			/>
			<Route
				exact
				path='/admin/deliveries/payment_details/:page/:id'
				element={<PaymentDetails />}
			/>
			<Route exact path='/admin/bills/:page' element={<BillPage />} />
			<Route exact path='/admin/bills/bill_details/:page/:id' element={<BillDetails />} />
			<Route exact path='/admin/bills/bill_update/:page/:id' element={<UpdateBill />} />
			<Route exact path='/admin/returns/:page' element={<ReturnsPage />} />
			<Route exact path='/admin/returns/return_details/:page/:id' element={<ReturnDetails />} />
			<Route exact path='/admin/returns/return_update/:id' element={<UpdateReturn />} />
			<Route exact path='/admin/complaints/:page' element={<ComplaintsPage />} />
			<Route
				exact
				path='/admin/complaints/complain_details/:page/:id'
				element={<ComplainDetails />}
			/>
			<Route exact path='*' element={<NotFound />} />
		</Routes>
	);
};

export default AdminRoutes;
