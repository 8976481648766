import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
	ClientProfile,
	UpdateClientProfile,
	CategoriesPage,
	ProductPageCLient,
	ProductCartClient,
	OrdersClientPage,
	DetailsOrderClient,
	BillDetailsClient,
	ComplaintsClient,
	ClientComplainDetails,
	UpdateClientPassword,
	BillsClient,
} from '../pages/client';
import NotFound from '../pages/NotFound';

const CustomerRoutes = () => {
	return (
		<Routes>
			<Route exact path='/' element={<ClientProfile />} />
			<Route exact path='/client/profile' element={<ClientProfile />} />
			<Route exact path='/client/profile/update' element={<UpdateClientProfile />} />
			<Route exact path='/client/profile/update_password' element={<UpdateClientPassword />} />
			<Route exact path='/client/categories/:page' element={<CategoriesPage />} />
			<Route exact path='/client/products/:page' element={<ProductPageCLient />} />
			<Route exact path='/client/products/product_cart' element={<ProductCartClient />} />
			<Route exact path='/client/orders/:page' element={<OrdersClientPage />} />
			<Route exact path='/client/orders/order_details/:page/:id' element={<DetailsOrderClient />} />
			<Route exact path='/client/bills/:page' element={<BillsClient />} />
			<Route exact path='/client/bills/bill_details/:page/:id' element={<BillDetailsClient />} />
			<Route exact path='/client/complaints/:page' element={<ComplaintsClient />} />
			<Route
				exact
				path='/client/complaints/complain_details/:page/:id'
				element={<ClientComplainDetails />}
			/>
			<Route exact path='*' element={<NotFound />} />
		</Routes>
	);
};

export default CustomerRoutes;
