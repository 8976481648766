import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '../../../api/redux/slices/Commercial';
import { icons } from '../../../assets/icons/index';
import './index.scss';
import Pagination from '../../../helpers/components/Pagination';
import { useParams } from 'react-router-dom';
import { LoaderComponent } from '../../../helpers/components/loader';

const CategoriesPageCom = () => {
	const { listCategories, total } = useSelector((state) => state.commercial);
	const params = useParams();
	const [page, setPage] = useState(Number(params.page));
	const [search, setSearch] = useState('');
	const dispatch = useDispatch();
	const token = localStorage.getItem('accesstoken');
	const [isLoding, setIsLoading] = useState(false);

	//----------------------- get all categories ---------------------------//
	const stableDispatch = useCallback(() => {
		setIsLoading(true);
		dispatch(getCategories({ token: token, page: search ? undefined : page, search }))
			.unwrap()
			.finally(() => setIsLoading(false));
	}, [dispatch, token, page, search]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [stableDispatch, token]);

	return (
		<div id='catagory-cp'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='search'
							placeholder='Rechercher ...'
							onChange={(e) => setSearch(e.target.value)}
						/>
						<icons.FiSearch className='searchicon' />
					</div>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Catégories</h1>
				</div>
			</div>
			<div className='mainContent'>
				<table className='content'>
					<thead>
						<tr className='titres'>
							<td>CATÉGORIE</td>
							<td>DESCRIPTION</td>
						</tr>
					</thead>
					<tbody>
						{isLoding ? (
							<tr>
								<td colSpan='7'>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							listCategories.map((element) => (
								<tr className='element' key={element._id}>
									<td style={{ textTransform: 'capitalize' }}>{element.designation}</td>
									<td id='last'>{element.description}</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink='/commercial/categories/' total={total} />
		</div>
	);
};

export default CategoriesPageCom;
