// import  usersReducer  from '../redux/slices/User';
import authReducer from '../redux/slices/Auth';
import superAdminReducer from '../redux/slices/SuperAdmin';
import adminReducer from '../redux/slices/Admin';
import customerReducer from '../redux/slices/Customer';
import publicReducer from '../redux/slices/Public';
import commercialReducer from '../redux/slices/Commercial';

const rootReducer = {
	auth: authReducer,
	superAdmin: superAdminReducer,
	admin: adminReducer,
	customer: customerReducer,
	commercial: commercialReducer,
	public: publicReducer,
};

export default rootReducer;
