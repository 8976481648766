import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import './index.scss';
import Container from '../container';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email address').required('Email is required'),
});

const SendEmail = () => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();

	const handleSendEmail = (data) => {
		validationSchema.validate(data).then(() => {});
	};

	return (
		<Container>
			<form onSubmit={handleSubmit(handleSendEmail)}>
				<label htmlFor='email'>Veuillez saisir votre e-mail</label>
				<Controller
					name='email'
					control={control}
					render={({ field }) => (
						<>
							<input
								type='email'
								placeholder='E-mail'
								className={`authInput ${errors.email ? 'error' : ''}`}
								{...field}
							/>
							{errors.email && <p className='error'>{errors.email.message}</p>}
						</>
					)}
				/>
				<p className='message'>Nous vous enverrons un e-mail pour confirmer</p>
				<input type='submit' value='Envoyer' className='btn' />
			</form>
		</Container>
	);
};
export default SendEmail;
