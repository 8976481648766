import React from 'react';
import { icons } from '../../assets/icons';
import StatusChangeComponent from './StatusChangeComponent';
import { CSVLink } from 'react-csv';

export const TopBar = ({
	searchValue,
	setSearchValue,
	setModalOpen,
	modalCsvexport,
	setModalCsvexport,
	buttonTitle,
	statusValue,
	setStatusValue,
	csvOrdersReport,
	isImport = false,
}) => {
	const listStatusOptions = [
		{ id: '1', label: 'ORDER', value: 'En cours' },
		{ id: '2', label: 'DELIVERY', value: 'Confirmer' },
	];

	return (
		<div className='navbar'>
			<div className='left'>
				<div className='nav-search'>
					<input
						type='text'
						placeholder='Rechercher ...'
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
					/>
					<icons.FiSearch className='searchicon' />
				</div>
				<StatusChangeComponent
					statusValue={statusValue}
					setStatusValue={setStatusValue}
					listStatusOptions={listStatusOptions}
					name='Commande'
				/>
			</div>
			<div className='right'>
				<div className='right-items'>
					{buttonTitle && (
						<button className='button-primary' onClick={() => setModalOpen(true)}>
							<icons.SiAddthis />
							<span>{buttonTitle}</span>
						</button>
					)}
					<CSVLink {...csvOrdersReport} target='blank'>
						<button className='button-nature' title='exporter commande(s)'>
							<icons.BiExport onClick={() => setModalCsvexport(!modalCsvexport)} />
						</button>
					</CSVLink>
					{isImport && (
						<button className='button-nature' title='importer commande(s)'>
							<icons.BiImport />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
