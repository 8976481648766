import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCategory, deleteCategory, getCategories } from '../../../api/redux/slices/Admin';
import { icons } from '../../../assets/icons/index';
import AddCategory from './AddCategory';
import './index.scss';
import UpdateCategory from './UpdateCategory';
import { CSVLink } from 'react-csv';
import { useParams, useNavigate } from 'react-router-dom';
import { LoaderComponent } from '../../../helpers/components/loader';
import Papa from 'papaparse';
import { DataErrorModal, showToast } from '../../../components/modal';
import Pagination from '../../../helpers/components/Pagination';
import DeleteConfirmationModal from '../addUser/ShopDeleteModal';
import useStableArr from '../../../utils/useStableArr';
import { handleCheck, handleCheckAll } from '../../../utils/checkUtilsAdmin';

const CategoryPage = () => {
	const { listCategories, checkedList, total } = useSelector((state) => state.admin);
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();

	const [modalOpen, setModalOpen] = useState(false);
	const [page, setPage] = useState(Number(params.page));

	const [addModalOpen, setAddModalOpen] = useState(false);
	const [delModalOpen, setDelModalOpen] = useState(false);
	const [lineIndice, setLineIndice] = useState(null);
	const [typing, setTyping] = useState(false);
	const [newArr, setNewArr] = useState(listCategories.map((el) => ({ ...el, checked: false })));
	const [isCheck, setIsCheck] = useState(false);

	const [categoryName, setCategoryName] = useState('');
	const token = localStorage.getItem('accesstoken');

	const [errorData, setErrorData] = useState([]);
	const [errorModalOpen, setErrorModalOpen] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	//----------------------------- retrieve a file to upload ---------------------------//
	const fileInputRef = useRef(null);
	const getfile = () => {
		fileInputRef.current.value = null;
		fileInputRef.current.click();
	};

	//------------ CSV export ----------------//
	const headers = [
		{ label: 'DESIGNATION', key: 'designation' },
		{ label: 'DESCRIPTION', key: 'description' },
	];
	const csvCategoriesReport = {
		filename: 'Catégories.csv',
		data: checkedList,
		headers: headers,
	};

	//----------------------- get all categories ---------------------------//
	const [isloading, setIsLoading] = useState(false);
	const stableDispatch = useCallback(() => {
		setIsLoading(true);
		dispatch(
			getCategories({ token: token, name: categoryName, page: categoryName ? undefined : page }),
		)
			.catch(() => showToast("Quelque chose s'est mal passé !", 'error'))
			.finally(() => setIsLoading(false));
	}, [dispatch, page, categoryName, token]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [stableDispatch, addModalOpen, modalOpen, token, navigate]);

	// --------------- clone the list of categories into the new array ---------------//
	useStableArr(listCategories, checkedList, setNewArr, setIsCheck);

	// ------------------------- Handle Delete selected categories ---------------------------------//
	const handleDeleteSelected = () => {
		const categoriesToDelete = newArr.filter((category) => category.checked);

		if (categoriesToDelete.length === 0) {
			return;
		}

		Promise.all(
			categoriesToDelete.map((category) =>
				dispatch(deleteCategory({ token: token, id: category._id })),
			),
		).then(() => {
			stableDispatch();
			showToast('Categories supprimées', 'infos');
		});
	};

	//------------ CSV impot ----------------//
	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];

		if (selectedFile) {
			const reader = new FileReader();

			reader.onload = async ({ target }) => {
				const csv = Papa.parse(target.result, {
					header: true,
				});
				const parsedData = csv?.data;
				const errors = [];

				const promises = parsedData.reduce((accumulator, values) => {
					const data = {
						description: values.DESCRIPTION,
						designation: values.DESIGNATION,
					};

					if (data.description && data.designation) {
						accumulator.push(
							dispatch(addCategory({ token: token, data }))
								.unwrap()
								.catch((err) => {
									errors.push({
										Designation: data.designation,
										error: err?.error || err?.message,
									});
								}),
						);
					}

					return accumulator;
				}, []);

				await Promise.all(promises);

				if (errors.length > 0) {
					setErrorData(errors);
					setErrorModalOpen(true);
				} else showToast('Categories ajoutées', 'success');

				stableDispatch();
			};

			reader.readAsText(selectedFile);
		}
	};

	const handleDelete = () => {
		dispatch(deleteCategory({ token: token, id: lineIndice }))
			.unwrap()
			.then(() => {
				showToast('Categorie supprimé', 'info');
				stableDispatch();
			});
	};

	//------------------------------------------------- Component -------------------------------------//
	return (
		<div id='categoryPage'>
			{showDeleteModal && (
				<DeleteConfirmationModal
					onDelete={() => {
						handleDeleteSelected();
						setShowDeleteModal(false);
					}}
					onCancel={() => setShowDeleteModal(false)}
					msg={
						checkedList.length > 1
							? 'Êtes-vous sûr de vouloir supprimer toutes les catégories sélectionnées ?'
							: 'Êtes-vous sûr de vouloir supprimer cette catégorie ?'
					}
				/>
			)}
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='text'
							placeholder='Rechercher ...'
							value={categoryName}
							onChange={(e) => {
								setCategoryName(e.target.value);
								setTyping(true);
							}}
						/>
						<icons.FiSearch className='searchicon' />
						{typing && (
							<icons.GrFormClose
								className='searchicon'
								onClick={() => {
									setCategoryName('');
									setTyping(false);
								}}
							/>
						)}
					</div>
				</div>
				<div className='right'>
					<div className='right-items'>
						<button className='button-primary' onClick={() => setAddModalOpen(!addModalOpen)}>
							<icons.SiAddthis />
							<span>Catégorie</span>
						</button>
						<CSVLink {...csvCategoriesReport} target='blank'>
							<button className='button-nature' title='exporter category'>
								<icons.BiExport />
							</button>
						</CSVLink>
						<button className='button-nature' title='importer category' onClick={getfile}>
							<icons.BiImport />
							<input
								type='file'
								id='fileuploading'
								ref={fileInputRef}
								style={{ display: 'none' }}
								accept='.csv'
								onChange={handleFileChange}
							/>
						</button>
					</div>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<div>
						<h1>Catégories</h1>
					</div>
					<div
						className='deluser'
						onClick={() => {
							if (checkedList.length > 0) {
								setShowDeleteModal(true);
							} else showToast('Choisissez au moins une catégorie.', 'warn');
						}}
					>
						<icons.FaTrashAlt className='del-cat' />
					</div>
				</div>
			</div>
			<AddCategory addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} />
			{modalOpen && (
				<UpdateCategory modalOpen={modalOpen} setModalOpen={setModalOpen} lineIndice={lineIndice} />
			)}
			{delModalOpen && (
				<DeleteConfirmationModal
					onDelete={() => {
						handleDelete();
						setDelModalOpen(false);
					}}
					onCancel={() => setDelModalOpen(false)}
					msg={'Êtes-vous sûr de vouloir supprimer cette catégorie ?'}
				/>
			)}
			<div id='categoriesContent'>
				<table id='categories'>
					<thead>
						<tr id='titres'>
							<td className='input-check'>
								<input
									type='checkbox'
									className='checkbox'
									checked={isCheck}
									onChange={() => handleCheckAll(dispatch, newArr, setNewArr, isCheck, setIsCheck)}
								/>
							</td>
							<td>CATÉGORIE</td>
							<td>DESCRIPTION</td>
							<td>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{isloading ? (
							<tr>
								<td colSpan='7'>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							newArr.map((category, index) => (
								<tr id={`element-${index}`} key={category._id} className='product'>
									<td className='input-check'>
										<input
											type='checkbox'
											checked={category.checked}
											className='checkbox'
											onChange={() => handleCheck(dispatch, newArr, setNewArr, category._id)}
										/>
									</td>
									<td>{category.designation}</td>
									<td id='category-desc'>{category.description}</td>
									<td className='last-user-element'>
										<icons.FaRegEdit
											className='edit-cat'
											onClick={() => {
												setModalOpen(true);
												setLineIndice(category._id);
											}}
										/>
										<icons.FaTrashAlt
											className='del-cat'
											onClick={() => {
												setDelModalOpen(true);
												setLineIndice(category._id);
											}}
										/>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink={`/admin/categories/`} total={total} />
			<DataErrorModal
				isOpen={errorModalOpen}
				closeModal={() => setErrorModalOpen(false)}
				errorData={errorData}
				width='35rem'
				height='auto'
			/>
		</div>
	);
};

export default CategoryPage;
