import React, { useEffect, useState } from 'react';
import './index.scss';
import EditProfile from './EditProfile';
import EditPassword from './EditPassword';
import { useSelector } from 'react-redux';
import { RotatingSquare } from 'react-loader-spinner';

const ProfilePage = () => {
	const { user } = useSelector((state) => state.auth);
	const { authenticated } = useSelector((state) => state.auth);
	const [infos, setInfos] = useState(user.data);

	const [pageSelected, setPageSelected] = useState('');

	useEffect(() => {
		setInfos(user.data);
	}, [user.data, setPageSelected]);

	if (!authenticated) {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					margin: '20%',
				}}
			>
				<RotatingSquare
					ariaLabel='rotating-square'
					visible={true}
					color='green'
					id='login-spinner'
				/>
			</div>
		);
	}
	const resetPage = () => {
		setPageSelected('');
	};

	return (
		<div id='profilePage'>
			<div
				className='navbar'
				style={{
					display: `${
						pageSelected === 'editProfile' || pageSelected === 'editPassword' ? 'none' : ''
					}`,
				}}
			>
				<div className='left'>
					<h1>Profile</h1>
				</div>
				<div className='right'>
					<div className='right-items'>
						<button
							style={{ border: 'none' }}
							onClick={() => setPageSelected('editPassword')}
							className='button-nature'
						>
							Changer le mot de passe
						</button>
						<button onClick={() => setPageSelected('editProfile')} className='button-primary'>
							Modifier le profile
						</button>
					</div>
				</div>
			</div>
			<div className='container'>
				<div className='main'>
					{pageSelected === 'editProfile' ? (
						<EditProfile resetPage={resetPage} infos={infos} />
					) : pageSelected === 'editPassword' ? (
						<EditPassword resetPage={resetPage} />
					) : (
						<div
							className={
								pageSelected === 'editProfile' || pageSelected === 'editPassword'
									? 'section active'
									: 'section'
							}
						>
							<div className='infos'>
								<h2>Information générale</h2>
								<div className='line'>
									<h4>Nom</h4>
									<span>{infos.first_name}</span>
								</div>
								<div className='line'>
									<h4>Prénom</h4>
									<span>{infos.last_name}</span>
								</div>
								<div className='line'>
									<h4>Adresse e-mail</h4>
									<span>{infos.email}</span>
								</div>
								<div className='line'>
									<h4>Numéro de téléphone</h4>
									<span>{infos.phone}</span>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProfilePage;
