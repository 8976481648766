import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { icons } from '../../../assets/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './index.scss';
import useOnclickOutside from 'react-cool-onclickoutside';
import {
	clientsOrderListByAdmin,
	deleteOrderByAdmin,
	getOrdersByAdmin,
} from '../../../api/redux/slices/Admin';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { convertDate, generateId } from '../../../helpers/modules';
import { TopBar } from '../../../components/navbar';
import { ButtonComponent } from '../../../components/buttons';
import { LoaderComponent } from '../../../helpers/components/loader';
import Pagination from '../../../helpers/components/Pagination';
import ModalConfirmDelivery from './ModalConfirmDelivery';
import DeleteConfirmationModal from '../addUser/ShopDeleteModal';
import useStableArr from '../../../utils/useStableArr';
import { showToast } from '../../../components/modal';
import { handleCheck, handleCheckAll } from '../../../utils/checkUtilsAdmin';

const OrdersPage = () => {
	const { listOrders, checkedList, total } = useSelector((state) => state.admin);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const token = localStorage.getItem('accesstoken');

	const [orderOpenDropdown, setOrderOpenDropdown] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [indiceOrder, setIndiceOrder] = useState(null);
	const [modalCsvexport, setModalCsvexport] = useState(false);
	const [page, setPage] = useState(Number(params.page));
	const [searchValue, setSearchValue] = useState('');
	const [statusValue, setStatusValue] = useState('');
	const [searchValueCompanies, setSearchValueCompanies] = useState('');
	const [isCheck, setIsCheck] = useState(false);
	const [newArr, setNewArr] = useState(
		listOrders.map((el) => ({ ...el, checked: false, custom_id: generateId() })),
	);

	const [filteredData, setFilteredData] = useState([]);
	const [choosed, setChoosed] = useState(false);
	const [user, setUser] = useState(null);
	const [loadingCompanies, setLoadingCompanies] = useState(false);
	const ref = useOnclickOutside(() => {
		setOrderOpenDropdown(false);
		setModalOpen(false);
		setSearchValueCompanies('');
	});

	const [isOpen, setIsOpen] = useState(false);
	const [idxConfirmDelviry, setIdxConfirmDelviry] = useState(null);
	const [createdId, setCreatedId] = useState(null);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteIndex, setDeleteIndex] = useState(null);

	//------------------------------ get list orders ------------------------//
	const [isloading, setIsLoading] = useState(false);

	const stableDispatch = useCallback(async () => {
		setIsLoading(true);
		dispatch(
			getOrdersByAdmin({
				token: token,
				page: searchValue || statusValue ? undefined : page,
				search: searchValue,
				status: statusValue,
			}),
		).finally(() => setIsLoading(false));
	}, [dispatch, page, token, searchValue, statusValue]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [stableDispatch, token, isOpen]);

	//------------------------------ get list companies ------------------------//
	const stableDispatchCompanies = useCallback(() => {
		if (token && searchValueCompanies !== '') {
			localStorage.removeItem('cart');
			localStorage.removeItem('order');
			localStorage.removeItem('company');
			setLoadingCompanies(true);
			dispatch(
				clientsOrderListByAdmin({
					token: token,
					value: searchValueCompanies,
				}),
			)
				.unwrap()
				.then((res) =>
					setFilteredData(
						res.data.list.filter((value) => {
							return value.company_name.toLowerCase().match(searchValueCompanies.toLowerCase());
						}),
					),
				)
				.finally(() => setLoadingCompanies(false));
		} else {
			setFilteredData([]);
		}
	}, [dispatch, token, searchValueCompanies]);

	useEffect(() => {
		stableDispatchCompanies();
	}, [stableDispatchCompanies, token, searchValueCompanies]);

	// --------------- clone the list of orders into the new array ---------------//
	useStableArr(listOrders, checkedList, setNewArr, setIsCheck);

	//-------------------------- Redirect to modify order -----------------//
	const modifyOrder = (id) => {
		navigate(`/admin/orders/order_update/${params.page}/${id}`);
	};
	//-------------------------------- CSV export ---------------------------//
	const headers = [
		{ label: 'NUMERO', key: 'number' },
		{ label: 'REFERENCE', key: 'reference' },
		{ label: 'DATE', key: 'date' },
		{ label: 'TIMBRE', key: 'timbre' },
		{ label: 'TOTAL', key: 'total' },
		{ label: 'TOTAL HT', key: 'total_HT' },
		{ label: 'TVA', key: 'tva' },
		{ label: 'CATEGORIE', key: 'clientInfo.categories' },
		{ label: 'NUMERO DE TEL', key: 'clientInfo.phone' },
		{ label: 'UTILISATEUR', key: 'clientInfo.client' },
		{ label: 'SOCIETE', key: 'clientInfo.company_name' },
	];
	const csvOrdersReport = {
		filename: 'Commandes.csv',
		data: checkedList,
		headers: headers,
	};
	//----------------------- DELETE SINGLE ORDER -------------------------------------//
	const handleDeleteOneOrder = (deleteIndex) => {
		dispatch(deleteOrderByAdmin({ token: token, id: deleteIndex }))
			.unwrap()
			.then(() => {
				setOrderOpenDropdown(false);
				dispatch(
					getOrdersByAdmin({
						token: token,
						page: page,
						search: searchValue,
						status: statusValue,
					}),
				);
				toast.success('Commande supprimé', {
					position: 'top-center',
					autoClose: 1000,
				});
			})
			.catch((err) => {
				toast.warn(err.message, {
					position: 'top-center',
					autoClose: 1000,
				});
			});
	};
	//-------------------------- DELETE CHECKED ORDERS ----------------------------//
	const deleteAllChecked = async () => {
		const isConfirm = checkedList.find((el) => el.status !== 'ORDER');
		if (checkedList.length === 0) {
			showToast('Veuillez selectionner une commande', 'warn');
			return;
		}
		if (isConfirm) {
			showToast('Commande confirmée, non supprimable.', 'warn');
			return;
		}
		try {
			const deletePromises = checkedList.map(async (order) => {
				await dispatch(deleteOrderByAdmin({ token: token, id: order._id })).unwrap();
			});

			await Promise.all(deletePromises);

			dispatch(
				getOrdersByAdmin({
					token: token,
					page: page,
					search: searchValue,
				}),
			);

			toast.info('Commandes supprimés', {
				position: 'top-center',
				autoClose: 1000,
			});
		} catch (err) {
			toast.error(err.message, {
				position: 'top-center',
				autoClose: 1000,
			});
		}
	};

	//-------------- redirect to the order market -----------------------//
	const redirectMarket = (e) => {
		e.preventDefault();
		if (user?.company_name && choosed) {
			localStorage.setItem('company', JSON.stringify(user));
			navigate(`/admin/orders/order_market/${page}/${user._id}`);
		} else showToast('Veuillez choisir une société', 'warn');
	};

	return (
		<div id='orderPageAdmin'>
			{isOpen && (
				<ModalConfirmDelivery
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					idxConfirmDelviry={idxConfirmDelviry}
					createdId={createdId}
				/>
			)}
			{showDeleteModal && (
				<DeleteConfirmationModal
					onDelete={() => {
						if (deleteIndex !== null) {
							handleDeleteOneOrder(deleteIndex);
						} else {
							deleteAllChecked();
						}
						setShowDeleteModal(false);
					}}
					onCancel={() => setShowDeleteModal(false)}
					msg={`${
						deleteIndex !== null || checkedList.length === 1
							? 'Êtes-vous sûr de vouloir supprimer cette commande ?'
							: 'Êtes-vous sûr de vouloir supprimer toutes les commandes sélectionnées ?'
					}`}
				/>
			)}
			<TopBar
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				modalCsvexport={modalCsvexport}
				setModalOpen={setModalOpen}
				setModalCsvexport={setModalCsvexport}
				buttonTitle={'Commande'}
				statusValue={statusValue}
				setStatusValue={setStatusValue}
				csvOrdersReport={csvOrdersReport}
			/>
			<div id='head'>
				<div>
					<h1>Bons de commandes</h1>
				</div>
				<div
					className='deluser'
					onClick={() => {
						if (checkedList.length > 0) {
							setShowDeleteModal(true);
						} else showToast('Choisissez au moins une commande.', 'warn');
					}}
				>
					<icons.FaTrashAlt className='trash' />
				</div>
			</div>
			<div className={'modalcontainer ' + (modalOpen && 'open')}>
				<div className={'modal ' + (modalOpen && 'active')} ref={ref}>
					<form onSubmit={redirectMarket}>
						<div className='field'>
							<label>Pour qui cette commande ?</label>
							<input
								type='text'
								placeholder='Entrer le nom'
								value={searchValueCompanies}
								onChange={(e) => {
									setSearchValueCompanies(e.target.value);
									setChoosed(false);
								}}
							/>
							<div className='dynamicData'>
								{!choosed &&
									filteredData.slice(0, 5).map((val, key) => {
										return (
											<Fragment key={key}>
												<span
													onClick={() => {
														setSearchValueCompanies(val.company_name);
														setChoosed(true);
														setUser(val);
													}}
												>
													{val.company_name}{' '}
													<span style={{ color: 'grey', fontSize: '0.8rem' }}>
														{val.tax_registration_number}
													</span>
												</span>
											</Fragment>
										);
									})}
							</div>
						</div>
						<div className='modalbtn' style={{ gap: '1rem' }}>
							<ButtonComponent
								className='button-nature'
								onClick={(e) => {
									e.preventDefault();
									setModalOpen(!modalOpen);
								}}
								type='button'
								name={'Annuler'}
							/>
							<ButtonComponent
								name={'Continuer'}
								className='button-primary'
								type='submit'
								loading={loadingCompanies}
							/>
						</div>
					</form>
				</div>
			</div>
			<div id='ordersContentAdmin'>
				<table id='orders'>
					<thead>
						<tr id='titres'>
							<td className='input-check'>
								<input
									type='checkbox'
									className='checkbox'
									checked={isCheck}
									onChange={() => handleCheckAll(dispatch, newArr, setNewArr, isCheck, setIsCheck)}
								/>
							</td>
							<td>NUMÉRO</td>
							<td>DATE</td>
							<td>CLIENT</td>
							<td>SOCIÉTÉ</td>
							<td>CRÉÉR PAR</td>
							<td>TOTAL</td>
							<td className='center'>STATUT</td>
							<td>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{isloading ? (
							<tr>
								<td colSpan='7'>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							newArr?.map((order, index) => (
								<tr className='order' key={index}>
									<td className='input-check'>
										<input
											type='checkbox'
											className='checkbox'
											checked={order.checked}
											onChange={() => handleCheck(dispatch, newArr, setNewArr, order._id)}
										/>
									</td>
									<td style={{ textTransform: 'uppercase' }}>{order?.reference || '-'}</td>
									<td>{order?.date ? convertDate(order?.date, 'DD-MM-YYYY') : '-'}</td>
									<td>{order?.clientInfo?.client || '-'}</td>
									<td>{order?.clientInfo?.company_name || '-'}</td>
									<td>{order?.created_By || '-'}</td>
									<td>{order?.total || '-'} DT</td>
									<td className={`status ${order?.status === 'ORDER' ? 'en-cours' : 'confirmer'}`}>
										{order?.status === 'ORDER' ? 'en cours' : 'confirmé'}
									</td>
									<td className='last-order-element'>
										<Link
											to={`/admin/orders/order_details/${page}/${order._id}`}
											className='link-order'
										>
											<icons.BsEye className='see-order' />
										</Link>
										<div className='contain'>
											<icons.BsThreeDotsVertical
												onClick={() => {
													setOrderOpenDropdown(!orderOpenDropdown);
													setIndiceOrder(order._id);
												}}
												className='order-option'
											/>
											{orderOpenDropdown &&
												indiceOrder === order._id &&
												order?.status === 'ORDER' && (
													<div className='modalSelectOpt' ref={ref}>
														<ul>
															<li onClick={() => modifyOrder(order?._id)}>Modifier commande</li>

															<li
																onClick={() => {
																	setIsOpen(true);
																	setIdxConfirmDelviry(order?._id);
																	order?.role_created_By === 'commercial' &&
																		setCreatedId(order?.created_id);
																}}
															>
																Faites-en une livraison
															</li>
															<li
																onClick={() => {
																	setDeleteIndex(order?._id);
																	setShowDeleteModal(true);
																}}
															>
																Supprimer commande
															</li>
														</ul>
													</div>
												)}
										</div>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink={'/admin/orders/'} total={total} />
		</div>
	);
};

export default OrdersPage;
