import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { toast } from 'react-toastify';
import { updateCommercialPassword } from '../../../api/redux/slices/Commercial';
import { useDispatch } from 'react-redux';
import { accesstoken } from '../../../helpers/modules';
import { handleLogout } from '../../navigation';
import { icons } from '../../../assets/icons';
import { ButtonComponent } from '../../../components/buttons';
import { showToast } from '../../../components/modal';
import PasswordInput from '../../../components/passwordInput';

const UpdateComPassword = () => {
	const dispatch = useDispatch();
	const [isLoading, setIsloading] = useState(false);

	const [values, setValues] = useState({
		oldPassword: '',
		newPassword: '',
		confirmPassword: '',
	});

	const handleUpdate = (e) => {
		e.preventDefault();
		const data = {
			oldPassword: values.oldPassword,
			newPassword: values.newPassword,
			confirmPassword: values.confirmPassword,
		};
		if (!data.oldPassword || !data.newPassword || !data.confirmPassword) {
			showToast('Veuillez remplir tous les champs', 'warn');
			return;
		}
		if (data.newPassword !== data.confirmPassword) {
			showToast('Les mots de passe ne sont pas identiques', 'warn');
			return;
		}
		setIsloading(true);
		dispatch(updateCommercialPassword({ token: accesstoken, data: data }))
			.unwrap()
			.then((res) => {
				toast.success(res.message, {
					position: 'top-center',
					autoClose: 2000,
				});
				handleLogout(dispatch, accesstoken);
			})
			.catch((err) => {
				showToast(err?.message || err, 'error');
			})
			.finally(() => setIsloading(false));
	};

	return (
		<div id='updatePassword'>
			<div id='box'>
				<form onSubmit={handleUpdate}>
					<div className='navbar' style={{ width: 'calc(100vw - 20rem)', marginBottom: '2rem' }}>
						<Link
							to='/commercial/profile'
							style={{ width: '500px', display: 'flex', alignItems: 'center' }}
						>
							<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
							<h1>Profile</h1>
						</Link>
						<div className='submit-btns'>
							<div className='add-btns'>
								<Link to='/commercial/profile'>
									<button className='button-nature'>Annuler</button>
								</Link>
								<ButtonComponent
									name='Enregister'
									loading={isLoading}
									type='submit'
									className='button-primary'
								/>
							</div>
						</div>
					</div>
					<div className='label-input' style={{ position: 'relative' }}>
						<label>Entrer le mot de passe actuel</label>
						<PasswordInput
							id={'oldPassword'}
							placeholder='Entrer le mot de passe actuel'
							value={values.oldPassword}
							onChange={(e) => {
								setValues({ ...values, oldPassword: e.target.value });
							}}
							marginBottom='22%'
						/>
					</div>
					<div className='label-input' style={{ position: 'relative' }}>
						<label>Entrer le mot de passe actuel</label>
						<PasswordInput
							id={'newPassword'}
							placeholder='Entrer le nouveau mot de passe'
							value={values.newPassword}
							onChange={(e) => {
								setValues({ ...values, newPassword: e.target.value });
							}}
							marginBottom='22%'
						/>
					</div>
					<div className='label-input' style={{ position: 'relative' }}>
						<label>Confirmer le nouveau mot de passe</label>
						<PasswordInput
							id={'confirmPassword'}
							placeholder='Confirmer le nouveau mot de passe'
							value={values.confirmPassword}
							onChange={(e) => {
								setValues({ ...values, confirmPassword: e.target.value });
							}}
							marginBottom='22%'
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default UpdateComPassword;
