import { setCheckedList, setUnCheckedList } from '../api/redux/slices/Admin';

export const handleCheckAll = (dispatch, newArr, setNewArr, isCheck, setIsCheck) => {
	const updatedNewArr = newArr.map((product) => {
		const updatedProduct = {
			...product,
			checked: !isCheck,
		};
		isCheck ? dispatch(setUnCheckedList(product)) : dispatch(setCheckedList(product));

		return updatedProduct;
	});

	setNewArr(updatedNewArr);
	setIsCheck(!isCheck);
};

export const handleCheck = (dispatch, newArr, setNewArr, id) => {
	const updatedNewArr = newArr.map((product) => {
		if (product._id === id) {
			const updatedProduct = {
				...product,
				checked: !product.checked,
			};
			product.checked ? dispatch(setUnCheckedList(product)) : dispatch(setCheckedList(product));

			return updatedProduct;
		}
		return product;
	});

	setNewArr(updatedNewArr);
};
