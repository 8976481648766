import React, { useEffect, useState } from 'react';
import { BsPlusSquareDotted, BsTrash } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { addVehicle, deleteVehicle } from '../../../api/redux/slices/Admin';
import { showToast } from '../../../components/modal';
import DeleteConfirmationModal from '../addUser/ShopDeleteModal';
import { ClipLoader } from 'react-spinners';

const ListVehicles = ({ id, listVehicles }) => {
	const [vehicles, setVehicles] = useState([]);
	const dispatch = useDispatch();
	const token = localStorage.getItem('accesstoken');
	const [selectedVehicle, setSelectedVehicle] = useState(null);
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (listVehicles) {
			const newlistVehicles = listVehicles.map((el) => ({
				...el,
				isAdded: true,
			}));
			setVehicles(newlistVehicles);
		}
	}, [listVehicles]);
	const handleAddVehicle = () => {
		const newVehicle = { vehicle_number: '' };
		setVehicles((prevVehicles) => [...prevVehicles, newVehicle]);
	};

	const handleDeleteVehicle = (index, id) => {
		const exist = vehicles.filter((item) => item.isAdded === true);
		if (exist.length === 1) {
			showToast('Au moins un véhicule doit être présent', 'error');
		} else {
			setSelectedVehicle({ index, id });
			setDeleteModalOpen(true);
		}
	};

	const handleConfirmDelete = () => {
		if (selectedVehicle) {
			const { index, id } = selectedVehicle;
			setIsLoading(true);
			dispatch(deleteVehicle({ token, id }))
				.unwrap()
				.then(() => {
					showToast('Véhicule supprimé avec succès', 'success');
					setVehicles((prevVehicles) => prevVehicles.filter((_, i) => i !== index));
					setDeleteModalOpen(false);
				})
				.catch((err) => {
					showToast(err.message, 'error');
					setDeleteModalOpen(false);
				})
				.finally(() => setIsLoading(false));
		}
	};

	const handleCancelDelete = () => {
		setSelectedVehicle(null);
		setDeleteModalOpen(false);
	};

	const handleConfirm = (vehicle_number) => {
		if (vehicle_number.trim() !== '') {
			setIsLoading(true);
			dispatch(
				addVehicle({
					token,
					data: { commercial_id: id, vehicle_number: vehicle_number.trim() },
				}),
			)
				.unwrap()
				.then((res) => {
					const data = res.data;
					showToast('Véhicule ajouté avec succès', 'success');
					setVehicles((prevVehicles) =>
						prevVehicles.map((v) =>
							v.vehicle_number === vehicle_number ? { ...data, isAdded: true } : v,
						),
					);
				})
				.catch((err) => {
					showToast(err.message, 'error');
				})
				.finally(() => setIsLoading(false));
		} else {
			showToast('Veuillez entrer le numéro de véhicule', 'error');
		}
	};

	const handleChange = (e, index) => {
		const { value } = e.target;
		setVehicles((prevVehicles) => {
			const updatedVehicles = [...prevVehicles];
			updatedVehicles[index].vehicle_number = value;
			return updatedVehicles;
		});
	};

	const handleAnnuler = (index) => {
		setVehicles((prevVehicles) => prevVehicles.filter((_, i) => i !== index));
	};
	return (
		<div className='vehicule'>
			<div className='add-shop-title'>
				<h2>Liste des véhicules</h2>
				<BsPlusSquareDotted
					style={{ fontSize: '1.3rem', cursor: 'pointer' }}
					title='ajouter une nouvelle boutique'
					onClick={handleAddVehicle}
				/>
			</div>
			{vehicles.map((inputValue, index) => (
				<div className='shop' key={index}>
					<div className='del-shop'>
						{inputValue.isAdded && (
							<div className='box-bordered' title='supprimer cette boutique'>
								<BsTrash
									className='del-shop-icon'
									onClick={() => handleDeleteVehicle(index, inputValue._id)}
								/>
							</div>
						)}
					</div>
					<label>Matricule de véhicule</label>
					<input
						type='text'
						placeholder='Matricule de véhicule'
						className='vehicule'
						name='vehicule'
						value={inputValue.vehicle_number}
						disabled={inputValue.isAdded}
						onChange={(e) => handleChange(e, index)}
					/>
					{!inputValue.isAdded && (
						<div className='vehicule-btns'>
							<button
								onClick={() => handleAnnuler(index)}
								className='button-nature annuler-vehicule'
							>
								Annuler
							</button>
							<button
								className='button-primary confirmer-vehicule'
								onClick={() => handleConfirm(inputValue.vehicle_number)}
							>
								{isLoading ? (
									<ClipLoader color='#316161' loading={isLoading} size={20} />
								) : (
									'Confirmer'
								)}
							</button>
						</div>
					)}
				</div>
			))}
			{isDeleteModalOpen && (
				<DeleteConfirmationModal
					onDelete={handleConfirmDelete}
					onCancel={handleCancelDelete}
					isLoading={isLoading}
				/>
			)}
		</div>
	);
};

export default ListVehicles;
