import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
	getCategoriesForProducts,
	getProductDetails,
	updateProductByAdmin,
} from '../../../api/redux/slices/Admin';
import { ButtonComponent } from '../../../components/buttons';

import { imageApi } from '../../../api/redux/slices/Public';
import { icons } from '../../../assets/icons';
import './index.scss';
import Avatar from '../../../components/avatar';
import { showToast } from '../../../components/modal';
import { LoaderComponent } from '../../../helpers/components/loader';

const UpdateProduct = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(false);

	const { listCategoriesProducts } = useSelector((state) => state.admin);

	const [values, setValues] = useState({});
	const [fileUploaded, setFileUploaded] = useState({
		file: '',
		url: '',
	});

	const token = localStorage.getItem('accesstoken');
	//---------------------- list of available categories a retrieving the product list for filtering purposes -----------------------//
	const stableDispatchCat = useCallback(() => {
		setLoading(true);
		dispatch(getProductDetails({ token: token, id: params.id }))
			.unwrap()
			.then((res) => {
				const { category, ...rest } = res.data;
				setValues({ ...rest, category: category._id });
				setFileUploaded({
					file: '',
					url: res.data?.image?.secure_url,
				});
			})
			.finally(() => setLoading(false));
		dispatch(getCategoriesForProducts({ token: token }));
	}, [dispatch, token, params.id]);

	useEffect(() => {
		if (token) {
			stableDispatchCat();
		}
	}, [stableDispatchCat, token]);

	// ----------------------------------------- Upload the image -----------------------------------//
	const pushUpdateWithImg = (fileUploaded, productValues) => {
		let formData = new FormData();
		formData.append('image', fileUploaded.file);

		setIsLoading(true);
		dispatch(imageApi({ token: token, formDta: formData }))
			.unwrap()
			.then((res) => {
				pushUpdateProduct(productValues, res);
			});
	};
	const pushUpdateProduct = (productValues, imgBlock) => {
		const data = {
			...productValues,
			image: imgBlock,
		};
		setIsLoading(true);
		dispatch(updateProductByAdmin({ token: token, id: params.id, data: data }))
			.unwrap()
			.then(() => {
				showToast('Produit mis à jour', 'success');
				setTimeout(() => {
					navigate(`/admin/products/${params.page}`);
				}, 1000);
			})
			.catch((err) => {
				showToast(err.message, 'error');
			})
			.finally(() => setIsLoading(false));
	};
	// ---------------------- dipatching add product -------------------------//
	const handleUpdateProduct = (e) => {
		e.preventDefault();
		const prices = {
			prise_HT: Number(values.prise_HT),
			Retail_Price: Number(values.Retail_Price),
			Wholesale_Price: Number(values.Wholesale_Price),
			Price_of_UMD: Number(values.Price_of_UMD),
			Monoprix_Price: Number(values.Monoprix_Price),
			Price_of_Black: Number(values.Price_of_Black),
		};
		const pricesIsValid = Object.values(prices).every((value) => value !== 0);

		if (!pricesIsValid) {
			showToast('Le prix du produit doit être supérieur à 0.', 'warn');
			return;
		}

		if (values.capacity <= 0) {
			showToast('La capacité doit être supérieure à 0.', 'warn');
			return;
		}
		if (values.nb_cardboard_piece <= 0) {
			showToast('Le nombre de carton doit être supérieure à 0.', 'warn');
			return;
		}
		const productValues = {
			designation: values.designation.trim(),
			description: values.description.trim(),
			category: values.category,
			reference: values.reference.trim(),
			barCode: values.barCode.trim(),
			capacity: parseInt(values.capacity),
			nb_cardboard_piece: parseInt(values.nb_cardboard_piece),
			color: values.color.trim(),
			material: values.material.trim(),
			Tva: values.Tva,
			stamp: 1,
			...prices,
		};
		if (
			!productValues.designation ||
			!productValues.description ||
			!productValues.reference ||
			!productValues.barCode ||
			!productValues.color ||
			!productValues.material
		) {
			showToast('Veuillez remplir tous les champs', 'warn');
			return;
		}

		if (fileUploaded?.file !== '') {
			pushUpdateWithImg(fileUploaded, productValues);
		} else {
			pushUpdateProduct(productValues, values?.image);
		}
	};

	const handleChangeNonNegative = (e) => {
		const { name, value } = e.target;
		const sanitizedValue = value.replace(/[^\d.]/g, '');

		const parts = sanitizedValue.split('.');
		const integerPart = parts[0];
		const decimalPart = parts.length > 1 ? `.${parts[1]}` : '';

		const finalValue = `${Math.max(0, integerPart)}${decimalPart}`;

		setValues({ ...values, [name]: finalValue });
	};

	if (loading) {
		return <LoaderComponent />;
	}
	return (
		<div id='add-product'>
			<div className='add-product-header'>
				<Link to={`/admin/products/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Produits</h1>
				</Link>
			</div>
			<form onSubmit={handleUpdateProduct}>
				<div className='section'>
					<div className='field-image-upload'>
						<label>Image du produit</label>
						<Avatar fileUploaded={fileUploaded} setFileUploaded={setFileUploaded} width='100%' />
					</div>
					<div className='field'>
						<label>Désignation</label>
						<input
							type='text'
							required
							value={values.designation}
							onChange={(e) => setValues({ ...values, designation: e.target.value })}
							placeholder='désignation'
						/>
					</div>
					<div className='info-dropdown'>
						<select
							value={values.category}
							required
							onChange={(e) => setValues({ ...values, category: e.target.value })}
						>
							<option value={''}>Choisir la categorie</option>
							{listCategoriesProducts.map((cat) => (
								<Fragment key={cat._id}>
									<option value={cat._id}>{cat.designation}</option>
								</Fragment>
							))}
						</select>
					</div>
					<div className='description-area'>
						<label>Description</label>
						<textarea
							required
							cols='3'
							rows='3'
							placeholder='Ecrire la description du produit ici'
							className='desc-product'
							value={values.description}
							onChange={(e) => setValues({ ...values, description: e.target.value })}
						/>
					</div>
					<div className='field'>
						<label>Référence</label>
						<input
							type='text'
							required
							value={values.reference}
							onChange={(e) => setValues({ ...values, reference: e.target.value })}
							placeholder='référence'
						/>
					</div>
					<div className='field'>
						<label>Code à barre</label>
						<input
							type='text'
							value={values.barCode}
							onChange={(e) => setValues({ ...values, barCode: e.target.value })}
							placeholder='code à barre'
							required
						/>
					</div>
					<div className='field'>
						<label htmlFor=''>Contenance</label>
						<input
							type='text'
							value={values.capacity}
							required
							name='capacity'
							onChange={handleChangeNonNegative}
							onWheel={(e) => e.currentTarget.blur()}
							placeholder='Contenance'
						/>
					</div>
					<div className='field'>
						<label htmlFor=''>Nb-pièce-carton</label>
						<input
							type='text'
							value={values.nb_cardboard_piece}
							required
							name='nb_cardboard_piece'
							onChange={handleChangeNonNegative}
							placeholder='1.00'
							onWheel={(e) => e.currentTarget.blur()}
						/>
					</div>
					<div className='field'>
						<label htmlFor=''>couleur</label>
						<input
							type='text'
							value={values.color}
							required
							onChange={(e) => setValues({ ...values, color: e.target.value })}
							placeholder='ex: noir'
						/>
					</div>
					<div className='field'>
						<label htmlFor=''>Matière</label>
						<input
							type='text'
							value={values.material}
							required
							onChange={(e) => setValues({ ...values, material: e.target.value })}
							placeholder='ex: plastique'
						/>
					</div>
				</div>
				<div className='section'>
					<div className='field'>
						<label htmlFor=''>Prix HT</label>
						<input
							required
							type='text'
							value={values.prise_HT}
							name='prise_HT'
							onChange={handleChangeNonNegative}
							placeholder='0.000 DT'
							onWheel={(e) => e.currentTarget.blur()}
						/>
					</div>
					<div className='info-dropdown'>
						<select
							required
							value={values.Tva}
							onChange={(e) => setValues({ ...values, Tva: e.target.value })}
							onBlur={(e) => e.currentTarget.blur()}
						>
							<option value='' disabled>
								Select TVA
							</option>
							<option value='0'>0%</option>
							<option value='7'>7%</option>
							<option value='13'>13%</option>
							<option value='19'>19%</option>
						</select>
					</div>
					<div className='field'>
						<label htmlFor=''>Timbre</label>
						<input type='number' required disabled value={'1'} />
					</div>
					<div className='field'>
						<label htmlFor=''>Prix de detailant</label>
						<input
							type='text'
							required
							value={values.Retail_Price}
							name='Retail_Price'
							onChange={handleChangeNonNegative}
							placeholder='0.000 DT'
							onWheel={(e) => e.currentTarget.blur()}
						/>
					</div>
					<div className='field'>
						<label htmlFor=''>Prix de grossiste</label>
						<input
							type='text'
							required
							value={values.Wholesale_Price}
							name='Wholesale_Price'
							onChange={handleChangeNonNegative}
							placeholder='0.000 DT'
							onWheel={(e) => e.currentTarget.blur()}
						/>
					</div>
					<div className='field'>
						<label htmlFor=''>Prix de Monoprix</label>
						<input
							type='text'
							value={values.Monoprix_Price}
							required
							name='Monoprix_Price'
							onChange={handleChangeNonNegative}
							placeholder='0.000 DT'
							onWheel={(e) => e.currentTarget.blur()}
						/>
					</div>
					{/* <div className='field'>
						<label htmlFor=''>Prix d'UMD</label>
						<input
							type='text'
							required
							value={values.Price_of_UMD}
							name='Price_of_UMD'
							onChange={handleChangeNonNegative}
							placeholder='0.000 DT'
							onWheel={(e) => e.currentTarget.blur()}
						/>
					</div>
					<div className='field'>
						<label htmlFor=''>Prix de Black</label>
						<input
							type='text'
							required
							value={values.Price_of_Black}
							name='Price_of_Black'
							onChange={handleChangeNonNegative}
							placeholder='0.000 DT'
							onWheel={(e) => e.currentTarget.blur()}
						/>
					</div> */}
				</div>
				<div className='submit-btns'>
					<div className='add-btns'>
						<Link to='/admin/products/1'>
							<button className='button-nature'>Annuler</button>
						</Link>
						<ButtonComponent
							name='Enregistrer'
							loading={isLoading}
							type='submit'
							className='button-primary'
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default UpdateProduct;
