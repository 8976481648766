import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AdminServices from '../../services/AdminServices';

const initialState = {
	users: [],
	list: [],
	categories: [],
	listCategories: [],
	productsArr: [],
	products: [],
	isFetching: false,
	listCategoriesProducts: [],
	orders: [],
	listOrders: [],
	cart: [],
	cartList: [],
	clientsListOrdering: [],
	orderDetails: {},
	claims: [],
	claim: {},
	returns: [],
	bills: [],
	checkedList: [],
	delivery: [],
	deliveryDetails: [],
	total: 0,
};

//------------------------------------- admin features -------------------------------------------------//
export const usersList_admin = createAsyncThunk(
	'/users_admin',
	async ({ token, name, categories, regulation, page }, thunkApi) => {
		try {
			const res = await AdminServices.getListForAdmin(token, name, categories, regulation, page);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getUserDetails = createAsyncThunk('/user_details', async ({ token, id }, thunkApi) => {
	try {
		const res = await AdminServices.getUserDetails(token, id);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
export const new_user_admin = createAsyncThunk(
	'/create_user_admin',
	async ({ token, data }, thunkApi) => {
		try {
			const res = await AdminServices.create_user_admin(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error);
		}
	},
);

export const getCategories = createAsyncThunk(
	'/getcategories',
	async ({ token, name, page }, thunkApi) => {
		try {
			const res = await AdminServices.getCategoriesForAdmin(token, name, page);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const getCategoriesForProducts = createAsyncThunk(
	'/getcategories_products',
	async ({ token }, thunkApi) => {
		try {
			const res = await AdminServices.getCategoriesForAdminProducts(token);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const deleteCategory = createAsyncThunk('/delcategory', async ({ token, id }, thunkApi) => {
	try {
		const res = await AdminServices.deleteCategoriesForAdmin(token, id);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const addCategory = createAsyncThunk('/addcategory', async ({ token, data }, thunkApi) => {
	try {
		const res = await AdminServices.addCategoryForAdmin(token, data);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const updateCategory = createAsyncThunk(
	'/addcategory',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await AdminServices.updateCategoryForAdmin(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const searchCategory = createAsyncThunk('/searchcategory', async ({ token }, thunkApi) => {
	try {
		const res = await AdminServices.searchCategoryForAdmin(token);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const userBlocked = createAsyncThunk(
	'/user_blocked',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await AdminServices.blockUserForAdmin(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const userUnblocked = createAsyncThunk(
	'/user_unblock',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await AdminServices.blockUserForAdmin(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const updateUserByAdmin = createAsyncThunk(
	'/updateuser_admin',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await AdminServices.updateUserByAdmin(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const getProductsByAdmin = createAsyncThunk(
	'/get_products',
	async ({ token, name, category_name, page }, thunkApi) => {
		try {
			const res = await AdminServices.getListProducts(token, name, category_name, page);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getProductDetails = createAsyncThunk(
	'/product_details',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await AdminServices.getProductDetails(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getOrdersProducts = createAsyncThunk(
	'/get_products_orders',
	async ({ token, search, category_name, page }, thunkApi) => {
		try {
			const res = await AdminServices.getOrdersProducts(token, search, category_name, page);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const productStatus = createAsyncThunk(
	'/productActivation',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await AdminServices.productActivation(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const newProductByAdmin = createAsyncThunk(
	'/addProductAdmin',
	async ({ token, data }, thunkApi) => {
		try {
			const res = await AdminServices.addProductAdmin(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const updateProductByAdmin = createAsyncThunk(
	'/updateProductAdmin',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await AdminServices.updateProductAdmin(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const deleteProductByAdmin = createAsyncThunk(
	'/deleteProductAdmin',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await AdminServices.deleteProductAdmin(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const updateProductStockByAdmin = createAsyncThunk(
	'/stockProductAdmin',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await AdminServices.updateStockAdmin(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const getOrdersByAdmin = createAsyncThunk(
	`/getOrdersAdmin`,
	async ({ token, page, search, status }, thunkApi) => {
		try {
			const res = await AdminServices.getListOrdersAdmin(token, page, search, status);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const deleteOrderByAdmin = createAsyncThunk(
	'/deleteOrderAdmin',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await AdminServices.deleteOrderAdmin(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const clientsOrderListByAdmin = createAsyncThunk(
	'/getClientListAdmin',
	async ({ token, value }, thunkApi) => {
		try {
			const res = await AdminServices.clientsOrderListAdmin(token, value);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

//------------------------- ADD ORDER ----------------------//
export const addOrderByAdmin = createAsyncThunk(
	'/addOrderAdmin',
	async ({ token, data }, thunkApi) => {
		try {
			const res = await AdminServices.addOrderAdmin(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

//------------------------- GET ORDER BY ID ----------------------//
export const getOrderByIdAdmin = createAsyncThunk(
	'/get_order_by_id_admin',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await AdminServices.getOrderByIdAdmin(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

//------------------------- UPDATE ORDER BY ID ----------------------//
export const updateOrderByIdAdmin = createAsyncThunk(
	'/update_order_by_id_admin',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await AdminServices.updateOrderByIdAdmin(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const getClaimsByAdmin = createAsyncThunk(
	'/getClaimListAdmin',
	async ({ token, status, search, page }, thunkApi) => {
		try {
			const res = await AdminServices.getClaims(token, status, search, page);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getClaimByIdAdmin = createAsyncThunk(
	'/getClaimByAdmin',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await AdminServices.getClaimById(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const updateClaimStatus = createAsyncThunk(
	'/getClaimByAdmin',
	async ({ token, id, status }, thunkApi) => {
		try {
			const res = await AdminServices.updateClaimStatus(token, id, status);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const getReturnsByAdmin = createAsyncThunk(
	'/get_return_admin',
	async ({ token, search, page }, thunkApi) => {
		try {
			const res = await AdminServices.getReturns(token, search, page);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getBillsByAdmin = createAsyncThunk(
	'/get_bills_admin',
	async ({ token, search, page }, thunkApi) => {
		try {
			const res = await AdminServices.getBills(token, search, page);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const addDelivery = createAsyncThunk('/add_delivery', async ({ token, data }, thunkApi) => {
	try {
		const res = await AdminServices.addDelivery(token, data);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
export const getDelivery = createAsyncThunk(
	'/get_delivery',
	async ({ token, page, search, status, statusCommerial }, thunkApi) => {
		try {
			const res = await AdminServices.getDelivery(token, page, search, status, statusCommerial);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getDeliveryById = createAsyncThunk(
	'/get_delivery_by_id',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await AdminServices.getDeliveryById(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const updateDelivery = createAsyncThunk(
	'/update_delivery',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await AdminServices.updateDelivery(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const checkStock = createAsyncThunk('/check_stock', async ({ token, list }, thunkApi) => {
	try {
		const res = await AdminServices.checkStock(token, list);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
export const getCommercial = createAsyncThunk('/get_commercial', async ({ token }, thunkApi) => {
	try {
		const res = await AdminServices.getCommercial(token);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
export const addVehicle = createAsyncThunk('/add_vehicle', async ({ token, data }, thunkApi) => {
	try {
		const res = await AdminServices.addVehicle(token, data);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const deleteVehicle = createAsyncThunk(
	'/delete_vehicle',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await AdminServices.deleteVehicle(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getPayementDetails = createAsyncThunk(
	'/getPayementDetails',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await AdminServices.getPayementDetails(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const confirmPayement = createAsyncThunk(
	'/confirmPayement',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await AdminServices.confirmPayement(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const refusePayement = createAsyncThunk(
	'/refusePayement',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await AdminServices.refusePayement(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getReturnById = createAsyncThunk(
	'/get_return_by_id',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await AdminServices.getReturnById(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getBillById = createAsyncThunk('/get_bill_by_id', async ({ token, id }, thunkApi) => {
	try {
		const res = await AdminServices.getBillById(token, id);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
const adminSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		clearCheckList: (state) => {
			state.checkedList = [];
		},
		setCheckedList: (state, action) => {
			const itemId = action.payload._id;
			if (!state.checkedList.some((item) => item._id === itemId)) {
				return {
					...state,
					checkedList: [...state.checkedList, action.payload],
				};
			}
			return state;
		},
		setUnCheckedList: (state, action) => {
			state.checkedList = state.checkedList.filter((el) => el._id !== action.payload._id);
		},
	},
	extraReducers: {
		[usersList_admin.fulfilled]: (state, action) => {
			state.users = action.payload;
			state.list = action.payload.data.list;
			state.total = action.payload.total;
			state.isFetching = true;
		},
		[new_user_admin.fulfilled]: (state, action) => {
			state.users = { ...state.users, ...action.payload };
		},
		[getCategories.fulfilled]: (state, action) => {
			state.categories = action.payload;
			state.listCategories = action.payload.data;
			state.total = action.payload.total;
		},
		[getCategoriesForProducts.fulfilled]: (state, action) => {
			state.categories = action.payload;
			state.listCategoriesProducts = action.payload.data;
		},
		[addCategory.fulfilled]: (state, action) => {
			state.categories = { ...state.categories, ...action.payload };
		},
		[updateCategory.fulfilled]: (state, action) => {
			state.categories = { ...state.categories, ...action.payload };
		},
		[userBlocked.fulfilled]: (state, action) => {
			state.users = action.payload;
			state.error = false;
		},
		[updateUserByAdmin.fulfilled]: (state, action) => {
			state.users = { ...state.users, ...action.payload };
		},
		[getProductsByAdmin.fulfilled]: (state, action) => {
			state.products = action.payload;
			state.productsArr = action.payload.data;
			state.total = action.payload.total;
		},
		[productStatus.fulfilled]: (state, action) => {
			state.products = action.payload;
			state.error = false;
		},
		[newProductByAdmin.fulfilled]: (state, action) => {
			state.products = { ...state.products, ...action.payload };
		},
		[updateProductByAdmin.fulfilled]: (state, action) => {
			state.products = { ...state.products, ...action.payload };
		},
		[updateProductStockByAdmin.fulfilled]: (state, action) => {
			state.products = { ...state.products, ...action.payload };
		},
		[getOrdersByAdmin.fulfilled]: (state, action) => {
			state.orders = action.payload;
			state.listOrders = action.payload.data;
			state.total = action.payload.total;
		},
		[clientsOrderListByAdmin.fulfilled]: (state, action) => {
			state.clientsListOrdering = action.payload.data.list;
		},
		[addOrderByAdmin.fulfilled]: (state, action) => {
			state.orders = { ...state.orders, ...action.payload };
		},
		[getOrderByIdAdmin.fulfilled]: (state, { payload }) => {
			state.orderDetails = payload.data;
		},
		[getOrderByIdAdmin.fulfilled]: (state, { payload }) => {
			state.orderDetails = payload.data;
		},
		[updateOrderByIdAdmin.fulfilled]: (state, { payload }) => {
			state.message = payload;
		},
		[getClaimsByAdmin.fulfilled]: (state, { payload }) => {
			state.claims = payload.data;
			state.total = payload.total;
		},
		[getClaimByIdAdmin.fulfilled]: (state, { payload }) => {
			state.claim = payload.data;
		},
		[updateClaimStatus.fulfilled]: (state, { payload }) => {
			state.claim = payload.data;
		},
		[getReturnsByAdmin.fulfilled]: (state, { payload }) => {
			state.returns = payload.data;
			state.total = payload.total;
		},
		[getDelivery.fulfilled]: (state, { payload }) => {
			state.delivery = payload.data;
			state.total = payload.total;
		},
		[getDeliveryById.fulfilled]: (state, { payload }) => {
			state.deliveryDetails = payload.data;
		},
		[getBillsByAdmin.fulfilled]: (state, { payload }) => {
			state.bills = payload.data;
			state.total = payload.total;
		},
	},
});

export default adminSlice.reducer;
export const {
	incrementQuantity,
	decrementQuantity,
	setCheckedList,
	setUnCheckedList,
	clearCheckList,
} = adminSlice.actions;
