import axios from '../http-common';

const createAxiosRequest = (token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return {
		get: (url, params = {}) => axios.get(url, { ...config, ...params }),
		post: (url, data) => axios.post(url, data, config),
		put: (url, data) => axios.put(url, data, config),
		delete: (url) => axios.delete(url, config),
	};
};

const axiosWithToken = createAxiosRequest;

export const CustomerServices = {
	getListClaimsCustomer: (page) => axiosWithToken().get(`/users/claim/type/?page=${page}`),
	updateCustomerProfile: (token, data) => axiosWithToken(token).put(`/customer/profile`, data),
	updateCustomerPassword: (token, data) =>
		axiosWithToken(token).put(`/customer/profile/password`, data),
	getCustomerCategories: (token, page, search) =>
		axiosWithToken(token).get(`/customer/categories/?page=${page}&search=${search}`),
	getListCategoriesProducts: (token) => axiosWithToken(token).get(`/customer/categories`),
	getCustomerProductsList: (token, page, search, categories) =>
		axiosWithToken(token).get(
			`/customer/products?page=${page}&search=${search}&categories=${categories}`,
		),
	addBasket: (token, data) => axiosWithToken(token).post(`/customer/basket`, data),
	getBasket: (token, id) => axiosWithToken(token).get(`/customer/basket/${id}`),
	deleteBasket: (token, id) => axiosWithToken(token).delete(`/customer/basket/${id}`),
	udpateStockProduitBasket: (token, id, data) =>
		axiosWithToken(token).put(`/customer/basket/stock/${id}`, data),
	clearBasket: (token) => axiosWithToken(token).delete(`/customer/basket`),
	addOrderByCustomer: (token, data) => axiosWithToken(token).post('/customer/orders', data),
	getOrdersByCustomer: (token, page, search, status) =>
		axiosWithToken(token).get('/customer/orders', { params: { page, search, status } }),
	getOrderByIdCustomer: (token, id) => axiosWithToken(token).get(`/customer/orders/${id}`),
	getBillsByCustomer: (token, page, search) =>
		axiosWithToken(token).get('/customer/bills/', { params: { page, search } }),
	getBillByIdCustomer: (token, id) => axiosWithToken(token).get(`/customer/bills/${id}`),
	ClaimsClientsData: (token, page, search) =>
		axiosWithToken(token).get('/customer/claims', { params: { page, search } }),
	CustomerClaimsTypes: (token) => axiosWithToken(token).get('/customer/claims/type'),
	addCliamsByCustomer: (token, data) => axiosWithToken(token).post('/customer/claims/add', data),
	getCliamById: (token, id) => axiosWithToken(token).get(`/customer/claims/${id}`),
	checkStock: (token, list) =>
		axiosWithToken(token).post('/customer/orders/products/stock', { list }),
	clearBasketCustomer: (token, id) => axiosWithToken(token).delete(`/customer/clear-basket/${id}`),
};

export default CustomerServices;
