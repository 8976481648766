import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../pages/NotFound';
import {
	SuperAdminProfile,
	UpdateProfile,
	AdminList,
	AdminComplaints,
	DetailsComplainAdmin,
} from '../pages/superAdmin';

const SuperAdminRoutes = () => {
	return (
		<Routes>
			<Route exact path='/' element={<SuperAdminProfile />} />
			<Route exact path='/superadmin/profile' element={<SuperAdminProfile />} />
			<Route exact path='/superadmin/profile/update/' element={<UpdateProfile />} />
			<Route exact path='/superadmin/users/:page' element={<AdminList />} />
			<Route exact path='/superadmin/complaints/:page' element={<AdminComplaints />} />
			<Route
				exact
				path='/superadmin/complaints/complain_details/:page/:id'
				element={<DetailsComplainAdmin />}
			/>
			<Route exact path='*' element={<NotFound />} />
		</Routes>
	);
};

export default SuperAdminRoutes;
