import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { icons } from '../../../assets/icons';
import './index.scss';
import { imageApi } from '../../../api/redux/slices/Public';
import { ModalSuccess, showToast } from '../../../components/modal';
import {
	addPayment,
	getPayementDetails,
	updatePayment,
} from '../../../api/redux/slices/Commercial';
import { ButtonComponent } from '../../../components/buttons';
import { convertDate } from '../../../helpers/modules';
import { LoaderComponent } from '../../../helpers/components/loader';
import Avatar from '../../../components/avatar';

const ConfirmDelivery = () => {
	const params = useParams();
	const dispatch = useDispatch();

	const token = localStorage.getItem('accesstoken');
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(false);

	const [payementDetails, setPayementDetails] = useState({});
	const [fileUploaded, setFileUploaded] = useState({});
	const [paymentType, setPaymentType] = useState('cash');
	const [amountPaid, setAmountPaid] = useState('');

	const [successModal, setSuccessModal] = useState({
		succMsg: '',
		isShow: false,
	});

	useEffect(() => {
		setLoading(true);
		dispatch(getPayementDetails({ token, id: params.id }))
			.unwrap()
			.then((res) => setPayementDetails(res.data))
			.finally(() => setLoading(false));
	}, [dispatch, token, params.id]);

	useEffect(() => {
		if (payementDetails?.payement?.image) {
			setFileUploaded({
				file: '',
				url: payementDetails?.payement?.image?.secure_url,
			});
		}
		setPaymentType(payementDetails?.payement?.type || 'cash');
		setAmountPaid(payementDetails?.payement?.price || '');
	}, [payementDetails]);

	// --------------Add payment--------------------
	const handleAddPayment = (imgObject = {}) => {
		setIsLoading(true);
		dispatch(
			addPayment({
				token: token,
				id: params.id,
				data: {
					type: paymentType,
					price: amountPaid,
					image: imgObject,
				},
			}),
		)
			.unwrap()
			.then(() => {
				setSuccessModal({
					succMsg:
						"La soumission a été envoyée avec succès. Attendez la confirmation de l'administrateur.",
					isShow: true,
				});
			})
			.finally(() => setIsLoading(false));
	};
	const addPaymentWithImg = () => {
		let formData = new FormData();
		formData.append('image', fileUploaded?.file);

		setIsLoading(true);
		dispatch(imageApi({ token: token, formDta: formData }))
			.unwrap()
			.then((res) => {
				handleAddPayment(res);
			})
			.catch((err) => {
				showToast(err.message || err, 'error');
			});
	};

	const handleSubmit = () => {
		if (!amountPaid) {
			showToast('Veuillez entrer le montant payé', 'error');
			return;
		}

		if (fileUploaded?.url) {
			addPaymentWithImg();
		} else {
			handleAddPayment();
		}
	};
	// --------------Update payment----------------
	const handleUpdatePayment = (imgObject) => {
		setIsLoading(true);
		dispatch(
			updatePayment({
				token: token,
				id: params.id,
				data: {
					type: paymentType,
					price: amountPaid,
					image: imgObject,
				},
			}),
		)
			.unwrap()
			.then(() => {
				setSuccessModal({
					succMsg:
						"La soumission a été envoyée avec succès. Attendez la confirmation de l'administrateur.",
					isShow: true,
				});
			})
			.finally(() => setIsLoading(false));
	};

	const updatePaymentWithImg = () => {
		let formData = new FormData();
		formData.append('image', fileUploaded?.file);

		setIsLoading(true);
		dispatch(imageApi({ token: token, formDta: formData }))
			.unwrap()
			.then((res) => {
				handleUpdatePayment(res);
			})
			.catch((err) => {
				showToast(err.message || err, 'error');
			});
	};
	const handleUpdate = () => {
		if (!amountPaid) {
			showToast('Veuillez entrer le montant payé', 'error');
			return;
		}

		if (fileUploaded?.url && fileUploaded?.url !== payementDetails?.payement?.image?.secure_url) {
			updatePaymentWithImg();
		} else {
			handleUpdatePayment(fileUploaded?.url ? payementDetails?.payement?.image : {});
		}
	};
	if (loading) {
		return <LoaderComponent />;
	}
	return (
		<div id='complainClientDetails'>
			{successModal.isShow && (
				<ModalSuccess
					succesModal={successModal}
					setModalSuccess={setSuccessModal}
					name={"D'accord"}
					navigate={`/commercial/deliveries/${params.page}`}
				/>
			)}
			<div id='details-complain-header'>
				<Link to={`/commercial/deliveries/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Bons de livraison</h1>
				</Link>
				<div className='buttons'>
					<Link to={`/commercial/deliveries/${params.id}`}>
						<button className='button-nature'>Annuler</button>
					</Link>
					<ButtonComponent
						name={payementDetails?.payement?._id ? 'Modifier' : 'Envoyer'}
						loading={isLoading}
						type={'button'}
						className='button-primary'
						onClick={payementDetails?.payement?._id ? handleUpdate : handleSubmit}
					/>
				</div>
			</div>
			<div id='headComplain'>
				<div className='infos'>
					<div>
						<h2>Référence: </h2>
						<span>{payementDetails?.delivery?.reference}</span>
					</div>
					<div>
						<h2>Date: </h2>
						<span>{convertDate(payementDetails?.delivery?.date, 'DD-MM-YYYY')}</span>
					</div>
					<div>
						<h2>Client: </h2>
						<span>{payementDetails?.delivery?.client} </span>
					</div>
					<div>
						<h2>Societe: </h2>
						<span>{payementDetails?.delivery?.company_name}</span>
					</div>
					<div>
						<h2>Total: </h2>
						<span>{payementDetails?.delivery?.total} DT</span>
					</div>
				</div>
			</div>
			{payementDetails?.payement?.message && (
				<div id='montant'>
					<h3 style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
						Raison du refus <span className='refus'>!</span>
					</h3>
					<p style={{ color: '#454545', fontSize: '1rem', width: '50rem' }}>
						{payementDetails.payement.message}
					</p>
				</div>
			)}
			<div id='object'>
				<h3>Type de paiement</h3>
				<div id='radio-status'>
					<label>
						<input
							type='radio'
							value='cash'
							checked={paymentType === 'cash'}
							onChange={() => setPaymentType('cash')}
						/>
						Espèces
					</label>
					<label>
						<input
							type='radio'
							value='cheque'
							checked={paymentType === 'cheque'}
							onChange={() => setPaymentType('cheque')}
						/>
						Chèque d'argent
					</label>
					<label>
						<input
							type='radio'
							value='bankTransfer'
							checked={paymentType === 'bankTransfer'}
							onChange={() => setPaymentType('bankTransfer')}
						/>
						Virement bancaire
					</label>
				</div>
			</div>
			<div id='montant'>
				<h3>Montant payé</h3>
				<input
					type='text'
					placeholder='0 dt'
					value={amountPaid}
					onChange={(e) => {
						const onlyNumbers = e.target.value.replace(/[^0-9.]/g, '');
						setAmountPaid(onlyNumbers);
					}}
					required
				/>
			</div>
			<div id='image'>
				<h3>Preuve d'image</h3>
				<div className='field-image-upload'>
					<Avatar
						fileUploaded={fileUploaded}
						setFileUploaded={setFileUploaded}
						width={'35rem'}
						height={'13rem'}
					/>
				</div>
			</div>
		</div>
	);
};

export default ConfirmDelivery;
