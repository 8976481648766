import moment from 'moment/moment';

export const generateId = () => {
	const id = String(Date.now().toString(32) + Math.random().toString(16)).replace(/\./g, '');
	return id;
};
export const accesstoken = localStorage.getItem('accesstoken');

export const convertDate = (dateEntered, format) => {
	var date = moment(dateEntered);
	if (date.isValid()) {
		var jjMmYy = date.format(format);
		return jjMmYy;
	} else {
		return '-';
	}
};

let count = 0;
export const generateReferenceNumber = () => {
	var date = moment().format('YYMMDDHHmmss');
	var referenceNumber = 'REF' + date + count;
	count++;
	return referenceNumber;
};
export const typePrice = (clientType) => {
	switch (clientType) {
		case 'detaillant':
			return 'Retail_Price';
		case 'monoprix':
			return 'Monoprix_Price';
		case 'umd':
			return 'Price_of_UMD';
		case 'black':
			return 'Price_of_Black';
		case 'grossiste':
			return 'Wholesale_Price';
		default:
			return 'prise_HT';
	}
};
export const convertRegulation = (category) => {
	switch (category) {
		case 'espece':
			return 'Espèce';
		case 'cheque':
			return 'Chèque';
		case 'traite':
			return 'Traitè';
		default:
			return null;
	}
};

export const convertCategory = (category) => {
	switch (category) {
		case 'grossiste':
			return 'Grossiste';
		case 'detaillant':
			return 'Détaillant';
		case 'monoprix':
			return 'Monoprix';
		case 'umd':
			return 'UMD';
		case 'black':
			return 'Black';
		default:
			return null;
	}
};
export const totalHT = (itemsArr, clientType) => {
	let total = 0;
	itemsArr?.forEach((el) => {
		total += el.quantity * el?.product_id?.[typePrice(clientType)] * (1 - el.remise / 100);
	});
	return roundToThreeDecimals(total);
};

export const totalTTC = (priceHT, tva, timbre) => {
	const totalPrice = priceHT * (1 + tva / 100) + timbre;
	return roundToThreeDecimals(totalPrice);
};

export const roundFixedNumber = (cost) => {
	if (Number(cost) % 1 === 0) {
		return Number(Math.floor(cost));
	} else {
		return Number(Number(cost).toFixed(3));
	}
};

export const roundToThreeDecimals = (number) => {
	if (!number) return 0;

	const parsedNumber = Number(number);

	if (parsedNumber % 1 === 0) {
		return parsedNumber;
	}

	const numberString = parsedNumber.toString();
	const roundedNumberString = numberString.slice(0, numberString.indexOf('.') + 4);
	return Number(roundedNumberString);
};
