import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { LoaderComponent } from '../../../helpers/components/loader';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../helpers/components/Pagination';
import { convertDate } from '../../../helpers/modules';
import { CSVLink } from 'react-csv';
import rotateSvg from '../../../assets/icons/svg/rotate-right.svg';
import { getDeliveryByCommercial } from '../../../api/redux/slices/Commercial';
import DeliveryOptions from './DeliveryOptions';
import useStableArr from '../../../utils/useStableArr';
import { handleCheck, handleCheckAll } from '../../../utils/checkUtilsCommercial';
import StatusChangeComponent from '../../../components/navbar/StatusChangeComponent';

const DeliveryPage = () => {
	const { delivery, checkedList, total } = useSelector((state) => state.commercial);
	const dispatch = useDispatch();
	const params = useParams();
	const [deliveryOpenDropdown, setDeliveryOpenDropdown] = useState(false);
	const [indiceDelivery, setIndiceDelivery] = useState(null);

	const [page, setPage] = useState(Number(params.page));
	const [newArr, setNewArr] = useState(delivery?.map((el) => ({ ...el, checked: false })));
	const [isCheck, setIsCheck] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [statusDelivery, setStatusDelivery] = useState('');

	const [loading, setLoading] = useState(false);
	const [deliveryChangeStatus, setDeliveryChangeStatus] = useState(false);
	const token = localStorage.getItem('accesstoken');

	//----------------------- GET ALL CLAIMS ---------------------------//
	const stableDispatch = useCallback(() => {
		setLoading(true);
		dispatch(
			getDeliveryByCommercial({
				token: token,
				page: searchValue || searchValue ? undefined : page,
				search: searchValue,
				status: statusDelivery,
			}),
		)
			.unwrap()
			.finally(() => setLoading(false));
		// eslint-disable-next-line
	}, [dispatch, page, searchValue, token, deliveryChangeStatus, statusDelivery]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [stableDispatch, token]);

	// --------------- RETRIVE LATEST CLAIMS DATA ---------------//
	useStableArr(delivery, checkedList, setNewArr, setIsCheck);

	//-------------- HANDLE Export ----------------//
	const headers = [
		{ label: 'NUMÉRO', key: 'order_id.reference' },
		{ label: 'DATE', key: 'createdAt' },
		{ label: 'CLIENT', key: 'order_id.user_id.first_name' },
		{ label: 'SOCIÉTÉ', key: 'order_id.user_id.company_name' },
		{ label: 'TOTAL', key: 'order_id.total' },
	];

	const csvDeliveryReport = {
		filename: 'Livraisons.csv',
		data: checkedList,
		headers: headers,
	};

	const listStatusDelivery = [
		{ id: '1', label: 'IN_PROGRESS', value: 'En cours' },
		{ id: '2', label: 'TO_CONFIRM', value: 'À confirmer' },
		{ id: '3', label: 'CONFIRM', value: 'Confirmer' },
		{ id: '4', label: 'REFUSE', value: 'Refuser' },
	];
	return (
		<div id='deliveryPage'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='text'
							placeholder='Rechercher ...'
							value={searchValue}
							onChange={(e) => {
								setSearchValue(e.target.value);
							}}
						/>
						<icons.FiSearch className='searchicon' />
					</div>
					<StatusChangeComponent
						statusValue={statusDelivery}
						setStatusValue={setStatusDelivery}
						listStatusOptions={listStatusDelivery}
						name='Livraison'
					/>
				</div>
				<div className='right'>
					<div className='right-items'>
						<CSVLink {...csvDeliveryReport} target='blank'>
							<button className='button-nature' title='exporter livraison(s)'>
								<icons.BiExport />
							</button>
						</CSVLink>
					</div>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Bons de livraisons</h1>
				</div>
			</div>
			<div id='deliveriesContent'>
				<table id='deliveries'>
					<thead>
						<tr id='titres'>
							<td className='input-check'>
								<input
									type='checkbox'
									className='checkbox'
									checked={isCheck}
									onChange={() => handleCheckAll(dispatch, newArr, setNewArr, isCheck, setIsCheck)}
								/>
							</td>
							<td>NUMÉRO</td>
							<td>DATE</td>
							<td>CLIENT</td>
							<td>SOCIÉTÉ</td>
							<td>TOTAL</td>
							<td className='center'>STATUT</td>
							<td>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{loading ? (
							<tr>
								<td colSpan='7'>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							newArr?.map((delivery, index) => (
								<tr className='delivery' key={index}>
									<td className='input-check'>
										<input
											type='checkbox'
											checked={delivery.checked}
											className='checkbox'
											onChange={() => handleCheck(dispatch, newArr, setNewArr, delivery._id)}
										/>
									</td>
									<td>{delivery?.reference}</td>
									<td>
										{delivery.createdAt ? convertDate(delivery.createdAt, 'DD-MM-YYYY') : '-'}
									</td>
									<td>{delivery.order_id?.clientInfo?.client}</td>
									<td>{delivery.order_id?.clientInfo?.company_name}</td>
									<td>{delivery.order_id?.total} DT</td>
									<td className='box' style={{ margin: 'auto' }}>
										<div
											style={{
												marginLeft: !!delivery?.return_id ? '15px' : '',
											}}
											className={`delivery-status ${
												delivery.status === 'IN_PROGRESS'
													? 'en-cours'
													: delivery.status === 'TO_CONFIRM'
													  ? 'a-confirmer'
													  : delivery.status === 'CONFIRM'
													    ? 'confirmer'
													    : 'refuser'
											}`}
										>
											{delivery.status === 'IN_PROGRESS'
												? 'En cours'
												: delivery.status === 'TO_CONFIRM'
												  ? 'À confirmer'
												  : delivery.status === 'CONFIRM'
												    ? 'Confirmer'
												    : 'Refuser'}
										</div>
										<Link
											to={`/commercial/returns/return_details/${params.page}/${delivery?.return_id}`}
											style={{ display: 'flex' }}
										>
											<img
												className={`${!!delivery?.return_id ? 'rotate' : ''}`}
												src={rotateSvg}
												alt='retour'
											/>
										</Link>
									</td>
									<td className='last-delivery-element'>
										<Link
											to={`/commercial/deliveries/delivery_details/${page}/${delivery._id}`}
											className='link-delivery'
										>
											<icons.BsEye className='see-delivery' />
										</Link>
										<div className='contain'>
											<icons.BsThreeDotsVertical
												onClick={() => {
													setDeliveryOpenDropdown(!deliveryOpenDropdown);
													setIndiceDelivery(delivery._id);
												}}
												className='delivery-option'
											/>
											{deliveryOpenDropdown && indiceDelivery === delivery._id && (
												<DeliveryOptions
													delivery={delivery}
													page={page}
													setDeliveryOpenDropdown={setDeliveryOpenDropdown}
													setDeliveryChangeStatus={setDeliveryChangeStatus}
												/>
											)}
										</div>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				pageLink={`/commercial/deliveries/`}
				total={total}
			/>
		</div>
	);
};

export default DeliveryPage;
