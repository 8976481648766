import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SuperAdminServices from '../../services/SuperAdminServices';

const initialState = {
	users: [],
	claims: [],
	loading: false,
	error: null,
	errorMessage: null,
	isSuccess: false,
	list: [],
	claimsList: [],
	badCredentials: null,
	total: 0,
};

//---------------------------------------------- SUPER-ADMIN users features -----------------------------------------------------------//
export const usersList = createAsyncThunk('/admins', async ({ token, page, search }, thunkApi) => {
	try {
		const res = await SuperAdminServices.getAdminsList(page, token, search);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const newAdmin = createAsyncThunk('/new_admin', async ({ token, data }, thunkApi) => {
	try {
		const res = await SuperAdminServices.createAdmin(token, data);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const adminBlocked = createAsyncThunk(
	'/admin_blocked',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await SuperAdminServices.blockAdmin(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const update_Admin = createAsyncThunk(
	'/updateadmin',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await SuperAdminServices.updateAdmin(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const search_admin_name = createAsyncThunk(
	'/searchname',
	async ({ token, name }, thunkApi) => {
		try {
			const res = await SuperAdminServices.searchAdmin(token, name);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

//---------------------------------------------- SUPER-ADMIN claims -----------------------------------------------------------//
export const search_admin_claim = createAsyncThunk(
	'/searchclaim',
	async ({ token, value, page, status }, thunkApi) => {
		try {
			const res = await SuperAdminServices.searchClaim(token, value, page, status);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const claimList = createAsyncThunk('claimslist', async ({ token, page }, thunkApi) => {
	try {
		const res = await SuperAdminServices.getClaimsList(token, page);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

//------------------------------------  Main feature -----------------------------------//
const superAdminSlice = createSlice({
	name: 'superAdmin',
	initialState,
	reducers: {
		clearState: (state) => {
			state.loading = false;
			state.users = [];
			state.error = [];
			state.errorMessage = [];
			state.list = [];
			state.badCredentials = [];
			state.isSuccess = [];
			state.claims = [];
			state.claimsList = [];
		},
	},
	extraReducers: {
		[usersList.pending]: (state) => {
			state.loading = true;
		},
		[usersList.fulfilled]: (state, action) => {
			state.users = action.payload;
			state.error = false;
			state.list = action.payload.data.list;
			state.total = action.payload.total;
			state.isSuccess = action.payload.success;
			state.loading = false;
		},
		[usersList.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload;
			state.loading = false;
			state.isSuccess = false;
		},
		[newAdmin.pending]: (state) => {
			state.loading = true;
			state.error = false;
		},
		[newAdmin.fulfilled]: (state, action) => {
			state.users = { ...state.users, ...action.payload };
			state.error = false;
			state.loading = false;
			state.addSuccess = true;
		},
		[newAdmin.rejected]: (state, { payload }) => {
			state.error = true;
			state.loading = false;
			state.isSuccess = false;
		},
		[adminBlocked.pending]: (state) => {
			state.loading = true;
		},
		[adminBlocked.fulfilled]: (state, action) => {
			state.users = action.payload;
			state.error = false;
			state.isSuccess = action.payload.success;
			state.loading = false;
		},
		[adminBlocked.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload;
			state.loading = false;
			state.isSuccess = false;
		},
		[update_Admin.pending]: (state) => {
			state.loading = true;
		},
		[update_Admin.fulfilled]: (state, action) => {
			state.users = { ...state.users, ...action.payload };
			state.error = false;
			state.isSuccess = action.payload.success;
			state.loading = false;
		},
		[update_Admin.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload;
			state.loading = false;
			state.isSuccess = false;
		},
		[search_admin_name.pending]: (state) => {
			state.loading = true;
		},
		[search_admin_name.fulfilled]: (state, action) => {
			state.users = action.payload;
			state.error = false;
			state.list = action.payload.data;
			state.isSuccess = action.payload.success;
			state.loading = false;
		},
		[search_admin_name.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload.message;
			state.loading = false;
			state.isSuccess = false;
		},
		[claimList.pending]: (state) => {
			state.loading = true;
		},
		[claimList.fulfilled]: (state, action) => {
			state.claims = action.payload;
			state.error = false;
			state.claimsList = action.payload.data;
			state.isSuccess = action.payload.success;
			state.loading = false;
		},
		[claimList.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload.message;
			state.loading = false;
			state.isSuccess = false;
		},
		[search_admin_claim.pending]: (state) => {
			state.loading = true;
		},
		[search_admin_claim.fulfilled]: (state, action) => {
			state.claims = action.payload.data;
			state.claimsList = action.payload.data;
			state.total = action.payload.total;
			state.error = true;
			state.errorMessage = action.payload.message;
			state.loading = false;
			state.isSuccess = true;
		},
		[search_admin_claim.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload.message;
			state.loading = false;
			state.isSuccess = false;
		},
	},
});

export default superAdminSlice.reducer;
