import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import { BillsData } from '../../../data';
import './index.scss';

const UpdateBill = () => {
	const params = useParams();
	const [detailBill, setDetailBill] = useState([]);
	const [billId, setBillId] = useState();

	useEffect(() => {
		if (params.id) {
			BillsData.forEach((el) => {
				if (el.id === params.id) {
					setDetailBill([el]);
					setBillId(params.id);
				}
			});
		}
	}, [params.id]);

	return (
		<div id='updateBill'>
			<div className='update-bill-header'>
				<Link to={`/admin/bills/bill_details/${billId}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Facture</h1>
				</Link>
				<div style={{ display: 'flex', gap: '1rem' }}>
					<Link to={`/admin/bills/bill_details/${billId}`}>
						<button className='button-nature'>Annuler</button>
					</Link>
					<Link to={`/admin/bills/bill_details/${billId}`}>
						<button className='button-primary'>Enregistrer</button>
					</Link>
				</div>
			</div>
			<div id='title-bill'>
				<h1>S.O.M.E.C Société Méditeranéenne d'Equipement et de Commerce</h1>
			</div>
			<div className='container'>
				<form>
					<div className='credentials'>
						<div className='left'>
							<div className='left-el'>
								<label>Numéro</label>
								<input type='text' defaultValue={`FC2200410`} />
							</div>
							<div className='left-el'>
								<label>Date</label>
								<input type='date' defaultValue={`2022-05-22`} />
							</div>
							<div className='left-el'>
								<label>Référence</label>
								<input type='text' defaultValue={`-`} />
							</div>
						</div>
						<div className='right'>
							<div className='right-el'>
								<label>Infos Client</label>
								<span>MAHDIA</span>
								<span>TVA 114795866F/N/C 000</span>
								<span>5111 MAHDIA</span>
							</div>
						</div>
					</div>
					<div className='devis'>
						<div className='devis-head'>
							<h1>FACTURE</h1>
							<span />
						</div>
						<div className='devis-table'>
							<table className='offres'>
								<thead>
									<tr id='titres'>
										<td>RÉFÉRENCE</td>
										<td>DÉSIGNATION</td>
										<td>QTÉ</td>
										<td>PRIX UNIT H.T</td>
										<td>REMISE</td>
										<td>MONTANT H.T</td>
										<td>TX.TVA</td>
									</tr>
								</thead>
								<tbody>
									<tr className='elements'>
										<td>F325</td>
										<td>SET DE BAIN 5 PIECE F325</td>
										<td>10.0</td>
										<td>22.424</td>
										<td>5</td>
										<td>213.089</td>
										<td>19</td>
									</tr>
									<tr className='elements'>
										<td>HE111251-063</td>
										<td>BROC A EAU 1L PARIS 111241-059</td>
										<td>10.0</td>
										<td>22.424</td>
										<td>5</td>
										<td>213.089</td>
										<td>19</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className='billTable'>
							<table id='bt-principal'>
								<tbody>
									<tr id='firstBigRow'>
										<td>
											<table id='bt-nested-1'>
												<thead>
													<tr id='bt-head-1'>
														<td>CODE</td>
														<td>BASE</td>
														<td>TAUX</td>
														<td>TOTAL TAXE</td>
													</tr>
												</thead>
												<tbody>
													<tr className='bt-tl-1'>
														<td>C18</td>
														<td>253.897</td>
														<td>48.240</td>
														<td>48.240</td>
													</tr>
													<tr className='bt-tl-1'>
														<td>CTI</td>
														<td>0.000</td>
														<td></td>
														<td>0.000</td>
													</tr>
												</tbody>
											</table>
										</td>
										<td>
											<table id='bt-nested-2'>
												<thead>
													<tr id='bt-head-2'>
														<td>TOTAL H.T</td>
														<td>REMISE</td>
														<td>ESCOMPTE</td>
														<td>TOTAL TTC</td>
													</tr>
												</thead>
												<tbody>
													<tr className='bt-tl-2' id='bt-tl-2'>
														<td>253.22</td>
														<td>15.22</td>
														<td>0.000</td>
														<td>322.737</td>
													</tr>
													<tr className='bt-tl-2'>
														<td />
														<td />
														<td />
														<td />
													</tr>
												</tbody>
											</table>
										</td>
										<td>
											<table id='bt-nested-3'>
												<thead>
													<tr id='bt-head-3'>
														<td>NET A PAYER</td>
													</tr>
												</thead>
												<tbody>
													<tr className='bt-tl-3'>
														<td>307.737</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
									<tr id='secondBigRow'>
										<td>
											<table id='bt-nested-4'>
												<tbody>
													<tr className='bt-head-4'>
														<td />
														<td />
														<td />
														<td />
													</tr>
													<tr className='bt-head-4'>
														<td />
														<td />
														<td />
														<td />
													</tr>
													<tr className='bt-head-4'>
														<td>Total</td>
														<td>253.897</td>
														<td />
														<td>48.840</td>
													</tr>
												</tbody>
											</table>
										</td>
										<td>
											<table id='bt-nested-5'>
												<thead>
													<tr id='bt-head-5'>
														<td>CONDITIONS DE REGLEMENT:</td>
														<td />
													</tr>
												</thead>
												<tbody>
													<tr className='bt-tl-5'>
														<td />
														<td />
													</tr>
													<tr className='bt-tl-5'>
														<td>90 JOURS</td>
														<td />
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
							</table>
							<div className='billEnd'>
								<h2>ARRETER LA PRÉSENTE FACTURE À LA SOMME DE : </h2>
								<span>Trois cent deux dinars, sept cent trente sept millimes</span>
							</div>
						</div>
					</div>
				</form>
				<div className='footer'>
					<div className='left'>
						<ul>
							<li>Rue Mahmoud El Karoui SAHLOUL, SOUSSE 4051</li>
							<li>
								CODE TVA <span>11021539B/A/M 0000</span>
							</li>
							<li>
								RC: <span>B9141302009</span>
							</li>
							<li>
								Code en Douane <span>842782Z</span>
							</li>
						</ul>
					</div>
					<div className='left'>
						<ul>
							<li>
								Tél <span>31 405 595</span>
							</li>
							<li>
								Fax <span>32 405 595</span>
							</li>
							<li>
								Email: <span>somec.sousse@gmail.com</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpdateBill;
