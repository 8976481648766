import React from 'react';
import { icons } from '../../../assets/icons';
import './index.scss';

const OrderFail = ({ orderFailModal, setOrderFailModal }) => {
	return (
		<div className={'modalcontainer ' + (orderFailModal && 'open')}>
			<div
				className={'modal ' + (orderFailModal && 'active')}
				style={{ width: '35rem', height: '8rem' }}
			>
				<div className='close-modal' onClick={() => setOrderFailModal(!orderFailModal)}>
					<icons.MdClose />
				</div>
				<div className='client-modal-order'>
					<h1 style={{ color: '#B91C1C' }}>
						Le devis n'a pas été envoyé ! <br /> Il reste 2 "Batterie Lipo 3.7V 2500mAh" en stock
					</h1>
				</div>
			</div>
		</div>
	);
};

export default OrderFail;
