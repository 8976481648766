import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useDispatch } from 'react-redux';
import { convertDate } from '../../../helpers/modules';
import { LoaderComponent } from '../../../helpers/components/loader';
import ImprimerLogo from '../../../assets/icons/svg/document-text.svg';
import { getDeliveryByIdCommercial } from '../../../api/redux/slices/Commercial';
import { ClientInfo, ShopInfo, SuperAdminInfo } from '../../../components/userInfo';
import { useReactToPrint } from 'react-to-print';
import PrintToPdf from '../../admin/deliveryDetails/PrintToPdf';

const DeliveryDetailCom = () => {
	const [deliveryDetails, setDeliveryDetails] = useState([]);
	const dispatch = useDispatch();
	const params = useParams();
	const token = localStorage.getItem('accesstoken');

	const [loadingData, setLoadingData] = useState(false);
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const stableDispatchOrderById = useCallback(() => {
		setLoadingData(true);
		dispatch(
			getDeliveryByIdCommercial({
				token: token,
				id: params.id,
			}),
		)
			.unwrap()
			.then((res) => setDeliveryDetails(res.data))
			.finally(() => setLoadingData(false));
	}, [dispatch, params, token]);

	useEffect(() => {
		if (token && params.id) {
			stableDispatchOrderById();
		}
	}, [stableDispatchOrderById, token, params]);

	if (loadingData) {
		return <LoaderComponent />;
	}
	return (
		<div id='deliveryDetails'>
			<div className='details-delivery-header'>
				<Link to={`/commercial/deliveries/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Bons de livraisons</h1>
				</Link>
				<div style={{ display: 'flex', gap: '10px' }} className='add-btns'>
					<button
						onClick={handlePrint}
						style={{ display: 'flex', gap: '10px' }}
						className='button-primary'
					>
						<img src={ImprimerLogo} alt='imprimer logo' />
						Imprimer
					</button>
				</div>
			</div>
			<div id='title-delivery'>
				<h1>S.O.M.E.C Société Méditeranéenne d'Equipement et de Commerce</h1>
			</div>
			<div className='container'>
				<div className='credentials'>
					<div className='left'>
						<div className='left-el'>
							<label>Numéro</label>
							<span>{deliveryDetails?.delivery?.reference || '-'}</span>
						</div>
						<div className='left-el'>
							<label>Date</label>
							<span>
								{deliveryDetails?.delivery?.createdAt
									? convertDate(deliveryDetails.createdAt, 'DD-MM-YYYY')
									: '-'}
							</span>
						</div>
						<div className='left-el'>
							<label>Référence code client</label>
							<span>{deliveryDetails?.delivery?.order_id?.clientInfo?.customs_code || '-'}</span>
						</div>
					</div>
					<div className='left'>
						<div className='left-el'>
							<ClientInfo clientInfo={deliveryDetails?.delivery?.order_id?.clientInfo} />
							<ShopInfo shop={deliveryDetails?.delivery?.order_id?.shopInfo} />
						</div>
					</div>
				</div>
				<div className='devis'>
					<div className='devis-head'>
						<h1>BON DE LIVRAISON</h1>
						<span />
					</div>
					<div className='devis-table'>
						<table className='offres'>
							<thead>
								<tr id='titres'>
									<td>RÉFÉRENCE</td>
									<td>CODE E.A.N</td>
									<td>DÉSIGNATION</td>
									<td>QTÉ</td>
								</tr>
							</thead>
							<tbody>
								{deliveryDetails?.list?.map((item, index) => (
									<tr className='elements' key={index}>
										<td>{item?.reference}</td>
										<td>{item?.refranceCTL}</td>
										<td>{item?.designation}</td>
										<td>{item?.quantity}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div className='livreur'>
					<div className='left'>
						<h4>NOM &amp; PRENOM DU LIVREUR</h4>
						<span>{deliveryDetails?.delivery?.commercial_name || '------------'}</span>
					</div>

					<div className='left'>
						<h4>MATRICULE VEHICULE</h4>
						<span>{deliveryDetails?.delivery?.matricule_vehicle || '------------'}</span>
					</div>
				</div>
				<SuperAdminInfo superAdminInfo={deliveryDetails?.delivery?.order_id?.superAdminInfo} />
			</div>
			<div style={{ display: 'none' }}>
				<PrintToPdf componentRef={componentRef} deliveryDetails={deliveryDetails} />
			</div>
		</div>
	);
};

export default DeliveryDetailCom;
