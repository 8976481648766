import React from 'react';
import { Route, Routes } from 'react-router-dom';

import {
	ComProfile,
	UpdateComProfile,
	UpdateComPassword,
	ClientListPageCom,
	AddClient,
	ClientDetails,
	CategoriesPageCom,
	ProductPageCom,
	ProductCartCom,
	OrderDetailCom,
	OrderMarketCom,
	OrderCartCom,
	BillPageCom,
	BillDetailsCom,
	DeliveryPageCom,
	DeliveryDetailCom,
	ConfirmDelivery,
	OrdersPage,
	AddReturn,
	ReturnsPage,
	ReturnDetails,
} from '../pages/commercial';
import NotFound from '../pages/NotFound';

const CommercialRoutes = () => {
	return (
		<Routes>
			<Route exact path='/' element={<ComProfile />} />
			<Route exact path='/commercial/profile' element={<ComProfile />} />
			<Route exact path='/commercial/profile/update' element={<UpdateComProfile />} />
			<Route exact path='/commercial/profile/update_password' element={<UpdateComPassword />} />
			<Route exact path='/commercial/users/:page' element={<ClientListPageCom />} />
			<Route exact path='/commercial/users/add_client/:page' element={<AddClient />} />
			<Route exact path='/commercial/users/client_details/:page/:id' element={<ClientDetails />} />
			<Route exact path='/commercial/categories/:page' element={<CategoriesPageCom />} />
			<Route exact path='/commercial/products/:page' element={<ProductPageCom />} />
			<Route exact path='/commercial/products/product_cart/:page' element={<ProductCartCom />} />
			<Route exact path='/commercial/orders/:page' element={<OrdersPage />} />
			<Route exact path='/commercial/orders/order_details/:page/:id' element={<OrderDetailCom />} />
			<Route exact path='/commercial/orders/order_market/:page' element={<OrderMarketCom />} />
			<Route exact path='/commercial/orders/order_cart/:page' element={<OrderCartCom />} />
			<Route exact path='/commercial/bills/:page' element={<BillPageCom />} />
			<Route exact path='/commercial/bills/bill_details/:page/:id' element={<BillDetailsCom />} />
			<Route exact path='/commercial/deliveries/:page' element={<DeliveryPageCom />} />
			<Route
				exact
				path='/commercial/deliveries/delivery_details/:page/:id'
				element={<DeliveryDetailCom />}
			/>
			<Route
				exact
				path='/commercial/deliveries/confirm_delivery/:page/:id'
				element={<ConfirmDelivery />}
			/>
			<Route exact path='/commercial/deliveries/add_return/:page/:id' element={<AddReturn />} />
			<Route exact path='/commercial/returns/:page' element={<ReturnsPage />} />
			<Route
				exact
				path='/commercial/returns/return_details/:page/:id'
				element={<ReturnDetails />}
			/>
			<Route exact path='*' element={<NotFound />} />
		</Routes>
	);
};

export default CommercialRoutes;
