import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import ImprimerLogo from '../../../assets/icons/svg/document-text.svg';
import './index.scss';
import { useReactToPrint } from 'react-to-print';
import PrintToPdf from './PrintToPdf';
import { useDispatch } from 'react-redux';
import { ClientInfo, ShopInfo, SuperAdminInfo } from '../../../components/userInfo';
import { convertDate, roundToThreeDecimals } from '../../../helpers/modules';
import { LoaderComponent } from '../../../helpers/components/loader';
import {
	billCalculation,
	convertNumberToWords,
	finalTotal,
	total,
	totalNet,
} from './billFunctions';
import { getBillById } from '../../../api/redux/slices/Commercial';

const BillDetails = () => {
	const params = useParams();
	const [detailBill, setDetailBill] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	useEffect(() => {
		setIsLoading(true);
		dispatch(getBillById({ token: localStorage.getItem('accesstoken'), id: params.id }))
			.unwrap()
			.then((res) => {
				setDetailBill({ bill: { ...res.bill }, list: [...res.list] });
			})
			.finally(() => setIsLoading(false));
	}, [dispatch, params]);

	const { billCalculationArr, totalNetValue, totalArr, finalTotalValues } = useMemo(() => {
		const billCalculationArr = billCalculation(detailBill);
		const totalNetValue = totalNet(detailBill);
		const totalArr = total(detailBill);
		const finalTotalValues = finalTotal(billCalculationArr);

		return { billCalculationArr, totalNetValue, totalArr, finalTotalValues };
	}, [detailBill]);

	if (isLoading) {
		return <LoaderComponent />;
	}
	return (
		<div id='billDetailsCommercail'>
			<div className='details-bill-header'>
				<Link to={`/commercial/bills/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Bons de factures</h1>
				</Link>
				<button
					onClick={handlePrint}
					style={{ display: 'flex', gap: '10px' }}
					className='button-primary'
				>
					<img src={ImprimerLogo} alt='imprimer logo' />
					Imprimer
				</button>
			</div>
			<div id='title-bill'>
				<h1>S.O.M.E.C Société Méditeranéenne d'Equipement et de Commerce</h1>
			</div>
			<div className='container'>
				<div className='credentials'>
					<div className='left'>
						<div className='left-el'>
							<label>Numéro</label>
							<span>{detailBill?.bill?.reference || '-'}</span>
						</div>
						<div className='left-el'>
							<label>Date</label>
							<span>
								{detailBill?.bill?.createdAt
									? convertDate(detailBill?.bill?.createdAt, 'DD-MM-YYYY')
									: '-'}
							</span>
						</div>
						<div className='left-el'>
							<label>Référence code client</label>
							<span>{detailBill?.bill?.delivery?.order_id?.clientInfo?.customs_code || '-'}</span>
						</div>
					</div>
					<div className='left'>
						<div className='left-el'>
							<ClientInfo clientInfo={detailBill?.bill?.delivery?.order_id?.clientInfo} />
							<ShopInfo shop={detailBill?.bill?.delivery?.order_id?.shopInfo} />
						</div>
					</div>
				</div>
				<div className='devis'>
					<div className='devis-head'>
						<h1>FACTURE</h1>
						<span />
					</div>
					<div className='devis-table'>
						<table className='offres'>
							<thead>
								<tr id='titres'>
									<td>RÉFÉRENCE</td>
									<td>DÉSIGNATION</td>
									<td>QTÉ</td>
									<td>PRIX UNIT H.T</td>
									<td>REMISE</td>
									<td>MONTANT H.T</td>
									<td>TX.TVA</td>
								</tr>
							</thead>
							<tbody>
								{detailBill?.list?.map((item, index) => (
									<tr className='elements' key={index}>
										<td>{item?.reference}</td>
										<td>{item?.designation}</td>
										<td>{item?.quantity}</td>
										<td>
											{roundToThreeDecimals(
												(item?.prise_product_HT * item?.quantity) / (1 - item?.remise / 100),
											)}
										</td>
										<td>{item?.remise}%</td>
										<td>{roundToThreeDecimals(item?.prise_product_HT * item?.quantity)}</td>
										<td>{item?.tva}%</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<div className='billTable'>
						<table id='bt-principal'>
							<tbody>
								<tr id='firstBigRow'>
									<td>
										<table id='bt-nested-1'>
											<thead>
												<tr id='bt-head-1'>
													<td>CODE</td>
													<td>BASE</td>
													<td>TAUX</td>
													<td>TOTAL TAXE</td>
												</tr>
											</thead>
											<tbody>
												{billCalculationArr?.map((el, index) => (
													<tr className='bt-tl-1' key={index}>
														<td>{el.CODE}</td>
														<td>{roundToThreeDecimals(el.BASE)}</td>
														<td>{el.TAUX}%</td>
														<td>{roundToThreeDecimals(el.TOTAL_TAXE)}</td>
													</tr>
												))}
												<tr className='bt-tl-1'>
													<td>CTI</td>
													<td>0.000</td>
													<td />
													<td>1</td>
												</tr>
											</tbody>
										</table>
									</td>
									<td>
										<table id='bt-nested-2'>
											<thead>
												<tr id='bt-head-2'>
													<td>TOTAL H.T</td>
													<td>REMISE</td>
													<td>ESCOMPTE</td>
													<td>TOTAL TTC</td>
												</tr>
											</thead>
											<tbody>
												<tr className='bt-tl-2' id='bt-tl-2'>
													<td>{finalTotalValues.TOTAL_HT}</td>
													<td>{finalTotalValues.TOTAL_REMISE}</td>
													<td>0.000</td>
													<td>{finalTotalValues.TOTAL_TCC}</td>
												</tr>
												<tr className='bt-tl-2'>
													<td />
													<td />
													<td />
													<td />
												</tr>
												<tr className='bt-tl-2'>
													<td />
													<td />
													<td />
													<td />
												</tr>
											</tbody>
										</table>
									</td>
									<td>
										<table id='bt-nested-3'>
											<thead>
												<tr id='bt-head-3'>
													<td>NET A PAYER</td>
												</tr>
											</thead>
											<tbody>
												<tr className='bt-tl-3'>
													<td>{totalNetValue}</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
								<tr id='secondBigRow'>
									<td>
										<table id='bt-nested-4'>
											<tbody>
												<tr className='bt-head-4'>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
												</tr>
												<tr className='bt-head-4'>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
												</tr>
												{totalArr}
											</tbody>
										</table>
									</td>
									<td>
										<table id='bt-nested-5'>
											<thead>
												<tr id='bt-head-5'>
													<td>CONDITIONS DE REGLEMENT:</td>
													<td></td>
												</tr>
											</thead>
											<tbody>
												<tr className='bt-tl-5'>
													<td></td>
													<td></td>
												</tr>
												<tr className='bt-tl-5'>
													<td>90 JOURS</td>
													<td></td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
						<div className='billEnd'>
							<h2>ARRETER LA PRÉSENTE FACTURE À LA SOMME DE : </h2>
							<span>{convertNumberToWords(parseInt(totalNetValue * 1000))}</span>
						</div>
					</div>
				</div>
				<SuperAdminInfo superAdminInfo={detailBill?.bill?.delivery?.order_id?.superAdminInfo} />
			</div>
			<div style={{ display: 'none' }}>
				<PrintToPdf
					componentRef={componentRef}
					detailBill={detailBill}
					billCalculationArr={billCalculationArr}
					totalNetValue={totalNetValue}
					totalArr={totalArr}
				/>
			</div>
		</div>
	);
};

export default BillDetails;
