import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { showToast } from '../../../components/modal';
import { useDispatch, useSelector } from 'react-redux';
import { addBasket, updatedbasketTotalQty } from '../../../api/redux/slices/Customer';

const SeeProduct = ({ product, modalOpen, setModalOpen, priceWithVAT }) => {
	const dispatch = useDispatch();
	const [quantity, setQuantity] = useState(1);
	const token = localStorage.getItem('accesstoken');
	const { basketTotalQty } = useSelector((state) => state.customer);
	const handleAddToCart = () => {
		if (quantity < 1 || isNaN(quantity)) {
			showToast('La quantité doit être supérieure à 0.', 'warn', 'top-center', 1000);
			return;
		}

		const total = quantity * priceWithVAT;
		dispatch(addBasket({ token, data: { product_id: product._id, quantity, total } }))
			.unwrap()
			.then(() => {
				showToast('Produit ajouté au panier !', 'success');
				dispatch(updatedbasketTotalQty(basketTotalQty + quantity));
			});

		setModalOpen(false);
	};

	return (
		<div className={'modalcontainer ' + (modalOpen && 'open')}>
			<div className={'modal ' + (modalOpen && 'active')} style={{ top: '40%' }}>
				<div className='left-modal'>
					<img src={product?.image?.secure_url} alt='image_produit' />
				</div>

				<div className='right-modal'>
					<h3>Kit ProtoSnap PLus Lilypad DEV-1922</h3>
					<span id='price'>{priceWithVAT} TTC</span>
					<p className='desc'>{product.description}</p>
					<div className='line-desc'>
						<h4>Contenance</h4>
						<span>{product.capacity || '-'}</span>
					</div>
					<div className='line-desc'>
						<h4>Nombre de pièce -carton</h4>
						<span>{product.nb_cardboard_piece || '-'}</span>
					</div>
					<div className='line-desc'>
						<h4>Couleur</h4>
						<span>{product.color || '-'}</span>
					</div>
					<div className='line-desc'>
						<h4>Matière</h4>
						<span>{product.material}</span>
					</div>
					<div className='line-desc'>
						<h4>Quantité</h4>
						<input
							type='number'
							value={quantity}
							onChange={(e) => {
								const inputNumber = parseInt(e.target.value, 10);
								if (inputNumber < 0) {
									setQuantity(0);
									return;
								}
								setQuantity(inputNumber);
							}}
						/>
					</div>
					<div className='btn'>
						<Link to='#!'>
							<button
								disabled={product.capacity < quantity}
								className='button-primary'
								onClick={handleAddToCart}
							>
								<icons.RiShoppingCartLine />
								<span>Ajouter au panier</span>
							</button>
						</Link>
					</div>
				</div>
				<div className='close-modal' onClick={() => setModalOpen(false)}>
					<icons.MdClose />
				</div>
			</div>
		</div>
	);
};

export default SeeProduct;
