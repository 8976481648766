import produit_image from './assets/produit_image.jpg';
import { v4 as uuidv4 } from 'uuid';

export const AdminsData = [
	{
		id: '1',
		name: 'maren staton',
		email: 'marenstaton@somec.com',
		telephone: '21 212 214',
		statut: 'activé',
	},
	{
		id: '2',
		name: 'maren staton',
		email: 'marenstaton@somec.com',
		telephone: '21 212 214',
		statut: 'activé',
	},
	{
		id: '3',
		name: 'maren staton',
		email: 'marenstaton@somec.com',
		telephone: '21 212 214',
		statut: 'bloqué',
	},
	{
		id: '4',
		name: 'maren staton',
		email: 'marenstaton@somec.com',
		telephone: '21 212 214',
		statut: 'activé',
	},
	{
		id: '5',
		name: 'maren staton',
		email: 'marenstaton@somec.com',
		telephone: '21 212 214',
		statut: 'bloqué',
	},
	{
		id: '6',
		name: 'maren staton',
		email: 'marenstaton@somec.com',
		telephone: '21 212 214',
		statut: 'activé',
	},
	{
		id: '7',
		name: 'maren staton',
		email: 'marenstaton@somec.com',
		telephone: '21 212 214',
		statut: 'bloqué',
	},
	{
		id: '8',
		name: 'maren staton',
		email: 'marenstaton@somec.com',
		telephone: '21 212 214',
		statut: 'activé',
	},
];
export const AdminComplaintsData = [
	{
		id: '1',
		user: 'maron staton',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'traité',
		responsable: 'james botosh',
	},
	{
		id: '2',
		user: 'jane smith',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Impossible de changer le mot de passe ',
		status: 'En cours',
		responsable: 'oralnd noah',
	},
	{
		id: '3',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'test',
		status: 'En cours',
		responsable: 'james smaith',
	},
	{
		id: '4',
		user: 'John ismael',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Prix des produits un peu plus elevés',
		status: 'traité',
	},
	{
		id: '5',
		user: 'paul roland',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'En cours',
	},
	{
		id: '6',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'En cours',
	},
	{
		id: '7',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'traité',
		responsable: 'othman abidi',
	},
	{
		id: '8',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Demande de facture et de nouveaux devis',
		status: 'En cours',
	},
	{
		id: '9',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'traité',
		responsable: 'ronice yemeli yemeli',
	},
	{
		id: '10',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Demande de remise speciale',
		status: 'traité',
	},
];
export const orderData = [
	{
		id: uuidv4(),
		reference: 'REF-1201',
		date: '26/02/2022',
		total: '20',
		client: 'Amine Gdoura',
		societe: 'Amine.Inc',
		nombreObjets: 15,
	},
	{
		id: uuidv4(),
		reference: 'REF-1201',
		date: '26/02/2022',
		total: '20',
		client: 'Amine Gdoura',
		societe: 'Amine.Inc',
		nombreObjets: 15,
	},
	{
		id: uuidv4(),
		reference: 'REF-1201',
		date: '26/02/2022',
		total: '20',
		client: 'Amine Gdoura',
		societe: 'Amine.Inc',
		nombreObjets: 15,
	},
	{
		id: uuidv4(),
		reference: 'REF-1201',
		date: '26/02/2022',
		total: '20',
		client: 'Amine Gdoura',
		societe: 'Amine.Inc',
		nombreObjets: 15,
	},
	{
		id: uuidv4(),
		reference: 'REF-1201',
		date: '26/02/2022',
		total: '20',
		client: 'Amine Gdoura',
		societe: 'Amine.Inc',
		nombreObjets: 15,
	},
	{
		id: uuidv4(),
		reference: 'REF-1201',
		date: '26/02/2022',
		total: '20',
		client: 'Amine Gdoura',
		societe: 'Amine.Inc',
		nombreObjets: 15,
	},
	{
		id: uuidv4(),
		reference: 'REF-1201',
		date: '26/02/2022',
		total: '20',
		client: 'Amine Gdoura',
		societe: 'Amine.Inc',
		nombreObjets: 15,
	},
	{
		id: uuidv4(),
		reference: 'REF-1201',
		date: '26/02/2022',
		total: '20',
		client: 'Amine Gdoura',
		societe: 'Amine.Inc',
		nombreObjets: 15,
	},
	{
		id: uuidv4(),
		reference: 'REF-1201',
		date: '26/02/2022',
		total: '20',
		client: 'Amine Gdoura',
		societe: 'Amine.Inc',
		nombreObjets: 15,
	},
];
export const orderProducts = [
	{
		id: uuidv4(),
		name: 'Potentiomètre de précision 359',
		url: produit_image,
		prix: 398,
	},
	{
		id: uuidv4(),
		name: 'Potentiomètre de précision 359',
		url: produit_image,
		prix: 398,
	},
	{
		id: uuidv4(),
		name: 'Potentiomètre de précision 359',
		url: produit_image,
		prix: 398,
	},
	{
		id: uuidv4(),
		name: 'Potentiomètre de précision 359',
		url: produit_image,
		prix: 398,
	},
	{
		id: uuidv4(),
		name: 'Potentiomètre de précision 359',
		url: produit_image,
		prix: 398,
	},
	{
		id: uuidv4(),
		name: 'Potentiomètre de précision 359',
		url: produit_image,
		prix: 398,
	},
	{
		id: uuidv4(),
		name: 'Potentiomètre de précision 359',
		url: produit_image,
		prix: 398,
	},
	{
		id: uuidv4(),
		name: 'Potentiomètre de précision 359',
		url: produit_image,
		prix: 398,
	},
	{
		id: uuidv4(),
		name: 'Potentiomètre de précision 359',
		url: produit_image,
		prix: 398,
	},
	{
		id: uuidv4(),
		name: 'Potentiomètre de précision 359',
		url: produit_image,
		prix: 398,
	},
	{
		id: uuidv4(),
		name: 'Potentiomètre de précision 359',
		url: produit_image,
		prix: 398,
	},
	{
		id: uuidv4(),
		name: 'Potentiomètre de précision 359',
		url: produit_image,
		prix: 398,
	},
];
export const ProductsData = [
	{
		id: '1',
		nom: 'HTC Vive',
		reference: '00215698746',
		categorie: 'Electronique',
		prixDetail: '1000',
		description:
			'Ce pichet en verre facilitera le service de vos boissons au sein de votre famille',
		designation: 'Broc à eau 1L paris 111241-059',
		codebarre: '978020137962',
		contenance: 2,
		nombrepiècecarton: 6,
		couleur: 'transparent',
		matiere: 'verre',
		tva: 18,
		timbre: 0.33,
		remiseSur: 7,
		prixht: 5.759,
		remiseGros: 15,
		article: 'mobile',
		stock: 200,
		prixGros: '20',
		prixSur: '17.5',
		remiseDetail: '25',
		etat: true,
		statut: 'activé',
		isCheck: false,
	},
	{
		id: '2',
		nom: 'HTC Vive',
		reference: '00215698746',
		categorie: 'Electronique',
		prixDetail: '1500',
		description:
			'Ce pichet en verre facilitera le service de vos boissons au sein de votre famille',
		designation: 'Broc à eau 1L paris 111241-059',
		codebarre: '978020137962',
		conténance: 1,
		nombrepiècecarton: 6,
		couleur: 'transparent',
		matiere: 'verre',
		tva: 19,
		timbre: 0.33,
		remiseSur: 7,
		prixht: 5.759,
		remiseGros: 15,
		article: 'mobile',
		stock: 0,
		prixGros: '20',
		prixSur: '17.5',
		remiseDetail: '25',
		etat: true,
		statut: 'desactivé',
		isCheck: false,
	},
	{
		id: '3',
		nom: 'HTC Vive',
		reference: '00215698746',
		categorie: 'Electronique',
		prixDetail: '1000',
		description:
			'Ce pichet en verre facilitera le service de vos boissons au sein de votre famille',
		designation: 'Broc à eau 1L paris 111241-059',
		codebarre: '978020137962',
		conténance: 1,
		nombrepiècecarton: 6,
		couleur: 'transparent',
		matiere: 'verre',
		tva: 19,
		timbre: 0.33,
		remiseSur: 7,
		prixht: 5.759,
		remiseGros: 15,
		article: 'mobile',
		stock: 200,
		prixGros: '20',
		prixSur: '17.5',
		remiseDetail: '25',
		etat: false,
		statut: 'activé',
		isCheck: false,
	},
	{
		id: '4',
		nom: 'HTC Vive',
		reference: '00215698746',
		categorie: 'Détaillant',
		prixDetail: '750',
		description:
			'Ce pichet en verre facilitera le service de vos boissons au sein de votre famille',
		designation: 'Broc à eau 1L paris 111241-059',
		codebarre: '978020137962',
		conténance: 1,
		nombrepiècecarton: 6,
		couleur: 'transparent',
		matiere: 'verre',
		tva: 19,
		timbre: 0.33,
		remiseSur: 7,
		prixht: 5.759,
		remiseGros: 15,
		article: 'mobile',
		stock: 200,
		prixGros: '20',
		prixSur: '17.5',
		remiseDetail: '25',
		etat: false,
		statut: 'activé',
		isCheck: false,
	},
	{
		id: '5',
		nom: 'HTC Vive',
		reference: '00215698746',
		categorie: 'Electronique',
		prixDetail: '1500',
		description:
			'Ce pichet en verre facilitera le service de vos boissons au sein de votre famille',
		designation: 'Broc à eau 1L paris 111241-059',
		codebarre: '978020137962',
		conténance: 1,
		nombrepiècecarton: 6,
		couleur: 'transparent',
		matiere: 'verre',
		tva: 19,
		timbre: 0.33,
		remiseSur: 7,
		prixht: 5.759,
		remiseGros: 15,
		article: 'mobile',
		stock: 0,
		prixGros: '20',
		prixSur: '17.5',
		remiseDetail: '25',
		etat: false,
		statut: 'activé',
		isCheck: false,
	},
	{
		id: '6',
		nom: 'HTC Vive',
		reference: '00215698746',
		categorie: 'Electronique',
		prixDetail: '750',
		description:
			'Ce pichet en verre facilitera le service de vos boissons au sein de votre famille',
		designation: 'Broc à eau 1L paris 111241-059',
		codebarre: '978020137962',
		conténance: 1,
		nombrepiècecarton: 6,
		couleur: 'transparent',
		matiere: 'verre',
		tva: 19,
		timbre: 0.33,
		remiseSur: 7,
		prixht: 5.759,
		remiseGros: 15,
		article: 'mobile',
		stock: 200,
		prixGros: '20',
		prixSur: '17.5',
		remiseDetail: '25',
		etat: true,
		statut: 'desactivé',
		isCheck: false,
	},
	{
		id: '7',
		nom: 'HTC Vive',
		reference: '00215698746',
		categorie: 'Détaillant',
		prixDetail: '1500',
		description:
			'Ce pichet en verre facilitera le service de vos boissons au sein de votre famille',
		designation: 'Broc à eau 1L paris 111241-059',
		codebarre: '978020137962',
		conténance: 1,
		nombrepiècecarton: 6,
		couleur: 'transparent',
		matiere: 'verre',
		tva: 19,
		timbre: 0.33,
		remiseSur: 7,
		prixht: 5.759,
		remiseGros: 15,
		article: 'mobile',
		stock: 200,
		prixGros: '20',
		prixSur: '17.5',
		remiseDetail: '25',
		etat: false,
		statut: 'activé',
		isCheck: false,
	},
	{
		id: '8',
		nom: 'HTC Vive',
		reference: '00215698746',
		categorie: 'Electronique',
		prixDetail: '750',
		description:
			'Ce pichet en verre facilitera le service de vos boissons au sein de votre famille',
		designation: 'Broc à eau 1L paris 111241-059',
		codebarre: '978020137962',
		conténance: 1,
		nombrepiècecarton: 6,
		couleur: 'transparent',
		matiere: 'verre',
		tva: 19,
		timbre: 0.33,
		remiseSur: 7,
		prixht: 5.759,
		remiseGros: 15,
		article: 'mobile',
		stock: 0,
		prixGros: '20',
		prixSur: '17.5',
		remiseDetail: '25',
		etat: true,
		statut: 'desactivé',
		isCheck: false,
	},
];
export const ClientsData = [
	{
		id: '1',
		name: 'ronice yemeli',
		prenom: 'john doe',
		email: 'ronice@gmail.com',
		telephone1: '21 212 212',
		telephone2: '21 212 212',
		type: 'client',
		adresse: '42, Avenue de la liberté, belvedère 1002 tunis, Tunisie',
		societe: 'Quetratech',
		matriculeFiscal: '0001985G A M 000',
		categorie: 'grossiste',
		reglement: 'traité',
		statut: 'Activé',
	},
	{
		id: '2',
		name: 'ronice yemeli',
		prenom: 'john doe',
		email: 'aminegdoura2@gmail.com',
		telephone1: '21 212 212',
		telephone2: '21 212 212',
		type: 'admin',
		adresse: '42, Avenue de la liberté, belvedère 1002 tunis, Tunisie',
		societe: 'Quetratech',
		matriculeFiscal: '0001985G A M 000',
		categorie: 'grande surface',
		reglement: 'traité',
		statut: 'Bloqué',
	},
	{
		id: '3',
		name: 'amine',
		prenom: 'john doe',
		email: 'aminegdoura2@gmail.com',
		telephone1: '21 212 212',
		telephone2: '21 212 212',
		type: 'admin',
		adresse: '42, Avenue de la liberté, belvedère 1002 tunis, Tunisie',
		societe: 'Quetratech',
		matriculeFiscal: '0001985G A M 000',
		categorie: 'commercial',
		reglement: 'traité',
		statut: 'Activé',
	},
	{
		id: '4',
		name: 'paul',
		prenom: 'john doe',
		email: 'aminegdoura2@gmail.com',
		telephone1: '21 212 212',
		telephone2: '21 212 212',
		type: 'admin',
		adresse: '42, Avenue de la liberté, belvedère 1002 tunis, Tunisie',
		societe: 'Quetratech',
		matriculeFiscal: '0001985G A M 000',
		categorie: 'grossiste',
		reglement: 'Espèce',
		statut: 'Bloqué',
	},
	{
		id: '5',
		name: 'momonoskey',
		prenom: 'john doe',
		email: 'aminegdoura2@gmail.com',
		telephone1: '21 212 212',
		telephone2: '21 212 212',
		type: 'admin',
		adresse: '42, Avenue de la liberté, belvedère 1002 tunis, Tunisie',
		societe: 'Quetratech',
		matriculeFiscal: '0001985G A M 000',
		categorie: 'grossiste',
		reglement: 'traité',
		statut: 'Activé',
	},
	{
		id: '6',
		name: 'ronice yemeli',
		prenom: 'john doe',
		email: 'aminegdoura2@gmail.com',
		telephone1: '21 212 212',
		telephone2: '21 212 212',
		type: 'admin',
		adresse: '42, Avenue de la liberté, belvedère 1002 tunis, Tunisie',
		societe: 'Quetratech',
		matriculeFiscal: '0001985G A M 000',
		categorie: 'grossiste',
		reglement: 'Espèce',
		statut: 'Bloqué',
	},
	{
		id: '7',
		name: 'richard',
		prenom: 'john doe',
		email: 'aminegdoura2@gmail.com',
		telephone1: '21 212 212',
		telephone2: '21 212 212',
		type: 'admin',
		adresse: '42, Avenue de la liberté, belvedère 1002 tunis, Tunisie',
		societe: 'Quetratech',
		matriculeFiscal: '0001985G A M 000',
		categorie: 'grossiste',
		reglement: 'traité',
		statut: 'Activé',
	},
];
export const ClientsDataCommercial = [
	{
		id: '1',
		name: 'ronice yemeli',
		prenom: 'john doe',
		email: 'aminegdoura2@gmail.com',
		telephone1: '21 212 212',
		telephone2: '21 212 212',
		type: 'admin',
		adresse: '42, Avenue de la liberté, belvedère 1002 tunis, Tunisie',
		societe: 'Quetratech',
		matriculeFiscal: '0001985G A M 000',
		categorie: 'grossiste',
		reglement: 'traité',
		statut: 'Activé',
	},
	{
		id: '2',
		name: 'ronice yemeli',
		prenom: 'john doe',
		email: 'aminegdoura2@gmail.com',
		telephone1: '21 212 212',
		telephone2: '21 212 212',
		type: 'admin',
		adresse: '42, Avenue de la liberté, belvedère 1002 tunis, Tunisie',
		societe: 'Quetratech',
		matriculeFiscal: '0001985G A M 000',
		categorie: 'grande surface',
		reglement: 'traité',
		statut: 'Bloqué',
	},
	{
		id: '3',
		name: 'amine dhaoui',
		prenom: 'john doe',
		email: 'aminegdoura2@gmail.com',
		telephone1: '21 212 212',
		telephone2: '21 212 212',
		type: 'admin',
		adresse: '42, Avenue de la liberté, belvedère 1002 tunis, Tunisie',
		societe: 'Quetratech',
		matriculeFiscal: '0001985G A M 000',
		categorie: 'commercial',
		reglement: 'traité',
		statut: 'Activé',
	},
	{
		id: '4',
		name: 'paul firmin',
		prenom: 'john doe',
		email: 'aminegdoura2@gmail.com',
		telephone1: '21 212 212',
		telephone2: '21 212 212',
		type: 'admin',
		adresse: '42, Avenue de la liberté, belvedère 1002 tunis, Tunisie',
		societe: 'Quetratech',
		matriculeFiscal: '0001985G A M 000',
		categorie: 'grossiste',
		reglement: 'Espèce',
		statut: 'Bloqué',
	},
	{
		id: '5',
		name: 'momonoskey sama',
		prenom: 'john doe',
		email: 'aminegdoura2@gmail.com',
		telephone1: '21 212 212',
		telephone2: '21 212 212',
		type: 'admin',
		adresse: '42, Avenue de la liberté, belvedère 1002 tunis, Tunisie',
		societe: 'Quetratech',
		matriculeFiscal: '0001985G A M 000',
		categorie: 'grossiste',
		reglement: 'traité',
		statut: 'Activé',
	},
	{
		id: '6',
		name: 'ronice yemeli',
		prenom: 'john doe',
		email: 'aminegdoura2@gmail.com',
		telephone1: '21 212 212',
		telephone2: '21 212 212',
		type: 'admin',
		adresse: '42, Avenue de la liberté, belvedère 1002 tunis, Tunisie',
		societe: 'Quetratech',
		matriculeFiscal: '0001985G A M 000',
		categorie: 'grossiste',
		reglement: 'Espèce',
		statut: 'Bloqué',
	},
	{
		id: '7',
		name: 'richard doe',
		prenom: 'john doe',
		email: 'aminegdoura2@gmail.com',
		telephone1: '21 212 212',
		telephone2: '21 212 212',
		type: 'admin',
		adresse: '42, Avenue de la liberté, belvedère 1002 tunis, Tunisie',
		societe: 'Quetratech',
		matriculeFiscal: '0001985G A M 000',
		categorie: 'grossiste',
		reglement: 'traité',
		statut: 'Activé',
	},
];

export const TunisiaCities = [
	{ name: 'Ariana' },

	{ name: 'Beja' },

	{ name: 'Ben Arous' },

	{ name: 'Bizerte' },

	{ name: 'Gabès' },

	{ name: 'Gafsa' },

	{ name: 'Jendouba' },

	{ name: 'Kairouan' },

	{ name: 'Kasserine' },

	{ name: 'Kebili' },

	{ name: 'Kef' },

	{ name: 'Mahdia' },

	{ name: 'Manouba' },

	{ name: 'Medenine' },

	{ name: 'Monastir' },

	{ name: 'Nabeul' },

	{ name: 'Sfax' },

	{ name: 'Sidi Bouzid' },

	{ name: 'Siliana' },

	{ name: 'Sousse' },

	{ name: 'Tataouine' },

	{ name: 'Tozeur' },

	{ name: 'Tunis' },

	{ name: 'Zaghouan' },
];

export const CategoriesData = [
	{
		id: '1',
		name: 'electronique',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique eius provident ipsam obcaecati dicta facere, veritatis optio saepe inventore consequuntur praesentium .',
	},
	{
		id: '2',
		name: 'mercerie',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique eius provident ipsam obcaecati dicta facere, veritatis optio saepe inventore consequuntur praesentium .',
	},
	{
		id: '3',
		name: 'vivres frais',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique eius provident ipsam obcaecati dicta facere, veritatis optio saepe inventore consequuntur praesentium .',
	},
	{
		id: '4',
		name: 'electronique',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique eius provident ipsam obcaecati dicta facere, veritatis optio saepe inventore consequuntur praesentium .',
	},
	{
		id: '5',
		name: 'divers',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique eius provident ipsam obcaecati dicta facere, veritatis optio saepe inventore consequuntur praesentium .',
	},
	{
		id: '6',
		name: 'alimentaire',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique eius provident ipsam obcaecati dicta facere, veritatis optio saepe inventore consequuntur praesentium .',
	},
	{
		id: '7',
		name: 'electromenager',
		description:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique eius provident ipsam obcaecati dicta facere, veritatis optio saepe inventore consequuntur praesentium .',
	},
];
export const DeliveriesData = [
	{
		id: '1',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
	},
	{
		id: '2',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
	},
	{
		id: '3',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
	},
	{
		id: '4',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
	},
	{
		id: '5',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
	},
	{
		id: '6',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
	},
	{
		id: '7',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
	},
	{
		id: '8',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
	},
	{
		id: '9',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
	},
];
export const BillsData = [
	{
		id: '1',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		nombreObjets: 15,
		total: 1200,
	},
	{
		id: '2',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		nombreObjets: 15,
		total: 1200,
	},
	{
		id: '3',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		nombreObjets: 15,
		total: 1200,
	},
	{
		id: '4',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		nombreObjets: 15,
		total: 1200,
	},
	{
		id: '5',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		nombreObjets: 15,
		total: 1200,
	},
	{
		id: '6',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		nombreObjets: 15,
		total: 1200,
	},
	{
		id: '7',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		nombreObjets: 15,
		total: 1200,
	},
	{
		id: '8',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		nombreObjets: 15,
		total: 1200,
	},
	{
		id: '9',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		nombreObjets: 15,
		total: 1200,
	},
];
export const ReturnsData = [
	{
		id: '1',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
		statut: 'traité',
	},
	{
		id: '2',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
		statut: 'traité',
	},
	{
		id: '3',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
		statut: 'en cours',
	},
	{
		id: '4',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
		statut: 'en cours',
	},
	{
		id: '5',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
		statut: 'traité',
	},
	{
		id: '6',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
		statut: 'en cours',
	},
	{
		id: '7',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
		statut: 'traité',
	},
	{
		id: '8',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
		statut: 'en cours',
	},
	{
		id: '9',
		numero: 'FC00024',
		date: '01/05/2022',
		client: 'Justin Bosh',
		societe: 'société de commerce',
		total: 1200,
		statut: 'traité',
	},
];
export const ComplaintsData = [
	{
		id: '1',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'traité',
	},
	{
		id: '2',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Impossible de changer le mot de passe ',
		status: 'En cours',
	},
	{
		id: '3',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'test',
		status: 'En cours',
	},
	{
		id: '4',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Prix des produits un peu plus elevés',
		status: 'traité',
	},
	{
		id: '5',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'En cours',
	},
	{
		id: '6',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'En cours',
	},
	{
		id: '7',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'traité',
	},
	{
		id: '8',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Demande de facture et de nouveaux devis',
		status: 'En cours',
	},
	{
		id: '9',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'traité',
	},
	{
		id: '10',
		user: 'John Doe',
		societe: 'company .Inc',
		numero: `21 212 212`,
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Demande de remise speciale',
		status: 'traité',
	},
];
export const BoutiquesData = [
	{
		id: '1',
		boutique: 'boutique de tunis',
	},
	{
		id: '2',
		boutique: 'boutique de nabeul',
	},
	{
		id: '3',
		boutique: 'boutique de sfax',
	},
];
export const ordersClient = [
	{
		id: '1',
		numero: 'FC20041',
		date: '01/05/2022',
		qty: 15,
		total: 1200,
		createdBy: 'ronice yemeli yemeli ',
	},
	{
		id: '2',
		numero: 'FC20041',
		date: '01/05/2022',
		qty: 15,
		total: 1200,
		createdBy: 'Hazem Rekik',
	},
	{
		id: '3',
		numero: 'FC20041',
		date: '01/05/2022',
		qty: 15,
		total: 1200,
		createdBy: 'Rekik Yasine',
	},
	{
		id: '4',
		numero: 'FC20041',
		date: '01/05/2022',
		qty: 15,
		total: 1200,
		createdBy: '',
	},
	{
		id: '5',
		numero: 'FC20041',
		date: '01/05/2022',
		qty: 15,
		total: 1200,
		createdBy: 'Jerbi mira',
	},
	{
		id: '6',
		numero: 'FC20041',
		date: '01/05/2022',
		qty: 15,
		total: 1200,
		createdBy: 'ahlemn rzouga',
	},
	{
		id: '7',
		numero: 'FC20041',
		date: '01/05/2022',
		qty: 15,
		total: 1200,
		createdBy: '',
	},
	{
		id: '8',
		numero: 'FC20041',
		date: '01/05/2022',
		qty: 15,
		total: 1200,
		createdBy: 'Walid Bachir',
	},
	{
		id: '9',
		numero: 'FC20041',
		date: '01/05/2022',
		qty: 15,
		total: 1200,
		createdBy: 'moi',
	},
];
export const ComplaintsClientsData = [
	{
		id: '1',
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'traité',
	},
	{
		id: '2',
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Impossible de changer le mot de passe ',
		status: 'En cours',
	},
	{
		id: '3',
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'test',
		status: 'En cours',
	},
	{
		id: '4',
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Prix des produits un peu plus elevés',
		status: 'traité',
	},
	{
		id: '5',
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'En cours',
	},
	{
		id: '6',
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'En cours',
	},
	{
		id: '7',
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'traité',
	},
	{
		id: '8',
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Demande de facture et de nouveaux devis',
		status: 'En cours',
	},
	{
		id: '9',
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Livraison',
		status: 'traité',
	},
	{
		id: '10',
		createdAt: '11/05/2022',
		email: 'christofer.vertrows@hotmail.com',
		message: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ipsa odit accusantium placeat
					architecto adipisci! Veniam ex voluptas reprehenderit odio suscipit blanditiis quos expedita modi
					architecto quibusdam. Dolore, porro dicta.`,
		object: 'Demande de remise speciale',
		status: 'traité',
	},
];
