import { Link, useParams } from 'react-router-dom';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { icons } from '../../../assets/icons';
import SeeProduct from './SeeProduct';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	addProductToBasket,
	deleteProductBasketByCommercial,
	getCategoriesForProducts,
	getCommercialProductsList,
	getProductsBasketByCommercial,
	updateBasketTotalQty,
} from '../../../api/redux/slices/Commercial';
import Pagination from '../../../helpers/components/Pagination';
import { LoaderComponent } from '../../../helpers/components/loader';
import { ProductCard } from '../../../components/cards/Product';
import { typePrice } from '../../../helpers/modules';
import { showToast } from '../../../components/modal';

const OrderMarketCom = () => {
	const [modalOpen, setModalOpen] = useState(false);
	const [indiceProduit, setIndiceProduit] = useState(null);
	const params = useParams();
	const { listProducts, total, basketTotalQty } = useSelector((state) => state.commercial);
	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState(Number(params.page));
	const [search, setSearch] = useState('');
	const [categoryValue, setCategoryValue] = useState('');

	const token = localStorage.getItem('accesstoken');
	const [categoriesList, setCategorisList] = useState([]);

	const dispatch = useDispatch();

	const company = JSON.parse(localStorage.getItem('company'));
	const clientType = company?.categories;

	//----------------------- get products and categories features -------------------------------//
	const stableDispatch = useCallback(() => {
		setIsLoading(true);
		dispatch(
			getCommercialProductsList({
				token: token,
				page: search || categoryValue ? undefined : page,
				search,
				category_name: categoryValue,
			}),
		)
			.unwrap()
			.finally(() => setIsLoading(false));
	}, [dispatch, token, page, categoryValue, search]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [stableDispatch, token]);

	useEffect(() => {
		dispatch(getCategoriesForProducts({ token: token }))
			.unwrap()
			.then((res) => setCategorisList(res.data));
	}, [dispatch, token]);

	useEffect(() => {
		if (token) {
			setIsLoading(true);
			dispatch(getProductsBasketByCommercial({ token: token, id: company._id }))
				.unwrap()
				.then((res) => {
					res.data.forEach((item) => {
						if (!item.product) {
							dispatch(deleteProductBasketByCommercial({ token, id: item._id }));
						}
					});
				})
				.finally(() => setIsLoading(false));
		}
	}, [dispatch, token, company._id]);

	const addCart = (product, qty) => {
		dispatch(updateBasketTotalQty(basketTotalQty + qty));
		const data = {
			product_id: product._id,
			quantity: qty,
			total: qty * product?.[typePrice(clientType)],
			user_id: company._id,
		};

		dispatch(addProductToBasket({ token, data: data }))
			.unwrap()
			.then(() => {
				showToast('Produit ajouté au panier', 'success');
			})
			.catch((err) => {
				showToast(err, 'error');
			});
	};
	//------------------------ main component ----------------------//
	return (
		<div id='pr-page-com'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='search'
							placeholder='Rechercher ...'
							onChange={(e) => setSearch(e.target.value)}
						/>
						<icons.FiSearch className='searchicon' />
					</div>
					<div className='filters'>
						<select
							name='catégories'
							value={categoryValue}
							onChange={(e) => setCategoryValue(e.target.value)}
						>
							<option value=''>Catégories</option>
							{categoriesList.map((cat) => (
								<Fragment key={cat._id}>
									<option value={cat._id}>{cat.designation}</option>
								</Fragment>
							))}
						</select>
					</div>
				</div>
				<Link to={`/commercial/orders/order_cart/${page}`}>
					<div className='right'>
						<div className='cart'>
							<icons.RiShoppingCartLine className='cart-icon' color='#000' />
							<div className='cart-count'>
								<span>{basketTotalQty}</span>
							</div>
						</div>
						<h3>Panier</h3>
					</div>
				</Link>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Produits</h1>
				</div>
			</div>
			{isLoading ? (
				<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
					<LoaderComponent margin='10%' />
				</div>
			) : (
				<div id='cartContent'>
					{listProducts.map((product, index) => (
						<div key={index}>
							<ProductCard
								img_url={product.image.secure_url || process.env.REACT_APP_IMAGE_PLACEHOLDER_URL}
								onClickShop={() => addCart(product, 1)}
								onClickSee={() => {
									setIndiceProduit(product._id);
									setModalOpen(!modalOpen);
								}}
								product_name={product.designation ?? '-'}
								product_price={product?.[typePrice(clientType)] ?? '-'}
								isDisabled={product.capacity < 1}
							/>
							{indiceProduit === product._id && (
								<SeeProduct
									product_id={product._id}
									modalOpen={modalOpen}
									setModalOpen={setModalOpen}
								/>
							)}
						</div>
					))}
				</div>
			)}
			<Pagination
				page={page}
				setPage={setPage}
				pageLink='/commercial/orders/order_market/'
				total={total}
			/>
		</div>
	);
};

export default OrderMarketCom;
