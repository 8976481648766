import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { useSelector } from 'react-redux';

const SuperAdminProfile = () => {
	const { user } = useSelector((state) => state.auth);
	const [infos, setInfos] = useState(user.data);

	useEffect(() => {
		setInfos(user.data);
	}, [user.data]);

	return (
		<div id='supProfile'>
			<div id='sup-profile-header'>
				<h1>Profile</h1>
				<Link to={`/superadmin/profile/update`}>
					<button className='button-primary'>Modifier Profile</button>
				</Link>
			</div>
			<div className='infos'>
				<div className='line'>
					<h2>Nom</h2>
					<span>{infos.name}</span>
				</div>
				<div className='line'>
					<h2>Adresse</h2>
					<span>{infos.address}</span>
				</div>
				<div className='line'>
					<h2>Code TVA</h2>
					<span>{infos.tva_code}</span>
				</div>
				<div className='line'>
					<h2>R.C</h2>
					<span>{infos.r_c}</span>
				</div>
				<div className='line'>
					<h2>Code en Douane</h2>
					<span>{infos.customs_code}</span>
				</div>
				<div className='line'>
					<h2>Téléphone</h2>
					<span>{infos.phone}</span>
				</div>
				<div className='line'>
					<h2>Fax</h2>
					<span>{infos.fax}</span>
				</div>
				<div className='line'>
					<h2>Email</h2>
					<span>{infos.email}</span>
				</div>
			</div>
		</div>
	);
};

export default SuperAdminProfile;
