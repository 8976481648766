import React, { useState, Fragment, useEffect } from 'react';
import { icons } from '../../../assets/icons';
import './index.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TunisiaCities } from '../../../data';
import { getUserDetails, updateUserByAdmin } from '../../../api/redux/slices/Admin';
import { useDispatch } from 'react-redux';
import DeleteConfirmationModal from '../addUser/ShopDeleteModal';
import { v4 as uuidv4 } from 'uuid';
import { ButtonComponent } from '../../../components/buttons';
import { showToast } from '../../../components/modal';
import ListVehicles from './ListVehicles';
import { LoaderComponent } from '../../../helpers/components/loader';

const UpdateUser = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const token = localStorage.getItem('accesstoken');
	const [shopToDeleteIndex, setShopToDeleteIndex] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(false);

	const [infos, setInfos] = useState({});
	const [isFocus, setIsFocus] = useState(false);
	const [client, setClient] = useState();
	const [shopFields, setShopFields] = useState();

	useEffect(() => {
		setIsLoading(true);
		dispatch(getUserDetails({ token: token, id: params.id }))
			.unwrap()
			.then((res) => {
				setInfos(res.data);
				setShopFields(res.data?.shop?.map((el, i) => ({ ...el, id: uuidv4() })));
				setClient(res.data?.role === 'customer' ? true : false);
			})
			.finally(() => setIsLoading(false));
	}, [dispatch, token, params.id]);

	const addField = () => {
		let newfield = {
			id: uuidv4(),
			name: '',
			address_number: '',
			street: '',
			ville: '',
			postal_code: '',
			region: '',
		};

		setShopFields([...shopFields, newfield]);
	};

	const handleChangeShopForm = (id, e) => {
		setIsFocus(true);
		let x = e.target.name;
		setShopFields((current) =>
			current.map((obj) => {
				if (obj.id === id) {
					if (x === 'name') {
						return { ...obj, name: e.target.value };
					} else if (x === 'address_number') {
						return { ...obj, address_number: e.target.value };
					} else if (x === 'street') {
						return { ...obj, street: e.target.value };
					} else if (x === 'ville') {
						return { ...obj, ville: e.target.value };
					} else if (x === 'postal_code') {
						return { ...obj, postal_code: e.target.value.replace(/\D/g, '') };
					} else {
						return { ...obj, region: e.target.value };
					}
				}
				return obj;
			}),
		);
	};

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const handleDeleteAddedShop = (id) => {
		if (shopFields.length > 1) {
			const updatedShopFields = shopFields.filter((field) => field.id !== id);
			setShopFields(updatedShopFields);
		}
	};

	//---------------------------------------- Update Commercial -------------------------------//
	const handleUpdateCommercial = (e) => {
		e.preventDefault();
		const data = {
			company_name: infos.company_name.trim(),
			email: infos.email.trim(),
			first_name: infos.first_name.trim(),
			last_name: infos.last_name.trim(),
			phone: infos.phone.trim(),
			tax_registration_number: infos.tax_registration_number.trim(),
		};
		if (
			!data.company_name ||
			!data.email ||
			!data.first_name ||
			!data.last_name ||
			!data.phone ||
			!data.tax_registration_number
		) {
			showToast('Veuillez remplir tous les champs', 'warn');
			return;
		}
		setLoading(true);
		dispatch(updateUserByAdmin({ token: token, id: params.id, data: data }))
			.unwrap()
			.then(() => {
				showToast('Utilisateur mis à jour', 'success', 'top-center', 1000);
				navigate(`/admin/users/user_details/${params.page}/${params.id}`);
			})
			.catch((err) => {
				showToast(err?.message || err, 'error');
			})
			.finally(() => setLoading(false));
	};

	const handleUpdateClient = (e) => {
		e.preventDefault();
		const dataClient = {
			company_name: infos.company_name.trim(),
			email: infos.email.trim(),
			first_name: infos.first_name.trim(),
			last_name: infos.last_name.trim(),
			phone: infos.phone.trim(),
			tax_registration_number: infos.tax_registration_number.trim(),
			customs_code: infos.customs_code.trim(),
			categories: infos.categories,
			regulation: infos.regulation,
			is_client: true,
		};
		if (
			!dataClient.company_name ||
			!dataClient.email ||
			!dataClient.first_name ||
			!dataClient.last_name ||
			!dataClient.phone ||
			!dataClient.tax_registration_number ||
			!dataClient.customs_code
		) {
			showToast('Veuillez remplir tous les champs', 'warn');
			return;
		}

		let canUpdateUser = true;
		let newShopFields = shopFields.map((field) => {
			const data = {
				name: field.name.trim(),
				address_number: field.address_number,
				street: field.street.trim(),
				ville: field.ville.trim(),
				postal_code: field.postal_code,
				region: field.region,
				_id: field._id,
			};
			if (!data.name || !data.street || !data.ville || !data.region) {
				showToast('Veuillez remplir tous les champs', 'warn');
				canUpdateUser = false;
			}
			return data;
		});

		if (canUpdateUser) {
			const clientinfos = { ...dataClient, shop: newShopFields };
			setLoading(true);
			dispatch(updateUserByAdmin({ token: token, id: params.id, data: clientinfos }))
				.unwrap()
				.then(() => {
					showToast('Utilisateur mis à jour', 'success', 'top-center', 1000);
					navigate(`/admin/users/user_details/${params.page}/${params.id}`);
				})
				.catch((err) => {
					showToast(err?.message || err, 'error');
				})
				.finally(() => setLoading(false));
		}
	};

	const handleRadioChange = (categoryOrRegulation) => {
		setInfos({
			...infos,
			[categoryOrRegulation.type]: categoryOrRegulation.value,
		});
		setIsFocus(true);
	};

	if (isLoading) {
		return <LoaderComponent />;
	}

	return (
		<div id='add-user'>
			<form onSubmit={client ? handleUpdateClient : handleUpdateCommercial}>
				<div className='navbar' style={{ width: 'calc(100vw - 20rem)', marginBottom: '2rem' }}>
					<Link
						to={`/admin/users/${params.page}`}
						style={{ width: '500px', display: 'flex', alignItems: 'center' }}
					>
						<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
						<h1>Liste d'utilisateurs</h1>
					</Link>
					<div className='submit-btns'>
						<div className='add-btns'>
							<Link to={`/admin/users/${params.page}`}>
								<button className={isFocus ? 'button-nature' : 'button-nature-inactive'}>
									Annuler
								</button>
							</Link>
							<ButtonComponent
								name='Enregistrer'
								loading={loading}
								type='submit'
								className={isFocus ? 'button-primary' : 'button-primary-inactive'}
							/>
						</div>
					</div>
				</div>
				<div className='user-infos'>
					<h2>Type d'utilisateur</h2>
					{infos?.role === 'commercial' ? (
						<div className='line'>
							<input
								type='radio'
								name='choose'
								checked={!client}
								onChange={() => {}}
								value={infos.role}
							/>
							<label>Commercial</label>
						</div>
					) : (
						<div className='line'>
							<input
								type='radio'
								name='choose'
								checked={client}
								onChange={() => {}}
								value={infos.role}
							/>
							<label>Client</label>
						</div>
					)}
				</div>
				{client && (
					<Fragment>
						<div className='user-infos'>
							<h2>Type catégorie</h2>
							<div className='line'>
								<input
									type='radio'
									name='usercategory'
									checked={infos.categories === 'detaillant'}
									value={infos.categories}
									onChange={() =>
										handleRadioChange({
											type: 'categories',
											value: 'detaillant',
										})
									}
								/>
								<label>Détaillant</label>
							</div>
							<div className='line'>
								<input
									type='radio'
									name='usercategory'
									checked={infos.categories === 'grossiste'}
									value='grossiste'
									onChange={() =>
										handleRadioChange({
											type: 'categories',
											value: 'grossiste',
										})
									}
								/>
								<label htmlFor='grossiste'>Grossiste</label>
							</div>
							<div className='line'>
								<input
									type='radio'
									name='usercategory'
									checked={infos.categories === 'monoprix'}
									value={infos.categories}
									onChange={() =>
										handleRadioChange({
											type: 'categories',
											value: 'monoprix',
										})
									}
								/>
								<label htmlFor='categoryclient'>Grande surface</label>
							</div>
							{/* <div className='line'>
								<input
									type='radio'
									name='usercategory'
									checked={infos.categories === 'umd'}
									value={infos.categories}
									onChange={() =>
										handleRadioChange({
											type: 'categories',
											value: 'umd',
										})
									}
								/>
								<label htmlFor='categoryclient'>UMD</label>
							</div>
							<div className='line'>
								<input
									type='radio'
									name='usercategory'
									checked={infos.categories === 'black'}
									value={infos.categories}
									onChange={() =>
										handleRadioChange({
											type: 'categories',
											value: 'black',
										})
									}
								/>
								<label htmlFor='categoryclient'>Black</label>
							</div> */}
						</div>
						<div className='user-infos'>
							<h2>Type de réglément</h2>
							<div className='line'>
								<input
									type='radio'
									name='userreglement'
									checked={infos.regulation === 'espece'}
									value={infos.regulation}
									onChange={() => handleRadioChange({ type: 'regulation', value: 'espece' })}
								/>
								<label htmlFor='espece'>Espèce</label>
							</div>
							<div className='line'>
								<input
									type='radio'
									name='userreglement'
									checked={infos.regulation === 'traite'}
									value={infos.regulation}
									onChange={() =>
										handleRadioChange({
											type: 'regulation',
											value: 'traite',
										})
									}
								/>
								<label htmlFor='traite'>Traité</label>
							</div>
							<div className='line'>
								<input
									type='radio'
									name='userreglement'
									checked={infos.regulation === 'cheque'}
									value={infos.regulation}
									onChange={() =>
										handleRadioChange({
											type: 'regulation',
											value: 'cheque',
										})
									}
								/>
								<label htmlFor='categoryclient'>Chèque</label>
							</div>
						</div>
					</Fragment>
				)}
				<div className='user-details'>
					<h2>Information générale</h2>
					<div className='form-line'>
						<div className='frag'>
							<label>Nom</label>
							<input
								type='text'
								placeholder='nom'
								value={infos.first_name}
								onChange={(e) => {
									setInfos({ ...infos, first_name: e.target.value });
									setIsFocus(true);
								}}
								required
							/>
						</div>
						<div className='frag'>
							<label>Prénom</label>
							<input
								type='text'
								placeholder='prenom'
								value={infos.last_name}
								onChange={(e) => {
									setInfos({ ...infos, last_name: e.target.value });
									setIsFocus(true);
								}}
								required
							/>
						</div>
					</div>
					<div className='field'>
						<label htmlFor='email'>Email</label>
						<input
							type='email'
							placeholder='nom@email.com'
							value={infos.email}
							onChange={(e) => {
								setInfos({ ...infos, email: e.target.value });
								setIsFocus(true);
							}}
						/>
					</div>
					<div className='field'>
						<label>Numéro de téléphone</label>
						<input
							type='text'
							placeholder='ex: 21 212 212'
							value={infos.phone}
							onChange={(e) => {
								const onlyNumbers = e.target.value.replace(/[^0-9+\s]/g, '');
								setInfos({ ...infos, phone: onlyNumbers });
								setIsFocus(true);
							}}
							required
						/>
					</div>
					<div className='field'>
						<label>Nom de société</label>
						<input
							type='text'
							placeholder='nom de société'
							value={infos.company_name}
							onChange={(e) => {
								setInfos({ ...infos, company_name: e.target.value });
								setIsFocus(true);
							}}
							required
						/>
					</div>
					<div className='field'>
						<label>Matricule fiscal</label>
						<input
							type='text'
							placeholder='ex: 0001985G A M 000'
							value={infos.tax_registration_number}
							onChange={(e) => {
								setInfos({
									...infos,
									tax_registration_number: e.target.value,
								});
								setIsFocus(true);
							}}
							required
						/>
					</div>
					{client && (
						<div className='field'>
							<label>Référence code client</label>
							<input
								type='text'
								placeholder='ex: 1245557'
								value={infos.customs_code}
								onChange={(e) => {
									setInfos({
										...infos,
										customs_code: e.target.value,
									});
									setIsFocus(true);
								}}
								required
							/>
						</div>
					)}
				</div>
				{client && (
					<Fragment>
						<div className='add-shop-title'>
							<h2>Liste des boutiques</h2>
							<icons.BsPlusSquareDotted
								style={{ fontSize: '1.3rem', cursor: 'pointer' }}
								title='ajouter une nouvelle boutique'
								onClick={addField}
							/>
						</div>
						{shopFields.map((inputValue, index) => {
							return (
								<div className='shop' key={index}>
									<div className='del-shop'>
										<div className='box-bordered' title='supprimer cette boutique'>
											<icons.BsTrash
												className='del-shop-icon'
												onClick={() => {
													setShowDeleteModal(true);
													setShopToDeleteIndex(inputValue.id);
												}}
											/>
										</div>
									</div>
									<label>Nom de boutique</label>
									<input
										type='text'
										placeholder='Nom de boutique'
										name='name'
										required
										value={inputValue.name}
										onChange={(e) => handleChangeShopForm(inputValue.id, e)}
									/>
									<div className='delivery-details'>
										<label>Adresse</label>
										<div className='info-1'>
											<input
												type='number'
												placeholder='Numéro'
												name='address_number'
												required
												value={inputValue.address_number}
												onChange={(e) => handleChangeShopForm(inputValue.id, e)}
												onWheel={(e) => e.currentTarget.blur()}
											/>
											<input
												type='text'
												placeholder='Rue'
												name='street'
												required
												value={inputValue.street}
												onChange={(e) => handleChangeShopForm(inputValue.id, e)}
											/>
										</div>
										<div className='info-1'>
											<input
												type='text'
												placeholder='Ville'
												name='ville'
												required
												value={inputValue.ville}
												onChange={(e) => handleChangeShopForm(inputValue.id, e)}
											/>
											<input
												type='text'
												placeholder='Code postal'
												name='postal_code'
												required
												value={inputValue.postal_code}
												onChange={(e) => handleChangeShopForm(inputValue.id, e)}
											/>
										</div>
										<div className='info-dropdown'>
											<select
												required
												name='region'
												value={inputValue.region}
												onChange={(e) => handleChangeShopForm(inputValue.id, e)}
											>
												<option>Choisir un gouvernorat</option>
												{TunisiaCities.map((city, index) => (
													<Fragment key={index}>
														<option key={index} value={city.name}>
															{city.name}
														</option>
													</Fragment>
												))}
											</select>
										</div>
									</div>
									{showDeleteModal && (
										<DeleteConfirmationModal
											onDelete={() => {
												handleDeleteAddedShop(shopToDeleteIndex);
												setIsFocus(true);
												setShowDeleteModal(false);
											}}
											onCancel={() => setShowDeleteModal(false)}
										/>
									)}
								</div>
							);
						})}
					</Fragment>
				)}
			</form>
			{!client && <ListVehicles id={infos?._id} listVehicles={infos?.vehicles} />}
		</div>
	);
};

export default UpdateUser;
