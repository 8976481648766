import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { icons } from '../../assets/icons/index';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../api/redux/slices/Auth';
import 'react-toastify/dist/ReactToastify.css';
import { clearCheckList as clearCheckListAdmin } from '../../api/redux/slices/Admin';
import { clearCheckList as clearCheckListCommercial } from '../../api/redux/slices/Commercial';
import { clearCheckList as clearCheckListCustomer } from '../../api/redux/slices/Customer';
import { showToast } from '../../components/modal';
import { ButtonComponent } from '../../components/buttons';
import Logo from '../../components/logo';

export const handleLogout = (dispatch, token, naviagte) => {
	dispatch(logout({ token: token }))
		.unwrap()
		.then(() => {
			localStorage.removeItem('accesstoken');
			localStorage.clear();
			window.location.href = '/login';
		})
		.catch(() => {
			showToast('Problème de deconnexion, veuillez récharger la page', 'warn');
		});
};

const NavBar = () => {
	const { pathname } = useLocation();
	const currentPage = pathname.split('/')[2];
	const [pageSelected, setPageSelected] = useState(currentPage);
	const { role } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const token = localStorage.getItem('accesstoken');
	const [disconnectModal, setDisconnectModal] = useState(false);
	const naviagte = useNavigate();

	useEffect(() => {
		setPageSelected(currentPage);
	}, [currentPage]);

	if (pathname === '/login') {
		return null;
	}

	const handleNavigateAdmin = (page) => {
		setPageSelected(page);
		dispatch(clearCheckListAdmin());
	};
	const handleNavigateCommercail = (page) => {
		setPageSelected(page);
		dispatch(clearCheckListCommercial());
	};

	const handleNavigateCustomer = (page) => {
		setPageSelected(page);
		dispatch(clearCheckListCustomer());
	};

	return (
		<div className='sidebar'>
			<div style={{ position: 'absolute' }} />
			<div className='content'>
				<div id='logoBox'>
					<Logo />
				</div>
				<ul id='nav'>
					{role === 'admin' && (
						<Fragment>
							<Link to='/admin/profile'>
								<li
									className={
										pageSelected === 'profile' || pageSelected === undefined
											? 'navItem active'
											: 'navItem'
									}
									onClick={() => handleNavigateAdmin('profile')}
								>
									<icons.CgProfile />
									<span>Profile</span>
								</li>
							</Link>
							<Link to={`/admin/users/${1}`}>
								<li
									className={pageSelected === 'users' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateAdmin('users')}
								>
									<icons.RiUserSearchLine />
									<span>Liste d'utilisateurs</span>
								</li>
							</Link>
							<Link to={`/admin/categories/${1}`}>
								<li
									className={pageSelected === 'categories' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateAdmin('categories')}
								>
									<icons.BiCategory />
									<span>Catégories</span>
								</li>
							</Link>
							<Link to={`/admin/products/${1}`}>
								<li
									className={pageSelected === 'products' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateAdmin('products')}
								>
									<icons.BsBoxSeam />
									<span>Produits</span>
								</li>
							</Link>
							<Link to={`/admin/orders/${1}`}>
								<li
									className={pageSelected === 'orders' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateAdmin('orders')}
								>
									<icons.TbClipboardText />
									<span>Bons de commandes</span>
								</li>
							</Link>
							<Link to={`/admin/deliveries/${1}`}>
								<li
									className={pageSelected === 'deliveries' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateAdmin('deliveries')}
								>
									<icons.TbTruckDelivery />
									<span>Bons de livraisons</span>
								</li>
							</Link>
							<Link to={`/admin/bills/${1}`}>
								<li
									className={pageSelected === 'bills' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateAdmin('bills')}
								>
									<icons.VscChecklist />
									<span>Bons de factures</span>
								</li>
							</Link>
							<Link to={`/admin/returns/${1}`}>
								<li
									className={pageSelected === 'returns' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateAdmin('returns')}
								>
									<icons.RiRepeatLine />
									<span>Bons de retour</span>
								</li>
							</Link>
							<Link to={`/admin/complaints/${1}`}>
								<li
									className={pageSelected === 'complaints' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateAdmin('complaints')}
								>
									<icons.BiMailSend />
									<span>Réclamations</span>
								</li>
							</Link>
						</Fragment>
					)}
					{role === 'super-admin' && (
						<Fragment>
							<Link to='/superadmin/profile'>
								<li
									className={
										pageSelected === 'profile' || pageSelected === undefined
											? 'navItem active'
											: 'navItem'
									}
									onClick={() => setPageSelected('profile')}
								>
									<icons.CgProfile />
									<span>Profile</span>
								</li>
							</Link>
							<Link to={`/superadmin/users/${1}`}>
								<li
									className={pageSelected === 'users' ? 'navItem active' : 'navItem'}
									onClick={() => setPageSelected('users')}
								>
									<icons.BsShield />
									<span>Liste administrateurs</span>
								</li>
							</Link>
							<Link to={`/superadmin/complaints/${1}`}>
								<li
									className={pageSelected === 'complaints' ? 'navItem active' : 'navItem'}
									onClick={() => setPageSelected('complaints')}
								>
									<icons.BiMailSend />
									<span>Réclamations</span>
								</li>
							</Link>
						</Fragment>
					)}
					{role === 'customer' && (
						<Fragment>
							<Link to='/client/profile'>
								<li
									className={
										pageSelected === 'profile' || pageSelected === undefined
											? 'navItem active'
											: 'navItem'
									}
									onClick={() => handleNavigateCustomer('profile')}
								>
									<icons.CgProfile />
									<span>Profile</span>
								</li>
							</Link>
							<Link to={`/client/categories/${1}`}>
								<li
									className={pageSelected === 'categories' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateCustomer('categories')}
								>
									<icons.BiCategory />
									<span>Catégories</span>
								</li>
							</Link>
							<Link to={`/client/products/${1}`}>
								<li
									className={pageSelected === 'products' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateCustomer('products')}
								>
									<icons.BsBoxSeam />
									<span>Produits</span>
								</li>
							</Link>
							<Link to='/client/orders/1'>
								<li
									className={pageSelected === 'orders' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateCustomer('orders')}
								>
									<icons.TbClipboardText />
									<span>Bons de commandes</span>
								</li>
							</Link>
							<Link to='/client/bills/1'>
								<li
									className={pageSelected === 'bills' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateCustomer('bills')}
								>
									<icons.VscChecklist />
									<span>Bons de Factures</span>
								</li>
							</Link>
							<Link to='/client/complaints/1'>
								<li
									className={pageSelected === 'complaints' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateCustomer('complaints')}
								>
									<icons.BiMailSend />
									<span>Réclamations</span>
								</li>
							</Link>
						</Fragment>
					)}
					{role === 'commercial' && (
						<Fragment>
							<Link to='/commercial/profile'>
								<li
									className={
										pageSelected === 'profile' || pageSelected === undefined
											? 'navItem active'
											: 'navItem'
									}
									onClick={() => handleNavigateCommercail('profile')}
								>
									<icons.CgProfile />
									<span>Profile</span>
								</li>
							</Link>
							<Link to='/commercial/users/1'>
								<li
									className={pageSelected === 'users' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateCommercail('users')}
								>
									<icons.RiUserSearchLine />
									<span>Liste des clients</span>
								</li>
							</Link>
							<Link to='/commercial/categories/1'>
								<li
									className={pageSelected === 'categories' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateCommercail('categories')}
								>
									<icons.BiCategory />
									<span>Catégories</span>
								</li>
							</Link>
							<Link to='/commercial/products/1'>
								<li
									className={pageSelected === 'products' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateCommercail('products')}
								>
									<icons.BsBoxSeam />
									<span>Produits</span>
								</li>
							</Link>
							<Link to='/commercial/orders/1'>
								<li
									className={pageSelected === 'orders' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateCommercail('orders')}
								>
									<icons.TbClipboardText />
									<span>Bons de commandes</span>
								</li>
							</Link>
							<Link to='/commercial/deliveries/1'>
								<li
									className={pageSelected === 'deliveries' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateCommercail('deliveries')}
								>
									<icons.TbTruckDelivery />
									<span>Bons de livraisons</span>
								</li>
							</Link>
							<Link to='/commercial/bills/1'>
								<li
									className={pageSelected === 'bills' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateCommercail('bills')}
								>
									<icons.VscChecklist />
									<span>Bons de factures</span>
								</li>
							</Link>
							<Link to='/commercial/returns/1'>
								<li
									className={pageSelected === 'returns' ? 'navItem active' : 'navItem'}
									onClick={() => handleNavigateCommercail('returns')}
								>
									<icons.BiMailSend />
									<span>Bons de retour</span>
								</li>
							</Link>
						</Fragment>
					)}
					<li className='logout' onClick={() => setDisconnectModal(true)}>
						<icons.IoMdLogOut />
						<span>Se deconnecter</span>
					</li>
				</ul>
			</div>
			{disconnectModal && (
				<>
					<div className='modal-delete'>
						<div className='delete-confirmation-modal'>
							<p>{'Voulez-vous vraiment vous déconnecter?'}</p>
							<div className='modal-btns'>
								<button
									className='button-nature-inactive'
									onClick={() => setDisconnectModal(false)}
								>
									Annuler
								</button>
								<ButtonComponent
									name={'Déconnecter'}
									type={'button'}
									className={'button-primary'}
									onClick={() => {
										setDisconnectModal(false);
										handleLogout(dispatch, token, naviagte);
									}}
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default NavBar;
