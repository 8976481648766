import { roundToThreeDecimals } from '../helpers/modules';

const calculateTotalTva = (orderDetails) => {
	if (!orderDetails?.list) {
		return [];
	}
	const tvaMap = new Map();

	orderDetails.list.forEach((item) => {
		const tva = item.tva;
		const totalAmount = item.prise_product_HT * (tva / 100) * item?.quantity;

		if (tvaMap.has(tva)) {
			tvaMap.set(tva, tvaMap.get(tva) + totalAmount);
		} else {
			tvaMap.set(tva, totalAmount);
		}
	});

	return Array.from(tvaMap, ([tvaValue, totalAmount]) => ({
		tvaValue,
		totalAmount: roundToThreeDecimals(totalAmount),
	}));
};

const renderTvaValues = (orderDetails) => {
	const tvaList = calculateTotalTva(orderDetails);

	return tvaList.length > 0 ? (
		tvaList.map(({ tvaValue, totalAmount }, index) => (
			<div key={index}>
				<h1>{`TVA (${tvaValue}%)`}</h1>
				<span id='somme-totale'>{totalAmount}</span>
			</div>
		))
	) : (
		<div>No TVA values available</div>
	);
};

export { calculateTotalTva, renderTvaValues };
