import axios from '../http-common';

const createAxiosRequest = (token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return {
		get: (url, params = {}) => axios.get(url, { ...config, ...params }),
		post: (url, data) => axios.post(url, data, config),
		put: (url, data) => axios.put(url, data, config),
		delete: (url) => axios.delete(url, config),
	};
};

const axiosWithToken = createAxiosRequest;
const getAdminsList = (page, token, search) => {
	return axiosWithToken(token).get(`/super-admin/list-admin?page=${page}&search=${search}`);
};

const getClaimsList = (token, page) => {
	return axiosWithToken(token).get(`/super-admin/claim/?page=${page}`);
};

const createAdmin = (token, data) => {
	return axiosWithToken(token).post('/super-admin/list-admin/create', data);
};

const blockAdmin = (token, id, data) => {
	return axiosWithToken(token).post(`/super-admin/list-admin/blocked/${id}`, data);
};

const updateAdmin = (token, id, data) => {
	return axiosWithToken(token).put(`/super-admin/list-admin/${id}`, data);
};

const searchAdmin = (token, name) => {
	return axiosWithToken(token).post(`/super-admin/list-admin/search?search=${name}`);
};

const searchClaim = (token, value, page, status) => {
	return axiosWithToken(token).get(`/super-admin/claim`, {
		params: { search: value, page: page, status: status },
	});
};

const SuperAdminServices = {
	getAdminsList,
	createAdmin,
	blockAdmin,
	updateAdmin,
	searchAdmin,
	getClaimsList,
	searchClaim,
};
export default SuperAdminServices;
