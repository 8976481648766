import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CommercialServices from '../../services/CommercialServices';

const initialState = {
	infos: {},
	claims: [],
	claimsArr: [],
	loading: false,
	error: null,
	list: [],
	listOrders: [],
	orderDetails: {},
	clientsListOrdering: [],
	categories: [],
	errorMessage: null,
	isSuccess: false,
	orders: [],
	listCategories: [],
	listProducts: [],
	checkedList: [],
	delivery: [],
	bills: [],
	returns: [],
	basketTotalQty: 0,
	total: 0,
};

export const usersList_commercial = createAsyncThunk(
	'/users_commercial',
	async ({ token, name, categories, regulation, page }, thunkApi) => {
		try {
			const res = await CommercialServices.getListForCommercial(
				token,
				name,
				categories,
				regulation,
				page,
			);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getProfileDetails = createAsyncThunk(
	'/getProfileDetails',
	async ({ token }, thunkApi) => {
		try {
			const res = await CommercialServices.getProfileDetails(token);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getUserDetails = createAsyncThunk('/user_details', async ({ token, id }, thunkApi) => {
	try {
		const res = await CommercialServices.getUserDetails(token, id);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
export const addVehicle = createAsyncThunk('/add_vehicle', async ({ token, data }, thunkApi) => {
	try {
		const res = await CommercialServices.addVehicle(token, data);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const deleteVehicle = createAsyncThunk(
	'/delete_vehicle',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await CommercialServices.deleteVehicle(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const new_user_commercial = createAsyncThunk(
	'/create_user_commercial',
	async ({ token, data }, thunkApi) => {
		try {
			const res = await CommercialServices.create_user_commercial(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const new_user_client_commercial = createAsyncThunk(
	'/create_client_by__commercial',
	async ({ token, data }, thunkApi) => {
		try {
			const res = await CommercialServices.create_user_client_by_commercial(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const updateUserByCommercial = createAsyncThunk(
	'/update_user_by_commercial',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await CommercialServices.updateUserByCommercial(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const userBlocked = createAsyncThunk(
	'/user_blocked',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await CommercialServices.blockUserForCommercial(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const userUnblocked = createAsyncThunk(
	'/user_unblock',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await CommercialServices.blockUserForCommercial(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const claimsListCommercial = createAsyncThunk(
	'/claims_commercial',
	async ({ page }, thunkApi) => {
		try {
			const res = CommercialServices.getListClaimsCommercial(page);
			const promise = Promise.resolve(res);
			return promise.then((value) => {
				return value.data;
			});
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const updateCommercialProfile = createAsyncThunk(
	'/update_profile_commercial',
	async ({ token, data }, thunkApi) => {
		try {
			const res = await CommercialServices.updateCommercialProfile(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const updateCommercialPassword = createAsyncThunk(
	'/update_profile_password_commercial',
	async ({ token, data }, thunkApi) => {
		try {
			const res = await CommercialServices.updateCommercialPassword(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const getCommercialProductsList = createAsyncThunk(
	'/get_products_for_commercial',
	async ({ token, page, search, category_name }, thunkApi) => {
		try {
			const res = await CommercialServices.getProductsListForCommercial(
				token,
				page,
				search,
				category_name,
			);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const getCategoriesForProducts = createAsyncThunk(
	'/getcategories_products',
	async ({ token }, thunkApi) => {
		try {
			const res = await CommercialServices.getCategoriesForProducts(token);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const getCategories = createAsyncThunk(
	'/get_categories_for_commercial',
	async ({ token, page, search }, thunkApi) => {
		try {
			const res = await CommercialServices.getCategoriesForCommercial(token, page, search);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getOrdersByCommercial = createAsyncThunk(
	`/getOrdersByCommercial`,
	async ({ token, page, search, status }, thunkApi) => {
		try {
			const res = await CommercialServices.getOrdersByCommercial(token, page, search, status);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getOrderByIdCommercial = createAsyncThunk(
	'/get_order_by_id_admin',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await CommercialServices.getOrderByIdCommercial(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const checkStock = createAsyncThunk('/check_stock', async ({ token, list }, thunkApi) => {
	try {
		const res = await CommercialServices.checkStock(token, list);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
export const clientsOrderListByCommercial = createAsyncThunk(
	'/getClientListAdmin',
	async ({ token, value }, thunkApi) => {
		try {
			const res = await CommercialServices.clientsOrderListByCommercial(token, value);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const addProductToBasket = createAsyncThunk(
	'/addOrderCommercial',
	async ({ token, data }, thunkApi) => {
		try {
			const res = await CommercialServices.addProductToBasket(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const udpateStockProduitBasket = createAsyncThunk(
	'/update_stock_basket',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await CommercialServices.udpateStockProduitBasket(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const getProductsBasketByCommercial = createAsyncThunk(
	'/getProductsBasketByCommercial',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await CommercialServices.getProductsBasketByCommercial(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const deleteProductBasketByCommercial = createAsyncThunk(
	'/deleteProductBasketByCommercial',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await CommercialServices.deleteProductBasketByCommercial(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const addOrderByCommercial = createAsyncThunk(
	'/addOrderCommercial',
	async ({ token, data }, thunkApi) => {
		try {
			const res = await CommercialServices.addOrderByCommercial(token, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const clearProductBasketByCommercial = createAsyncThunk(
	'/clearProductBasketByCommercial',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await CommercialServices.clearProductBasketByCommercial(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getDeliveryByCommercial = createAsyncThunk(
	'/getDeliveryByCommercial',
	async ({ token, page, search, status }, thunkApi) => {
		try {
			const res = await CommercialServices.getDeliveryByCommercial(token, page, search, status);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const addPayment = createAsyncThunk('/addPayment', async ({ token, id, data }, thunkApi) => {
	try {
		const res = await CommercialServices.addPayment(token, id, data);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
export const updatePayment = createAsyncThunk(
	'/updatePayment',
	async ({ token, id, data }, thunkApi) => {
		try {
			const res = await CommercialServices.updatePayment(token, id, data);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getDeliveryByIdCommercial = createAsyncThunk(
	'/getDeliveryByIdCommercial',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await CommercialServices.getDeliveryByIdCommercial(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);

export const getPayementDetails = createAsyncThunk(
	'/getPayementDetails',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await CommercialServices.getPayementDetails(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const accepteDelivery = createAsyncThunk(
	'/accepteDelivery',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await CommercialServices.accepteDelivery(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const refuseDelivery = createAsyncThunk(
	'/refuseDelivery',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await CommercialServices.refuseDelivery(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getListProductDelivery = createAsyncThunk(
	'/getListProductDelivery',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await CommercialServices.getListProductDelivery(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const addReturn = createAsyncThunk('/addReturn', async ({ token, id, data }, thunkApi) => {
	try {
		const res = await CommercialServices.addReturn(token, id, data);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
export const getClaimByCommercial = createAsyncThunk(
	'/getClaimByCommercial',
	async ({ token }, thunkApi) => {
		try {
			const res = await CommercialServices.getClaimByCommercial(token);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getReturnByCommercial = createAsyncThunk(
	'/getReturnByCommercial',
	async ({ token, page, search }, thunkApi) => {
		try {
			const res = await CommercialServices.getReturnByCommercial(token, page, search);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getBillsByCommercial = createAsyncThunk(
	'/getBillsByCommercial',
	async ({ token, search, page }, thunkApi) => {
		try {
			const res = await CommercialServices.getBillsByCommercial(token, search, page);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
export const getBillById = createAsyncThunk('/getBillById', async ({ token, id }, thunkApi) => {
	try {
		const res = await CommercialServices.getBillById(token, id);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
export const getReturnByIdCommercial = createAsyncThunk(
	'/getReturnByIdCommercial',
	async ({ token, id }, thunkApi) => {
		try {
			const res = await CommercialServices.getReturnByIdCommercial(token, id);
			return res.data;
		} catch (error) {
			return thunkApi.rejectWithValue(error.response.data);
		}
	},
);
const commercialSlice = createSlice({
	name: 'commercial',
	initialState,
	reducers: {
		clearCheckList: (state) => {
			state.checkedList = [];
		},
		setCheckedList: (state, action) => {
			const itemId = action.payload._id;
			if (!state.checkedList.some((item) => item._id === itemId)) {
				return {
					...state,
					checkedList: [...state.checkedList, action.payload],
				};
			}
			return state;
		},
		setUnCheckedList: (state, action) => {
			state.checkedList = state.checkedList.filter((el) => el._id !== action.payload._id);
		},
		updateBasketTotalQty: (state, action) => {
			if (action.payload < 0) {
				return;
			}
			state.basketTotalQty = action.payload;
		},
	},
	extraReducers: {
		[getProfileDetails.fulfilled]: (state, action) => {
			state.infos = action.payload.data;
		},
		[getCategories.pending]: (state) => {
			state.loading = true;
		},
		[getCategories.fulfilled]: (state, action) => {
			state.categories = action.payload;
			state.listCategories = action.payload.data;
			state.total = action.payload.total;
			state.isSuccess = action.payload.success;
			state.loading = false;
		},
		[getCategories.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload;
			state.loading = false;
			state.isSuccess = false;
		},
		[getOrdersByCommercial.fulfilled]: (state, action) => {
			state.listOrders = action.payload.data;
			state.total = action.payload.total;
		},
		[getOrderByIdCommercial.fulfilled]: (state, action) => {
			state.orderDetails = action.payload.data;
		},
		[clientsOrderListByCommercial.fulfilled]: (state, action) => {
			state.clientsListOrdering = action.payload.data.list;
		},
		[usersList_commercial.pending]: (state) => {
			state.loading = true;
		},
		[usersList_commercial.fulfilled]: (state, action) => {
			state.users = action.payload;
			state.list = action.payload.data.list;
			state.total = action.payload.total;
			state.isSuccess = action.payload.success;
			state.isFetching = true;
			state.loading = false;
		},
		[usersList_commercial.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload;
			state.loading = false;
			state.isSuccess = false;
		},
		[new_user_commercial.pending]: (state) => {
			state.loading = true;
		},
		[new_user_client_commercial.pending]: (state) => {
			state.loading = true;
		},
		[new_user_commercial.fulfilled]: (state, action) => {
			state.users = { ...state.users, ...action.payload };
			state.isSuccess = action.payload.success;
			state.loading = false;
		},
		[new_user_client_commercial.fulfilled]: (state, action) => {
			state.users = { ...state.users, ...action.payload };
			state.isSuccess = action.payload.success;
			state.loading = false;
		},
		[new_user_commercial.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload;
			state.loading = false;
			state.isSuccess = false;
		},
		[new_user_client_commercial.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload;
			state.loading = false;
			state.isSuccess = false;
		},
		[claimsListCommercial.pending]: (state) => {
			state.loading = true;
		},
		[claimsListCommercial.fulfilled]: (state, action) => {
			state.claims = action.payload;
			state.claimsArr = action.payload.data;
			state.isSuccess = true;
			state.loading = false;
		},
		[claimsListCommercial.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload.message;
			state.loading = false;
			state.isSuccess = false;
		},
		[updateCommercialProfile.fulfilled]: (state, action) => {
			state.isSuccess = true;
			state.loading = false;
		},
		[updateCommercialPassword.fulfilled]: (state, action) => {
			state.isSuccess = true;
			state.loading = false;
		},
		[getCommercialProductsList.fulfilled]: (state, action) => {
			state.isSuccess = true;
			state.loading = false;
			state.listProducts = action.payload.data;
			state.total = action.payload.total;
		},
		[getCommercialProductsList.pending]: (state) => {
			state.loading = true;
		},
		[getCommercialProductsList.rejected]: (state, { payload }) => {
			state.error = true;
			state.errorMessage = payload;
			state.loading = false;
			state.isSuccess = false;
		},
		[getProductsBasketByCommercial.fulfilled]: (state, action) => {
			state.basketTotalQty = isNaN(action.payload.NbProduct) ? 0 : action.payload.NbProduct;
		},
		[getDeliveryByCommercial.fulfilled]: (state, action) => {
			state.delivery = action.payload.data;
			state.total = action.payload.total;
		},
		[getBillsByCommercial.fulfilled]: (state, action) => {
			state.bills = action.payload.data;
			state.total = action.payload.total;
		},
		[getReturnByCommercial.fulfilled]: (state, action) => {
			state.returns = action.payload.data;
			state.total = action.payload.total;
		},
	},
});

export const { setCheckedList, clearCheckList, setUnCheckedList, updateBasketTotalQty } =
	commercialSlice.actions;
export default commercialSlice.reducer;
