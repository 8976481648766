import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getCommercial, getDeliveryById, updateDelivery } from '../../../api/redux/slices/Admin';
import { showToast } from '../../../components/modal';
import { convertDate } from '../../../helpers/modules';
import { LoaderComponent } from '../../../helpers/components/loader';
import { ButtonComponent } from '../../../components/buttons';
import { ClientInfo, ShopInfo, SuperAdminInfo } from '../../../components/userInfo';

const DeliveryConfirm = () => {
	const { deliveryDetails } = useSelector((state) => state.admin);
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();
	const token = localStorage.getItem('accesstoken');
	const [commercial, setCommercial] = useState([]);
	const [selectedCommercialId, setSelectedCommercialId] = useState('');
	const [selectedVehicle, setSelectedVehicle] = useState('');

	const [loadingData, setLoadingData] = useState(false);
	const [isloading, setIsLoading] = useState(false);

	const stableDispatchOrderById = useCallback(() => {
		setLoadingData(true);
		dispatch(
			getDeliveryById({
				token: token,
				id: params.id,
			}),
		)
			.unwrap()
			.catch((err) => {
				showToast(err?.message || 'can not fetch this delivery', 'error');
				setTimeout(() => {
					navigate(`/admin/deliveries/${params.page}`);
				}, 1000);
			})
			.finally(() => setLoadingData(false));
	}, [dispatch, params, token, navigate]);

	useEffect(() => {
		if (deliveryDetails?.delivery?.commercial_id) {
			setSelectedCommercialId(deliveryDetails.delivery.commercial_id);
		}

		if (deliveryDetails?.delivery?.matricule_vehicle) {
			setSelectedVehicle(deliveryDetails.delivery.matricule_vehicle);
		}
	}, [deliveryDetails]);

	useEffect(() => {
		if (token && params.id) {
			stableDispatchOrderById();
		}
	}, [stableDispatchOrderById, token, params]);

	useEffect(() => {
		dispatch(getCommercial({ token: token }))
			.unwrap()
			.then((res) => setCommercial(res.users))
			.catch((err) => {});
	}, [dispatch, token]);

	const handleCommercialChange = (e) => {
		setSelectedCommercialId(e.target.value);
	};

	const handleVehicleChange = (e) => {
		setSelectedVehicle(e.target.value);
	};

	const handleConfirm = () => {
		if (!selectedCommercialId) {
			showToast('Veuillez selectionner le commercial', 'error');
			return;
		}
		if (!selectedVehicle) {
			showToast('Veuillez selectionner le véhicule', 'error');
			return;
		}
		setIsLoading(true);
		dispatch(
			updateDelivery({
				token: token,
				id: params.id,
				data: {
					commercial_id: selectedCommercialId,
					matricule_vehicle: selectedVehicle,
				},
			}),
		)
			.unwrap()
			.then((res) => {
				showToast('Le bon de livraison a été mis à jour!', 'success');
				setTimeout(() => {
					navigate(`/admin/deliveries/delivery_details/${params.page}/${params.id}`);
				}, 1000);
			})
			.catch((err) => {
				showToast(err?.message || 'can not confirm this delivery', 'error');
			})
			.finally(() => setIsLoading(false));
	};

	if (loadingData) {
		return <LoaderComponent />;
	}
	return (
		<div id='deliveryDetails'>
			<div className='details-delivery-header'>
				<Link to={`/admin/deliveries/${params.page}`}>
					<icons.BsArrowLeft style={{ color: '#5B5B5B', marginRight: '10px' }} />
					<h1>Bons de livraisons</h1>
				</Link>

				<div style={{ display: 'flex', gap: '10px' }} className='add-btns'>
					<button
						className='button-nature'
						onClick={() => navigate(`/admin/deliveries/${params.page}`)}
					>
						Annuler
					</button>
					<ButtonComponent
						name={'Enregistrer'}
						loading={isloading}
						type={'button'}
						className={'button-primary'}
						onClick={handleConfirm}
					/>
				</div>
			</div>
			<div id='title-delivery'>
				<h1>S.O.M.E.C Société Méditeranéenne d'Equipement et de Commerce</h1>
			</div>
			<div className='container'>
				<div className='credentials'>
					<div className='left'>
						<div className='left-el'>
							<label>Numéro</label>
							<span>{deliveryDetails?.delivery?.reference || '-'}</span>
						</div>
						<div className='left-el'>
							<label>Date</label>
							<span>
								{deliveryDetails?.delivery?.createdAt
									? convertDate(deliveryDetails.createdAt, 'DD-MM-YYYY')
									: '-'}
							</span>
						</div>
						<div className='left-el'>
							<label>Référence</label>
							<span>{deliveryDetails?.delivery?.order_id?.clientInfo?.customs_code || '-'}</span>
						</div>
					</div>
					<div className='left'>
						<div className='left-el'>
							<ClientInfo clientInfo={deliveryDetails?.delivery?.order_id?.clientInfo} />
							<ShopInfo shop={deliveryDetails?.delivery?.order_id?.shopInfo} />
						</div>
					</div>
				</div>
				<div className='devis'>
					<div className='devis-head'>
						<h1>BON DE LIVRAISON</h1>
						<span />
					</div>
					<div className='devis-table'>
						<table className='offres'>
							<thead>
								<tr id='titres'>
									<td>RÉFÉRENCE</td>
									<td>CODE E.A.N</td>
									<td>DÉSIGNATION</td>
									<td>QTÉ</td>
								</tr>
							</thead>
							<tbody>
								{deliveryDetails?.list?.map((item, index) => (
									<tr className='elements' key={index}>
										<td>{item?.reference}</td>
										<td>{item?.refranceCTL}</td>
										<td>{item?.designation}</td>
										<td>{item?.quantity}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div className='livreur'>
					<div className='left'>
						<h4>NOM &amp; PRENOM DU LIVREUR</h4>
						<select value={selectedCommercialId} onChange={handleCommercialChange}>
							<option value=''>Ajouter un livreur</option>
							{commercial.map((item) => (
								<option key={item._id} value={item._id}>
									{item.first_name} {item.last_name}
								</option>
							))}
						</select>
					</div>
					<div className='left'>
						<h4>MATRICULE VEHICULE</h4>
						<div className='left'>
							<select
								value={selectedVehicle}
								onChange={handleVehicleChange}
								disabled={!selectedCommercialId}
							>
								<option value=''>Select Vehicle</option>
								{selectedCommercialId &&
									commercial
										.find((item) => item._id === selectedCommercialId)
										?.vehicles.map((vehicle) => (
											<option key={vehicle._id} value={vehicle.vehicle_number}>
												{vehicle.vehicle_number}
											</option>
										))}
							</select>
						</div>
					</div>
				</div>
				<SuperAdminInfo superAdminInfo={deliveryDetails?.delivery?.order_id?.superAdminInfo} />
			</div>
		</div>
	);
};

export default DeliveryConfirm;
