import React from 'react';

const ShopInfo = ({ shop_id, shop }) => {
	const ShopInfo = shop?.find((item) => item._id === shop_id);

	if (!ShopInfo) {
		return null;
	}

	return (
		<div>
			<span>{ShopInfo.address_number} </span>
			<span>{ShopInfo.street}</span>
			<span>{ShopInfo.ville} </span>
			<span>{ShopInfo.postal_code} </span>
			<span>{ShopInfo.region} </span>
		</div>
	);
};

export default ShopInfo;
