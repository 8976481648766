import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useDispatch, useSelector } from 'react-redux';
import { userBlocked, usersList_admin } from '../../../api/redux/slices/Admin';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { LoaderComponent } from '../../../helpers/components/loader';
import Pagination from '../../../helpers/components/Pagination';
import useCSVExport from './csvExport';
import useStableArr from '../../../utils/useStableArr';
import { handleCheck, handleCheckAll } from '../../../utils/checkUtilsAdmin';
import { convertCategory, convertRegulation } from '../../../helpers/modules';

const ClientListPage = () => {
	const { list, checkedList, total } = useSelector((state) => state.admin);

	const [openDropdown, setOpenDropdown] = useState(false);
	const [indice, setIndice] = useState(null);
	const [userName, setUserName] = useState('');
	const [regulationName, setRegulationName] = useState('');
	const [categoriesName, setCategoriesName] = useState('');
	const [typing, setTyping] = useState(false);
	const [newArr, setNewArr] = useState(list.map((el) => ({ ...el, checked: false })));
	const [isCheck, setIsCheck] = useState(false);
	const params = useParams();
	const dispatch = useDispatch();
	const [page, setPage] = useState(Number(params.page));
	const token = localStorage.getItem('accesstoken');
	const csvUsersReport = useCSVExport(checkedList);

	const ref = useOnclickOutside(() => {
		setOpenDropdown(false);
	});

	//------------------------- get list of all users -------------------------------//
	const [isloading, setIsLoading] = useState(false);

	const stableDispatch = useCallback(() => {
		setIsLoading(true);
		dispatch(
			usersList_admin({
				token: token,
				name: userName,
				categories: categoriesName,
				regulation: regulationName,
				page: userName || categoriesName || regulationName ? undefined : page,
			}),
		)
			.unwrap()
			.finally(() => setIsLoading(false));
	}, [dispatch, userName, categoriesName, regulationName, page, token]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [stableDispatch, token]);

	// --------------- clone the list of users into the new array ---------------//
	useStableArr(list, checkedList, setNewArr, setIsCheck);

	const handleBlock = (e, id) => {
		e.preventDefault();

		const data = {
			is_active: false,
		};
		dispatch(userBlocked({ token: token, id: id, data: data }))
			.unwrap()
			.then(() => {
				setOpenDropdown(false);
				toast.info('Utilsateur bloqué', {
					position: 'bottom-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				dispatch(
					usersList_admin({
						token: token,
						name: userName,
						categories: categoriesName,
						regulation: regulationName,
						page: page,
					}),
				);
			})
			.catch((err) => {
				toast.error(err, {
					position: 'bottom-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
	};

	const handleUnblock = (e, id) => {
		e.preventDefault();
		const data = {
			is_active: true,
		};
		dispatch(userBlocked({ token: token, id: id, data: data }))
			.unwrap()
			.then(() => {
				setOpenDropdown(false);
				toast.info('Utilsateur debloqué', {
					position: 'bottom-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				dispatch(
					usersList_admin({
						token: token,
						name: userName,
						categories: categoriesName,
						regulation: regulationName,
						page: page,
					}),
				);
			})
			.catch((err) => {
				toast.warn(err, {
					position: 'right-bottom',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
	};

	return (
		<div id='clientListPageAdmin'>
			<div className='navbar'>
				<div className='left'>
					<div className='nav-search'>
						<input
							type='text'
							placeholder='Rechercher ...'
							value={userName}
							onChange={(e) => {
								setUserName(e.target.value);
								setTyping(true);
							}}
						/>
						<icons.FiSearch className='searchicon' />
						{typing && (
							<icons.GrFormClose
								className='searchicon'
								onClick={() => {
									setUserName('');
									setTyping(false);
								}}
							/>
						)}
					</div>
					<div className='filters'>
						<select value={categoriesName} onChange={(e) => setCategoriesName(e.target.value)}>
							<option value=''>Catégories</option>
							<option value='grossiste'>Grossiste</option>
							<option value='detaillant'>Detaillant</option>
							<option value='monoprix'>Grande surface</option>
							{/* <option value='umd'>UMD</option>
							<option value='black'>Black</option> */}
						</select>
					</div>
					<div className='filters'>
						<select value={regulationName} onChange={(e) => setRegulationName(e.target.value)}>
							<option value=''>Régléments</option>
							<option value='cheque'>Chèque</option>
							<option value='espece'>Espèce</option>
							<option value='traite'>Traité</option>
						</select>
					</div>
				</div>
				<div className='right'>
					<div className='right-items'>
						<Link to={`/admin/users/add_user/${page}`}>
							<button className='button-primary'>
								<icons.SiAddthis />
								<span>Utilisateur</span>
							</button>
						</Link>
						<button className='button-nature' title='exporter utilisateur'>
							<CSVLink {...csvUsersReport} target='blank'>
								<icons.BiExport />
							</CSVLink>
						</button>
					</div>
				</div>
			</div>
			<div id='head'>
				<div className='title-user'>
					<h1>Liste d'utilisateurs</h1>
				</div>
			</div>
			<div className='usersContent'>
				<table id='users'>
					<thead>
						<tr id='titres'>
							<td className='input-check'>
								<input
									type='checkbox'
									className='checkbox'
									checked={isCheck}
									onChange={() => handleCheckAll(dispatch, newArr, setNewArr, isCheck, setIsCheck)}
								/>
							</td>
							<td>UTILISATEUR</td>
							<td>SOCIÉTÉ</td>
							<td>MATRICULE FISCALE</td>
							<td style={{ margin: 'auto' }}>CATÉGORIE</td>
							<td style={{ margin: 'auto' }}>REGLEMENT</td>
							<td style={{ margin: 'auto' }}>CHIFFRE D'AFFAIRE</td>
							<td style={{ margin: 'auto' }}>ACCOMPTE</td>
							<td style={{ margin: 'auto' }}>STATUT</td>
							<td>ACTION</td>
						</tr>
					</thead>
					<tbody>
						{isloading ? (
							<tr>
								<td colSpan='7'>
									<LoaderComponent margin='10%' />
								</td>
							</tr>
						) : (
							newArr.map((u, index) => (
								<tr id={`element-${index}`} className='user' key={u._id}>
									<td className='input-check'>
										<input
											type='checkbox'
											className='checkbox'
											checked={u.checked}
											onChange={() => handleCheck(dispatch, newArr, setNewArr, u._id)}
										/>
									</td>
									<td>
										{u.first_name} {u.last_name}
									</td>
									<td>{u.company_name ? u.company_name : '-'}</td>
									<td>{u.tax_registration_number}</td>
									<td style={{ margin: 'auto' }}>
										{u.categories && u.role === 'customer' ? convertCategory(u.categories) : '-'}
									</td>
									<td style={{ margin: 'auto' }}>
										{u.regulation && u.role === 'customer' ? convertRegulation(u.regulation) : '-'}
									</td>
									<td>{u.received_amount ? Number(u.received_amount).toFixed(2) : '-'}</td>
									<td style={{ margin: 'auto' }}>
										{u.received_amount && u.spent_amount
											? (Number(u.received_amount) - Number(u.spent_amount)).toFixed(2)
											: '-'}
									</td>
									<td
										style={{ margin: 'auto' }}
										className={u.is_active === true ? 'user-status' : 'user-status activé'}
									>
										{u.is_active === true ? 'Activé' : 'Bloqué'}
									</td>
									<td className='last-user-element'>
										<Link to={`/admin/users/user_details/${page}/${u._id}`} className='link-user'>
											<icons.BsEye className='see-user' />
										</Link>
										<div className='contain'>
											<icons.BsThreeDotsVertical
												onClick={() => {
													setOpenDropdown(!openDropdown);
													setIndice(u._id);
												}}
												className='user-option'
											/>
											{openDropdown && indice === u._id && (
												<div className='modalSelectOpt' ref={ref}>
													<ul>
														{u.is_active ? (
															<li
																onClick={(e) => {
																	handleBlock(e, u._id);
																}}
															>
																Bloqué utilisateur
															</li>
														) : (
															<li
																onClick={(e) => {
																	handleUnblock(e, u._id);
																}}
															>
																Débloquer utilisateur
															</li>
														)}
														<Link to={`/admin/users/update_user/${page}/${u._id}`}>
															<li>Modifier utilisateur</li>
														</Link>
													</ul>
												</div>
											)}
										</div>
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
			<Pagination page={page} setPage={setPage} pageLink={`/admin/users/`} total={total} />
		</div>
	);
};

export default ClientListPage;
