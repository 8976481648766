import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { icons } from '../../../assets/icons';
import './index.scss';
import { generateReferenceNumber, roundToThreeDecimals, typePrice } from '../../../helpers/modules';
import OrderConfirm from './OrderConfirm';
import OrderSuccessCom from './OrderSuccess';
import { useDispatch, useSelector } from 'react-redux';
import {
	addOrderByCommercial,
	checkStock,
	clearProductBasketByCommercial,
	deleteProductBasketByCommercial,
	getProductsBasketByCommercial,
	udpateStockProduitBasket,
	updateBasketTotalQty,
} from '../../../api/redux/slices/Commercial';
import DeleteConfirmationModal from '../../admin/addUser/ShopDeleteModal';
import { DataErrorModal, showToast } from '../../../components/modal';
import { calculateTotalTva } from '../../../utils/groupTvaValues';
import { ButtonComponent } from '../../../components/buttons';

const OrderCartCom = () => {
	const token = localStorage.getItem('accesstoken');
	const company = JSON.parse(localStorage.getItem('company'));
	const clientType = company?.categories;

	const [confirmOrderModal, setConfirmOrderModal] = useState(false);
	const [orderSuccess, setOrderSuccess] = useState(false);
	const [productsBasket, setProductsBasket] = useState([]);
	const { basketTotalQty } = useSelector((state) => state.commercial);
	const [oldBaksetTotalQty, setOldBasketTotalQty] = useState(basketTotalQty);

	const params = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [indexDeleteCart, setIndexDeleteCart] = useState(null);

	const [boutiqueId, setBoutiqueId] = useState('');

	const [errorData, setErrorData] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();

	const stableDispatch = useCallback(() => {
		setIsLoading(true);
		dispatch(getProductsBasketByCommercial({ token: token, id: company._id }))
			.unwrap()
			.then((res) => setProductsBasket(res.data))
			.finally(() => setIsLoading(false));
	}, [dispatch, token, company._id]);

	useEffect(() => {
		if (token) {
			stableDispatch();
		}
	}, [token, stableDispatch]);

	const handleQuantityChange = (product, e) => {
		if (Number(e.target.value) <= 0) {
			dispatch(updateBasketTotalQty(basketTotalQty - parseInt(product.quantity)));
			setProductsBasket((prevProducts) =>
				prevProducts.map((item) => (item._id === product._id ? { ...item, quantity: 0 } : item)),
			);
			return;
		}
		dispatch(
			updateBasketTotalQty(basketTotalQty + parseInt(e.target.value) - parseInt(product.quantity)),
		);
		setProductsBasket((prevProducts) =>
			prevProducts.map((item) =>
				item._id === product._id ? { ...item, quantity: e.target.value } : item,
			),
		);
	};

	const totalHT = () => {
		let total = 0;
		productsBasket?.forEach((el) => {
			let tmp = total + el.quantity * el?.product?.[typePrice(clientType)];
			total = tmp * (1 - (el.remise ?? 0) * 0.01);
		});
		return roundToThreeDecimals(total);
	};

	const handleDeleteCart = () => {
		dispatch(deleteProductBasketByCommercial({ token: token, id: indexDeleteCart }))
			.unwrap()
			.then(() => {
				showToast('Produit supprimé du panier', 'info');
				stableDispatch();
			});
		setOpenDeleteModal(false);
	};

	const totalTCC = () => {
		const list = productsBasket.map((el) => {
			return {
				tva: el?.product?.Tva,
				prise_product_HT: el?.product?.[typePrice(clientType)],
				quantity: el?.quantity,
			};
		});

		const totalTvaSum = calculateTotalTva({ list }).reduce(
			(acc, { totalAmount }) => acc + totalAmount,
			0,
		);

		const totalHTAmount = totalHT();
		const timbre = 1;

		const totalTCC = Number(totalTvaSum) + Number(totalHTAmount) + parseInt(timbre);
		return roundToThreeDecimals(Number(totalTCC));
	};

	const handleOrder = () => {
		let canOrder = true;
		const cartProducts = productsBasket.map((el) => {
			const data = {
				product_id: el.product._id,
				quantity: el.quantity,
			};
			if (data.quantity <= 0 || isNaN(data.quantity)) {
				showToast('La quantité doit être supérieure à 0.', 'warn', 'top-center', 1000);
				canOrder = false;
			}
			return data;
		});

		if (!canOrder) return;
		setIsLoading(true);
		dispatch(checkStock({ token, list: cartProducts }))
			.unwrap()
			.then((res) => {
				if (res.data.length > 0) {
					setIsOpen(true);
					const errData = res.data.map((item) => ({
						Designation: item.designation,
						error:
							item.stock > 0
								? `Stock insuffisant pour ce produit maximum disponible : ${item.stock}`
								: 'Stock insuffisant pour ce produit',
					}));
					setErrorData(errData);
				} else {
					setConfirmOrderModal(!confirmOrderModal);
				}
			})
			.catch((error) => {
				showToast(error.data, 'error');
			})
			.finally(() => setIsLoading(false));
	};

	const handleAddOrder = () => {
		const list = productsBasket.map((item) => {
			const prise_product_HT = item?.product?.[typePrice(clientType)];
			const Amount_excluding_tax = prise_product_HT * (1 + (item?.product?.Tva || 0) / 100);

			return {
				product_id: item.product._id,
				quantity: item.quantity,
				remise: item.remise ?? 0,
				prise_product_HT: prise_product_HT,
				Amount_excluding_tax: Amount_excluding_tax,
			};
		});
		const data = {
			list,
			user_id: company._id,
			shop_id: boutiqueId,
			date: new Date().toISOString(),
			total: totalTCC(),
			total_HT: totalHT(),
			number: generateReferenceNumber(),
			tva: 19,
			timbre: 1,
		};
		setIsLoading(true);
		dispatch(addOrderByCommercial({ token: token, data: data }))
			.unwrap()
			.then((res) => {
				dispatch(clearProductBasketByCommercial({ token: token, id: company._id }));
				navigate('/commercial/orders/1');
			})
			.catch((err) => {
				if (err?.message === 'Ces produits ne sont pas disponibles.')
					showToast('Il y a un produit qui est désactivé', 'error');
				else showToast(err?.message || err, 'error');
			})
			.finally(() => {
				setIsLoading(false);
				setConfirmOrderModal(false);
			});
	};

	const handleChangebasket = async () => {
		const data = productsBasket.map((product) => {
			const data = {
				quantity: Number(product.quantity),
				total: roundToThreeDecimals(product.quantity * product.product?.[typePrice(clientType)]),
			};
			return dispatch(udpateStockProduitBasket({ token, id: product._id, data }));
		});

		await Promise.all(data).then(() => {
			showToast('Produits mis à jour avec succès', 'success', 'top-center', 1000);
			setOldBasketTotalQty(basketTotalQty);
		});
	};
	return (
		<div id='orderCart'>
			{openDeleteModal && (
				<DeleteConfirmationModal
					onDelete={handleDeleteCart}
					onCancel={() => setOpenDeleteModal(false)}
				/>
			)}
			{isOpen && (
				<DataErrorModal isOpen={isOpen} closeModal={() => setIsOpen(false)} errorData={errorData} />
			)}
			<div className='navbar'>
				<div className='left'>
					<div className='link-return' style={{ width: 'max-content' }}>
						<Link to={`/commercial/orders/order_market/${params.page}`}>
							<icons.IoIosArrowBack className='arrow' />
							<h4>Continuer mes achats</h4>
						</Link>
					</div>
				</div>
				<div className='right' style={{ cursor: 'pointer' }}>
					<div className='cart'>
						<icons.RiShoppingCartLine className='cart-icon' />
						<div className='cart-count'>
							<span>{basketTotalQty}</span>
						</div>
					</div>
					<h3>Panier</h3>
				</div>
			</div>
			<Fragment>
				<OrderConfirm
					setConfirmOrderModal={setConfirmOrderModal}
					confirmOrderModal={confirmOrderModal}
					handleAddOrder={handleAddOrder}
					loading={isLoading}
				/>
			</Fragment>
			<Fragment>
				<OrderSuccessCom orderSuccess={orderSuccess} setOrderSuccess={setOrderSuccess} />
			</Fragment>
			<div className='content'>
				<div className='box'>
					<h1>Panier</h1>
					<button
						disabled={basketTotalQty === oldBaksetTotalQty}
						className={basketTotalQty !== oldBaksetTotalQty ? 'active' : ''}
						onClick={handleChangebasket}
					>
						Actualiser mon panier
					</button>
				</div>
				<div className='cart-section'>
					<div className='purchases'>
						{productsBasket?.map((product) => (
							<div className='purchase' key={product?._id}>
								<div className='left'>
									<div className='element'>
										<div className='element-img'>
											<img
												src={
													product?.product?.image.secure_url ||
													process.env.REACT_APP_IMAGE_PLACEHOLDER_URL
												}
												alt='product_image'
											/>
										</div>
										<div className='element-desc'>
											<h4>{product?.product?.designation}</h4>
											<span className='price'>{product?.product?.[typePrice(clientType)]} TND</span>
										</div>
									</div>
								</div>
								<div className='right'>
									<div className='confirmQTY'>
										<div className='qty'>
											<input
												type='number'
												value={parseInt(product?.quantity, 10).toString()}
												onChange={(e) => handleQuantityChange(product, e)}
												onWheel={(e) => e.currentTarget.blur()}
											/>
										</div>
									</div>
									<div id='qty-price'>
										<span>
											{roundToThreeDecimals(
												product?.product?.[typePrice(clientType)] * product?.quantity,
											)}{' '}
											TND
										</span>
									</div>
									<div className='del'>
										<icons.FaTrashAlt
											className='trash'
											onClick={() => {
												setOpenDeleteModal(true);
												setIndexDeleteCart(product?._id);
											}}
										/>
									</div>
								</div>
							</div>
						))}
					</div>
					<div className='count'>
						<div className='count-header'>
							<label>Choisissez une boutique</label>
							<div id='shop-select'>
								<select onChange={(e) => setBoutiqueId(e.target.value)}>
									<option value=''>Choisir une boutique</option>
									{company?.shop?.map((boutique, index) => (
										<option key={index} value={boutique._id}>
											{boutique.name} - {boutique.ville}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className='top'>
							<ul>
								<li id='number'>
									<span>{basketTotalQty}</span>
									{basketTotalQty === 1 ? 'ARTICLE' : 'ARTICLES'}
								</li>
								<li>
									<span>{totalHT()} TND</span>
								</li>
							</ul>
						</div>
						<div className='bottom'>
							<ButtonComponent
								onClick={() => {
									if (!boutiqueId) {
										showToast('Veuillez choisir une boutique', 'warn');
										return;
									}
									handleOrder();
								}}
								className={'button-primary'}
								name={'Commander'}
								loading={isLoading}
								disabled={!productsBasket?.length}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderCartCom;
